
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import NewReleasesCard from '@/app/components/book/card/NewReleasesCard.v4.vue'
import ListingBlock from '@/app/components/listing/ListingBlock.vue'

import { BooksView } from '@/models/book'

@Options({
  components: {
    NewReleasesCard,
    ListingBlock,
  },
})
export default class FreeBooksBlock extends Vue {
  @Prop() private view!: BooksView
  @Prop() private viewAllLink!: string

  // Hide third card on the desktop
  thirdCard(index: number): string {
    if (index === 2) {
      return 'releases-block__item--hide-desktop'
    }
    return ''
  }
}
