
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import DocLayout from '@/app/components/doc/Layout.vue'
import BookHero from '@/app/components/doc/highlights/Hero.vue'
import Highlight from '@/app/components/doc/highlights/Highlight.vue'
import AppButton from '@/app/components/button/AppButton.vue'

import { backend } from '@/services/backend'
import { AnnotationView } from '@/models/doc'
import { DocView } from '@/models/doc'

@Options({
  components: {
    DocLayout,
    BookHero,
    Highlight,
    AppButton,
  },
})
export default class BookHighlights extends Vue {
  @Prop() private doc!: DocView
  @Prop({ default: 0 }) private limit: number = 0
  @Prop() private hideButtonOnMobile: boolean = false

  private highlightEditId: string = ''

  get highlightsQty(): number {
    if (this.limit === 0) {
      return this.doc.highlights.length
    }
    return this.limit
  }

  toggleEdit(highlight: AnnotationView): void {
    if (this.highlightEditId) {
      this.highlightEditId = ''
    } else {
      this.highlightEditId = highlight.id
      // Focus input field
      // Vue.nextTick(function() {
      //     ev.$$.input.focus();
      // })
    }
  }

  saveEdit(highlight: AnnotationView): void {
    // Save your changes.
    backend.updateHighlightNote(highlight)
    this.toggleEdit(highlight)
  }

  get readingButton(): any {
    return this.doc.startReadingButton()
  }

  get hideButtonClass(): string {
    return this.hideButtonOnMobile
      ? 'highlights__read-button--hide-on-mobile'
      : ''
  }
}
