import { v4 as uuidv4 } from 'uuid'
import { Annotation, AnnotationContent } from '@/models/interfaces'
import { ContentView } from '@/models/doc'
import { backend } from './backend'
import { storage } from '@/helpers/storage'

enum OfflineStateFlag {
  New, // Annotation has been created offline
  Updated, // An annotation that already existed has been edited offline
  Deleted, // An annotation that already existed has been deleted offline
}

// What actually gets stored in localStorage when the user makes a
// highlight while offline
interface OfflineAnnotationWrapper {
  content: AnnotationContent
  annotation: Annotation
  offlineStateFlag: OfflineStateFlag
}

// Name in localStorage
const storageKey = 'offline_annotations'

// We store a map of Temporary annotation ids to wrapped annotations
type OfflineStore = Map<string, OfflineAnnotationWrapper>

function mapToJson(map: Map<any, any>): string {
  return JSON.stringify(Array.from(map.entries()))
}

function jsonToMap(json: string): Map<any, any> {
  return new Map(JSON.parse(json))
}

// Note that there is a possible, but probably very rare race condition
// between different browser tabs doing getStore and setStore.
// We don't have a lock on the store, but we try to mitigate it by keeping the
// computation short between setStore() and any getStore() which it depends on.
// We are unlikely to be able to demonstrate the race condition even if we
// tried, because as far as this module is concerned, localStorage updates
// are always triggered manually by human user actions, and it's unlikely
// that anyone could switch tabs faster than javascript updates the store.

// Return the list annotations of that where edited offline from localeStorate
function getStore(): OfflineStore {
  const maybeStore = storage.getItem(storageKey)
  return maybeStore === null ? new Map() : jsonToMap(maybeStore)
}

// Update (the entire) store in localStorage
function setStore(store: OfflineStore): void {
  storage.setItem(storageKey, mapToJson(store))
}

export class OfflineAnnotations {
  // Note:
  //
  // window.online even is imperfect
  //
  //   - safari and chrome react to any network, regardless of internet
  //      connectivity
  //   - in development, we might want to be "offline" by taking down the
  //      backend, which is on localhost
  //   - if the production backend where to be down for any reason, that
  //      may as well be treated as "offline" for our purposes
  //
  // But it is still better than nothing, as it gives us a good time to
  // at least try and see if "waking up" works right now.
  //
  // So to deal with all this, we do check if we are back online on
  // the window.online event. However we also need to:
  //
  //   - Check for network failures (not all backend errors!) when coming back
  //      online, and then assume that we are not actually back online yet
  //   - Periodically (setTimeout) check the same thing, just in case
  //
  // We don't bother to try and accurately track "current onlineness state",
  // instead, the test for whether or not we need to keep doing all this is
  // simply whether or not we still have any offline annotations stored that
  // have not yet been either synced to the backend, or failed to sync for non-
  // network related reasons.
  checkOnlineTimeoutId?: number
  contentView: ContentView
  listeningForOnlineness: boolean

  constructor(contentView: ContentView) {
    this.contentView = contentView
    this.checkOnlineTimeoutId = undefined
    this.listeningForOnlineness = false
  }

  listenForOnlineness(): void {
    if (this.listeningForOnlineness) {
      // Don't setup timers twice
      return
    }
    const restartTimer = (): void => {
      clearTimeout(this.checkOnlineTimeoutId)
      const oneMinute = 60 * 1000
      this.checkOnlineTimeoutId = setTimeout(onTimedOut, oneMinute)
    }
    const onTimedOut = (): void => {
      // Timed out waiting for online event, so try to check if maybe we
      // are already online and just didn't get an event.
      const doneForNow = this.handleMaybeBackOnline()
      if (!doneForNow) {
        restartTimer()
      }
    }
    window.addEventListener('online', this.handleMaybeBackOnline)
    restartTimer()
    this.listeningForOnlineness = true
  }

  stopListeningForOnlineness(): void {
    window.removeEventListener('online', this.handleMaybeBackOnline)
    clearTimeout(this.checkOnlineTimeoutId)
    this.checkOnlineTimeoutId = undefined
    this.listeningForOnlineness = false
  }

  handleMaybeBackOnline = async (): Promise<boolean> => {
    // Try sending offline annotation changes to the backend one by one.
    //
    // Return `true` if everything has been sync'd, and there are no more
    // offline changes pending in localStorage, otherwise return `false`
    //
    // If we can't contact the backend due to what appears to be network
    // problems, assume that we are actually still offline, and stop trying,
    // for the moment, but setup the timer try again later.
    //
    // If we can talk to the backend, remove the annotation from the offline
    // store, regardless of whether the backend actually accepted it. Errors
    // from the backend are just swallowed, as it doesn't make sense to
    // asyncronously (i.e., out of nowhere) present sync issues to the user.
    //
    // In terms of users working on multiple devices, there may be theoretical
    // race conditions or differences in syncronisation results depending on
    // what order we present the updates to the backend. But that issue is
    // currently ignored, and the storage is setup in such a way that it
    // stores the full offline state (not ordered deltas), so for the moment
    // its ok to just iterate the store in whatever order JavaScript gives us.
    // That happens to be insertion-order for `Map`, which makes sense anyway.

    const store = getStore()
    for (const [annotationId, wrappedAnnotation] of store.entries()) {
      try {
        await this.syncOneAnnotation(wrappedAnnotation)
      } catch (e: any) {
        if (e.code === 'ERR_NETWORK' || e.code === 'ECONNABORTED') {
          // Still offline, or back offline, come back later.
          setStore(store)
          return false
        }
        // Backend doesn't like our annotation. nothing we can do, skip it..
        const msg = `Failed to sync an offline annotation: ${e}`
        console.warn(msg)
      }
      store.delete(annotationId)
    }
    if (store.size < 1) {
      // Nothing left to sync, no reason to keep waking up
      this.stopListeningForOnlineness()
    }
    setStore(store)
    return store.size < 1
  }

  async syncOneAnnotation(wrapped: OfflineAnnotationWrapper): Promise<void> {
    switch (wrapped.offlineStateFlag) {
      case OfflineStateFlag.New:
        await this.syncNew(wrapped.content, wrapped.annotation)
        break
      case OfflineStateFlag.Updated:
        await this.syncUpdate(wrapped.content, wrapped.annotation)
        break
      case OfflineStateFlag.Deleted:
        await this.syncDeletion(wrapped.content, wrapped.annotation)
        break
    }
  }

  async syncNew(
    content: AnnotationContent,
    annotation: Annotation,
  ): Promise<void> {
    // Create for real on the backend an annotation which was previously
    // "created" offline on the frontend, and then remap the temporary
    // annotation id to the new id provided by the backend
    const temporaryId = annotation.id
    annotation.id = ''
    const result = await backend.createHighlight(content, annotation)
    for (const displayedAnnotation of this.contentView.highlights) {
      if (displayedAnnotation.id === temporaryId) {
        displayedAnnotation.id = result.id
        break
      }
    }
  }

  async syncUpdate(
    content: AnnotationContent,
    annotation: Annotation,
  ): Promise<void> {
    // Edit the annotation on the backend to match the offline state
    await backend.updateHighlight(content, annotation)
  }

  async syncDeletion(
    content: AnnotationContent,
    annotation: Annotation,
  ): Promise<void> {
    // Delete pre-existing annotation from backend.
    await backend.deleteHighlight(content, annotation)
  }

  // New annotation created offline.
  //
  // - Create a temporary (frontend only) id for the annotation
  // - Mark the annotation as "new"
  // - Add it to localStorage
  // - start listening for online event
  create(content: AnnotationContent, annotation: Annotation): Annotation {
    const result: Annotation = Object.assign({}, annotation)
    result.id = uuidv4()
    const wrapped: OfflineAnnotationWrapper = {
      annotation: result,
      content: content,
      offlineStateFlag: OfflineStateFlag.New,
    }
    const store = getStore()
    store.set(result.id, wrapped)
    setStore(store)
    this.listenForOnlineness()
    return result
  }

  // Annotation edited while offline
  //
  // If it is already in the store:
  //   - We know that this annotation does not exist on the backend
  //   - Edit the state in localStorage
  //   - Do not adjust the state flag
  //     - If it was marked new, it is still new to the backend after editing
  //     - If it was marked updated, it is still edited after editing twice
  //     - It is not possible in the UI to edited a deleted annotation
  // Otherwise:
  //   - Mark the annotation as "updated"
  //   - Add it to localStorage
  // - start listening for online event
  update(content: AnnotationContent, annotation: Annotation): Annotation {
    const store = getStore()
    const existing = store.get(annotation.id)
    if (existing !== undefined) {
      existing.annotation = annotation
      store.set(annotation.id, existing)
      setStore(store)
      this.listenForOnlineness()
      return annotation
    } else {
      const result = Object.assign({}, annotation)
      const wrapped: OfflineAnnotationWrapper = {
        annotation: result,
        content: content,
        offlineStateFlag: OfflineStateFlag.Updated,
      }
      store.set(annotation.id, wrapped)
      setStore(store)
      this.listenForOnlineness()
      return result
    }
  }

  // Annotation deleted while offline
  //
  // If it is already in the store, and marked new:
  //   - We know that it does not exist on the backend
  //   - Simply remove it from the store, no longer to be created later
  // Otherwise:
  //   - It's something that needs to be removed from the backend later
  //   - Mark it deleted.
  //   - Add it to the store if it's not already there
  // - start listening for online event
  delete(content: AnnotationContent, annotation: Annotation): Annotation {
    const store = getStore()
    const existing = store.get(annotation.id)
    if (
      existing !== undefined &&
      existing.offlineStateFlag === OfflineStateFlag.New
    ) {
      store.delete(annotation.id)
      setStore(store)
      this.listenForOnlineness()
      return annotation
    } else {
      let toDelete
      if (existing !== undefined) {
        existing.offlineStateFlag = OfflineStateFlag.Deleted
        toDelete = existing
      } else {
        toDelete = {
          annotation: annotation,
          content: content,
          offlineStateFlag: OfflineStateFlag.Deleted,
        }
      }
      store.set(annotation.id, toDelete)
      setStore(store)
      this.listenForOnlineness()
      return annotation
    }
  }
}
