
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import HighlightsList from '@/app/components/doc/highlights/HighlightsList.vue'
import ExportHighlightsButton from '@/app/components/book/highlights/ExportHighlightsButton.vue'

import { DocView } from '@/models/doc'

@Options({
  components: {
    HighlightsList,
    ExportHighlightsButton,
  },
})
export default class DocHighlights extends Vue {
  @Prop() private doc!: DocView

  get hasHighlights(): boolean {
    return this.doc.highlights.length > 0
  }
}
