
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { platform } from '@/services/platform'
import { apiURL } from '@/services/request'
import { BookView } from '@/models/book'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {},
})
export default class BookPdfDownloadLink extends Vue {
  @Prop() private book!: BookView

  private isAndroid: boolean = false
  private showSpinner: boolean = false

  spinnerDelay: number = 4000

  async beforeMount(): Promise<void> {
    // The `download` link property does not work on Android (just nothing happens
    // when clicking the link, no error and no download).
    // So we are rendering the link without that property.
    // In other cases, the property is usefl and browsers handle the file download
    // in a better way.
    //
    // Note: the problem with the `download` property is not generic, it is also related
    // to the app url, as download worked for me when I set app URL to local,
    // like http://192.168.88.254:8080/app and the pdf URL starts with
    // http://192.168.88.254:5000/api in this case.
    // While on dev URLs are https://dev.shortform.com/app and https://dev.shortform.com/api
    // and the download does not work.
    this.isAndroid = await platform.isNativeAndroidApp()
  }

  async registerDocExport(): Promise<void> {
    this.book.registerDocExport()
    // We put direct links back, so software download is not needed.
    // await platform.openUrl(this.downloadUrl)
    this.showSpinner = true
    setTimeout(() => (this.showSpinner = false), this.spinnerDelay)
  }

  get downloadName(): string {
    // We replace `/` with `-` in the file name as the server interprets the `/` as a URL
    // path separator and we end up with a 404 error.
    //
    // We can safely do the replacement here: the title can actually be anything and is used
    // for a local file name when the browser saves the PDF.
    // Our backend uses the `token` parameter in the download URL to identify the file.
    const downloadTitle = this.book.title.replace('/', '-')
    return `Shortform Summary - ${downloadTitle}.pdf`
  }

  get downloadUrl(): string {
    const token = assertNotNull(this.book.doc_export_stats).token
    const title = encodeURIComponent(this.downloadName)
    return apiURL + `pdfbooks/${title}?token=${token}`
  }
}
