
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import IconButton from '@/app/components/button/IconButton.vue'

import { DocView, docShareFacebook, docShareTwitter } from '@/models/doc'

import { clickOutside } from '@/helpers/directives'

@Options({
  directives: {
    clickOutside,
  },
  components: {
    IconButton,
  },
})
export default class ShareMenu extends Vue {
  @Prop() doc!: DocView

  shareOnFacebook = docShareFacebook
  shareOnTwitter = docShareTwitter

  isMenuVisible: boolean = false

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible
  }

  hideMenu(): void {
    this.isMenuVisible = false
  }
}
