
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'
import QuestionnaireLoading from './Loading.vue'
import QuestionnaireLikeBar from '@/app/components/questionnaire/LikeBar.vue'
import BookCardThumbnail from '@/app/components/book/card/BookCardThumbnail.vue'
import { scrollIntoView, scrollElement } from '@/helpers/dom'

@Options({
  components: { QuestionnaireLoading, QuestionnaireLikeBar, BookCardThumbnail },
})
export default class QuestionnaireBook extends Vue {
  @Prop() view!: QuestionnaireView

  async updated(): Promise<void> {
    if (this.view.loading) {
      // The 'updated' can be invoked while the page is in the "loading" state
      // ("Finding your recommendations..."), skip scroll attempt in this case.
      return
    }
    // Scroll the wrapper to show the book title on tablet/mobile width
    scrollIntoView('.onboarding-small__title')
    // Scroll the book text to the top on desktop width
    scrollElement('.onboarding-book__text')
  }
}
