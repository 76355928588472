import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import {
  Book,
  BookListItem,
  ApiResultBooks,
  ApiResult,
} from '@/models/interfaces'
import write_blob from 'capacitor-blob-writer'
import { Sentry } from '@/services/sentry'
import { platform } from '@/services/platform'
import { Capacitor } from '@capacitor/core'
import * as TypedAssert from 'typed-assert'
import { backend } from '@/services/backend'
import { BOOKS_CACHE, AUDIO_CACHE, BOOK_CACHE } from '@/helpers/cache'
import { BACKEND_URL } from '@/init/settings'

// Note: Normally we could just assign this with `Directory.Library`. But that leads
// to errors in tests as `Directory` seems to be undefined there. Casting works
// around this issue.
const directory = 'LIBRARY' as Directory.Library
const utf8Encoding = 'utf8' as Encoding.UTF8

/**
 * Download a book to native storage. Only supported in native apps.
 *
 * - Downloads the book's audio file and stores it as a base64 string
 * - Stores the book's detail data (response from e.g. /api/books/atomic-habits) as
 * stringified JSON
 * - Stores the whole book list (response from /api/books/) as stringified JSON
 *
 * Does nothing if the current platform is not a native app or if the given book has
 * no audio defined.
 */
export async function downloadBook(book: Book): Promise<void> {
  if (!book.audio || !(await isNativeStorageSupported())) {
    return
  }

  try {
    await downloadAudio(book)
    await downloadBookDetail(book.url_slug)
    await downloadBookList()
  } catch (error) {
    Sentry.captureException(error)
    throw error
  }
}

/**
 * Requests data for the book with the given `urlSlug` from the backend
 * and stores it in native storage as stringified JSON
 *
 * @param urlSlug The book's URL slug, e.g. `atomic-habits`
 */
async function downloadBookDetail(urlSlug: string): Promise<void> {
  const book: ApiResult<Book> = { data: await backend.getBook(urlSlug) }
  await writeStringToNativeStorage(
    JSON.stringify(book),
    bookDetailFilePath(urlSlug),
  )
}

/**
 * Requests the book list from the backend (i.e. /api/books/)
 * and stores it in native storage as stringified JSON
 */
async function downloadBookList(): Promise<void> {
  const books: ApiResultBooks = await backend.getBooks()
  await writeStringToNativeStorage(JSON.stringify(books), bookListFilePath())
}

/**
 * Write a UTF-8 encoded string to native storage. If a file at the given `path`
 * already exists, it gets overwritten.
 * @param data The data to write to native storage.
 * @param path The path of the target file.
 */
async function writeStringToNativeStorage(
  data: string,
  path: string,
): Promise<void> {
  await Filesystem.writeFile({
    path,
    directory,
    data,
    recursive: true,
    encoding: utf8Encoding,
  })
}

/**
 * Download the book's audio file and store it native storage as a base64 encoded string.
 */
async function downloadAudio(book: Book): Promise<void> {
  // We need to use two separate Capacitor/Cordova plugins for iOS and Android
  // to do the download, as neither plugin works on both platforms.
  if (await platform.isNativeAndroidApp()) {
    await downloadAudioAndroid(book)
  } else if (await platform.isNativeIOSApp()) {
    await downloadAudioIos(book)
  }
}

/**
 * Load the audio file for the given book from mobile app native storage into
 * the browser cache. If there is already an entry for the book's audio in the
 * browser cache or if no file for the audio exists in native storage, nothing happens.
 *
 * Does nothing if the current platform is not a native app or if the given book has
 * no audio defined.
 *
 * When loading data into the browser cache, a `X-Download: 'true'` header is attached
 * to the cache entry. This way it can be identified that a cache entry was created
 * from a native storage downloaded book.
 *
 * @returns `Promise(true)` if the audio item was loaded from native storage into
 *  browser cache now, or previously and was still present in cache.
 *  Returns `Promise(false)` otherwise, i.e. if the book has no audio, the audio was
 *  not loaded from native storage into cache, or if the audio was already in cache
 *  but was not populated by native storage.
 */
export async function loadAudioFromNativeStorage(book: Book): Promise<boolean> {
  if (!book.audio || !(await isNativeStorageSupported())) {
    return false
  }

  try {
    const cache: Cache = await window.caches.open(AUDIO_CACHE)
    const match = await cache.match(book.audio.url)
    if (match) {
      return match.headers.get('X-Download') === 'true'
    }

    const file = await Filesystem.readFile({
      path: audioFilePath(book),
      directory,
    })
    const blob = await base64ToBlob(file.data, 'binary/octet-stream')
    const resp = new Response(blob, {
      status: 200,
      headers: { 'Accept-Ranges': 'bytes', 'X-Download': 'true' },
    })

    await cache.put(book.audio.url, resp)
    return true
  } catch (error) {
    if (!isExpectedStorageError(error)) {
      Sentry.captureException(error)
    }
    return false
  }
}

/**
 * Load detail data for the given book from mobile app native storage into
 * the browser cache. This is the data for requests like e.g.
 * GET `/api/books/atomic-habits`
 *
 * Does nothing if the current platform is not a native app, data is not
 * downloaded, or there is already a cache entry for the book's URL.
 *
 * If there is already a cache entry for the book's URL, it is not overwritten as we
 * expect the browser cache to have the most recent data.
 */
export async function loadBookDetailFromNativeStorage(
  urlSlug: string,
): Promise<void> {
  await loadStringFromNativeStorageIntoCache(
    bookDetailFilePath(urlSlug),
    BOOK_CACHE,
    `${BACKEND_URL}/api/books/${urlSlug}`,
  )
}

/**
 * Load book list data from mobile app native storage into
 * the browser cache. This is the data for requests the request
 * GET `/api/books/`.
 *
 * Does nothing if the current platform is not a native app, data is not
 * downloaded, or there is already a cache entry for `/api/books/`.
 *
 * If there is already a cache entry for `/api/books/`, it is not overwritten as we
 * expect the browser cache to have the most recent data.
 */
export async function loadBookListFromNativeStorage(): Promise<void> {
  await loadStringFromNativeStorageIntoCache(
    bookListFilePath(),
    BOOKS_CACHE,
    BACKEND_URL + '/api/books/',
  )
}

/**
 * Load a UTF-8 encoded string from native storage into the browser cache.
 *
 * If there is already an entry in the browser cache for the given URL
 * then nothing is done - we expect the browser cache to have the most recent version of
 * the data, so we do not override it with native storage data.
 *
 * @param path The path of the file to read.
 * @param cacheName The name of the browser cache.
 * @param cacheUrl The URL to use as the cache key.
 */
async function loadStringFromNativeStorageIntoCache(
  path: string,
  cacheName: string,
  cacheUrl: string,
): Promise<void> {
  if (!(await isNativeStorageSupported())) {
    return
  }

  try {
    const cache: Cache = await window.caches.open(cacheName)
    if (await cache.match(cacheUrl)) {
      return
    }

    const file = await Filesystem.readFile({
      path,
      directory,
      encoding: utf8Encoding,
    })
    const resp = new Response(file.data, {
      status: 200,
      headers: { 'Content-Type': 'application/json' },
    })

    await cache.put(cacheUrl, resp)
  } catch (error) {
    if (!isExpectedStorageError(error)) {
      Sentry.captureException(error)
    }
  }
}

/**
 * From https://stackoverflow.com/a/16245768
 */
function base64ToBlob(b64Data: string, contentType: string): Blob {
  const sliceSize = 512
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

function audioFilePath(book: Book): string {
  return `audio/${book.id}`
}

function bookDetailFilePath(urlSug: string): string {
  return `book/${urlSug}`
}

function bookListFilePath(): string {
  return 'books'
}

/**
 * Deletes the audio and book detail download of a given book. Nothing happens
 * if downloads already don't exist.
 * @param book
 */
export async function deleteDownload(book: Book): Promise<void> {
  if (await platform.isNativeApp()) {
    try {
      await Filesystem.deleteFile({ path: audioFilePath(book), directory })
      await Filesystem.deleteFile({
        path: bookDetailFilePath(book.url_slug),
        directory,
      })
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

/**
 * Check if persistent audio downloads are supported.
 *
 * True for iOS if native storage capacitor plugins are available (i.e. after a certain
 * app version).
 *
 * True for Android if native storage capacitor plugins are available and
 * `offline_audio_android_6414` feature flag is set to 'yes'.
 *
 * False otherwise.
 */
export async function isNativeStorageSupported(): Promise<boolean> {
  if (await platform.isNativeIOSApp()) {
    return Capacitor.isPluginAvailable('Filesystem') && 'FileTransfer' in window
  }
  if (await platform.isNativeAndroidApp()) {
    return (
      Capacitor.isPluginAvailable('BlobWriter') &&
      Capacitor.isPluginAvailable('Filesystem')
    )
  }
  return false
}

/**
 * Check if the audio of the given book is downloaded to persistent storage.
 */
export async function isAudioDownloaded(book: Book): Promise<boolean> {
  if (!book.audio || !(await isNativeStorageSupported())) {
    return false
  }

  try {
    await Filesystem.stat({ path: audioFilePath(book), directory })
    return true
  } catch (error) {
    if (!isExpectedStorageError(error)) {
      Sentry.captureException(error)
    }
    return false
  }
}

/**
 * Filters the given list of books for books that have their audio downloaded to the device.
 */
export async function getDownloadedBooks(
  books: BookListItem[],
): Promise<BookListItem[]> {
  const dirEntries = await getAudioDownloadDirectoryEntries()
  return books.filter((book) => dirEntries.has(book.id))
}

async function getAudioDownloadDirectoryEntries(): Promise<Set<string>> {
  if (!(await isNativeStorageSupported())) {
    return new Set()
  }

  try {
    const res = await Filesystem.readdir({ path: 'audio', directory })
    return new Set(res.files.map((f) => f.name))
  } catch (error) {
    if (!isExpectedStorageError(error)) {
      Sentry.captureException(error)
    }
    return new Set()
  }
}

/**
 * Download the audio of the given book and store it to persistent storage - implementation for iOS.
 * The download is done using `cordova-plugin-file-transfer`, this plugin works only on iOS.
 */
async function downloadAudioIos(book: Book): Promise<void> {
  await Filesystem.mkdir({ path: 'audio', directory, recursive: true })
  const targetPath = await Filesystem.getUri({
    directory,
    path: audioFilePath(book),
  })
  return new Promise((resolve, reject) => {
    TypedAssert.isNotUndefined(book.audio)
    const fileTransfer = new FileTransfer()
    const trustAllHosts = false
    fileTransfer.download(
      book.audio.url,
      targetPath.uri,
      resolve,
      reject,
      trustAllHosts,
      {},
    )
  })
}

/**
 * Download the audio of the given book and store it to persistent storage - implementation for Android.
 * The download is with fetch and then stored to native storage with the plugin `capacitor-blob-writer`,
 * this plugin works only on Android.
 */
async function downloadAudioAndroid(book: Book): Promise<void> {
  TypedAssert.isNotUndefined(book.audio)
  const audioBlob = await fetch(book.audio.url).then((response) =>
    response.blob(),
  )
  // For explanations of the options see
  // https://github.com/diachedelic/capacitor-blob-writer/blob/19ccc04e9f674b4f147ee477bff98b27214d954a/README.md
  await write_blob({
    path: audioFilePath(book),
    directory,
    blob: audioBlob,
    fast_mode: false,
    recursive: true,
    on_fallback(error) {
      Sentry.captureMessage(
        `Using fallback method for native audio storage. Error: ${error}`,
      )
    },
  })
}

/**
 * Check if the given error is an expected error. Returns `true` if the error is
 * expected and `false` otherwise.
 *
 * Expected errors are:
 * - Errors with a `error.message` field that includes the substring `'there is no such file'`
 */
function isExpectedStorageError(error: any): boolean {
  return (
    error?.message === 'File does not exist' ||
    error?.message === 'Directory does not exist' ||
    error?.message?.includes('there is no such file')
  )
}

/**
 * Returns a URL that can be used to play the audio of a downloaded book locally from
 * the device. Works only on Android.
 *
 * The url is of format
 * `${window.location.origin}/_capacitor_file_/${path_of_audio_file_on_device}`.
 * E.g. `https://www.shortform.com/_capacitor_file_/data/user/0/com.shortform.app/files/audio/5957010e-68f3-41b4-8ffa-ce0093858cd9`
 *
 * This format causes Capacitor to intercept the request and serve the local file from
 * the device, instead of the request going to the actual origin (e.g. https://www.shortform.com).
 *
 * The format is adapted from the result returned by `Capacitor.convertFileSrc`. We
 * can not use `Capacitor.convertFileSrc` though because it has a bug and doesn't work
 * for our setup. For more details see
 * {@link https://github.com/shortformhq/main/issues/6169#issuecomment-1225582895}
 */
export async function androidLocalAudioFileSrc(book: Book): Promise<string> {
  const targetPath = await Filesystem.getUri({
    directory,
    path: audioFilePath(book),
  })

  return targetPath.uri.replace(
    'file://',
    window.location.origin + '/_capacitor_file_',
  )
}
