
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { RateControl, AudioDoc } from '@/services/player'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {},
})
export default class Player extends Vue {
  @Prop() private audio!: AudioDoc

  private rateControl: RateControl | null = null

  mounted(): void {
    this.rateControl = new RateControl(this.audio)
  }

  formatRate(rate: number): string {
    const result = (Math.round(rate * 10) / 10).toLocaleString(undefined, {
      minimumFractionDigits: 1,
    })
    return `${result}x`
  }

  /**
   * Expose playback rate as reactive property.
   *
   * If we just use `rateControl.rate()` inside the view (event if it is made property),
   * it doesn't work and changes are not reflected in the view.
   */
  get playbackRate(): number {
    return assertNotNull(this.rateControl).rate()
  }
}
