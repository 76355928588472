
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class FAQUnit extends Vue {
  @Prop() title!: string

  collapsed = true

  toggleCollapsible(): void {
    this.collapsed = !this.collapsed
  }
}
