
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import Layout from '@/app/components/Layout.vue'

import HeaderInternal from '@/app/components/header/Internal.vue'
import BackLink from '@/app/components/BackLink.vue'
import ListingPageLayoutSimple from '@/app/components/listing/ListingPageLayoutSimple.vue'

import { ListingView } from '@/models/docs.listing'
import { wait } from '@/helpers/vue-wait'
import { Sentry } from '@/services/sentry'

@Options({
  components: {
    Layout,
    'v-wait': vLoading,
    LoadingSpinner,
    HeaderInternal,
    BackLink,
    ListingPageLayoutSimple,
  },
})
export default class ListingPageLayout extends Vue {
  @Prop({}) initView!: () => Promise<ListingView>
  @Prop({}) sectionClass!: string

  view: ListingView | null = null

  async mounted(): Promise<void> {
    this.loadData()
  }

  async loadData(): Promise<void> {
    wait.start(this, 'Loading List')
    try {
      this.view = await this.initView()
      wait.end(this, 'Loading List')
    } catch (error: any) {
      wait.start(this, 'Error Loading List')
      Sentry.captureException(error)
    }
  }
}
