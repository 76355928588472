import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f2ebee0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "listing-header" }
const _hoisted_2 = { class: "listing-header__title section-title" }
const _hoisted_3 = { class: "listing-header__btn" }
const _hoisted_4 = {
  key: 1,
  class: "listing-header__link"
}
const _hoisted_5 = { class: "listing-header__title section-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.to)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.to,
          class: "listing-header__link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.viewAllText || 'View all'), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]))
  ]))
}