
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import CollectionCard from '@/app/components/collections/card/CollectionCard.vue'
import ListingBlock from '@/app/components/listing/ListingBlock.vue'

import { CollectionsView } from '@/models/collection'

@Options({
  components: {
    CollectionCard,
    ListingBlock,
  },
})
export default class CollectionsBlock extends Vue {
  @Prop() private view!: CollectionsView
  @Prop() private title!: string
  @Prop() private viewAllLink!: string
}
