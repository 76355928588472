import { backend } from '@/services/backend'
import { platform } from '@/services/platform'
import { resetAppCache } from '@/helpers/cache'
import { apStyleDate } from '@/helpers/time'
import { AdjustWrapper } from '@/analytics/adjust'
import { Mixpanel } from '@/analytics/mixpanel'
import { trackSubscribe } from '@/analytics/logic'

import {
  AppStore,
  AppStorePluginProduct,
  UserCancelledError,
} from '@/services/cordova.purchase.types'
import { Sentry } from '@/services/sentry'

import {
  Billing,
  Plan,
  BillingPlans,
  BillingType,
  StripeCard,
} from './interfaces'
import {
  PlanView,
  FreePlanView,
  MonthlyPlanView,
  AnnualPlanView,
  AppStoreMonthlyPlanView,
  AppStoreAnnualPlanView,
} from './billing.plans'
import { Country } from './billing.localized.pricing'
export { PlanView, AnnualPlanView, MonthlyPlanView }
import { formatTrialDate, trialEndDiff, futureDate } from '@/helpers/time'

export enum BillingAlert {
  none = 'none',
  canceledTrial = 'canceledTrial',
  canceledPaid = 'canceledPaid',
  downgraded = 'downgraded',
  upgraded = 'upgraded',
  subscribed = 'subscibed',
  resubscribed = 'resubscibed',
  subscribedAndRedirect = 'subscibedAndRedirect',
}

/**
 * Check if we have Apple subscription in the web or Android app.
 *
 * We display a special "manage your account on iOS" page in this case
 */
export async function isAppleWeb(billing: Billing): Promise<boolean> {
  const info = await platform.deviceInfo()

  return (
    info.platform !== 'ios' &&
    billing.billing_type === 'apple' &&
    billing.plan_id !== 'plan_free'
  )
}

/**
 * Check if we have a Google subscription in the web or iOS app.
 *
 * We display a special "manage your account on Android" page in this case.
 */
export async function isGoogleWeb(billing: Billing): Promise<boolean> {
  const info = await platform.deviceInfo()

  return (
    info.platform !== 'android' &&
    billing.billing_type === 'google' &&
    billing.plan_id !== 'plan_free'
  )
}

/**
 * Factory function to create platform-specific billing view.
 *
 * Creates AppStoreBillingView for iOS and BillingView for other platforms.
 */
export async function createBilling(billing: Billing): Promise<BillingView> {
  const info = await platform.deviceInfo()

  // - if billing.billing_type === 'stripe' - always use Stripe billing
  if (billing.billing_type === 'stripe') {
    return new BillingView(billing)
  }

  // TODO: for existing subscription:
  // - if billing.billing_type === 'apple' and platform is not iOS - show
  // special version

  // Use Apple Payments on iOS and Google Play Billing on Android.
  // We determine which plugin version to use based on the mobile app version.
  // In versions 7 and beyond, we use v13 of the plugin, but in previous
  // versions, we use v11.
  if (info.platform === 'ios' || info.platform === 'android') {
    let purchaseModule
    const majorVersion = parseInt(info.appVersion.split('.')[0])
    if (majorVersion >= 7) {
      purchaseModule = await import('@/services/cordova.purchase')
    } else {
      purchaseModule = await import('@/services/cordova.purchase.v11')
    }
    const appStore = purchaseModule.appStore
    await appStore.init(info.platform)
    const view = new AppStoreBillingView(billing, appStore, info.platform)
    return view
  }

  // Use Stripe otherwise.
  const view = new BillingView(billing)
  return view
}

/**
 * Billing view.
 *
 * Note: we use "!" modifier for properties to avoid an error:
 *   @ts-ignore 2564: property has no initializer
 *   and is not assinged in the constructor (which is not true).
 * In this case, properties are assigned in the constructor, but
 * inderectly, in the `this.update` call.
 */
export class BillingView implements Billing {
  public user_id!: string
  public plan_id!: string
  public cancel_at_period_end!: boolean
  public change_plan_at_period_end!: boolean
  public current_period_end!: number
  public current_period_end_datetime!: string
  public is_trial!: boolean
  public is_limited!: boolean
  public status!: string
  public status_message!: string
  public trial_start!: number | null
  public trial_start_datetime!: string | null
  public trial_end!: number | null
  public trial_end_datetime!: string | null
  public card_data?: StripeCard
  public billing_plans!: BillingPlans
  public billing_type!: BillingType
  public userCountry?: Country

  protected _freePlan?: FreePlanView
  protected _monthlyPlan?: MonthlyPlanView
  protected _annualPlan?: AnnualPlanView

  public cardEditMode!: boolean

  // Set to true right after the subscription.
  public alert: BillingAlert = BillingAlert.none

  constructor(billing: Billing) {
    this.update(billing)
  }

  public update(billing: Billing): void {
    this.user_id = billing.user_id
    this.plan_id = billing.plan_id
    this.cancel_at_period_end = billing.cancel_at_period_end
    this.change_plan_at_period_end = billing.change_plan_at_period_end
    this.current_period_end = billing.current_period_end
    this.current_period_end_datetime = billing.current_period_end_datetime
    this.is_trial = billing.is_trial
    this.is_limited = billing.is_limited
    this.status = billing.status
    this.status_message = billing.status_message
    this.trial_start = billing.trial_start
    this.trial_start_datetime = billing.trial_start_datetime
    this.trial_end = billing.trial_end
    this.trial_end_datetime = billing.trial_end_datetime
    this.card_data = billing.card_data
    this.billing_plans = billing.billing_plans
    this.billing_type = billing.billing_type

    // If we have card already - hide the card input.
    if (this.card_data && this.card_data.last4) {
      this.cardEditMode = false
    } else {
      this.cardEditMode = true
    }
  }

  get freePlan(): PlanView {
    if (!this._freePlan) {
      this._freePlan = new FreePlanView(this.billing_plans)
    }
    return this._freePlan
  }

  get monthlyPlan(): PlanView {
    if (!this._monthlyPlan) {
      this._monthlyPlan = new MonthlyPlanView(this.billing_plans)
    }
    return this._monthlyPlan
  }

  get annualPlan(): PlanView {
    if (!this._annualPlan) {
      this._annualPlan = new AnnualPlanView(this.billing_plans)
    }
    return this._annualPlan
  }

  /**
   * Current billing plan
   */
  get plan(): PlanView {
    const plan = this.plans.find((p) => p.id === this.plan_id)
    if (plan === undefined) {
      throw new Error('Wrong billing plan id: ' + this.plan_id)
    }
    return plan
  }

  /**
   * Billing plan name to show in UI.
   */
  get planName(): string {
    let name = this.plan.nickname
    if (this.cancel_at_period_end) {
      name = name + ' (canceled)'
    } else if (
      this.change_plan_at_period_end &&
      this.plan_id === 'plan_annual'
    ) {
      name = name + ' (scheduled to downgrade)'
    } else if (
      this.change_plan_at_period_end &&
      this.plan_id === 'plan_monthly'
    ) {
      name = name + ' (scheduled to upgrade)'
    }
    return name
  }

  /**
   * Is current billing plan paid?
   */
  get isPaidPlan(): boolean {
    return this.plan_id !== 'plan_free'
  }

  /**
   * Additional billing status message.
   */
  get statusMessage(): string {
    if (this.status === 'subscription_bad' && this.billing_type === 'google') {
      return `We ran into an issue charging your card for this subscription, so we've downgraded your account to the Free plan. Please <a href="${this.paymentProviderSettingsUrl}">fix your payment method on Google Play</a>, or contact us at <a href="mailto:help@shortform.com">help@shortform.com</a> to resolve the issue.`
    }
    if (this.status === 'subscription_bad') {
      return `We ran into an issue charging your card for this subscription, so we've downgraded your account to the Free plan. Please contact us at <a href="mailto:help@shortform.com">help@shortform.com</a> to resolve the issue.`
    }
    return ''
  }

  /**
   * Title to show at the top of the billing page.
   */
  get subscriptionBoxTitle(): string {
    return this.isTrialOver
      ? 'Subscribe for full access'
      : 'Start your 5-day free trial'
  }

  /**
   * Message to show at the top of the billing page.
   */
  get subscriptionBoxInfo(): string {
    if (this.isTrialOver) {
      return 'Get full access to Shortform and supercharge your learning. Cancel anytime.'
    }
    return `Get full access to Shortform. You won't be charged until your free trial ends on ${this.freeTrialExpiry}. Cancel anytime.`
  }

  /**
   * List of all billing plans.
   */
  get plans(): PlanView[] {
    // It would be better to query these on the backend from Stripe
    // and add to the billing data, but for now we just fill them
    // in here for simplicity and speed.
    // Note: actual price is on Stripe, if we change the price, we
    // need to update it here too.
    // Note: for now we don't include annual plan here.
    return [this.freePlan, this.monthlyPlan, this.annualPlan]
  }

  /**
   * Trial mode description (not started, active, orver) with
   * trial mode dates.
   */
  get trialMode(): string {
    if (this.trial_end === null) {
      return 'Not Started'
    }
    if (this.is_trial === true) {
      return `Active (${this.trialStart} - ${this.trialEnd})`
    }
    return `Over (${this.trialEnd})`
  }

  /**
   * Is trial over?
   *
   * Returns 'true' if the user has finished the trial period.
   *
   * Note: this is different from just `!this.is_trial` as we need
   * to exclude the case when the trial has not been started yet.
   *
   * This property additionally checks the `trial_end` to make sure
   * that it is actually over (the user had the trial in the past
   * and it is not active anymore).
   */
  get isTrialOver(): boolean {
    if (this.trial_end !== null && this.is_trial === false) {
      return true
    }
    return false
  }

  /**
   * End of the billing period when new payment is to be performed or
   * plan to be canceled.
   */
  get billingPeriodEnd(): string {
    return formatTrialDate(this.current_period_end_datetime)
  }

  /**
   * Start of the trial period.
   */
  get trialStart(): string {
    if (this.trial_start_datetime) {
      return formatTrialDate(this.trial_start_datetime)
    }
    return ''
  }

  /**
   * End of the trial period.
   */
  get trialEnd(): string {
    if (this.trial_end_datetime) {
      return formatTrialDate(this.trial_end_datetime)
    }
    if (!this.isPaidPlan && !this.isTrialOver) {
      // This is when the trial is not started yet, but
      // we want display the date it ends if started now.
      return futureDate(5)
    }
    return ''
  }

  /**
   * Days until the end of trial.
   */
  daysUntilTrialEnd(): string {
    if (this.trial_end_datetime) {
      return trialEndDiff(this.trial_end_datetime)
    }
    return ''
  }

  /**
   * Is given `plan` currently active?
   */
  isActive(plan: Plan): boolean {
    return plan.id === this.plan_id
  }

  /**
   * Subscribe to the specified billing plan.
   */
  async subscribeTo(plan: PlanView): Promise<void> {
    const isNewUser = this.trialMode === 'Not Started'
    const wasCanceled = this.cancel_at_period_end === true

    const data = {
      id: plan.id,
      amount: plan.amount,
      nickname: plan.nickname,
    }
    const response = await backend.subscribe(data)
    await trackSubscribe(data, this)
    this.update(response)

    if (plan.id === 'plan_free') {
      if (this.is_trial) {
        this.alert = BillingAlert.canceledTrial
      } else {
        this.alert = BillingAlert.canceledPaid
      }
    } else {
      // Show subscribed alert and redirect to books page after delay.
      if (isNewUser) {
        this.alert = BillingAlert.subscribedAndRedirect
      } else if (wasCanceled) {
        this.alert = BillingAlert.resubscribed
      } else {
        this.alert = BillingAlert.subscribed
      }
    }

    // Reset app cache to force API data to reload.
    // When we switch from free to paid and back, the content changes,
    // so we need to get the fresh data before showing it.
    await resetAppCache()
  }

  get afterCancelTrialAlert(): boolean {
    return this.alert === BillingAlert.canceledTrial
  }

  get afterCancelPaidAlert(): boolean {
    return this.alert === BillingAlert.canceledPaid
  }

  get subscribedAlert(): boolean {
    return this.alert === BillingAlert.subscribed
  }

  get resubscribedAlert(): boolean {
    return this.alert === BillingAlert.resubscribed
  }

  get downgradedAlert(): boolean {
    return this.alert === BillingAlert.downgraded
  }

  get upgradedAlert(): boolean {
    return this.alert === BillingAlert.upgraded
  }

  get subscribedAlertAndRedirect(): boolean {
    return this.alert === BillingAlert.subscribedAndRedirect
  }

  isAppStore(): boolean {
    return false
  }

  // If the user has not started their free trial:
  //   Return the (ap formatted) date that the trial period would expire on,
  //   if they signed up today.
  //
  // If the user already started their free trial, and they still have time
  // left on it:
  //   Return the (ap formatted) date that it is set to expire.
  //
  // If the users free trial already expired:
  //   Return `null`
  //
  // This function is used to display (or not) a "free trial timeline"
  // widget on the billing page. See #5656 for details.
  get freeTrialExpiry(): string | null {
    if (this.trial_end === null) {
      // If the user has not started their trial yet, then assuming they
      // signed up today, it would expire in 5 days
      const hypotheticalExpiry = new Date()
      const futureDate = hypotheticalExpiry.getDate() + 5
      hypotheticalExpiry.setDate(futureDate)
      return apStyleDate(hypotheticalExpiry)
    } else {
      // Whether the trial period is currently active or not
      // we know that it was once started, which means that the expiry date
      // is now fixed and cannot be changed.
      const trialEndDate = new Date(this.trial_end * 1000)
      const now = new Date()
      const expiresInFuture = trialEndDate > now
      if (expiresInFuture) {
        // The user still has time left on their free trial, so we want
        // to show them when it will expire
        return apStyleDate(trialEndDate)
      } else {
        // The users trial period is already over, so the widget should
        // not be shown at all.
        return null
      }
    }
  }

  /**
   * Determines if a discount banner on the billing page should be shown for
   * the free plan. If true, a banner with a message like
   * "You've unlocked 21% off your subscription" will be shown.
   *
   * For Stripe (i.e. in browsers) this depends on `billing_plans.prompt` being
   * defined.
   *
   * For Apple payments (and soon Google payments), i.e. in mobile apps, this is
   * always false as we do not support applying discounts there.
   */
  get showDiscountBanner(): boolean {
    return !!this.billing_plans.prompt
  }

  /**
   * Returns a link to the page provided by the payment provider where users can
   * manage their subscription. Available only for Apple and Google billing.
   */
  get paymentProviderSettingsUrl(): string {
    if (this.billing_type === 'apple') {
      return 'https://apps.apple.com/account/subscriptions'
    } else if (this.billing_type === 'google') {
      return `https://play.google.com/store/account/subscriptions?sku=${this.plan_id}&package=com.shortform.app`
    }
    // Else there is no settings page by the payment provider.
    Sentry.captureException(
      `Unexpected code path. No settings page for billing_type '${this.billing_type}'.`,
    )
    return ''
  }

  /**
   * We use this method to hide parts of the UI when it is not possible to switch
   * billing plans with Google billing.
   *
   * It is not possible to switch billing plans when:
   * - A plan change is scheduled in the future (not possible to undo with Google).
   * - Or subscription is in ON_HOLD state (i.e. subscription_bad)
   */
  get allowPlanChange(): boolean {
    if (this.billing_type !== 'google') {
      return true
    }
    return !this.change_plan_at_period_end && this.status !== 'subscription_bad'
  }

  /**
   * Returns true if the current billing type supports cancelling the free trial
   * to move directly to the first paid period.
   *
   * This allows users to start with the paid plan earlier to allow them to
   * export books as PDF without waiting for the trial time to end.
   */
  get supportsCancelTrial(): boolean {
    return this.billing_type === 'apple' || this.billing_type === 'google'
  }
}

export class AppStoreBillingView extends BillingView {
  public appStore: AppStore

  protected _appStoreFreePlan?: FreePlanView
  protected _appStoreMonthlyPlan?: AppStoreMonthlyPlanView
  protected _appStoreAnnualPlan?: AppStoreAnnualPlanView

  private platform: 'ios' | 'android'

  constructor(
    billing: Billing,
    appStore: AppStore,
    platform: 'ios' | 'android',
  ) {
    super(billing)
    this.appStore = appStore
    this.platform = platform
  }

  isAppStore(): boolean {
    return true
  }

  get freePlan(): PlanView {
    if (!this._appStoreFreePlan) {
      this._appStoreFreePlan = new FreePlanView(this.billing_plans)
    }
    return this._appStoreFreePlan
  }

  get monthlyPlan(): AppStoreMonthlyPlanView {
    if (!this._appStoreMonthlyPlan) {
      this._appStoreMonthlyPlan = new AppStoreMonthlyPlanView(
        this.appStore,
        this.platform,
      )
    }
    return this._appStoreMonthlyPlan
  }

  get annualPlan(): AppStoreAnnualPlanView {
    if (!this._appStoreAnnualPlan) {
      this._appStoreAnnualPlan = new AppStoreAnnualPlanView(
        this.appStore,
        this.platform,
        this.monthlyPlan,
      )
    }
    return this._appStoreAnnualPlan
  }

  /**
   * Subscribe to the specified billing plan.
   */
  async subscribeTo(plan: PlanView): Promise<void> {
    const isNewUser = this.trialMode === 'Not Started'
    const wasCanceled = this.cancel_at_period_end === true

    if (plan.id !== 'plan_monthly' && plan.id !== 'plan_annual') {
      throw new Error(`Unexpected plan id ${plan.id}`)
    }

    const isPlanChange =
      this.plan_id !== 'plan_free' && this.plan_id !== plan.id

    let product: AppStorePluginProduct
    try {
      if (plan.id === 'plan_monthly') {
        product = await this.appStore.subscribeMonthly(isPlanChange)
      } else if (plan.id === 'plan_annual') {
        product = await this.appStore.subscribeAnnual(isPlanChange)
      }
    } catch (error) {
      if (!(error instanceof UserCancelledError)) {
        Sentry.captureException(error)

        // Refresh billing data to handle errors that were caused by stale
        // UI. E.g. user clicks 'reactivate' in our UI but had reactivated
        // through the payment provider UI just before.
        const billing = await backend.getBilling()
        this.update(billing)
      }
      throw error
    }

    // Cordova purchase plugin issues the Apple receipt verification
    // request to the backend that also updates billing information
    // for the user.
    // Here we fetch the fresh information after that update:
    const billing = await backend.getBilling()

    if (billing.plan_id !== 'plan_free') {
      // Track billing events.
      // Note: we could also send new user analytics in the
      // webhook handler for the SUBSCRIBED:INITIAL_BUY event.
      if (isNewUser) {
        await AdjustWrapper.trackCardSave()
        Mixpanel.trackCardSave(billing)
      }
      await trackSubscribe(
        {
          id: plan.id,
          amount: plan.amount,
          nickname: plan.nickname,
        },
        this,
      )
    }

    this.update(billing)
    if (!this.isPaidPlan) {
      // Exit if backend was not updated with the paid plan
      // during the purchase process.
      // This is an unexpected situation: iOS marked the product as `owned`,
      // so the receipt validation should have passed successfully.
      // We log it to Sentry, so we know if this actually happens.
      Sentry.withScope((scope) => {
        scope.setExtra('Billing data', billing)
        scope.setExtra('Product', product)
        Sentry.captureMessage(
          'Unexpected: billing plan is not paid after subscribing.',
        )
      })
      return
    }

    // Show subscribed alert for the user.
    if (isNewUser) {
      this.alert = BillingAlert.subscribedAndRedirect
    } else if (wasCanceled) {
      this.alert = BillingAlert.resubscribed
    } else if (this.change_plan_at_period_end && plan.id === 'plan_monthly') {
      this.alert = BillingAlert.downgraded
    } else if (this.change_plan_at_period_end && plan.id === 'plan_annual') {
      this.alert = BillingAlert.upgraded
    } else {
      this.alert = BillingAlert.subscribed
    }

    // Reset app cache to force API data to reload.
    // When we switch from free to paid and back, the content changes,
    // so we need to get the fresh data before showing it.
    await resetAppCache()
  }

  override get showDiscountBanner(): boolean {
    return false
  }
}
