import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCard = _resolveComponent("BookCard")!
  const _component_ListingBlock = _resolveComponent("ListingBlock")!

  return (_openBlock(), _createBlock(_component_ListingBlock, {
    class: "listing-block--books",
    title: _ctx.title,
    viewAllLink: _ctx.viewAllLink,
    numberOfColumns: 4,
    useBigCards: false
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.filteredDocs.slice(0, 4), (book) => {
        return (_openBlock(), _createBlock(_component_BookCard, {
          key: book.id,
          doc: book,
          view: _ctx.view,
          showFreeBooks: _ctx.showFreeBooks
        }, null, 8, ["doc", "view", "showFreeBooks"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "viewAllLink"]))
}