import { auth } from '@/services/auth'
import { assertNotNull } from '@/helpers/typing'

import { DocView } from './doc'
import { DocExportStats } from './interfaces'
import { BillingView } from './billing'
import { addDays } from '@/helpers/time'

enum DocExportViewVariant {
  FREE = 1,
  FREE_CAN_DOWNLOAD,
  TRIAL,
  TRIAL_CAN_DOWNLOAD,
  PAID_DOWNLOADED,
  PAID_LIMIT,
  PAID_CAN_DOWNLOAD,
}

export class DocExportView {
  private _doc: DocView
  private _docExportStats: DocExportStats

  public variant: DocExportViewVariant

  constructor(doc: DocView, billing: BillingView) {
    this._doc = doc
    this._docExportStats = assertNotNull(doc.doc_export_stats)

    if (auth.isFreePlan()) {
      if (this._doc.is_free) {
        this.variant = DocExportViewVariant.FREE_CAN_DOWNLOAD
      } else {
        // Free users
        this.variant = DocExportViewVariant.FREE
      }
    } else {
      if (billing.is_trial) {
        // Trial users
        if (this._doc.is_free) {
          // Trial user, free book
          this.variant = DocExportViewVariant.TRIAL_CAN_DOWNLOAD
        } else {
          // Trial user, non-free book
          this.variant = DocExportViewVariant.TRIAL
        }
      } else {
        if (this._docExportStats.exported) {
          // Paid user, previously downloaded
          this.variant = DocExportViewVariant.PAID_DOWNLOADED
        } else {
          if (
            this._docExportStats.limit.exports >=
            this._docExportStats.limit.limit
          ) {
            // Paid user, have not downloaded previously, limit is over
            this.variant = DocExportViewVariant.PAID_LIMIT
          } else {
            // Paid user, have not downloaded previously:
            this.variant = DocExportViewVariant.PAID_CAN_DOWNLOAD
          }
        }
      }
    }
  }

  isFree(): boolean {
    return this.variant === DocExportViewVariant.FREE
  }
  isFreeCanDownload(): boolean {
    return this.variant === DocExportViewVariant.FREE_CAN_DOWNLOAD
  }
  isTrialCanDownload(): boolean {
    return this.variant === DocExportViewVariant.TRIAL_CAN_DOWNLOAD
  }
  isTrial(): boolean {
    return this.variant === DocExportViewVariant.TRIAL
  }
  isPaidDownloaded(): boolean {
    return this.variant === DocExportViewVariant.PAID_DOWNLOADED
  }
  isPaidLimit(): boolean {
    return this.variant === DocExportViewVariant.PAID_LIMIT
  }
  isPaidCanDownload(): boolean {
    return this.variant === DocExportViewVariant.PAID_CAN_DOWNLOAD
  }

  canDownload(): boolean {
    return (
      this.isFreeCanDownload() ||
      this.isTrialCanDownload() ||
      this.isPaidCanDownload() ||
      this.isPaidDownloaded()
    )
  }

  /**
   * Return seconds left before next download is avaliable
   */
  nextDownloadSeconds(): number {
    const downloadCount = this._docExportStats.limit.exports
    if (downloadCount === 0) {
      return 0
    }
    const latestDownload =
      this._docExportStats.limit.recent_exports[downloadCount - 1]
    const currentDate = new Date()
    const latestDownloadDate = new Date(latestDownload.created)
    const futureDownloadDate = addDays(
      latestDownloadDate,
      this._docExportStats.limit.days,
    )
    return Math.floor(
      (futureDownloadDate.getTime() - currentDate.getTime()) / 1000,
    )
  }

  remainingDownloadsText(): string {
    const remaining =
      this._docExportStats.limit.limit - this._docExportStats.limit.exports
    return remaining > 1 ? `${remaining} downloads` : `${remaining} download`
  }

  /**
   * Link to the book preview page.
   */
  get backLink(): Record<string, unknown> {
    return {
      name: 'book.preview',
      params: {
        url_slug: this._doc.url_slug,
      },
    }
  }
}
