
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import {
  enableIosContextMenu,
  disableIosContextMenu,
} from '@/services/contextMenuPlugin'

@Options({
  emits: ['update:modelValue'],
})
export default class InputWithContextMenu extends Vue {
  @Prop({ default: 'input' })
  is: 'input' | 'textarea' = 'input'

  @Prop()
  modelValue: string = ''

  @Prop({ default: false })
  autoResize: boolean = false

  enableIosContextMenu = enableIosContextMenu
  disableIosContextMenu = disableIosContextMenu

  /**
   * If the component has `autoResize`, resize it on load
   * to make sure it renders at the correct height.
   */
  mounted(): void {
    if (this.autoResize && this.is === 'textarea') {
      this.resizeTextArea((this.$refs as any).inputElement)
    }
  }

  /**
   * Emit the input value. If the component has `autoResize`,
   * update its height.
   */
  handleInput(event: InputEvent): void {
    const target = event.target as HTMLInputElement

    this.$emit('update:modelValue', target.value)

    if (this.autoResize && this.is === 'textarea') {
      this.resizeTextArea(target)
    }
  }

  /**
   * Update the text area's element's height based on its contents.
   */
  resizeTextArea(element: HTMLInputElement): void {
    // Reset height to 'auto' to ensure the `scrollHeight` is accurate before resizing.
    element.style.height = 'auto'
    element.style.height = `${element.scrollHeight}px`
  }
}
