// This module implements a query string based command processor to help
// with running devlopment or admin frontend operations in situations
// where browser development console might not be available (e.g. iOS).
//
// The commands are currently read on the "help" page only, so for example
// you can visit:
//
// https://shortform.com/app/help?command=localStorageObjectSet&key=sf_ab_data&prop=design_v4_4359&value=yes
//
// Which will set property "design_v4_4359" of local storage object
// "sf_ab_data" to value "yes".
//
// For more information on the motivation, see #5405

import { storage } from '@/helpers/storage'

interface ArgsObject {
  [key: string]: string
}

// Set prop args.prop on localstorage object args.key to value args.value.
function localStorageObjectSet(args: ArgsObject): void {
  const key = args.key
  const prop = args.prop
  const value = args.value
  if (!key) {
    console.error("LocalStorageObjectSet: missing arg 'key'")
    return
  }
  if (!prop) {
    console.error("LocalStorageObjectSet: missing arg 'prop'")
    return
  }
  if (!value) {
    console.error("LocalStorageObjectSet: missing arg 'value'")
    return
  }
  const storedObject = storage.getItem(key)
  let objectToEdit: any = {}
  if (!storedObject) {
    console.warn(`key '${key}' did not exist, creating.`)
  } else {
    objectToEdit = JSON.parse(storedObject)
  }
  objectToEdit[prop] = value
  storage.setItem(key, JSON.stringify(objectToEdit))
  console.log('LocalStorageObjectSet: ok.')
}

const commandHandlers = new Map([
  ['localStorageObjectSet', localStorageObjectSet],
])

export function processDevCommands(): void {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const queryStringValues = Object.fromEntries(urlSearchParams.entries())
  const commandName = queryStringValues['command']
  if (commandName !== undefined) {
    const commandHandler = commandHandlers.get(commandName)
    if (commandHandler !== undefined) {
      const args = Object.assign({}, queryStringValues)
      delete args.command
      commandHandler(args)
    } else {
      console.error(`Unknown development command '${commandName}'`)
    }
  }
}
