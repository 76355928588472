import { DocSortOption, DocsView } from './doc'
import { ListingPage } from './docs.listing'
import {
  ApiResultDocs,
  DocListItem,
  DocType,
  EpisodeListItem,
} from './interfaces'

export class PodcastEpisodesView extends DocsView {
  // When set to true, the episode card will display the podcast title
  // as a subtitle, otherwise it will display the episode blurb.
  private _showPodcastAsSubtitle: boolean

  constructor(
    docs: DocListItem[],
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
    showPodcastAsSubtitle: boolean = false,
    sortOption: DocSortOption = DocSortOption.first_published_at,
  ) {
    super(docs, [], undefined, listingPage)
    this._showCtaBlock = showCtaBlock
    this._showPodcastAsSubtitle = showPodcastAsSubtitle
    this._sort = sortOption
  }

  static async createView(
    response: ApiResultDocs,
    listingPage?: ListingPage,
    showCtaBlock: boolean = true,
    showPodcastAsSubtitle: boolean = false,
    defaultSort: DocSortOption = DocSortOption.first_published_at,
  ): Promise<PodcastEpisodesView> {
    const view = new PodcastEpisodesView(
      response.data,
      listingPage,
      showCtaBlock,
      showPodcastAsSubtitle,
      defaultSort,
    )
    await view.init()
    return view
  }

  static async createSearchView(
    searchTerm: string = '',
  ): Promise<PodcastEpisodesView> {
    const episodes = await DocsView.searchDocsLimited(
      'podcast_episode',
      searchTerm,
    )

    const view = new PodcastEpisodesView(episodes, undefined, false, false)
    await view.init()
    return view
  }

  public docTypeName(): DocType {
    return 'podcast_episode'
  }

  public link(episode: DocListItem): Record<string, unknown> | string {
    return {
      name: 'podcast.preview',
      params: {
        url_slug: episode.url_slug,
      },
    }
  }

  public showPodcastAsSubtitle(): boolean {
    return this._showPodcastAsSubtitle
  }

  latestEpisodes(): EpisodeListItem[] {
    return this.docs.slice(0, 3) as EpisodeListItem[]
  }
}
