import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-495eabf9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderInternal = _resolveComponent("HeaderInternal")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_LoadingError = _resolveComponent("LoadingError")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_ListingPageLayoutSimple = _resolveComponent("ListingPageLayoutSimple")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "layout--basic" }, {
    default: _withCtx(() => [
      (_ctx.view)
        ? (_openBlock(), _createBlock(_component_HeaderInternal, {
            key: 0,
            title: _ctx.view.pageTitle,
            class: _normalizeClass(_ctx.view.isHeaderCollapsed ? 'header--scroll' : 'header--default')
          }, {
            actions: _withCtx(() => [
              _renderSlot(_ctx.$slots, "filterBoxMobileButton", {}, undefined, true)
            ]),
            _: 3
          }, 8, ["title", "class"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.view)
          ? (_openBlock(), _createBlock(_component_BackLink, {
              key: 0,
              class: "docs-listing__backlink container"
            }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "descriptionBox", {}, undefined, true),
        _createElementVNode("section", {
          class: _normalizeClass(["docs-listing", _ctx.sectionClass])
        }, [
          _createVNode(_component_v_wait, { for: "Error Loading List" }, {
            waiting: _withCtx(() => [
              _createVNode(_component_LoadingError)
            ]),
            _: 1
          }),
          _createVNode(_component_v_wait, {
            for: "Loading List",
            class: "layout__wrapper-inner container"
          }, {
            waiting: _withCtx(() => [
              _createVNode(_component_LoadingSpinner)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_ListingPageLayoutSimple, { view: _ctx.view }, {
                filterBox: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "filterBox", {}, undefined, true)
                ]),
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                nodata: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "nodata", {}, () => [
                    _createTextVNode(" No data found. ")
                  ], true)
                ]),
                filterBoxMobile: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "filterBoxMobile", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["view"])
            ]),
            _: 3
          })
        ], 2)
      ])
    ]),
    _: 3
  }))
}