import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "collections__item" }
const _hoisted_2 = { class: "collections__img-box" }
const _hoisted_3 = {
  crossorigin: "anonymous",
  class: "collections__img",
  alt: ""
}
const _hoisted_4 = { class: "collections__text-box" }
const _hoisted_5 = { class: "collections__name" }
const _hoisted_6 = { class: "collections__count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.view.startLinkParams(_ctx.doc),
      class: "collections__link"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
            [_directive_lazy, { src: _ctx.doc.cover_image }]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.doc.title), 1),
          _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.doc.doc_count) + " books", 1)
        ])
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}