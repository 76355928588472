
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'

@Options({
  components: {},
})
export default class QuestionnaireProgressDots extends Vue {
  @Prop() view!: QuestionnaireView
}
