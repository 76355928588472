import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fbf38bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "layout__wrapper" }
const _hoisted_3 = { class: "title-section" }
const _hoisted_4 = { class: "container" }
const _hoisted_5 = { class: "title-section__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderDoc = _resolveComponent("HeaderDoc")!
  const _component_ContentLabel = _resolveComponent("ContentLabel")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_SidebarTOC = _resolveComponent("SidebarTOC")!
  const _component_SidebarTutorial = _resolveComponent("SidebarTutorial")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Tutorial = _resolveComponent("Tutorial")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderDoc, { doc: _ctx.doc }, null, 8, ["doc"]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "banner", {}, undefined, true),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "page-title", {}, () => [
            _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.doc.title), 1),
            (_ctx.doc.showAiLabel)
              ? (_openBlock(), _createBlock(_component_ContentLabel, {
                  key: 0,
                  class: "doc-preview__content-label"
                }))
              : _createCommentVNode("", true)
          ], true)
        ])
      ]),
      _createElementVNode("section", {
        class: _normalizeClass(["book-reading background-section", _ctx.doc.readingCssClass])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.doc.controls.containerClass, "container book-reading__content control-container"])
        }, [
          _createVNode(_component_BackLink, { class: "book-reading__backlink" }),
          _createVNode(_component_Sidebar, {
            doc: _ctx.doc,
            "show-text-controls": _ctx.showTextControls
          }, {
            default: _withCtx(() => [
              _createVNode(_component_SidebarTOC, {
                doc: _ctx.doc,
                class: _normalizeClass(_ctx.doc.tutorial ? _ctx.doc.tutorial.tocBtnClass : '')
              }, null, 8, ["doc", "class"]),
              (_ctx.doc.tutorial && _ctx.doc.isCurrentFirstPage())
                ? (_openBlock(), _createBlock(_component_SidebarTutorial, {
                    key: 0,
                    "book-tutorial": _ctx.doc.tutorial
                  }, null, 8, ["book-tutorial"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["doc", "show-text-controls"]),
          _renderSlot(_ctx.$slots, "page-content", {}, undefined, true)
        ], 2),
        (_ctx.doc.tutorial && _ctx.doc.isCurrentFirstPage())
          ? (_openBlock(), _createBlock(_component_Tutorial, {
              key: 0,
              "book-tutorial": _ctx.doc.tutorial
            }, null, 8, ["book-tutorial"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "page-footer", {}, undefined, true)
      ], 2)
    ])
  ]))
}