
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { OpenQuestionsView, ExerciseView } from '@/models/book'
import { assertNotNull } from '@/helpers/typing'

import EOCButton from '@/app/components/billing/EOCButton.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

@Options({
  components: {
    EOCButton,
    InputWithContextMenu,
  },
})
export default class BookExerciseFree extends Vue {
  @Prop() private content!: OpenQuestionsView

  get exercise(): ExerciseView {
    return assertNotNull(this.content).currentExercise
  }
}
