import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26bcea2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "categories-box" }
const _hoisted_2 = { class: "section-title" }
const _hoisted_3 = { class: "categories-filter" }
const _hoisted_4 = { class: "categories-filter__list categories-filter__list--desktop" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoryItem = _resolveComponent("CategoryItem")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.view.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.tags, (tag, index) => {
          return (_openBlock(), _createBlock(_component_CategoryItem, {
            key: tag,
            tag: tag,
            view: _ctx.view,
            itemIndex: index
          }, null, 8, ["tag", "view", "itemIndex"]))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.view.mobileClass)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_CategoryItem, {
            key: tag,
            tag: tag,
            view: _ctx.view,
            isMobile: true
          }, null, 8, ["tag", "view"]))
        }), 128))
      ], 2),
      (_ctx.view.tags.length > 6 && !_ctx.view.showAllTags)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "categories-filter__btn categories-filter__btn--show",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.view.toggleShowAllTags && _ctx.view.toggleShowAllTags(...args)))
          }, " Show all categories "))
        : (_ctx.view.tags.length > 6)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "categories-filter__btn categories-filter__btn--hide",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.view.toggleShowAllTags && _ctx.view.toggleShowAllTags(...args)))
            }, " Hide categories "))
          : _createCommentVNode("", true)
    ])
  ]))
}