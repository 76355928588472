
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { HeaderView } from '@/models/header'

import HeaderUserMenu from '@/app/components/header/UserMenu.vue'
import AppMenu from '@/app/components/header/AppMenu.vue'
import StartFreeTrialButton from './StartFreeTrialButton.vue'

@Options({
  components: {
    HeaderUserMenu,
    AppMenu,
    StartFreeTrialButton,
  },
})
export default class HeaderAppDesktop extends Vue {
  public view: HeaderView | null = null

  async beforeMount(): Promise<void> {
    this.view = new HeaderView(this)
    await this.view.init()
  }
}
