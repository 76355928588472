import { storage } from '@/helpers/storage'
import { StatusBar, Style } from '@capacitor/status-bar'
import { platform } from './platform'

const NIGHT_MODE_CSS_CLASS = 'night-mode'
// Local Storage key
const NIGHT_MODE_LS_KEY = 'sf_night_mode'

/**
 * Global Night Mode object.
 *
 * We use this object to track the Night (dark) mode state inside the app, to enable or
 * disable it.
 * We keep track of user setting (on/off) through the browser local storage and restore
 * the setting on the App initialization.
 */
export const nightMode = {
  // Current Night Mode state
  _active: false,
  _isNativeIOSApp: false,

  /**
   * Initialize the object.
   *
   * This method is called only once, from root App component. Here we get the A/B
   * experiment state (whether the whole App dark mode enabled) and the user setting
   * for the night mode from the local storage (on/off)
   */
  async init(): Promise<void> {
    this._active = this._getNightMode()
    this._isNativeIOSApp = await platform.isNativeIOSApp()
  },

  /**
   * Get current night mode state.
   */
  get active(): boolean {
    return this._active
  },

  /**
   * Turn the night mode on and update the state in local storage.
   */
  async on(): Promise<void> {
    document.body.classList.add(NIGHT_MODE_CSS_CLASS)
    this._active = true
    this._setNightMode()
    if (this._isNativeIOSApp) {
      await StatusBar.setStyle({ style: Style.Dark })
    }
  },

  /**
   * Turn the night mode off and update the state in the local storage.
   */
  async off(): Promise<void> {
    document.body.classList.remove(NIGHT_MODE_CSS_CLASS)
    this._active = false
    this._setNightMode()
    if (this._isNativeIOSApp) {
      await StatusBar.setStyle({ style: Style.Light })
    }
  },

  /**
   * Restore the night mode state.
   *
   * We use this method on the initial App load. We first check the user setting for the
   * night mode and set the field ``this._active`` accordingly, then we either re-enable
   * or re-disable the night mode based on its value.
   */
  async restore(): Promise<void> {
    this.active ? await this.on() : await this.off()
  },

  /**
   * Toggle the night mode state.
   *
   * Disable if it was enabled, enable if it was disabled.
   */
  async toggle(): Promise<void> {
    this.active ? await this.off() : await this.on()
  },

  /**
   * Get the night mode state from the local storage.
   * @returns {boolean} Current night mode state
   */
  _getNightMode(): boolean {
    if (storage.getItem(NIGHT_MODE_LS_KEY)) {
      return JSON.parse(storage.getItem(NIGHT_MODE_LS_KEY) as string)
    }
    return false
  },

  /**
   * Save the night mode state into the local storage.
   */
  _setNightMode(): void {
    storage.setItem(NIGHT_MODE_LS_KEY, JSON.stringify(this._active))
  },
}
