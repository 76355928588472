
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import BookCard from '@/app/components/book/card/BookCard.vue'
import BookCTACard from '@/app/components/book/card/BookCTACard.vue'
import DocsSort from '@/app/components/listing/DocsSort.vue'
import DocsSortMobile from '@/app/components/listing/DocsSort.mobile.vue'
import ListingPageLayoutSimple from '@/app/components/listing/ListingPageLayoutSimple.vue'

import { BooksView } from '@/models/book'

@Options({
  components: {
    BookCard,
    BookCTACard,
    DocsSort,
    DocsSortMobile,
    ListingPageLayoutSimple,
  },
})
export default class InfiniteScrollBooksBlock extends Vue {
  @Prop() view!: BooksView
}
