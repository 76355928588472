
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { ArticleView } from '@/models/article'

import Comments from '@/app/components/community/Comments.vue'
import CommentEdit from '@/app/components/community/CommentEdit.vue'

@Options({
  components: {
    Comments,
    CommentEdit,
  },
})
export default class ArticleThread extends Vue {
  @Prop() private article!: ArticleView
}
