import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href", "download"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_spinner = _resolveComponent("button-spinner")!

  return (_ctx.isAndroid)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.downloadUrl,
        target: "_blank",
        class: "btns",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.registerDocExport && _ctx.registerDocExport(...args)))
      }, " Download PDF ", 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("a", {
        key: 1,
        href: _ctx.downloadUrl,
        target: "_blank",
        class: "btns",
        download: _ctx.downloadName,
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.registerDocExport && _ctx.registerDocExport(...args)))
      }, [
        (!_ctx.showSpinner)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Download PDF"))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createTextVNode("Downloading"),
              _createVNode(_component_button_spinner)
            ]))
      ], 8, _hoisted_2))
}