
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'
import { QuestionnaireReadingTimeItem } from '@/models/interfaces'
import QuestionnairePrevNext from '@/app/components/questionnaire/PrevNext.vue'

@Options({
  components: { QuestionnairePrevNext },
})
export default class QuestionnaireReadingTime extends Vue {
  @Prop() view!: QuestionnaireView

  iconUrl(readingTime: QuestionnaireReadingTimeItem): string {
    return require(`@/assets.app/images/${readingTime.icon}`)
  }
}
