
import { initClassComponentHooks } from '@/init/class-component-hooks'
import { Commentaries } from '@/models/interfaces'
initClassComponentHooks()

import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import LearnMoreItem from '@/app/components/doc/reading/LearnMoreItem.vue'
import PaywallCTA from '@/components/public/PaywallCTA.vue'
import RelatedSummaryItem from '@/app/components/doc/reading/RelatedSummaryItem.vue'

@Options({
  components: {
    PaywallCTA,
    LearnMoreItem,
    RelatedSummaryItem,
  },
})
export default class AdditionalInformationBlock extends Vue {
  @Prop() commentaries!: Commentaries
  @Prop() isPublicPage: boolean = false
  @Prop() title?: string
  @Prop() order?: number

  get textClasses(): string[] {
    return this.addFade('additional-information__section-text')
  }

  get learnMoreItemClasses(): string[] {
    return this.addFade('additional-information__learn-more-item')
  }

  get relatedSummaryItemClasses(): string[] {
    return this.addFade('additional-information__related-summary-item')
  }

  get componentLocationClass(): string {
    return this.isPublicPage
      ? 'additional-information--public'
      : 'additional-information--app'
  }

  /**
   * Add "--fade" modifier for all chapters except the first one.
   *
   * @param baseClass: base class of the additional information part.
   */
  addFade(baseClass: string): string[] {
    const classes = [baseClass]
    if (this.order !== 0 && this.isPublicPage) {
      classes.push(`${baseClass}--fade`)
    }
    return classes
  }
}
