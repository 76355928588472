<template>
  <aside class="early-access-cta">
    <div class="container">
      <div class="early-access-cta__content">
        <slot name="text" />
        <i class="iconfont ireward"></i>
      </div>
    </div>
  </aside>
</template>

<style lang="scss">
@import '@/assets.app/css/common/variables';

.early-access-cta {
  padding-block: 33px;

  &__content {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 28px 40px;
    background-color: $c-yellow-10;
    border: 1px solid $c-yellow;
    border-radius: 12px;

    @media only screen and (max-width: $w-mobile-s) {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding: 24px;
    }
  }

  &__text {
    width: 100%;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 4px;
    color: $c-black-2;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    color: $c-black-3;

    a,
    a:hover {
      text-decoration: underline;
    }
  }

  .iconfont {
    font-size: 52px;
    height: 52px;
    color: $c-yellow;

    @media only screen and (max-width: $w-mobile-s) {
      order: -1;
      font-size: 32px;
      height: 32px;
    }
  }
}

.night-mode .early-access-cta {
  background-color: rgba($color: $c-black-8, $alpha: 0.5);

  &__content {
    background-color: $c-yellow-9;
  }

  &__title {
    color: $c-black-11;
  }

  &__subtitle,
  &__subtitle a[href] {
    // the [href] is just to add specificity
    color: $c-dark-7;
  }
}
</style>
