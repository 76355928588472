
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { clickOutside } from '@/helpers/directives'
import { HeaderView } from '@/models/header'
import { nightMode } from '@/services/night'
import BasePopup from '@/components/ui/BasePopup.vue'
import { emitter } from '@/services/mitt'

@Options({
  directives: {
    clickOutside,
  },
  components: {
    BasePopup,
  },
})
export default class HeaderMenu extends Vue {
  @Prop() public view!: HeaderView

  public isNightModeActive: boolean = false

  public routerLink(link: string): Record<string, string> {
    return { name: link }
  }

  public async toggleNightMode(): Promise<void> {
    await this.view.toggleNightMode()
    emitter.emit('toggleNightMode')
  }

  mounted(): void {
    this.isNightModeActive = nightMode.active
    emitter.on('logoutOfflineFailure', () => {
      ;(this.$refs.logoutFailurePopup as any)?.show()
    })
    emitter.on('toggleNightMode', async () => {
      this.isNightModeActive = nightMode.active
    })
  }

  /**
   * Proxy function to call the view.hideMenu()
   *
   * This function is necessary for Vue to properly handle
   * reactivity and set the view._isMenuHidden property on
   * click outside.
   */
  hideMenu(): void {
    this.view.hideMenu()
  }
}
