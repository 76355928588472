
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'
import HeaderRoot from '@/app/components/header/Root.vue'
import CategoriesSelection from '@/app/components/listing/categories/CategoriesSelection.vue'
import InfiniteScrollBooksBlock from '@/app/components/listing/InfiniteScrollBooksBlock.vue'

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

import {
  BooksView,
  showReviewPrompt,
  trustpilotReviewPrompt,
} from '@/models/book'
import LoadingError from '@/components/ui/LoadingError.vue'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import { DocsTagsView } from '@/models/docs.tags'
import { RouteLocationNormalized } from 'vue-router'
import { PageType, createListingPage } from '@/models/docs.listing'
import { offline } from '@/services/offline'
import { loadBookListFromNativeStorage } from '@/services/native.storage'

@Options({
  components: {
    Layout,
    HeaderRoot,
    CategoriesSelection,
    LoadingSpinner,
    'v-wait': vLoading,
    LoadingError,
    InfiniteScrollBooksBlock,
  },
})
export default class BooksV4 extends Vue {
  booksView: BooksView | null = null
  tagsView: DocsTagsView | null = null
  infiniteScrollExperimentVariant: 'A' | 'B' = 'A'

  async beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): Promise<void> {
    if (from.name === 'book.page') {
      to.query = { from: from.name }
    }
  }

  async beforeMount(): Promise<void> {
    await this.loadBooks()
  }

  async mounted(): Promise<void> {
    // Check if we need to show the App Review Prompt
    // The trigger is going back to Books from reading a book. (#2812)
    const queryFrom = this.$router.currentRoute.value.query.from
    if (queryFrom === 'book.page') {
      await showReviewPrompt()
      await trustpilotReviewPrompt()
    }
  }

  async loadBooks(): Promise<void> {
    wait.start(this, 'Loading Books')
    try {
      if (offline.isOffline) {
        await loadBookListFromNativeStorage()
      }

      const listingPage = createListingPage(PageType.all)
      const response = await backend.getBooksWithRecommendations()

      this.booksView = await BooksView.createView(
        response,
        undefined,
        listingPage,
        true,
        undefined,
        response.tags,
      )

      this.tagsView = new DocsTagsView(response.tags, 'books')
      wait.end(this, 'Loading Books')
    } catch (error: any) {
      wait.start(this, 'Error Loading Books')
      Sentry.captureException(error)
    }
  }
}
