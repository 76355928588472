import { platform } from '@/services/platform'

/**
 * Handle the logic of the BottomSheet.vue component.
 */
export class SheetView {
  public isOpen: boolean = false
  private _componentHeight: number = 0
  private _startTime: number = 0
  private _startY: number = 0
  private _currentY: number = 0

  /**
   * Get the CSS variable that will control the element's position.
   */
  get currentPositionCssVariable(): string {
    return `--sheet-current-position: ${this._currentY}px`
  }

  /**
   * Close the sheet.
   */
  close(event?: MouseEvent): void {
    if (event && event.target !== event.currentTarget) {
      // Don't trigger it when clicking on the component's children
      return
    }
    this.isOpen = false
  }

  /**
   * Open the sheet.
   */
  open(): void {
    if (platform.isDesktopWidth()) {
      // The sheet should only open on tablet and mobile
      return
    }
    this.isOpen = true
    this._currentY = 0
  }

  /**
   * Handle the drag start.
   */
  handleTouchStart(event: TouchEvent): void {
    // This will be used to calculate the swipe velocity
    this._startTime = Date.now()
    // This will be used to calculate the swipe distance
    this._startY = event.touches[0].clientY

    const target = event.target as HTMLElement
    if (target) {
      this._componentHeight = target.clientHeight
    }
  }

  /**
   * Handle the middle of the drag action.
   */
  handleTouchMove(event: TouchEvent): void {
    // The current position of the finger
    const touchY = event.touches[0].clientY
    const distanceMoved = touchY - this._startY

    if (distanceMoved <= 0) {
      // Don't let the component move beyond its initial position
      this._currentY = 0
    } else {
      this._currentY = distanceMoved
    }
  }

  /**
   * Handle the drag end.
   */
  handleTouchEnd(): void {
    const endTime = Date.now()

    // The time spent dragging
    const timeSpan = endTime - this._startTime
    // The velocity the drag action
    const velocity = this._currentY / timeSpan

    // Threshold velocity in pixels per millisecond.
    // There's not much science to this number, it's just
    // a value that feels good.
    const velocityThreshold = 0.15
    const halfComponentHeight = this._componentHeight / 2

    if (velocity > velocityThreshold || this._currentY > halfComponentHeight) {
      // If the user swiped quick enough or if they
      // swiped to below half the height of the element,
      // close it.
      this.close()
    } else {
      // Spring it back to its initial position
      this._currentY = 0
    }
  }
}
