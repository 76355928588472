import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header__inner" }
const _hoisted_2 = { class: "header__title-wrapper" }
const _hoisted_3 = { class: "header__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocsSortButtonMobile = _resolveComponent("DocsSortButtonMobile")!
  const _component_StartFreeTrialButton = _resolveComponent("StartFreeTrialButton")!
  const _component_HeaderUserMenu = _resolveComponent("HeaderUserMenu")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header header--mobile", { 'header--ios': _ctx.view.isIOS }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ]),
      (_ctx.hasDocSort)
        ? (_openBlock(), _createBlock(_component_DocsSortButtonMobile, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.view.showCtaButtonOnMobile)
        ? (_openBlock(), _createBlock(_component_StartFreeTrialButton, {
            key: 1,
            view: _ctx.view
          }, null, 8, ["view"]))
        : _createCommentVNode("", true),
      _createVNode(_component_HeaderUserMenu, { view: _ctx.view }, null, 8, ["view"])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}