
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { PasswordResetView } from '@/models/auth/models'
import { authCssFix } from '@/helpers/html'

import FormError from '@/components/ui/FormError.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

@Options({
  components: {
    FormError,
    InputWithContextMenu,
  },
})
export default class PasswordReset extends Vue {
  view: PasswordResetView = new PasswordResetView()

  mounted(): void {
    const token = this.$route.query.reset_token
    this.view.initResetToken(token as string)

    authCssFix()
  }

  async submitForm(): Promise<void> {
    this.view.submitForm()
  }
}
