import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-932abf6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header__controls icon-toolbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_AddToFavorite = _resolveComponent("AddToFavorite")!
  const _component_ExportBookButton = _resolveComponent("ExportBookButton")!
  const _component_ShareMenu = _resolveComponent("ShareMenu")!
  const _component_ActionsMenu = _resolveComponent("ActionsMenu")!

  return (_ctx.doc)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["header header--book header--book-preview", { 'header--book-preview-rounded': _ctx.isRounded }])
      }, [
        _createVNode(_component_BackLink, { class: "header__backlink" }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_AddToFavorite, {
            class: "book-preview-favorite-btn icon-toolbar__item icon-toolbar__item--separator",
            doc: _ctx.doc
          }, null, 8, ["doc"]),
          (_ctx.doc.showDownload)
            ? (_openBlock(), _createBlock(_component_ExportBookButton, {
                key: 0,
                class: "book-preview-download-btn icon-toolbar__item icon-toolbar__item--separator",
                doc: _ctx.doc
              }, null, 8, ["doc"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ShareMenu, {
            class: "book-preview-share-btn icon-toolbar__item icon-toolbar__item--separator",
            doc: _ctx.doc
          }, null, 8, ["doc"]),
          _createVNode(_component_ActionsMenu, {
            class: "book-preview-actions-btn icon-toolbar__item icon-toolbar__item--separator",
            doc: _ctx.doc
          }, null, 8, ["doc"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}