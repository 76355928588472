<template>
  <section class="billing-box question-box">
    <div class="container">
      <div class="question-box__content billing-box__content">
        <h2 class="billing-box__title question-box__title">
          Have
          <span class="billing-box__title--yellow-text">any questions</span>
          before upgrading?
        </h2>
        <p class="billing-box__text question-box__text">
          If you have any questions about your Shortform subscription, get in
          touch with us. We'd be glad to help you.
        </p>
        <router-link
          :to="{ name: 'help' }"
          class="billing-box__btn btns btns--largest btns--font-large"
          >Contact Us</router-link
        >
      </div>
    </div>
  </section>
</template>
