<template>
  <footer class="short-onboarding__footer">
    Copyright © {{ new Date().getFullYear() }} ShortForm™ | All Rights Reserved
  </footer>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/variables.scss';

.short-onboarding__footer {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: $c-black-6;

  padding: 20px 280px;
  border-top: 1px solid $c-black-13;

  @media (max-width: $w-large-s) {
    padding: 20px 48px;
  }

  @media (max-width: $w-medium-s) {
    font-size: 12px;
    line-height: 20px;
  }
}
</style>
