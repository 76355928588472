/**
 * Append the current URL query params to the given path.
 *
 * In case when additional params object has the same key
 * as the one that's already present in the current URL params,
 * the value from the additional params object will be used.
 *
 * @param path - The path to append the params to.
 * @param additionalParams - The object with additional params to append.
 *
 * @example
 * Assuming that the current URL query is `?key1=value1`:
 *
 * appendCurrentParamsToPath('/path')
 * // => '/path?key1=value1'
 * appendCurrentParamsToPath('/path', { key2: 'value2' })
 * // => '/path?key1=value1&key2=value2'
 * appendCurrentParamsToPath('/path', { key2: 'differentValue' })
 * // => '/path?key1=value1&key2=differentValue'
 *
 * @returns Path with appended params.
 */
export function appendCurrentParamsToPath(
  path: string,
  additionalParams?: Record<string, string>,
): string {
  const currentParams = window.location.search

  if (!additionalParams) {
    return path + currentParams
  }

  const newParams = new URLSearchParams(currentParams)

  // Adds additional params to the params object, overwriting existing ones.
  Object.entries(additionalParams).forEach(([key, value]) => {
    newParams.set(key, value)
  })

  return `${path}?${newParams.toString()}`
}
