
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import CommentComponent from '@/app/components/community/Comment.vue'
import CommentReply from '@/app/components/community/CommentReply.vue'

import { Comment } from '@/models/interfaces'
import { DocView } from '@/models/doc'
import { ThreadView } from '@/models/forum'

@Options({
  components: {
    CommentComponent,
    CommentReply,
  },
})
export default class Thread extends Vue {
  @Prop() private doc!: DocView
  @Prop() private thread!: ThreadView
  replyComment: Comment | null = null

  isShowReply(comment: Comment): boolean {
    return !!this.replyComment && this.replyComment === comment
  }

  showReply(comment: Comment): void {
    this.replyComment = comment
  }

  hideReply(): void {
    this.replyComment = null
  }
}
