
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import BillingSubscribeButton from './BillingSubscribeButton.vue'

import { BillingView, PlanView } from '@/models/billing'

@Options({
  components: {
    BillingSubscribeButton,
  },
})
export default class PlanMonthly extends Vue {
  @Prop() private billing!: BillingView
  @Prop() private plan!: PlanView
}
