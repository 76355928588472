
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'
import QuestionnaireLikeBar from '@/app/components/questionnaire/LikeBar.vue'
import { scrollIntoView } from '@/helpers/dom'

@Options({
  components: { QuestionnaireLikeBar },
})
export default class QuestionnaireQuote extends Vue {
  @Prop() view!: QuestionnaireView

  get coverUrl(): string {
    return require(`@/assets.app/images/${this.view.currentQuote.background_image}`)
  }

  async updated(): Promise<void> {
    // Scroll the wrapper to show the quote title on tablet/mobile width
    scrollIntoView('.onboarding-small__title')
  }
}
