import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subscription-content-c" }
const _hoisted_2 = { class: "subscription-content-c__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BillingSubscribeButton = _resolveComponent("BillingSubscribeButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_BillingSubscribeButton, {
      billing: _ctx.billing,
      plan: _ctx.plan
    }, null, 8, ["billing", "plan"])
  ]))
}