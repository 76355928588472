'use strict'

const ESCAPE_MAP: { [Key: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#47;',
}

// The escapeHtml sanitizes special characters in text that could be interpreted as
// HTML.
export function escapeHtml(str: string): string {
  return String(str).replace(/[&<>"'\/]/g, function (c) {
    return ESCAPE_MAP[c]
  })
}

// I18N
export const gettext = (function () {
  // @ts-ignore
  if (typeof global.Gettext === 'function') {
    // @ts-ignore
    const _gettext = new global.Gettext({ domain: 'annotator' })
    return function (msgid: any) {
      return _gettext.gettext(msgid)
    }
  }

  return function (msgid: any) {
    return msgid
  }
})()
