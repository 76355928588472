import { ComponentPublicInstance } from 'vue'
import { nightMode } from '@/services/night'
import { BookTutorial, BookTutorialStep } from './doc.tutorial'
import { DocTocView } from './doc.toc'
import { DESKTOP_WIDTH } from '@/init/settings'
import { DocView } from './doc'
import { emitter } from '@/services/mitt'

/**
 * Doc controls bar logic.
 *
 * The logic here is used by @/components/Sidebar.vue.
 * It is displayed as a sidebar on desktop and as a bottom bar on mobile.
 */
export class DocControls {
  private _vm: ComponentPublicInstance
  private _view: DocView

  private _bookControlVisible: boolean = true

  private _readingSettingsVisible: boolean = false

  // We only render the player component after clicking Play button
  // this way audio file does not start loading until we show the player.
  private _isPlayerRendered: boolean = false
  // Once player is rendered, we can show/hide it, this flag controls visibility.
  private _playerVisible: boolean = false

  private _isNightModeActive: boolean = false

  private _bookTutorial: BookTutorial | null = null
  private _bookTOC: DocTocView | null = null

  constructor(view: DocView, vm: ComponentPublicInstance) {
    this._view = view
    this._vm = vm
    this._isNightModeActive = nightMode.active
  }

  /**
   * Show the sidebar on window resize.
   *
   * See @/app/components/Sidebar.vue where we handle the windowResize event.
   * We have to make the sidebar visible when the window size is changed from a
   * mobile/tablet window size to a desktop size, as on desktop the sidebar should
   * always be visible whereas on mobile/tablet it might get hidden.
   */
  onWindowResize(): void {
    if (window.innerWidth >= DESKTOP_WIDTH) {
      this.show()
    }
  }

  /**
   * Toggle the sidebar on book content click.
   *
   * See @/app/components/Sidebar.vue where we handle the docContentClicked event.
   *
   * Hide the book control if it is visible while docContentClicked event is triggered.
   * Show the book control if it is hidden while docContentClicked event is triggered.
   */
  onDocContentClicked(event: any): void {
    // Do not hide controls when clicking links or buttons
    if (event.target.tagName === 'A' || event.target.tagName === 'BUTTON') {
      return
    }
    // Do not hide controls on desktop screens,
    // since control is shown on the left side and we never hide it.
    if (window.innerWidth >= DESKTOP_WIDTH) {
      return
    }

    // Do not hide the book control if book tutorial is open.
    if (this._view.keepSidebarAndHeaderOpen()) {
      return
    }

    this._bookControlVisible = !this._bookControlVisible
  }

  onToggleNightMode(): void {
    this._isNightModeActive = nightMode.active
  }

  show(): void {
    this._bookControlVisible = true
  }

  hide(): void {
    this._bookControlVisible = false
  }

  get bookSidebarClass(): string {
    return this._bookControlVisible ? 'control--active' : 'control--hide'
  }

  get playerClass(): Record<string, boolean> {
    return {
      'player--show': this._playerVisible,
      'player--hide': !this._playerVisible,
      'player--control': this._bookControlVisible,
    }
  }

  get isPlayerRendered(): boolean {
    return this._isPlayerRendered
  }

  get containerClass(): string {
    return this._playerVisible ? 'control-container--player-active' : ''
  }

  get readingSettingsClass(): Record<string, boolean> {
    return {
      'control__btn font-btn': true,
      'control__btn--active': this._readingSettingsVisible,
    }
  }

  togglePlayer(event: Event): void {
    // First `togglePlayer` call will set _isPlayerRendered flag to true.
    // After that, we can keep it rendered, it will be shown/hidden
    // with CSS.
    this._isPlayerRendered = true

    this._playerVisible = !this._playerVisible
    event.stopPropagation()
  }

  async toggleNightMode(): Promise<void> {
    await nightMode.toggle()
    emitter.emit('toggleNightMode')
  }

  toggleReadingSettings($event: Event): void {
    $event.preventDefault()
    $event.stopImmediatePropagation()

    /**
    The HTML structue of the settings button and popup is approximately this:
    <div title="Reading settings" class="control__btn font-btn">
     <div class="control__icon-box">
       <span class="control__icon iconfont icontrol-font"></span>
     </div>
     <div class="control-font">
       .. Hidden initially, settings dialog
     </div>
    </div>

    Here the whole outer div serves as a button and a popup div is placed inside it, hidden initially.
    This way, the button is the parent element of the settings dialog.
    We have a click handler attached to the button, but it also receives clicks
    on the dialog itself (since it is a child).

    The check below skips these dialog clicks, so the dialog will only disappear when we click the
    button again and not on the dialog body click.

    Note: a proper solution would be to move the dialog out of the button, but it requires both
    HTML structure and CSS changes, so we keep it as it is for now.

    **/
    let childClick = false
    const parentElement = ($event.target as HTMLElement).parentElement
    if (parentElement !== null) {
      parentElement.classList.forEach(function (element: string) {
        if (element.includes('control-font') || element.includes('select-c')) {
          childClick = true
        }
      })
    }

    if (childClick && this._readingSettingsVisible) {
      return
    }
    this._readingSettingsVisible = !this._readingSettingsVisible
  }

  hideReadingSettings(): void {
    this._readingSettingsVisible = false
  }

  get readingSettingsVisible(): boolean {
    return this._readingSettingsVisible
  }

  get playerBtnClass(): string[] {
    return [
      this._playerVisible ? 'control__btn--active' : '',
      this.tutorialBtnClass(BookTutorialStep.player),
    ]
  }

  get favoriteBtnClass(): string[] {
    return [this.tutorialBtnClass(BookTutorialStep.favorite)]
  }

  get nightModeBtnClass(): string[] {
    return [this.tutorialBtnClass(BookTutorialStep.night)]
  }

  get nightModeIconClass(): string[] {
    const activeClass = this._isNightModeActive
      ? 'icontrol-sun'
      : 'icontrol-night'
    return ['control__icon', 'iconfont', activeClass]
  }

  get fontsBtnClass(): string[] {
    return [this.tutorialBtnClass(BookTutorialStep.fonts)]
  }

  tutorialBtnClass(btnStep: BookTutorialStep): string {
    // Tutorial is enabled only for Book pages, if this is an Article page,
    // the property would be empty and we don't need to alter button classes
    if (!this._view.tutorial) {
      return ''
    }
    return this._view.tutorial.step === btnStep ? 'control__btn--tutorial' : ''
  }
}
