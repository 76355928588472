import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "layout__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_Layout = _resolveComponent("Layout")!
  const _component_ArticleContent = _resolveComponent("ArticleContent")!
  const _component_LoadingError = _resolveComponent("LoadingError")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isLoadingDoc)
      ? (_openBlock(), _createBlock(_component_Layout, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_loading_spinner)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.article)
      ? (_openBlock(), _createBlock(_component_ArticleContent, {
          key: 1,
          article: _ctx.article
        }, null, 8, ["article"]))
      : _createCommentVNode("", true),
    (!_ctx.article && _ctx.isErrorLoadingDoc)
      ? (_openBlock(), _createBlock(_component_Layout, { key: 2 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_LoadingError)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}