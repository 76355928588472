// Register Vue router hooks for class-based components.
// Should be done before importing Vue and other components.
// Should be imported from the external module (doesn't work as inline code).
import { initClassComponentHooks } from '@/init/class-component-hooks'
initClassComponentHooks()

import '@/assets.app/css/style.scss'
// Load annotator CSS code.
import '@/app/annotator/css/annotator.css'

import { createApp, h } from 'vue'
import App from './App.vue'
import { router } from './router'

import { registerServiceWorker } from '@/init/registerServiceWorker'
import { sentryInit } from '@/init/sentry-init'
import { initMixpanel } from '@/init/mixpanel'
import { initWebVitals } from '@/init/webVitals'

import { initAB } from '@/services/ab'
import { init as initNativeApp } from '@/init/native.app'
import { clickOutside } from '@/helpers/directives'

// Init Stripe.js
import { initStripe } from '@/init/stripe-init'

import { createVueWait } from 'vue-wait'
import VueLazyLoad from 'vue-lazyload-next'

// Global UI components.
import Logo from '@/components/ui/Logo.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import LoadingError from '@/components/ui/LoadingError.vue'

import { lazyLoadConfig } from '@/services/lazy'

registerServiceWorker()
sentryInit()
initMixpanel()
initWebVitals()
initStripe()

const app = createApp({
  router,
  render: (): any => h(App),
})

// Set global Vue instance, it is used by router
// helpers in @/helpers/router.
// @ts-ignore
window._vueRouter = router

app.use(router)

const VueWait = createVueWait()
app.use(VueWait)

app.use(VueLazyLoad, lazyLoadConfig)

app.component('Logo', Logo)
app.component('ButtonSpinner', ButtonSpinner)
app.component('LoadingSpinner', LoadingSpinner)
app.component('LoadingError', LoadingError)
app.directive('click-outside', clickOutside)

app.mount('#appRoot')

document.addEventListener('DOMContentLoaded', function () {
  initAB()
})

initNativeApp(router)
