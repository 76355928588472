
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'

import DocLayout from '@/app/components/doc/Layout.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

import UsernameInput from './UsernameInput.vue'

import { ValidationState } from '@/helpers/form'
import { wait } from '@/helpers/vue-wait'
import { auth } from '@/services/auth'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { ThreadView } from '@/models/forum'
import { setInputFocusStart } from '@/helpers/form'
import { DocView } from '@/models/doc'

const EMPTY_THREAD = { id: null, title: '', text: '' }

@Options({
  components: {
    DocLayout,
    FormError,
    UsernameInput,
    'v-wait': vLoading,
    ButtonSpinner,
    InputWithContextMenu,
  },
})
export default class ThreadEdit extends Vue {
  @Prop() private doc!: DocView

  private threadUrlSlug!: string

  isLoading: boolean = false
  validation: ValidationState = new ValidationState()
  model: any = Object.assign({}, EMPTY_THREAD)

  mounted(): void {
    // Retrieve the threadUrlSlug from the history api state
    this.threadUrlSlug = window.history.state.threadUrlSlug
    const threadTitle = window.history.state.threadTitle
    const threadText = window.history.state.threadText
    const userWorkoutId = window.history.state.userWorkoutId

    if (!this.threadUrlSlug) {
      // The component was pre-filled with the exercise content,
      // put the cursor at the start of the text
      const input: HTMLInputElement = (this.$refs.text as any)
        .$el as HTMLInputElement
      setInputFocusStart(input)
    } else {
      this.loadThread()
    }
    if (threadTitle) {
      this.model.title = threadTitle
    }
    if (threadText) {
      this.model.text = threadText
    }
    if (userWorkoutId) {
      this.model.user_workout_id = userWorkoutId
    }
  }

  get hasUsername(): boolean {
    return !!auth.getUsername()
  }

  async save(): Promise<void> {
    try {
      if (!this.model) {
        throw new Error('Can not save thread, no model')
      }
      wait.start(this, 'Posting')
      const thread = await backend.saveThread(this.doc, this.model)
      this.validation.reset()
      this.$router.push({
        name: this.doc.routeNames().thread,
        params: { thread: thread.url_slug },
      })
    } catch (error) {
      this.validation.showErrors(error)
    } finally {
      wait.end(this, 'Posting')
    }
  }

  async loadThread(): Promise<void> {
    try {
      this.isLoading = true
      const response = await backend.getThread(this.threadUrlSlug)
      this.model = new ThreadView(response)
      this.isLoading = false
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        Sentry.captureException(error)
        throw error
      }
    }
  }
}
