import { IOS_APP_ID } from '@/init/settings'
import { Mixpanel } from '@/analytics/mixpanel'
import { platform } from '@/services/platform'
import { Sentry } from '@/services/sentry'

async function checkIsNativePromptAvailable(): Promise<boolean> {
  return new Promise(function (resolve, reject) {
    cordova.exec(resolve, reject, 'AppRate', 'isNativePromptAvailable', [])
  })
}

export async function promptForRating(): Promise<boolean> {
  const deviceInfo = await platform.deviceInfo()

  const IS_IOS = deviceInfo.platform === 'ios'
  const IS_ANDROID = deviceInfo.platform === 'android'

  if (!IS_IOS && !IS_ANDROID) {
    return false
  }

  if (!window.cordova) {
    Sentry.captureMessage('No window.cordova, promptForRating call')
    return false
  }

  // App rate plugin does not register itself on window.cordova.plugins,
  // nor in capacitor's Plugins, only exposes global object.
  // @ts-ignore
  const AppRate = window.AppRate

  if (!AppRate) {
    Sentry.captureMessage(
      'No AppRate, probably old native app, skip review prompt',
    )
    return false
  }

  const isNativePromptAvailable = await checkIsNativePromptAvailable()
  if (!isNativePromptAvailable) {
    Sentry.captureMessage('Native prompt is not available')
    return false
  }

  Mixpanel.trackAppReviewShow()

  // This starts the defualt AppRate plugin's process that also has a pre-prompt.
  // AppRate.promptForRating()

  // To skip the default AppRate logic, we manually invoke native implementations
  // via cordova.exec - native implementations will only prompt for review
  // with no extra logic.
  AppRate.ready.then(function () {
    const resolve = (result: any): void => {
      if (window.__UNIT_TESTING !== true) {
        console.log(result)
      }
      Sentry.captureMessage('Review Success')
      Mixpanel.trackAppReviewSuccess()
    }
    const reject = (error: any): void => {
      if (window.__UNIT_TESTING !== true) {
        console.log(error)
      }
      Sentry.captureMessage('Review Error')
      Mixpanel.trackAppReviewFailure()
    }

    if (IS_ANDROID) {
      cordova.exec(resolve, reject, 'AppRate', 'launchReview', [])
    }
    if (IS_IOS) {
      cordova.exec(resolve, reject, 'AppRate', 'launchiOSReview', [
        IOS_APP_ID,
        true,
      ])
    }
  })
  // TODO: maybe return promise and return `true` from resolve callback?
  // For now it should be enough just returning `true` here and `false` in
  // all the cases where we skip the review prompt.
  return true
}
