
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

@Options({})
export default class InfoWhyGreat extends Vue {
  @Prop() private imageIndex!: number

  imgClass(): string {
    return `great-unit__img great-unit__img--0${this.imageIndex}`
  }
}
