
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BookTutorial, BookTutorialStep } from '@/models/doc.tutorial'

import SidebarTutorialStepFavorite from './SidebarTutorialStepFavorite.vue'
import SidebarTutorialStepFonts from './SidebarTutorialStepFonts.vue'
import SidebarTutorialStepNight from './SidebarTutorialStepNight.vue'
import SidebarTutorialStepPlayer from './SidebarTutorialStepPlayer.vue'
import SidebarTutorialStepTOC from './SidebarTutorialStepTOC.vue'

enum BookTutorialSidebarStep {
  fonts = BookTutorialStep.fonts,
  toc = BookTutorialStep.toc,
  player = BookTutorialStep.player,
  favorite = BookTutorialStep.favorite,
  night = BookTutorialStep.night,
}

/* We use dynamic rendering for sidebar tutorial steps because there are books
 * that don't have audio narrations and we need to skip Player button step. The
 * CSS is made the way it uses n-th child selectors, so if we just hide the step
 * (with v-if of v-show) it's still present in the DOM, which makes all further
 * steps misaligned, so we need to actually add only the needed components to
 * the Tutorial Sidebar - thus we use dynamic rendering here.
 */
const COMPONENTS = {
  [BookTutorialSidebarStep.fonts]: 'SidebarTutorialStepFonts',
  [BookTutorialSidebarStep.toc]: 'SidebarTutorialStepTOC',
  [BookTutorialSidebarStep.player]: 'SidebarTutorialStepPlayer',
  [BookTutorialSidebarStep.favorite]: 'SidebarTutorialStepFavorite',
  [BookTutorialSidebarStep.night]: 'SidebarTutorialStepNight',
}

@Options({
  components: {
    SidebarTutorialStepFavorite,
    SidebarTutorialStepFonts,
    SidebarTutorialStepNight,
    SidebarTutorialStepPlayer,
    SidebarTutorialStepTOC,
  },
})
export default class SidebarTutorial extends Vue {
  @Prop() private bookTutorial!: BookTutorial

  public componentName(step: BookTutorialSidebarStep): string {
    return COMPONENTS[step]
  }
}
