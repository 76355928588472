
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { Sentry } from '@/services/sentry'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import FormError from '@/components/ui/FormError.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { ValidationState } from '@/helpers/form'

import DisablePopup from '@/app/components/export/DisablePopup.vue'
import { backend } from '@/services/backend'
import { wait } from '@/helpers/vue-wait'

export function parseDatabaseUrl(urlString: string): string {
  const url = new URL(urlString)
  const pathname = url.pathname // After the domain, before the query string.
  const parts = pathname.split('/')
  return parts[parts.length - 1] // After any slashes.
}

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    DisablePopup,
    InputWithContextMenu,
  },
})
export default class NotionSettings extends Vue {
  validation: ValidationState = new ValidationState()
  token: string | null = null
  database: string | null = null

  @Prop({ default: false }) protected isNotionEnabled!: boolean

  async beforeMount(): Promise<void> {
    await this.refresh()
    this.validation.reset()
  }

  async refresh(): Promise<void> {
    const credentials = await backend.getNotionCredentials()
    this.token = credentials.notion_integration_token
    if (!credentials.notion_database_id) {
      this.database = null
    } else {
      this.database = `https://www.notion.so/${credentials.notion_database_id}`
    }
    this.$emit('update-enabled', this.token !== null && this.database !== null)
  }

  async checkForm(): Promise<void> {
    if (this.isNotionEnabled) {
      this.disableNotion()
    } else {
      await this.enableNotion()
    }
  }

  getErrorMessage(error: any): string {
    if (error.name === 'TypeError' && error.toString().includes('URL')) {
      return 'Invalid Notion Link'
    } else if (error.response === undefined) {
      Sentry.captureException(error)
      return 'Could not talk to backend. Network or authorisation error?'
    } else {
      const maybeErrorMessage = error?.response?.data?.errors?._schema?.[0]
      if (maybeErrorMessage === 'Insufficient permissions for this endpoint.') {
        return 'Make sure your integration has read and insert capabilities.'
      }
      Sentry.captureException(error)
      return (
        maybeErrorMessage ?? 'Notion integration error. Please contact support.'
      )
    }
  }

  async enableNotion(): Promise<void> {
    try {
      this.validation.reset()
      wait.start(this, 'Enabling')
      const credentials = {
        notion_database_id: parseDatabaseUrl(this.database || ''),
        notion_integration_token: this.token || '',
      }
      await backend.saveNotionCredentials(credentials)
      this.$emit('update-enabled', true)
    } catch (error) {
      this.validation.showMessage('notion_token', this.getErrorMessage(error))
    } finally {
      wait.end(this, 'Enabling')
    }
  }

  disableNotion(): void {
    ;(this.$refs.popupDisable as any).show()
  }

  async disableIntegration(): Promise<void> {
    await backend.removeNotionCredentials()
  }
}
