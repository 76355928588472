
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import DocLayout from '@/app/components/doc/Layout.vue'
import Hero from '@/app/components/doc/highlights/Hero.vue'
import HighlightsList from '@/app/components/doc/highlights/HighlightsList.vue'

import { DocView } from '@/models/doc'

@Options({
  components: {
    DocLayout,
    Hero,
    HighlightsList,
  },
})
export default class BookHighlights extends Vue {
  @Prop() private doc!: DocView
}
