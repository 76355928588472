import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1323525b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stripe-card" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "alert alert-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_StripeElement = _resolveComponent("StripeElement")!
  const _component_StripeElements = _resolveComponent("StripeElements")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_v_wait = _resolveComponent("v-wait")!

  return (_openBlock(), _createBlock(_component_v_wait, {
    class: "stripe-card__wrapper",
    for: "ABExperimentRequest"
  }, {
    waiting: _withCtx(() => [
      _createVNode(_component_LoadingSpinner)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StripeElements, {
          ref: "elms",
          "stripe-key": _ctx.stripeKey,
          "instance-options": _ctx.instanceOptions,
          "elements-options": _ctx.elementsOptions
        }, {
          default: _withCtx(({ elements, instance }) => [
            _createVNode(_component_StripeElement, {
              ref: "card",
              elements: elements,
              options: _ctx.cardOptions,
              onChange: _ctx.changedHandler
            }, null, 8, ["elements", "options", "onChange"])
          ]),
          _: 1
        }, 8, ["stripe-key", "instance-options", "elements-options"])
      ]),
      _createVNode(_component_FormError, {
        class: _normalizeClass(_ctx.validation.stateClass('card') === 'is-invalid' ? 'd-block' : ''),
        errors: _ctx.validation.errors,
        field: "card"
      }, null, 8, ["class", "errors"]),
      (_ctx.showButton)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-primary mt-3",
            disabled: !_ctx.complete || _ctx.saving,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)))
          }, " Save Card ", 8, _hoisted_2))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("div", _hoisted_3, "Card Saved", 512), [
        [_vShow, _ctx.savedAlert]
      ])
    ]),
    _: 1
  }))
}