import * as TypedAssert from 'typed-assert'

import {
  FirebaseError,
  initializeApp,
  deleteApp,
  FirebaseApp,
} from 'firebase/app'
import {
  isSupported,
  getMessaging,
  onMessage,
  getToken,
  Messaging,
  deleteToken,
} from 'firebase/messaging'
import { backend } from '@/services/backend'
import { FIREBASE_CONFIG } from '@/init/settings'
import { Sentry } from '@/services/sentry'
import { platform } from '@/services/platform'
import {
  FirebaseMessaging,
  NotificationActionPerformedEvent,
  NotificationReceivedEvent,
} from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { Mixpanel } from '@/analytics/mixpanel'
import {
  ActionPerformed,
  LocalNotifications,
  LocalNotificationSchema,
} from '@capacitor/local-notifications'

interface NotificationServiceInterface {
  shouldBeInitialized(): Promise<boolean>

  /**
   * Returns the firebase token.
   * This method should not register the token with the backend.
   * This method should not fail. Instead, undefined should be returned
   * if the firebase initialization does not succeed.
   */
  init(router: any): Promise<string | undefined>

  /**
   * Deregister the current app from firebase.
   * This method should not delete the token from the backend.
   * This method should not fail.
   */
  unsubscribe(): Promise<void>
}

/**
 * Service for operations related to push notifications. Implementation depends on the
 * platform - on web the Firebase Javascript SDK is used and in native apps a native
 * Capacitor plugin is used.
 */
class NotificationService {
  private _service: undefined | NotificationServiceInterface

  /**
   * Firebase token as retrieved from either the web-sdk or the
   * firebase-messaging native plugin.
   */
  private token: string | undefined

  /**
   * Has the initialization been run before?
   * A failed initialization counts towards "finished".
   */
  private initializationFinished: boolean = false

  /**
   * Initialize push notifications.
   *
   * Triggers native prompt to ask for notifications permissions (only if it hasn't
   * been answered yet).
   *
   * If permissions are granted, gets a firebase token and registers it with
   * the backend.
   */
  async init(router: any, currentPage: string): Promise<void> {
    // Check if initialization can happen on the current page.
    // Don't run initialization twice.
    if (
      !this.canInitializeNotificationsOnPage(currentPage) ||
      this.initializationFinished
    ) {
      return
    }

    // Any way this method terminates, we don't want to repeat
    // attempting to initialize.
    this.initializationFinished = true

    const service = await this.service()

    // Check if we should even attempt to init.
    if (!(await service.shouldBeInitialized())) {
      return
    }

    // Register with firebase.
    this.token = await service.init(router)

    // Track result of initialization. At this point we have web, iOS, and
    // Android clients that succeeded in acquiring a token. On Android pre-13
    // we always get granted, because it is hardcoded. To evaluate the whether
    // we should support web, we evaluate the ratio of iOS native vs. Web
    // clients. We get this code path once per device when denying
    // notifications and every time on app-load when allowing notifications.
    // To properly interpret the results we need to group by user and device.
    // We can't use profile properties because these can't hold multiple
    // devices.
    if (!(await platform.isNativeAndroidApp())) {
      Mixpanel.trackNotificationPermission(this.token !== undefined)
    }

    if (this.token === undefined) {
      // If firebase initialization fails, then we just quit.
      return
    }

    // Register with our backend.
    try {
      await backend.createToken(this.token)
    } catch {
      // If this fails, then we deinitialize and quit.
      await service.unsubscribe()
      this.token = undefined
      return
    }
  }

  canInitializeNotificationsOnPage(page: string): boolean {
    // Firebase notifications will not be initialized on these pages.
    const ignoredPages = [
      '/app/questionnaire',
      '/app/login',
      '/app/signup',
      '/app/password',
      '/app/404',
      '/app/billing',
    ]

    return !ignoredPages.some((ignore) => page.startsWith(ignore))
  }

  /**
   * Unsubscribe the device from push notifications.
   *
   * Deletes this device's token from the backend.
   */
  async unsubscribe(): Promise<void> {
    // Delete from firebase.
    const service = await this.service()
    await service.unsubscribe()

    // Delete from our backend.
    try {
      if (this.token !== undefined) {
        await backend.deleteToken(this.token)
      }
    } catch {
      // Ignore any error that might occur here.
    }
  }

  /**
   * Returns a platform specific service object for operations related to
   * push notifications.
   */
  private async service(): Promise<NotificationServiceInterface> {
    if (this._service) {
      return this._service
    }

    if (await platform.isNativeApp()) {
      this._service = new NotificationServiceNative()
    } else {
      this._service = new NotificationServiceWeb()
    }
    return this._service
  }
}

export const notificationService = new NotificationService()

/**
 * Push notifications implementation for web platform. Uses Firebase through the
 * Firebase Javascript SDK.
 */
class NotificationServiceWeb implements NotificationServiceInterface {
  private app: FirebaseApp | undefined
  private messaging: Messaging | undefined

  async shouldBeInitialized(): Promise<boolean> {
    // If the current platform is not supported, then we can't do anything.
    if (!(await isSupported())) {
      return false
    }

    // This condition is necessary but it is not enforced by `isSupported`.
    if (!('Notification' in window)) {
      return false
    }

    // If the user has explicitly denied notifications,
    // it's pointless to initialize FCM.
    if (Notification.permission === 'denied') {
      return false
    }

    return true
  }

  async init(): Promise<string | undefined> {
    let token: string
    try {
      // Initialize Firebase SDK and get messaging instance.
      this.app = initializeApp(FIREBASE_CONFIG)
      this.messaging = getMessaging(this.app)

      // Ask the user for permissions.
      // If allowed, subscribe to push notifications and register the
      // push-url with firebase to get a firebase token.
      token = await getToken(this.messaging)
    } catch (e) {
      // The error code below comes from firebase.
      // We expect a Firebase-permission-blocked error here.
      // Everything else is unexpected and is logged to sentry.
      if (
        !(e instanceof FirebaseError) ||
        e.code !== 'messaging/permission-blocked'
      ) {
        Sentry.captureException(e)
      }

      try {
        TypedAssert.isNotUndefined(this.app)
        deleteApp(this.app)
      } catch {
        // We ignore anything that fails during SDK deinitialization.
      }

      return undefined
    }

    // Handle messages received when the app is active.
    // TODO The foreground handler will be used to log to mixpanel.
    onMessage(this.messaging, this.handleForegroundMessage.bind(this))

    return token
  }

  async unsubscribe(): Promise<void> {
    try {
      // Unsubscribe from the Push API.
      TypedAssert.isNotUndefined(this.messaging)
      deleteToken(this.messaging)
    } catch {
      // We ignore any error that surfaces here.
    }

    try {
      // Delete the app.
      TypedAssert.isNotUndefined(this.app)
      deleteApp(this.app)
    } catch {
      // We ignore anything that fails during SDK deinitialization.
    }
  }

  /**
   * Handle foreground messages.
   * Display them using `registration` of the firebase service worker.
   */
  private async handleForegroundMessage(payload: any): Promise<void> {
    const scope = 'firebase-cloud-messaging-push-scope'
    const registration = await navigator.serviceWorker.getRegistration(scope)

    // Theoretically registration may be null, i.e. in some edge cases.
    // If there is no registration for the firebase service worker, then
    // ignore the foreground message.
    if (registration !== undefined) {
      const {
        data: { link },
        notification: { title, image, body },
      } = payload
      const options = { image, body, data: { link } }
      registration.showNotification(title, options)
    }
  }
}

/**
 * We map topics to notification channels. This here is a mirror of
 * `app.notification.logic.ATTRIBUTE_TOPICS_MAPPING` and holds the topic-
 * -to-humanization strings. They are used to manage the notification channels.
 */
export const notificationChannels = {
  NEW_RELEASE: 'New Releases',
}

/**
 * Push notifications implementation for native apps. Uses Firebase through a native
 * Capacitor plugin.
 *
 * The native implementation doesn't have to be deinitialized on failure.
 */
class NotificationServiceNative implements NotificationServiceInterface {
  /**
   * Returns true if push notifications should be initialized in native apps.
   * Checks if the necessary plugin is installed and supported on the device.
   */
  async shouldBeInitialized(): Promise<boolean> {
    if (!Capacitor.isPluginAvailable('FirebaseMessaging')) {
      return false
    }

    if (!Capacitor.isPluginAvailable('LocalNotifications')) {
      return false
    }

    return (
      (await FirebaseMessaging.isSupported()).isSupported &&
      // If the user explicitly denied push notifications, then there's no reason even trying.
      (await FirebaseMessaging.checkPermissions()).receive !== 'denied'
    )
  }

  async init(router: any): Promise<string | undefined> {
    const requestPermissionResult = await FirebaseMessaging.requestPermissions()
    if (requestPermissionResult.receive !== 'granted') {
      return undefined
    }

    const { token } = await FirebaseMessaging.getToken()

    if (await platform.isNativeAndroidApp()) {
      // On Android we manually need to handle notifications that arrive while
      // the app is in foreground, we do this here.
      // On iOS, foreground notifications are automatically displayed, so we
      // don't need to do anything special.
      await FirebaseMessaging.addListener(
        'notificationReceived',
        this.handleForegroundMessage.bind(this),
      )

      // Create all channels from `notificationChannels` that don't exist yet.
      const { channels } = await LocalNotifications.listChannels()
      const existingChannels = new Set()
      channels.forEach((channel) => existingChannels.add(channel.id))
      const missingChannels = Object.entries(notificationChannels).filter(
        ([id]) => !existingChannels.has(id),
      )
      await Promise.all(
        missingChannels.map(([id, name]) =>
          LocalNotifications.createChannel({ id, name }),
        ),
      )

      // Foreground notification clicks handled here on Android.
      LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (event: ActionPerformed) => {
          const { notification } = event
          const link = (notification?.extra as any)?.link
          if (link !== undefined) {
            router.push(link)
          }
        },
      )
    }

    // Background notification clicks handled here on Android.
    // Background and foreground notification clicks handled here on iOS.
    FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (event: NotificationActionPerformedEvent) => {
        const { notification } = event
        const link = (notification?.data as any)?.link
        if (link !== undefined) {
          router.push(link)
        }
      },
    )

    return token
  }

  async unsubscribe(): Promise<void> {
    try {
      await FirebaseMessaging.deleteToken()
    } catch {
      // We ignore all errors here.
    }
  }

  /**
   * Use the `LocalNotifications` plugin to display messages arriving
   * when the app is in the foreground.
   * Caveat: This plugin does not support displaying images from remote sources.
   */
  private handleForegroundMessage(event: NotificationReceivedEvent): void {
    const {
      notification: { title, body, data },
    } = event
    // The ID is necessary to address the notification later on. It doesn't
    // have to be unique. We are only sending fire-and-forget messages, so
    // there is no need to keep them identifiable. For reference see:
    // https://stackoverflow.com/questions/39607856/what-is-notification-id-in-android
    const localNotification: LocalNotificationSchema = {
      id: 12345,
      title: title ?? '',
      body: body ?? '',
      extra: data,
    }

    // The topic to which a message was sent is not passed through from android to this code.
    // To properly map foreground notifications into channels, we need to specify the topic
    // in two places in the FCM UI: when selecting the target audience, and in "Additional Data"
    // using the `topic` key.
    const topic = (data as any)?.topic as string | undefined
    if (topic !== undefined && topic in notificationChannels) {
      localNotification.channelId = topic
    }

    LocalNotifications.schedule({ notifications: [localNotification] })
  }
}
