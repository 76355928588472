import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "article-categories" }
const _hoisted_3 = { class: "docs-listing docs-listing--articles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderRoot = _resolveComponent("HeaderRoot")!
  const _component_LoadingError = _resolveComponent("LoadingError")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_CategoriesSelection = _resolveComponent("CategoriesSelection")!
  const _component_InfiniteScrollArticlesBlock = _resolveComponent("InfiniteScrollArticlesBlock")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "layout--basic" }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderRoot, {
        title: "Articles",
        hasDocSort: true
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_wait, { for: "Error Loading Articles" }, {
          waiting: _withCtx(() => [
            _createVNode(_component_LoadingError)
          ]),
          _: 1
        }),
        _createVNode(_component_v_wait, {
          class: "layout__wrapper-inner layout__wrapper-inner--small-gap container",
          for: "Loading Articles"
        }, {
          waiting: _withCtx(() => [
            _createVNode(_component_loading_spinner)
          ]),
          default: _withCtx(() => [
            _createElementVNode("section", _hoisted_2, [
              (_ctx.tagsView?.hasTags)
                ? (_openBlock(), _createBlock(_component_CategoriesSelection, {
                    key: 0,
                    view: _ctx.tagsView
                  }, null, 8, ["view"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("section", _hoisted_3, [
              _createVNode(_component_InfiniteScrollArticlesBlock, { view: _ctx.articleView }, null, 8, ["view"])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}