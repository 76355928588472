
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'

@Options({})
export default class DocPrevNext extends Vue {
  @Prop() doc!: DocView
}
