
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BookTutorial, BookTutorialStep } from '@/models/doc.tutorial'

@Options({})
export default class SidebarTutorialStepFavorite extends Vue {
  @Prop() private bookTutorial!: BookTutorial

  get isActive(): boolean {
    return this.bookTutorial.step === BookTutorialStep.favorite
  }
}
