import { GA4_MEASUREMENT_ID, IS_NOT_PRODUCTION } from '@/init/settings'
import { AuthUser, Billing, Plan } from '@/models/interfaces'

export function register(user: AuthUser): void {
  if (!hasGtag()) {
    // Exit if 'gtag' is undefined (tracking blocked).
    return
  }
  // Note: we also set user_id when setting up gtag globally in index.html
  gtag('set', { user_id: user.id })
  gtag('event', 'Signup', {
    event_category: 'Signup',
    event_action: 'Success',
  })
}

export function subscribe(
  user: AuthUser | null,
  new_plan: Plan,
  billing: Billing,
  done: () => void,
): void {
  let value = 0
  let label = ''
  let category = 'CardSave'
  if (new_plan.id === 'plan_monthly') {
    value = 1
    label = 'Monthly'
  } else if (new_plan.id === 'plan_annual') {
    value = 1
    label = 'Annual'
  }
  if (billing.trial_start === null) {
    // We only want to track the event once,
    // and we use trial_start for that - it is
    // null only on the first subscription,
    // once subscribed, trial starts and after
    // that trial_start / trial_end remain unchanged.
    // Set 'TestCardSave' category for admins.
    if (user && user.email.trim().endsWith('shortform.com')) {
      category = 'TestCardSave'
    }
    // Set 'TestCardSave' category for non-production environments.
    if (IS_NOT_PRODUCTION) {
      category = 'TestCardSave'
    }
    // Note: we also set user_id when setting up gtag globally in index.html
    gtag('set', { user_id: billing.user_id })
    gtag('event', category, {
      event_category: category,
      event_action: 'Success',
      event_label: label,
      value: value,
      event_callback: done,
    })
  } else {
    gtag('set', { user_id: billing.user_id })
    gtag('event', 'BillingChange', {
      event_category: 'BillingChange',
      event_action: 'Success',
      event_label: label,
      value: value,
      event_callback: done,
    })
  }
}

/**
 * Get the current client's Google Analytics 4 client ID and call
 * clientIdHandler with it.
 */
export function getGa4ClientId(
  clientIdHandler: (clientId: string) => void,
): void {
  if (!hasGtag()) {
    // Exit if 'gtag' is undefined (tracking blocked).
    return
  }
  gtag('get', GA4_MEASUREMENT_ID, 'client_id', clientIdHandler)
}

export function hasGtag(): boolean {
  if (typeof gtag !== 'undefined' && gtag) {
    return true
  }
  return false
}

/*
 * Send Google Analytics events via gtag.js.
 *
 * Related documentation:
 * - https://developers.google.com/analytics/devguides/collection/gtagjs/events
 * - https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data
 * - https://developers.google.com/gtagjs/reference/api
 * - https://developers.google.com/gtagjs/reference/parameter
 *
 * "New" documentation updated for Google Analytics 4:
 * - https://developers.google.com/tag-platform/gtagjs/reference
 */
export * as GTAG from './ga'
