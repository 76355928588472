import { IS_NOT_PRODUCTION } from '@/init/settings'
import { ValidationState } from '@/helpers/form'
import { platform } from '@/services/platform'

import { AuthView, OnAuthStart, OnAuthSuccess, OnAuthError } from './models'

import { GoogleAuthView } from './google'
import { GoogleNativeAuthView } from './google.native'
import { FacebookAuthView } from './facebook'
import { FacebookNativeAuthView } from './facebook.native'
import { AppleNativeAuthView } from './apple.native'

export interface SocialAuthView extends AuthView {
  validation: ValidationState

  init: () => void

  onStart: OnAuthStart
  onSuccess: OnAuthSuccess
  onError: OnAuthError

  isEnabled: boolean
}

export async function createSocialAuthView(
  socialLoginStart: OnAuthStart,
  socialLoginSuccess: OnAuthSuccess,
  socialLoginError: OnAuthError,
): Promise<SocialAuthComponentView | null> {
  const isNativeApp = await platform.isNativeApp()

  const info = await platform.deviceInfo()
  const build = info.appBuild
  const isAndroidApp = info.platform === 'android'

  if (isNativeApp && build < 21) {
    return null
  }

  return new SocialAuthComponentView(
    isNativeApp,
    isAndroidApp,
    socialLoginStart,
    socialLoginSuccess,
    socialLoginError,
  )
}

/**
 * Social auth logic.
 *
 * Wraps Google and Facebook auth views, provides common `validation` object.
 */
export class SocialAuthComponentView {
  public validation: ValidationState = new ValidationState()

  public googleAuthView: SocialAuthView
  public facebookAuthView: SocialAuthView
  public appleAuthView: SocialAuthView | undefined

  // Note: it should be called after UI is rendered
  // (Google hooks to the existing button).
  constructor(
    isNativeApp: boolean,
    isAndroidApp: boolean,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    if (isNativeApp) {
      this.googleAuthView = new GoogleNativeAuthView(
        this.validation,
        onStart,
        onSuccess,
        onError,
      )
      this.facebookAuthView = new FacebookNativeAuthView(
        this.validation,
        onStart,
        onSuccess,
        onError,
      )
    } else {
      this.googleAuthView = new GoogleAuthView(
        this.validation,
        onStart,
        onSuccess,
        onError,
      )
      this.facebookAuthView = new FacebookAuthView(
        this.validation,
        onStart,
        onSuccess,
        onError,
      )
    }

    // We want to show apple sign in:
    // * Production: web app
    // * Production: android app - never
    // * Production: iOS app - always
    // * Not production: all clients locally and on develop, for testing
    if (!isAndroidApp || IS_NOT_PRODUCTION) {
      this.appleAuthView = new AppleNativeAuthView(
        this.validation,
        onStart,
        onSuccess,
        onError,
      )
    }
  }

  /**
   * Init auth view.
   *
   * We can not do this in the constructor as `this` is used
   * in event listeners and it is not reactive yet in the
   * constructor.
   * See #4824.
   */
  public init(): void {
    this.googleAuthView.init()
    this.facebookAuthView.init()
    if (this.appleAuthView) {
      this.appleAuthView.init()
    }
  }
}

/**
 * Logout the user out if logged in with social auth.
 *
 * We do not keep the way the user has logged in, so
 * we call this method on user logout along
 * with logout from our backend and here we just try
 * to detect the social auth state and logout if necessary.
 */
export async function socialLogout(): Promise<void> {
  const isNativeApp = await platform.isNativeApp()

  if (isNativeApp) {
    await GoogleNativeAuthView.tryLogout()
    await FacebookNativeAuthView.tryLogout()
  } else {
    await FacebookAuthView.tryLogout()
  }
}
