/**
 * Documents tags view.
 *
 * This view contains logic that's needed to visually handle the
 * book tags.
 * - Render the list of tags.
 * - Show/hide the tags menu.
 *
 * The logic of documents filtering is implemented in the `doc.ts` model.
 */
export class DocsTagsView {
  private _visible: boolean = false
  private _tags: string[]
  private _showAllTags: boolean = false
  private _currentPage: 'articles' | 'books'
  private _title: string = 'Categories'

  constructor(
    tags: string[],
    current_page: 'articles' | 'books',
    title: string = 'Categories',
  ) {
    this._title = title
    /** Replace '/' with ' / ' to allow the frontend to make linebreaks **/
    this._tags = tags.map((tag) => tag.replace('/', ' / '))
    this._currentPage = current_page
  }

  get title(): string {
    return this._title
  }

  /**
   * Return true if tags menu visible.
   */
  public get visible(): boolean {
    return this._visible
  }

  /**
   * Toggle menu visibility state.
   */
  public toggleMenu(): void {
    this._visible = !this._visible
  }

  /**
   * Hide tags menu.
   *
   * We use this method along with click-outside directive.
   */
  public hide(): void {
    this._visible = false
  }

  public listingUrl(tag: string): string {
    return `/app/books/category/${encodeURIComponent(tag)}`
  }

  /**
   * Get the Icon for the tag
   */
  public tagIcon(tag: string): string {
    const tagName = tag.toLowerCase().replace(' / ', '/').replace('/', '-')
    return require(`@/assets.app/images/category-${tagName}.svg`)
  }

  /**
   * Get the url for the tag
   */
  getUrl(tag: string): string {
    return `${this._currentPage}/category/${encodeURIComponent(
      tag.replace(' / ', '/').toLowerCase(),
    )}`
  }

  /**
   * Get if all tags should be shown
   */
  get showAllTags(): boolean {
    return this._showAllTags
  }

  /**
   * Toggle if all or first 6 tags are shown
   */
  toggleShowAllTags(): void {
    this._showAllTags = !this._showAllTags
  }

  /**
   * Get all tags
   */
  get tags(): string[] {
    return this._tags
  }

  /**
   * Returns different CSS classes for the tags, only show two rows if there are more than 10 tags
   */
  get mobileClass(): string[] {
    const classes = ['categories-filter__list--mobile categories-filter__list']

    if (this.tags.length <= 10) {
      classes.push('categories-filter__list--mobile-oneline')
    }

    return classes
  }

  get hasTags(): boolean {
    return this.tags.length > 0
  }
}
