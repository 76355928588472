
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BillingView } from '@/models/billing'

@Options({
  components: {},
})
export default class StripeCardInfo extends Vue {
  @Prop() private billing!: BillingView
}
