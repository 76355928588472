import {
  AppStore,
  AppStorePluginProduct,
  AppStorePluginStore,
} from '@/services/cordova.purchase.types'
import { Plan, BillingPlans } from './interfaces'
import { Sentry } from '@/services/sentry'

/**
 * Base class for billing plans.
 */
export abstract class PlanView implements Plan {
  // Plan id - 'plan_free', 'plan_monthly', 'plan_annual' -
  // we use these on the backend and on Stripe.
  public id: string
  // Plan price (cents, integer).
  public amount: number
  // Plan nickname ('Free', 'Monthly', 'Annual').
  public nickname: string

  constructor(id: string, amount: number, nickname: string) {
    this.id = id
    this.amount = amount
    this.nickname = nickname
  }

  /**
   * Return string representation of the plan price.
   */
  abstract get price(): string

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  abstract get priceSubscribed(): string

  /**
   * Optional discounted price for the initial period, i.e. the first year or month
   * (Currently discounts are only available on the annual plan, so can only be defined
   * there).
   */
  get priceIntroPeriod(): string | null {
    return null
  }

  /**
   * Total yearly price of the plan (only value)
   */
  abstract get priceYearValue(): string

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  abstract get basis(): string

  /**
   * Return 'year' / 'month' string.
   */
  abstract get period(): string

  /**
   * Return 'once per year' / 'once per month' string.
   */
  abstract get periodInfo(): string

  /**
   * Format price with given currency.
   * Examples:
   *   - price=20099 and currency='USD' --> '$200.99'
   *   - price=20099 and currency='EUR' --> '€200.99'
   * @param price Price in cents
   * @param currency Currency code, e.g. 'USD' or 'EUR'. Default 'USD'.
   * @param fractionDigits Number of exact fraction digits. Default 2
   */
  formatPrice(
    price: number,
    currency: string,
    fractionDigits: number = 2,
  ): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    })
    return formatter.format(price / 100)
  }

  /**
   * Format price value as `$12.34/period`.
   */
  formatPricePeriod(price: number, period: string, currency: string): string {
    return `${this.formatPrice(price, currency)}/${period}`
  }
}

/**
 * Free billing plan.
 */
export class FreePlanView extends PlanView {
  constructor(billing_plans: BillingPlans) {
    const amount = billing_plans.plan_free
    super('plan_free', amount, 'Free')
  }

  /**
   * String representation of the plan price.
   */
  get price(): string {
    return 'Free'
  }

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  get priceSubscribed(): string {
    return this.price
  }

  /**
   * Total yearly price of the plan (only value)
   */
  get priceYearValue(): string {
    return this.price
  }

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  get basis(): string {
    return ''
  }

  /**
   * Return ' year' / 'month' string.
   */
  get period(): string {
    return ''
  }

  /**
   * Return 'once per year' / 'once per month' string.
   */
  get periodInfo(): string {
    return ''
  }
}

/**
 * Monthly billing plan.
 */
export class MonthlyPlanView extends PlanView {
  private _hasAnnualDiscount: boolean

  constructor(billing_plans: BillingPlans) {
    const amount = billing_plans.plan_monthly
    super('plan_monthly', amount, 'Monthly')
    this._hasAnnualDiscount = !!billing_plans.prompt_value
  }

  /**
   * String representation of the plan price.
   */
  get price(): string {
    return this.formatPricePeriod(this.amount, 'month', 'USD')
  }

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  get priceSubscribed(): string {
    return this.price
  }

  /**
   * Total yearly price of the plan (only value)
   */
  get priceYearValue(): string {
    return `$${(this.amount * 12) / 100}`
  }

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  get basis(): string {
    return 'a monthly'
  }

  /**
   * Return 'year' / 'month' string.
   */
  get period(): string {
    return 'month'
  }

  /**
   * Return 'once per year' / 'once per month' string.
   */
  get periodInfo(): string {
    return 'once per month'
  }

  /**
   * Return true if there is an annual subscription discount.
   *
   * We insert a placeholder to monthly view in this case to
   * align monthly plan data with annual plan.
   */
  get hasAnnualDiscount(): boolean {
    return this._hasAnnualDiscount
  }
}

/**
 * Annual billing plan.
 */
export class AnnualPlanView extends PlanView {
  private _discount: number | null
  private _amountMonthly: number

  constructor(billing_plans: BillingPlans) {
    super('plan_annual', billing_plans.plan_annual, 'Annual')
    this._discount = billing_plans.prompt_value
    this._amountMonthly = billing_plans.plan_monthly
  }

  /**
   * String representation of the plan price per month.
   */
  get price(): string {
    return this.formatPricePeriod(this.amount / 12, 'month', 'USD')
  }

  get priceNew(): string | null {
    if (!this._discount) {
      return null
    }

    const value = this.discountedPrice(this.amount)
    return this.formatPricePeriod(value / 12, 'month', 'USD')
  }

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  get priceSubscribed(): string {
    return this.formatPricePeriod(this.amount, 'year', 'USD')
  }

  /**
   * Optional discounted price for the initial period, i.e. the first year or month
   * (Currently discounts are only available on the annual plan, so can only be defined
   * there).
   */
  override get priceIntroPeriod(): string {
    const discounted = this.discountedPrice(this.amount)
    return `$${(discounted / 100).toFixed(2)}`
  }

  /**
   * Total yearly price of the plan (only value).
   */
  get priceYearValue(): string {
    const value = this.discountedPrice(this.amount)
    const price = this.formatPricePeriod(value / 12, 'month', 'USD')
    return `$${value / 100} (${price})`
  }

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  get basis(): string {
    return 'an annual'
  }

  /**
   * Return ' year' / 'month' string.
   */
  get period(): string {
    return 'year'
  }

  /**
   * Return 'once per year' / 'once per month' string.
   */
  get periodInfo(): string {
    return 'once per year'
  }

  /**
   * Return an annual plan savings string.
   */
  get savings(): string {
    const monthlyCost = this._amountMonthly * 12
    const amount = monthlyCost - this.discountedPrice(this.amount)
    const percent = ((amount / monthlyCost) * 100).toFixed(0)
    const amountFormatted = (amount / 100).toFixed(0)
    return `${percent}% Savings | Save $${amountFormatted} per year`
  }

  /**
   * Calculate price with discount.
   */
  discountedPrice(price: number): number {
    if (this._discount) {
      return price * (1 - this._discount / 100)
    }
    return price
  }
}

/**
 * AppStore monthly billing plan.
 */
export class AppStoreMonthlyPlanView extends PlanView {
  private _hasAnnualDiscount: boolean
  private _currency: string

  constructor(appStore: AppStore, platform: 'ios' | 'android') {
    if (!appStore.store) {
      throw new Error('appStore.store is not initialized.')
    }
    if (platform === 'ios' && !appStore.monthly) {
      throw new Error('No monthly plan in apple data.')
    }
    const fallbackAmount = 2399
    const amount = getAmount(
      appStore.monthly,
      appStore.store,
      platform,
      fallbackAmount,
    )
    super('plan_monthly', amount, 'Monthly')

    this._hasAnnualDiscount = false

    this._currency = 'USD'
    if (appStore.monthly) {
      // For cordova-plugin-purchase version
      // 11: `currency` is a direct property of the product.
      // 13: `currency` is a property of PricingPhase.
      const appStoreCurrency =
        'offers' in appStore.monthly
          ? getLastPricingPhase(appStore.monthly)?.currency
          : appStore.monthly.currency
      this._currency = appStoreCurrency || this._currency
    }
  }

  /**
   * String representation of the plan price.
   */
  get price(): string {
    return `${this.formatPrice(this.amount, this._currency)} per month`
  }

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  get priceSubscribed(): string {
    return this.price
  }

  /**
   * Total yearly price of the plan (only value)
   */
  get priceYearValue(): string {
    return this.formatPrice(this.amount * 12, this._currency)
  }

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  get basis(): string {
    return 'a monthly'
  }

  /**
   * Return ' year' / 'month' string.
   */
  get period(): string {
    return 'month'
  }

  /**
   * Return 'once per year' / 'once per month' string.
   */
  get periodInfo(): string {
    return 'once per month'
  }

  /**
   * Return true if there is an annual subscription discount.
   *
   * We insert a placeholder to monthly view in this case to
   * align monthly plan data with annual plan.
   */
  get hasAnnualDiscount(): boolean {
    return this._hasAnnualDiscount
  }
}

/**
 * AppStore annual billing plan.
 */
export class AppStoreAnnualPlanView extends PlanView {
  private _discount: number | null
  private _amountMonthly: number
  private _currency: string

  constructor(
    appStore: AppStore,
    platform: 'ios' | 'android',
    monthlyPlanView: AppStoreMonthlyPlanView,
  ) {
    if (!appStore.store) {
      throw new Error('appStore.store is not initialized.')
    }
    if (platform === 'ios' && !appStore.annual) {
      throw new Error('No annual plan in apple data.')
    }
    const fallbackAmount = 19499
    const amount = getAmount(
      appStore.annual,
      appStore.store,
      platform,
      fallbackAmount,
    )
    super('plan_annual', amount, 'Annual')

    this._discount = 0

    this._currency = 'USD'
    if (appStore.annual) {
      // For cordova-plugin-purchase version
      // 11: `currency` is a direct property of the plan.
      // 13: `currency` is a property of PricingPhase.
      const appStoreCurrency =
        'offers' in appStore.annual
          ? getLastPricingPhase(appStore.annual)?.currency
          : appStore.annual.currency
      this._currency = appStoreCurrency || this._currency
    }

    this._amountMonthly = monthlyPlanView.amount
  }

  /**
   * String representation of the plan price per month.
   */
  get price(): string {
    const perYear = `${this.formatPrice(this.amount, this._currency)} per year`
    const perMonth = this.formatPricePeriod(
      this.amount / 12,
      'month',
      this._currency,
    )

    return `${perYear} (${perMonth})`
  }

  /**
   * String representation of the discounted plan price per month.
   */
  get priceNew(): string | null {
    if (!this._discount) {
      return null
    }

    const value = this.discountedPrice(this.amount)
    return this.formatPricePeriod(value / 12, 'month', this._currency)
  }

  /**
   * Total price of the plan (displayed on the billing page after subscription).
   */
  get priceSubscribed(): string {
    return `${this.formatPrice(this.amount, this._currency)} per year`
  }

  /**
   * Total yearly price of the plan (only value).
   */
  get priceYearValue(): string {
    const value = this.discountedPrice(this.amount)
    const perMonth = this.formatPricePeriod(value / 12, 'month', this._currency)
    return `${this.formatPrice(value, this._currency)} (${perMonth})`
  }

  /**
   * Return 'an annual' / 'a monthly' basis string.
   */
  get basis(): string {
    return 'an annual'
  }

  /**
   * Return ' year' / 'month' string.
   */
  get period(): string {
    return 'year'
  }

  /**
   * Return 'once per year' / 'once per month' string.
   */
  get periodInfo(): string {
    return 'once per year'
  }

  /**
   * Return an annual plan savings string.
   */
  get savings(): string {
    const monthlyCost = this._amountMonthly * 12
    const amount = monthlyCost - this.discountedPrice(this.amount)
    const percent = ((amount / monthlyCost) * 100).toFixed(0)
    const amountFormatted = this.formatPrice(amount, this._currency, 0)
    return `${percent}% Savings | Save ${amountFormatted} per year`
  }

  /**
   * Calculate price with discount.
   */
  discountedPrice(price: number): number {
    if (this._discount) {
      return price * (1 - this._discount / 100)
    }
    return price
  }
}

/**
 * Get the plan's price in cents.
 *
 * On Android, may fall back to `fallbackPrice` if Google billing is unavailable, in
 * which case no price is returned by Google.
 *
 * On iOS, it is expected that a price is always present, so an error is thrown in case
 * it is not.
 */
function getAmount(
  plan: AppStorePluginProduct | undefined,
  store: AppStorePluginStore,
  platform: 'ios' | 'android',
  fallbackPrice: number,
): number {
  // First, we try to get the price of the plan. The method depends on which
  // version of cordova-plugin-purchase the mobile app uses, so we switch based
  // on the type of the plan.
  //
  // TypeScript does not like it when we try to use `instanceof
  // IapStore.IStoreProduct`, which seems to be some problem with IapStore
  // being a namespace:
  // >> Cannot use namespace 'IapStore' as a value.
  //
  // So instead, we check for the existence of the properties we access.
  if (plan) {
    if ('priceMicros' in plan && plan.priceMicros) {
      // Version 11:
      return plan.priceMicros / 10000
    } else if ('offers' in plan && plan.offers.length > 0) {
      // Version 13:
      // https://github.com/j3k0/cordova-plugin-purchase/blob/master/api/classes/CdvPurchase.Offer.md
      const lastPhase = getLastPricingPhase(plan)
      if (lastPhase && lastPhase.priceMicros > 0) {
        return lastPhase.priceMicros / 10000
      }
      // At this point there are no pricing phases with a valid price; either
      // there are no pricing phases or no pricing phases with a non-zero
      // price.
    }
  }

  // If we reach this point, there is no valid price in `plan`. How we handle
  // this depends on platform.
  if (platform === 'ios') {
    // With Apple billing, we always expect a price to be present. A Sentry error
    // gets logged further up the call chain.
    const message = `Annual plan in Apple data has no price.`
    setAppStoreDataSentryContext(message, plan, store)
    throw new Error(message)
  }
  // With Google billing it can happen that no price (and no other subscription
  // details) get returned for various reasons. For example: the user is not signed
  // in into a Google account on the device, or the device has an old version of
  // Google Play.
  // This is not an error case. We fall back to displaying a hard-coded price in USD.
  // We still log a message to Sentry though, to see how often this happens.
  const message = `Annual plan in Google data has no price. Showing hard-coded price as fallback.`
  setAppStoreDataSentryContext(message, plan, store)
  Sentry.captureMessage(message)
  return fallbackPrice
}

/**
 * Add app store data to Sentry context for debugging. We only add the context here,
 * a Sentry error gets logged later, further up in the call chain.
 *
 * The data is added in JSON stringified form to ensure all data is displayed in
 * Sentry in its raw form.
 */
function setAppStoreDataSentryContext(
  message: string,
  plan: AppStorePluginProduct | undefined,
  store: AppStorePluginStore,
): void {
  let allPlansData = {}

  try {
    // Using internal property of store that contains all loaded products.
    if ('products' in store) {
      allPlansData = { all_plans_data: JSON.stringify(store.products) }
    }
  } catch (e) {
    // Ignore stringify error
  }

  const context = {
    plan: JSON.stringify(plan),
    ...allPlansData,
  }

  if (Sentry.setContext) {
    Sentry.setContext('app_store_data', context)
  }
}

/**
 * Get the last pricing phase for this product. Returns undefined if it doesn't exist.
 *
 * On iOS, there are two pricing phases: one for the free trial and one
 * for the actual subscription.
 * On Android, there is only one pricing phase: the actual subscription.
 * So to get the "true" subscription pricing, we want to get the last pricing
 * phase.
 */
function getLastPricingPhase(
  product: CdvPurchase.Product,
): CdvPurchase.PricingPhase | undefined {
  if (product.offers.length > 0) {
    const pricingPhases = product.offers[0].pricingPhases
    if (pricingPhases.length > 0) {
      return pricingPhases[pricingPhases.length - 1]
    }
  }
  return undefined
}
