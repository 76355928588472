/* package annotator.notifier */

'use strict'

import { escapeHtml } from './util'

export const INFO = 'info'
export const SUCCESS = 'success'
export const ERROR = 'error'

const bannerClasses = {
  show: 'annotator-notice-show',
  info: 'annotator-notice-info',
  success: 'annotator-notice-success',
  error: 'annotator-notice-error',
}

/**
 * function:: banner(message[, severity=notification.INFO])
 *
 * Creates a user-visible banner notification that can be used to display
 * information, warnings and errors to the user.
 *
 * :param String message: The notice message text.
 * :param severity:
 *    The severity of the notice (one of `notification.INFO`,
 *    `notification.SUCCESS`, or `notification.ERROR`)
 *
 * :returns:
 *   An object with a `close` method that can be used to close the banner.
 */
export function banner(message: any, severity: any): any {
  if (typeof severity === 'undefined' || severity === null) {
    severity = INFO
  }

  let closed = false
  const element = document.createElement('div')
  element.className = 'annotator-notice'

  const close = function () {
    if (closed) {
      return
    }

    closed = true

    element.classList.remove(bannerClasses.show)
    // @ts-ignore
    element.classList.remove(bannerClasses[severity])

    // The removal of the above classes triggers a 400ms ease-out
    // transition, so we can dispose the element from the DOM after
    // 500ms.
    setTimeout(function () {
      element.remove()
    }, 500)
  }

  element.classList.add(bannerClasses.show)
  // @ts-ignore
  element.classList.add(bannerClasses[severity])
  element.innerHTML = escapeHtml(message || '')
  document.body.appendChild(element)

  element.addEventListener('click', close)

  // Hide the notifier after 5s
  setTimeout(close, 5000)

  return {
    close: close,
  }
}

export const defaultNotifier = banner
