
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { RouteLocationNormalized } from 'vue-router'
// Note: docs say (https://github.com/vuejs/vue-class-component#adding-custom-hooks)
// that it should be enough to import class-component-hooks only in the main.ts,
// but, for some reason, that didn't work and I also have to import here.
//
// Relasted issues:
//  https://github.com/vuejs/vue-class-component/issues/264
//  https://github.com/vuejs/vue-class-component/issues/261
//
import { initClassComponentHooks } from '@/init/class-component-hooks'
initClassComponentHooks()

import { backend } from '@/services/backend'
import { scrollIntoView } from '@/helpers/dom'
import { Mixpanel } from '@/analytics/mixpanel'

import { wait } from '@/helpers/vue-wait'
import { meta } from '@/services/meta'
import { Sentry } from '@/services/sentry'
import { ReadingProgress } from '@/services/readingProgress'
import { assertNotNull } from '@/helpers/typing'

import { ArticleView } from '@/models/article'

import Layout from '@/app/components/Layout.vue'
import ArticleContent from '@/app/components/article/Content.vue'
import LoadingError from '@/components/ui/LoadingError.vue'

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

@Options({
  components: {
    Layout,
    LoadingSpinner,
    ArticleContent,
    LoadingError,
  },
})
export default class ArticleSummaryPage extends Vue {
  article: ArticleView | null = null

  readingProgress: ReadingProgress | null = null
  // For tests, to permanently disable timers,
  // can be set by `disableTimers()` method call.
  timersDisabled: boolean = false

  private readingStartTime: number = 0

  async beforeRouteUpdate(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    // Called when the route that renders this component has changed,
    // but this component is reused in the new route.
    await this.updateReadTime()
    // We're navigating to a new article, but the component is reused (not
    // re-rendered) so beforeMount() isn't called. Thus we need to load the new
    // article's data here.
    await this.loadDocument(to)
    scrollIntoView('.article-single__content-editor')

    next()
  }

  async beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    await this.updateReadTime()
    next()
  }

  async beforeMount(): Promise<void> {
    await this.loadDocument(this.$route)
  }

  async beforeUnmount(): Promise<void> {
    if (this.readingProgress) {
      assertNotNull(this.readingProgress).destroy()
    }
  }

  async updateReadTime(): Promise<void> {
    // Updates the reading time, to be used when we navigate away from this page.
    const timeDiff = Math.floor((Date.now() - this.readingStartTime) / 1000)
    await Mixpanel.trackDocReadTime(this.article, timeDiff)
  }

  async _fetchDocument(route: RouteLocationNormalized): Promise<ArticleView> {
    const response = await backend.getArticle(route.params.url_slug as string)
    this.article = new ArticleView(this, response)
    this.readingStartTime = Date.now()
    return this.article
  }

  disableTimers(): void {
    this.timersDisabled = true
    if (this.readingProgress) {
      assertNotNull(this.readingProgress).clearTimers()
    }
  }

  restartTracking(): void {
    if (!this.timersDisabled) {
      assertNotNull(this.readingProgress).restartTracking()
    }
  }

  async loadDocument(route: RouteLocationNormalized): Promise<void> {
    try {
      wait.start(this, 'Loading Doc')
      wait.end(this, 'Error Loading Doc')
      this.article = await this._fetchDocument(route)
      this.readingProgress = new ReadingProgress(assertNotNull(this.article))
      // Set the current page from current route.
      this.updateMeta()

      // Start the 20 sec timer to add the document to the user library
      // or update the access time.
      this.restartTracking()
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        wait.start(this, 'Error Loading Doc')
        Sentry.captureException(error)
      }
    } finally {
      wait.end(this, 'Loading Doc')
    }
  }

  updateMeta(): void {
    meta.setAppTitle(assertNotNull(this.article).title)
  }

  get isLoadingDoc(): boolean {
    return wait.is(this, 'Loading Doc')
  }

  get isErrorLoadingDoc(): boolean {
    return wait.is(this, 'Error Loading Doc')
  }
}
