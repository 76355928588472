
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { OpenQuestionsView, ExerciseView } from '@/models/book'
import { convertError } from '@/models/error'
import { formatTrialDate } from '@/helpers/time'

import { Sentry } from '@/services/sentry'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { assertNotNull } from '@/helpers/typing'
import { wait } from '@/helpers/vue-wait'

@Options({
  components: {
    'v-wait': vLoading,
    ButtonSpinner,
    InputWithContextMenu,
  },
})
export default class DocExercise extends Vue {
  @Prop() private content!: OpenQuestionsView

  private timeoutId?: number = undefined
  private isSaving: boolean = false
  private editSubmitted: boolean = false
  private error: string | null = null

  // For tests, to permanently disable timers,
  // can be set by `disableTimers()` method call.
  timersDisabled: boolean = false

  /**
   * Auto-saves exercise data as user enters is.
   */
  async onChange(): Promise<void> {
    this.error = null
    // If a timer was already started, clear it.
    if (this.timeoutId || this.isSaving) {
      clearTimeout(this.timeoutId)
    }

    if (this.timersDisabled) {
      return
    }

    // Set timer that will save comment when it fires.
    this.timeoutId = setTimeout(async () => {
      // Make ajax call to save data.
      this.isSaving = true
      // TODO: Add UI for autosaving
      wait.start(this, 'Autosaving')
      try {
        await this.exercise.save()
      } finally {
        wait.end(this, 'Autosaving')
        this.isSaving = false
      }
    }, 750)
  }

  get exercise(): ExerciseView {
    return assertNotNull(this.content).currentExercise
  }

  disableTimers(): void {
    this.timersDisabled = true
  }

  get readOnlyMode(): boolean {
    return !!this.exercise.submitted_at && !this.editSubmitted
  }

  switchToEdit(): void {
    this.editSubmitted = true
  }

  async submit(): Promise<void> {
    this.isSaving = true
    this.error = null
    try {
      wait.start(this, 'Submitting')
      await this.exercise.submit()
    } catch (error: any) {
      const err = convertError(error)
      if (err.isValidationError()) {
        this.error = err.getOne('answers')
      } else {
        Sentry.captureException(error)
        throw error
      }
    } finally {
      wait.end(this, 'Submitting')
      this.isSaving = false
    }
    this.editSubmitted = false
  }

  async discuss(): Promise<void> {
    this.error = null

    try {
      wait.start(this, 'Sending')
      const thread: any = await this.exercise.discuss()
      this.$router.push({
        name: 'book.edit_thread',
        state: {
          threadTitle: thread.title,
          threadText: thread.text,
          userWorkoutId: thread.user_workout_id,
        },
      })
    } finally {
      wait.end(this, 'Sending')
    }
  }

  get validationState(): boolean | null {
    if (this.error) {
      return false
    }
    return null
  }

  _formatTime(time: string): string {
    return formatTrialDate(time)
  }
}
