import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "book-section book-lists-section--highlights",
  name: "books",
  tag: "div"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "book-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HighlightsCard = _resolveComponent("HighlightsCard")!
  const _component_HighlightsEmpty = _resolveComponent("HighlightsEmpty")!

  return (!_ctx.isLoadingBooks())
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.books, (book) => {
              return (_openBlock(), _createBlock(_component_HighlightsCard, {
                key: book.id,
                book: book,
                onUnitClick: ($event: any) => (_ctx.openBook(book))
              }, null, 8, ["book", "onUnitClick"]))
            }), 128))
          ])
        ]),
        (_ctx.books.length === 0)
          ? (_openBlock(), _createBlock(_component_HighlightsEmpty, { key: 0 }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}