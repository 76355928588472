
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import vLoading from 'vue-wait/src/components/v-wait.vue'

import { SignupView } from '@/models/auth/models'
import { authCssFix } from '@/helpers/html'
import { assertNotNull } from '@/helpers/typing'
import { wait } from '@/helpers/vue-wait'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'

import SocialAuth from '@/app/components/auth/SocialAuth.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { AuthMode } from '@/models/auth/google'
import AppleSignInButton from '../../auth/AppleSignInButton.vue'
import BottomSheet from '@/app/components/BottomSheet.vue'

import FacebookLoginButton from '../../auth/FacebookLoginButton.vue'
import GoogleSignInButton from '../../auth/GoogleSignInButton.vue'
import {
  createSocialAuthView,
  SocialAuthComponentView,
} from '@/models/auth/social'
import SignupSheet from '../signup/SignupSheet.vue'
import Slider from '../signup/Slider.vue'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    SocialAuth,
    InputWithContextMenu,
    AppleSignInButton,
    BottomSheet,
    SignupSheet,
    FacebookLoginButton,
    GoogleSignInButton,
    Slider,
  },
})
export default class Signup extends Vue {
  @Prop() nextOnboardingPage!: () => void

  view: SignupView | null = null
  private socialView: SocialAuthComponentView | null = null

  authMode: AuthMode = AuthMode.Signup

  bottomSheetRef: InstanceType<typeof BottomSheet> | null = null

  onStart(): void {
    wait.start(this, 'Register')
  }
  openInfoSheet(): void {
    this.bottomSheetRef?.view.open()
  }

  closeInfoSheet(): void {
    this.bottomSheetRef?.view.close()
  }

  async onSuccess(): Promise<void> {
    wait.start(this, 'Register')

    // Timeout before going to the next page
    // to let Mixpanel calls complete.
    setTimeout(() => {
      this.nextOnboardingPage()
      wait.end(this, 'Register')
    }, 100)
  }

  onError(): void {
    wait.end(this, 'Register')
  }

  async beforeMount(): Promise<void> {
    this.view = new SignupView(this.onStart, this.onSuccess, this.onError)

    this.socialView = await createSocialAuthView(
      this.onStart,
      this.onSuccess,
      this.onError,
    )
    if (this.socialView) {
      this.socialView.init()
    }
  }

  get _view(): SignupView {
    return assertNotNull(this.view)
  }

  async mounted(): Promise<void> {
    // If user name was passed a prop (from the signup with email form)
    if (this.$route.query.email) {
      this._view.model.email = this.$route.query.email as string
    }

    authCssFix()
  }

  async submitForm(): Promise<void> {
    await this._view.start()
  }

  get loginRoute(): Record<string, unknown> {
    const route = { name: 'login', query: this.$route.query }
    // If we have the email set, pass it on to the signup page.
    if (this._view.model.email) {
      route.query.email = this._view.model.email
    }
    return route
  }
}
