/** This serivce allows to detect offline mode:
 * - `isOnline`, `isOffline` data properties
 * - `online`, `offline` in-component events
 *
 * Based on https://github.com/filrak/vue-offline
 * */
class OfflineService {
  isOnline: boolean = true
  isOffline: boolean = false

  _removeListeners?: () => void

  constructor() {
    navigator && navigator.onLine
      ? (this.isOnline = true)
      : (this.isOffline = true)

    const onlineHandler = (): void => {
      this.isOnline = true
      this.isOffline = false
    }

    const offlineHandler = (): void => {
      this.isOffline = true
      this.isOnline = false
    }

    window.addEventListener('online', onlineHandler)
    window.addEventListener('offline', offlineHandler)

    this._removeListeners = (): void => {
      window.removeEventListener('online', onlineHandler)
      window.removeEventListener('offline', offlineHandler)
    }
  }

  shutdown(): void {
    if (this._removeListeners) {
      this._removeListeners()
    }
  }
}

export const offline = new OfflineService()
