import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleCard = _resolveComponent("ArticleCard")!
  const _component_ListingBlock = _resolveComponent("ListingBlock")!

  return (_openBlock(), _createBlock(_component_ListingBlock, {
    title: _ctx.title,
    viewAllLink: _ctx.viewAllLink
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.filteredDocs.slice(0, 3), (article) => {
        return (_openBlock(), _createBlock(_component_ArticleCard, {
          key: article.id,
          view: _ctx.view,
          doc: article,
          class: "articles__item"
        }, null, 8, ["view", "doc"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title", "viewAllLink"]))
}