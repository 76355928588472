
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { FacebookAuthView } from '@/models/auth/facebook'

@Options({})
export default class FacebookLoginButton extends Vue {
  @Prop() private view!: FacebookAuthView
  @Prop() private title!: string

  async fbLoginClick(): Promise<void> {
    await this.view.start()
  }
}
