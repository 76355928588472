import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.is), {
    ref: "inputElement",
    value: _ctx.modelValue,
    class: _normalizeClass({ 'auto-resize-input': _ctx.autoResize }),
    onInput: _cache[0] || (_cache[0] = (event) => _ctx.handleInput(event)),
    onFocusin: _ctx.enableIosContextMenu,
    onFocusout: _ctx.disableIosContextMenu
  }, null, 40, ["value", "class", "onFocusin", "onFocusout"]))
}