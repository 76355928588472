
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { PasswordForgotView } from '@/models/auth/models'
import { authCssFix } from '@/helpers/html'
import { platform } from '@/services/platform'
import { waitTimer } from '@/helpers/timer'
import { wait } from '@/helpers/vue-wait'
import FormError from '@/components/ui/FormError.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    InputWithContextMenu,
  },
})
export default class PasswordForgot extends Vue {
  view: PasswordForgotView = new PasswordForgotView()
  isNativeIOSApp: boolean = false

  async mounted(): Promise<void> {
    this.isNativeIOSApp = await platform.isNativeIOSApp()
    authCssFix()
  }

  async submitForm(): Promise<void> {
    wait.start(this, 'Submit')
    try {
      // Wait for submit to complete plus up to two seconds delay
      // to prevent fast clicking issues.
      await Promise.all([this.view.submitForm(), waitTimer(2000)])
    } finally {
      wait.end(this, 'Submit')
    }
  }
}
