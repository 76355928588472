import { ComponentPublicInstance } from 'vue'
import { auth } from '@/services/auth'
import { nightMode } from '@/services/night'
import { emitter } from '@/services/mitt'
import {
  DESKTOP_WIDTH,
  SCROLL_SHOW_THRESHOLD,
  SCROLL_HIDE_THRESHOLD,
} from '@/init/settings'
import { backend } from '@/services/backend'
import { offline } from '@/services/offline'
import { DocView } from '@/models/doc'
import { backLink } from '@/models/backlink'
import { notificationService } from '@/services/notification'
import { platform } from '@/services/platform'

type MenuItem = {
  text: string
  link: string
  icon: string
}

/**
 * Header controls logic.
 *
 * The logic here is used by @/components/header/Header.vue.
 * It is displayed as a header bar on desktop and as a menu on mobile.
 */
export class HeaderView {
  private _vm: ComponentPublicInstance
  private _isMenuVisible: boolean = false

  public isLogoutFailure: boolean = false
  public isIOS: boolean = false

  /** The pages the CTA button is available on for mobile screens */
  private _ctaButtonAvailablePagesForMobile = [
    'home',
    'discover',
    'books',
    'articles',
    'my_library.books',
    'my_library.articles',
    'my_library.podcast_episodes',
    'podcasts',
  ]

  private _prevScrollTop: number = 0
  private _prevInnerWidth: number = window.innerWidth
  private _mobileOpen: boolean = false
  private _visible: boolean = true
  private _docView?: DocView

  public isFree: boolean = false

  public showPodcastsPage: boolean = false

  // Header menu items.
  public menuItems: MenuItem[] = [
    {
      text: 'Discover',
      link: 'home',
      icon: 'idiscover',
    },
    {
      text: 'Books',
      link: 'books',
      icon: 'ibook',
    },
    {
      text: 'Articles',
      link: 'articles',
      icon: 'iarticles',
    },
    {
      text: 'Podcasts',
      link: 'podcasts',
      icon: 'ipodcasts',
    },
    {
      text: 'My library',
      link: 'my_library',
      icon: 'ilibrary',
    },
    {
      text: 'Search',
      link: 'search',
      icon: 'isearch',
    },
  ]

  constructor(vm: ComponentPublicInstance, docView?: DocView) {
    this._vm = vm
    this._docView = docView

    this.isFree = auth.isFreePlan()

    // Get the value for the Podcasts page experiment.
    // This determines wether or not to show the Podcasts page
    // item in the navigation
  }

  public async init(): Promise<void> {
    // Initialize global Night Mode object and start App Night Mode A/B experiment
    await nightMode.init()
    // Enable night mode if it's saved to local storage
    await nightMode.restore()

    this.initEventListeners()

    this.isIOS = await platform.isNativeIOSApp()
  }

  get mobileOpen(): boolean {
    return this._mobileOpen
  }

  private initEventListeners(): void {
    emitter.on('windowResize', () => {
      this.onWindowResize()
    })

    emitter.on('appScroll', (scrollElement: HTMLElement) => {
      // See @/app/App.vue where we $emit the appScroll event.
      this.onAppScroll(scrollElement)
    })

    emitter.on('docContentClicked', (event: any) => {
      // See @/app/components/Content.vue where we $emit the docContentClicked event.
      this.onDocContentClicked(event)
    })

    emitter.on('docContentSelectionStarted', () => {
      // See @/app/components/doc/reading/Annotator.vue where we $emit the 'docContentSelectionStarted' event.

      // Don't hide header on desktop screens
      if (window.innerWidth >= DESKTOP_WIDTH) {
        return
      }

      this.hide()
    })

    emitter.on('closeOverlayMenu', () => {
      this.hideMenu()
    })
  }

  private onWindowResize(): void {
    // We have to make the header visible when the window size is changed from a desktop
    // to mobile/tablet size. When changing from desktop to mobile/tablet size, the book
    // controls will always be made visible, so we also have to make the header visible
    // so that they don't get out of sync - on mobile/tablet book controls and header
    // should only ever both be visible or both hidden.
    if (
      window.innerWidth < DESKTOP_WIDTH &&
      this._prevInnerWidth >= DESKTOP_WIDTH
    ) {
      this.show()
    }
    this._prevInnerWidth = window.innerWidth
  }

  /**
   * Scroll handler.
   * @param scrollElement - app element to which the scroll event is attached.
   *
   * This function handles the logic both for desktop and mobile because on tablets
   * it's possible that device rotation actually switches the app width: desktop on
   * horizontal position and tablet/mobile on the vertical device position.
   *
   * Implements the following logic for desktop:
   * - Show header on scroll up, hide on scroll down.
   * - There's a threshold to avoid header being too responsive.
   * - There's a minimal height at which we start showing the header.
   *
   * Implements the following logic for tablet/mobile:
   * - If the scrolling position is at the top or at the bottom: force show the header
   *   and the toolbar.
   * - For the rest of scrolling positions (within the scrollHeight of scrollElement) -
   *   do nothing, we handle header show/hide on click on the mobile.
   */
  private onAppScroll(scrollElement: HTMLElement): void {
    if (window.innerWidth < DESKTOP_WIDTH) {
      // Force show the header and toolbar if scroll is at the top or at bottom position
      if (this._docView?.keepSidebarAndHeaderOpen()) {
        this.show()
        // Force show the control bar to sync header and toolbar state.
        emitter.emit('showBookControlBar')
        return
      }
      // For the rest of scrolling positions - do nothing, we show/hide the header
      // On the content click on mobile.
      return
    }

    // Don't do anything with the header when the menu is open
    // (hiding the header on scroll also hides the menu which
    // is not what we want).
    if (this.mobileOpen) {
      return
    }

    const scrollTop = scrollElement.scrollTop

    // Minimum scroll height when we show the header
    const scrollTopThreshold = 50

    const diff = scrollTop - this._prevScrollTop
    if (scrollTop > scrollTopThreshold && diff > SCROLL_SHOW_THRESHOLD) {
      this.hide()
    } else if (scrollTop < scrollTopThreshold || diff < SCROLL_HIDE_THRESHOLD) {
      this.show()
    }
    this._prevScrollTop = scrollTop
  }

  private onDocContentClicked(event: any): void {
    /**
     * Hide/show the header on desktop/mobile if it is visible/hidden while
     * docContentClicked event is triggered. See issue #1846 and #4783 for
     * more context. Ignore the event if the target's a button or a link.
     */
    if (
      window.innerWidth < DESKTOP_WIDTH &&
      !this._docView?.keepSidebarAndHeaderOpen() &&
      event.target.tagName !== 'A' &&
      event.target.tagName !== 'BUTTON'
    ) {
      this.toggle()
    }
  }

  /**
   * Helper method to get current route.
   */
  private get _currentRouteName(): string {
    return this._vm.$router.currentRoute.value.name as string
  }

  /**
   * Compute the current css classes to use with header.
   * `header--active` or `header--hide` to toggle the visibility of the header
   */
  get headerClass(): string {
    return this._visible ? 'header--active' : 'header--hide'
  }

  /**
   * Toggle night mode on or off
   */
  async toggleNightMode(): Promise<void> {
    await nightMode.toggle()
  }

  /**
   * Logout handler.
   */
  async logout(): Promise<void> {
    if (offline.isOnline) {
      try {
        await notificationService.unsubscribe()
        await backend.logout()
      } catch (e: any) {
        if (
          e.code === 'ERR_NETWORK' ||
          e.code === 'ECONNABORTED' ||
          e.code === 'ERR_INTERNET_DISCONNECTED'
        ) {
          emitter.emit('logoutOfflineFailure')
          return
        }
      }
    } else {
      emitter.emit('logoutOfflineFailure')
      return
    }
    await nightMode.off()
    this._vm.$router.push({ name: 'login' })
  }

  /**
   * Check if the given menuItem refers to the active page.
   *
   * The active page is the page root page at the start
   * of the navigation history.
   */
  isItemActive(menuItem: MenuItem): boolean {
    // Some routes, like `my_library`, have children routes that are part
    // of the same page (representing tabs on the same page), and the route
    // name in the navigation history will include this child route. However,
    // in `this._menuItems` we only register the name of the main path, so
    // to get a match, we remove the subpath from the root page route name.
    const rootPageNameRaw = backLink.value.rootPage.name as string
    const rootPageName = rootPageNameRaw.split('.').shift()

    return rootPageName === menuItem.link
  }

  get isMenuVisible(): boolean {
    return this._isMenuVisible
  }

  /**
   * Show/hide the CTA "Start Free Trial" button
   */
  get showCtaButton(): boolean {
    return this.isFree && this._currentRouteName !== 'billing'
  }

  /**
   * Show/hide the CTA "Start Free Trial" button on tablet and mobile screens
   */
  get showCtaButtonOnMobile(): boolean {
    return (
      this.showCtaButton &&
      this._ctaButtonAvailablePagesForMobile.includes(this._currentRouteName)
    )
  }

  /**
   * Show header
   */
  show(): void {
    this._visible = true
  }

  /**
   * Hide header
   */
  hide(): void {
    this._visible = false
  }

  /**
   * Toggle header visibility state.
   */
  toggle(): void {
    this._visible = !this._visible
  }

  public toggleMenu(): void {
    this._isMenuVisible ? this.hideMenu() : this.showMenu()
  }

  public hideMenu(): void {
    // This if check is added because the `hideMenu` method gets called by the
    // `v-click-outside` hook in `UserMenu.vue` also when opening the menu. Somehow the
    // menu still works fine and does not get closed immediately after opening it.
    // But without the if check, the `modalClosed` event would get emitted, even in
    // cases where the menu didn't actually get closed.
    if (this._isMenuVisible) {
      this._isMenuVisible = false
      emitter.emit('overlayMenuClosed')
    }
  }

  private showMenu(): void {
    this._isMenuVisible = true
    emitter.emit('overlayMenuOpened')
  }
}
