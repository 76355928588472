import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "books__icons" }
const _hoisted_2 = { class: "books__link" }
const _hoisted_3 = { class: "books__progress progress" }
const _hoisted_4 = ["aria-valuenow"]
const _hoisted_5 = { class: "books__text-box" }
const _hoisted_6 = { class: "books__text" }
const _hoisted_7 = { class: "books__wrapper" }
const _hoisted_8 = { class: "books__category card__category" }
const _hoisted_9 = { class: "books__title card__title" }
const _hoisted_10 = { class: "books__author card__author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookListenIcon = _resolveComponent("BookListenIcon")!
  const _component_FreeBookIcon = _resolveComponent("FreeBookIcon")!
  const _component_BookCardThumbnail = _resolveComponent("BookCardThumbnail")!
  const _component_FavoriteFlag = _resolveComponent("FavoriteFlag")!
  const _component_ActionMenu = _resolveComponent("ActionMenu")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.isCTA)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.view.startLinkParams(_ctx.doc),
        class: _normalizeClass(["books__item", _ctx.doc.id])
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.doc.has_audio)
              ? (_openBlock(), _createBlock(_component_BookListenIcon, { key: 0 }))
              : _createCommentVNode("", true),
            (_ctx.doc.is_free && _ctx.showFreeBooks)
              ? (_openBlock(), _createBlock(_component_FreeBookIcon, { key: 1 }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BookCardThumbnail, {
              class: "books__img-box",
              coverClass: "books__img",
              fallbackCoverClass: "books__img",
              doc: _ctx.doc,
              cacheBust: true
            }, null, 8, ["doc"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                "aria-valuenow": _ctx.doc.progress,
                "aria-valuemin": "0",
                "aria-valuemax": "100",
                style: _normalizeStyle({ width: _ctx.doc.progress + '%' }),
                class: "progress__now"
              }, null, 12, _hoisted_4)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.view.firstTag(_ctx.doc)), 1),
                  _createVNode(_component_FavoriteFlag, {
                    doc: _ctx.doc,
                    class: "books__collection collection collection--book-card"
                  }, null, 8, ["doc"])
                ]),
                _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.doc.title), 1),
                _createElementVNode("h4", _hoisted_10, "By " + _toDisplayString(_ctx.doc.author), 1)
              ]),
              (_ctx.view.showActionsButton)
                ? (_openBlock(), _createBlock(_component_ActionMenu, {
                    key: 0,
                    view: _ctx.view,
                    doc: _ctx.doc
                  }, null, 8, ["view", "doc"]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      }, 8, ["to", "class"]))
    : _createCommentVNode("", true)
}