
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import BookCard from '@/app/components/book/card/BookCard.vue'
import ListingBlock from '@/app/components/listing/ListingBlock.vue'

import { BooksView } from '@/models/book'

@Options({
  components: {
    BookCard,
    ListingBlock,
  },
})
export default class NewReleasesBooksBlock extends Vue {
  @Prop() private view!: BooksView
  @Prop() private title!: string
  @Prop() private viewAllLink!: string
  @Prop({ default: true }) private showFreeBooks!: boolean
}
