import { type DirectiveBinding, type Directive } from 'vue'
import { assertNotNull } from '@/helpers/typing'

interface ClickOutSideElement extends HTMLElement {
  clickOutsideEvent?: (e: MouseEvent) => void
}

export const clickOutside: Directive = {
  created: function (el: ClickOutSideElement, binding: DirectiveBinding): void {
    el.clickOutsideEvent = function (event): void {
      // Here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target as Node))) {
        // And if it did, call method provided in attribute value
        // Vue 3 version: https://stackoverflow.com/a/63910611/4612064
        binding.value(event, el)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: function (el: ClickOutSideElement): void {
    document.body.removeEventListener(
      'click',
      assertNotNull(el.clickOutsideEvent),
    )
  },
}
