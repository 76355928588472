import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocSummaryPage = _resolveComponent("DocSummaryPage")!

  return (_openBlock(), _createBlock(_component_DocSummaryPage, {
    doc: _ctx.episode,
    isLoadingDoc: _ctx.isLoadingDoc,
    isErrorLoadingDoc: _ctx.isErrorLoadingDoc,
    class: "layout--podcast"
  }, null, 8, ["doc", "isLoadingDoc", "isErrorLoadingDoc"]))
}