import { ComponentPublicInstance } from 'vue'
import { DocsView, DocSortOption } from '@/models/doc'
import { Mixpanel } from '@/analytics/mixpanel'
import { delay } from '@/helpers/form'

type OptionData = {
  option: DocSortOption
  name: string
  icon: string
  click?: () => void
  isActive?: () => boolean
}

/**
 * Human-readable option name.
 */
const _docSortOptionNames: Record<DocSortOption, string> = {
  [DocSortOption.title]: 'Alphabetical',
  [DocSortOption.first_published_at]: 'Latest',
  [DocSortOption.popular]: 'Popular',
  [DocSortOption.recommended]: 'Recommended',
  [DocSortOption.accessed]: 'Last Accessed',
  [DocSortOption.none]: 'Relevancy',
}

class SortOptionQueryString {
  _vm: ComponentPublicInstance
  _view: DocsView
  _options: OptionData[]

  constructor(
    vm: ComponentPublicInstance,
    view: DocsView,
    options: OptionData[],
  ) {
    this._vm = vm
    this._view = view
    this._options = options
  }

  /**
   * Restore the sorting from the query string.
   */
  public restore(): void {
    if (this.option && this.isEligible()) {
      this._view.sort(this.option)
    }
  }

  /**
   * Get current sort option.
   *
   * If there's a sort option in the query, e.g. ``?sort=first_published_at``, return the
   * sort option, in this case - `first_published_at`
   */
  public get option(): DocSortOption | undefined {
    const querySort = this._vm.$route.query.sort as keyof typeof DocSortOption
    if (querySort) {
      return DocSortOption[querySort]
    }
  }

  /**
   * Is the current option eligible for sorting?
   *
   * Check whether the current ``option`` field, that's populated from the query string,
   * is eligible for sorting, for example `recommended` cannot be chosen if there are no
   * recommendations.
   * @returns true - if option is eligible for sorting.
   */
  private isEligible(): boolean {
    return this._options.some(({ option }) => option === this.option)
  }

  /**
   * Update the query string with current sort option value.
   * @param sortValue: string with sort option name
   */
  public update(sortValue: string): void {
    this._vm.$router.replace({ query: { sort: sortValue } })
  }
}

/**
 * Documents sort view.
 *
 * This view contains logic that's needed to visually handle the
 * book sorting:
 * - Show the list of available sorting options
 * - Show/hide the menu
 * - Get the sort option from the query string
 *
 * The sorting functionality itself is in `doc.ts` model where we
 * manipulate documents order.
 */
export class DocsSortView {
  private _vm: ComponentPublicInstance
  private _view: DocsView
  private _queryString: SortOptionQueryString

  public options: OptionData[] = []

  /**
   * Holds the current sort selection while the sort-request is delayed.
   * `undefined` otherwise.
   */
  private _nextOption?: DocSortOption
  private _visible: boolean = false

  constructor(vm: ComponentPublicInstance, isDesignV4: boolean = false) {
    this._vm = vm
    this._view = (vm as any).view

    this.options = [
      this._createOptionData(
        DocSortOption.first_published_at,
        'isort-by-first_published_at',
      ),
      this._createOptionData(DocSortOption.popular, 'isort-by-popular'),
      this._createOptionData(DocSortOption.title, 'isort-by-title'),
    ]
    if (this._view.hasRecommendations) {
      this.options.push(
        this._createOptionData(
          DocSortOption.recommended,
          'isort-by-recommended',
        ),
      )
    }

    this._queryString = new SortOptionQueryString(
      this._vm,
      this._view,
      this.options,
    )

    if (isDesignV4) {
      this.replaceOptionsToDesignV4()
    }

    this._queryString.restore()
  }

  private replaceOptionsToDesignV4(): void {
    this.options = [
      this._createOptionData(DocSortOption.first_published_at, 'itime'),
      this._createOptionData(DocSortOption.popular, 'ipopular'),
      this._createOptionData(DocSortOption.title, 'iasc'),
    ]
    if (this._view.hasRecommendations) {
      this.options.push(
        this._createOptionData(
          DocSortOption.recommended,
          'isort-by-recommended',
        ),
      )
    }
  }

  /**
   * Create the list of sort options.
   */
  private _createOptionData(option: DocSortOption, icon: string): OptionData {
    return {
      option: option,
      icon: icon,
      name: _docSortOptionNames[option],
      click: (): void => this._optionClick(option),
      isActive: (): boolean => option === this._displayedSortOption,
    }
  }

  /**
   * The currently selected dropdown value.
   */
  private get _displayedSortOption(): DocSortOption {
    return this._nextOption ?? this._view.sortOption
  }

  /**
   * The name of the currently selected sort option.
   */
  public get displayedSortOptionName(): string {
    return _docSortOptionNames[this._displayedSortOption]
  }

  /**
   * Option click handler.
   *
   * Do not apply sort if the view is already sorted.
   * This prevents automatic re-sorting and URL query update when we enter
   * the articles page (where the sorting is by "first_published_at" field and different
   * from the default "title").
   * And that re-sorting makes it impossible using links with query parameters,
   * for example `/app/articles?search=BBC` immediately turns into
   * `/app/articles?sort=first_published_at.
   */
  private _optionClick(option: DocSortOption): void {
    if (this._displayedSortOption === option) {
      return
    }

    this._nextOption = option
    Mixpanel.trackSort(option)

    delay(() => {
      this._view.sort(option)
      this._nextOption = undefined
      // If something was found, push the search string to the URL.
      if (
        this._view.filteredDocs.length &&
        this._vm.$route.query.sort !== option
      ) {
        this._queryString.update(option)
      }
    }, 500)
  }

  /**
   * Update the query string with current sort option value.
   * @param sortValue: string with sort option name
   */

  /**
   * Blur sort options import if there are sort options.
   */
  public get selectOnFocus(): string {
    return this.options.length > 0 ? 'this.blur()' : ''
  }

  /**
   * Return true if the sort options menu visible.
   */
  public get visible(): boolean {
    return this._visible
  }

  /**
   * Hide sort options menu.
   *
   * We use this method along with click-outside directive.
   */
  public hide(): void {
    this._visible = false
  }

  /**
   * Toggle sort options menu visibility.
   */
  public toggleMenu(): void {
    this._visible = !this._visible
  }

  public get activeOptIcon(): string {
    let icon = ''
    this.options.forEach((option) => {
      if (option.isActive && option.isActive()) {
        icon = option.icon
      }
    })
    return icon
  }
}

/**
 * Documents sort mobile component view.
 *
 * This view contains logic that's needed to visually handle the
 * book sorting:
 * - Show the list of available sorting options
 * - Show/hide the menu
 * - Get the sort option from the query string
 * - Select the sort option
 * - Apply selected sort option
 *
 * The sorting functionality itself is in `doc.ts` model where we
 * manipulate documents order.
 */
export class DocsSortMobileView {
  private _vm: ComponentPublicInstance
  private _view: DocsView
  private _queryString: SortOptionQueryString
  private _visible: boolean = false

  public options: OptionData[] = [
    {
      option: DocSortOption.first_published_at,
      name: 'Latest',
      icon: 'itime',
    },
    {
      option: DocSortOption.popular,
      name: 'Popular',
      icon: 'ipopular',
    },
    {
      option: DocSortOption.title,
      name: 'Alphabetical',
      icon: 'iasc',
    },
  ]

  private _activeOption: OptionData

  constructor(vm: ComponentPublicInstance) {
    this._vm = vm
    this._view = (vm as any).view

    // Default sorting is by Popular
    this._activeOption = this.options[1]

    // Create SortOptionQueryString object and restore the search from query string
    this._queryString = new SortOptionQueryString(
      this._vm,
      this._view,
      this.options,
    )
    if (this._queryString.option) {
      this._queryString.restore()
      this.setActiveOption(this._queryString.option)
    }

    if (this._view.hasRecommendations) {
      this.options.push({
        option: DocSortOption.recommended,
        name: 'Recommended',
        icon: 'isort-by-recommended',
      })
    }

    if (this._activeOption.option !== this._view.sortOption) {
      this.setActiveOption(this._view.sortOption)
    }
  }

  /**
   * Set ``_activeOption`` field by DocSortOption.
   * @param option - one of the options, e.g. `first_published_at`
   *
   * We use this method to se the active option (of ``OptionData`` type) to match
   * the given value of ``DocSortOption``.
   */
  private setActiveOption(option: DocSortOption): void {
    this.options.forEach((opt) => {
      if (opt.option === option) {
        this._activeOption = opt
      }
    })
  }

  /**
   * Option click handler.
   *
   * Update the ``_activeOption`` with the clicked option.
   */
  public optionClick(option: OptionData): void {
    this.setActiveOption(option.option)
  }

  /**
   * Actually apply the sort.
   *
   *  Triggered on click on the "Apply" button.
   */
  private applySort(): void {
    Mixpanel.trackSort(this._activeOption.option)

    this.hide()
    delay(() => {
      this._view.sort(this._activeOption.option)
      // If something was found, push the search string to the URL.
      if (
        this._view.filteredDocs.length &&
        this._vm.$route.query.sort !== this._activeOption.option
      ) {
        this._queryString.update(this._activeOption.option)
      }
    }, 500)
  }

  /**
   * Return true if the sort options menu visible.
   */
  public get visible(): boolean {
    return this._visible
  }

  /**
   * Hide sort options popup.
   */
  public hide(): void {
    this._visible = false
  }

  /**
   * Show sort options popup.
   */
  public show(): void {
    this._visible = true
  }

  /**
   * Toggle sort options popup visibility.
   */
  public toggleMenu(): void {
    this._visible = !this._visible
  }

  /**
   * Get the active item class.
   */
  public activeOptionClass(option: OptionData): string {
    return this._activeOption === option ? 'doc-sort__item--active' : ''
  }
}
