import * as TypedAssert from 'typed-assert'
import { START_LOCATION, RouteLocationNormalized } from 'vue-router'
import { storage as localStorageWrapper } from '@/helpers/storage'

const POSITION_KEY = 'sf_position'

/**
 * Store `position` in the storage.
 * It will be put into `POSITION_KEY`.
 */
export function storePosition(position: string): void {
  // We periodically get error in Sentry when restoring the position
  // Failed to set the 'href' property on 'Location':
  // 'https://www.shortform.com{"path":"/app/billing"}'
  // is not a valid URL.
  // So, probably, we somehow save the wrong position (object instead of
  // string). Not sure how this happens, since we have the
  // `!position.startsWith('/app/')` condition in the shouldStorePosition
  // method.
  // The typed assert below should raise the error early and prevent saving
  // the wrong position.
  TypedAssert.isString(position)
  localStorageWrapper.setItem(POSITION_KEY, position)
}

/**
 * Retrieve a stored `Position` from the storage.
 * It will be read from `POSITION_KEY`.
 */
export function retrievePosition(): string | undefined {
  return localStorageWrapper.getItem(POSITION_KEY) || undefined
}

/**
 * Retrieve the position the app should redirect to.
 * This only happens when we open the page up the first time,
 * not after a regular intra-shortform navigation.
 * `from.path` will be set to `/` when we first open the page.
 */
export function nextPositionOnRedirect(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): string | undefined {
  if (from.path === START_LOCATION.path && to.path === '/app') {
    const position = retrievePosition()
    // Make sure the previously stored position is still valid for use.
    const validatedPosition = position && validatePosition(position)
    if (validatedPosition) {
      return validatedPosition
    }

    // If the position is not valid (should not be stored) - remove it for good.
    // This might fix the Sentry issue SHORTFORM-FRONTEND-4Q8.
    clearStoredPosition()
  }
  return undefined
}

/**
 * Clear the stored `Position` from the storage at `POSITION_KEY`.
 */
export function clearStoredPosition(): void {
  localStorageWrapper.removeItem(POSITION_KEY)
}

/**
 * Decide whether we want to store or drop this position.
 * Validate and sanitize the path in the process, removing query strings etc.,
 * making sure that the value we got is definitely something we can navigate to.
 *
 * Returns the validated, sanitized path or undefined, if the path is invalid.
 */
export function validatePosition(position: string): string | undefined {
  try {
    // Make sure it's a valid path. Otherwise the constructor would throw.
    const url = new URL('https://localhost' + position)
    const path = url.pathname

    if (!path.startsWith('/app/')) {
      return undefined
    }

    // We'll add whitelisted paths more often than blacklisted ones.
    // A blacklist approach is better because it requires less maintenance.
    const ignoredPaths = [
      '/app/export',
      '/app/login',
      '/app/signup',
      '/app/password',
      '/app/404',
    ]
    if (ignoredPaths.some((ignore) => path.startsWith(ignore))) {
      return undefined
    }

    return path
  } catch {
    return undefined
  }
}

/**
 * This "method" should be called on all URL/Path changes in the app
 * to keep track of where the user went.
 */
export function storePositionOnChange(route: RouteLocationNormalized): void {
  // Note: we used to have `route.fullPath` here, but it does not
  // make much sense:
  // * path is a current URL path
  // * fullPath is a current URL with query string
  //
  // We use query strings for things like books search and sorting,
  // but we should not save and restore these, for example:
  // * the user searches for "blue" and has a few matching books
  // * the user closes the app
  // * the user opens the app few days later
  //
  // Now the books are still filtered by "blue", but most likely the
  // user does not remember filtereing books and may not notice the
  // "blue" word in the search box.
  //
  // Result: the user will see the limited list of books without realizing
  // that many are missing (we still have quite many matching the "blue"
  // term).
  // To avoid the confusion, we use `route.path` here.
  const validatedPosition = validatePosition(route.path)
  if (validatedPosition) {
    storePosition(validatedPosition)
  } else {
    clearStoredPosition()
  }
}

/**
 * The position service object.
 */
export * as appPositionTrackingService from './app.position.tracking'
