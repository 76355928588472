import { DateTime, Duration } from 'luxon'
import humanizeDuration from 'humanize-duration'

export function formatDate(isoDate: string): string {
  // 2020-12-24T19:05:24.000000+00:00
  return DateTime.fromISO(isoDate).toFormat('MMM d, yyyy')
}

export function formatTrialDate(dateTime: string): string {
  // 2020-12-24T19:05:24.000000+00:00
  return DateTime.fromISO(dateTime).toFormat('DDD')
}

export function formatTrialDateDebug(dateTime: string): string {
  // 2020-12-24T19:05:24.000000+00:00
  return DateTime.fromISO(dateTime).toString()
}

export function calendar(isoDate: string): string {
  // 2020-12-24T19:05:24.000000+00:00
  const result = DateTime.fromISO(isoDate).toRelativeCalendar()
  return result ? result : ''
}

export function relative(isoDate: string): string {
  const result = DateTime.fromISO(isoDate).toRelative()
  return result ? result : ''
}

export function futureDate(daysAhead: number): string {
  return DateTime.local().plus({ days: daysAhead }).toFormat('DDD')
}

export function nextDownloadDate(daysAhead: number): string {
  return DateTime.local().plus({ days: daysAhead }).toFormat('MMMM d')
}

export function trialEndDiff(dateTime: string): string {
  const nowDate = DateTime.local()
  const otherDate = DateTime.fromISO(dateTime)
  return humanizeDuration(otherDate.diff(nowDate).as('seconds') * 1000, {
    largest: 1,
    round: true,
  })
}

export function humanDaysDiff(days: number): string {
  return humanizeDuration(
    Duration.fromObject({ days: days }).as('seconds') * 1000,
    {
      largest: 2,
      round: true,
    },
  )
}

// Add `number` amount of days to the specified `date`.
export function addDays(date: Date, days: number): Date {
  const result = new Date(date)
  return new Date(result.setDate(result.getDate() + days))
}

// AP style dates:
// https://writingexplained.org/ap-style/ap-style-months#:~:text=Month%20Abbreviations%20AP%20Style,are%20alone%20with%20a%20year.
//
// Except never with a '.', even for abbreviated months
//
// Examples: June 5, Oct 31, Sept 15
export function apStyleDate(date: Date): string {
  const monthNames = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ]
  const monthName = monthNames[date.getMonth()]
  const dayOfMonth = date.getDate()
  return `${monthName} ${dayOfMonth}`
}

/**
 * Reformat seconds to a "days, hours, minutes, seconds" string.
 */
export function secondsToTextDate(secondsLeft: number): string {
  // Count how many days/hours/minutes/seconds left
  let timeLeft = secondsLeft
  const days = Math.floor(timeLeft / (3600 * 24))
  timeLeft -= days * 3600 * 24
  const hrs = Math.floor(timeLeft / 3600)
  timeLeft -= hrs * 3600
  const mnts = Math.floor(timeLeft / 60)
  timeLeft -= mnts * 60
  // Return date in text format
  return `${days} days, ${hrs} hours, ${mnts} minutes, ${timeLeft} seconds`
}
