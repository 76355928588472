import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4dc8fe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "docs-listing docs-listing--podcasts" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderRoot = _resolveComponent("HeaderRoot")!
  const _component_EarlyAccessBannerPodcast = _resolveComponent("EarlyAccessBannerPodcast")!
  const _component_LoadingError = _resolveComponent("LoadingError")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_LatestEpisodesBlock = _resolveComponent("LatestEpisodesBlock")!
  const _component_InfiniteScrollPodcastsBlock = _resolveComponent("InfiniteScrollPodcastsBlock")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { class: "layout--basic" }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderRoot, { title: "Podcasts" }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_EarlyAccessBannerPodcast),
        _createVNode(_component_v_wait, { for: "Error Loading Podcasts" }, {
          waiting: _withCtx(() => [
            _createVNode(_component_LoadingError)
          ]),
          _: 1
        }),
        _createVNode(_component_v_wait, {
          class: "layout__wrapper-inner container",
          for: "Loading Podcasts"
        }, {
          waiting: _withCtx(() => [
            _createVNode(_component_loading_spinner)
          ]),
          default: _withCtx(() => [
            (_ctx.followedEpisodesView)
              ? (_openBlock(), _createBlock(_component_LatestEpisodesBlock, {
                  key: 0,
                  view: _ctx.followedEpisodesView
                }, null, 8, ["view"]))
              : _createCommentVNode("", true),
            _createElementVNode("section", _hoisted_2, [
              _createVNode(_component_InfiniteScrollPodcastsBlock, { view: _ctx.podcastsView }, null, 8, ["view"])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}