import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderAppDesktop = _resolveComponent("HeaderAppDesktop")!
  const _component_HeaderInternal = _resolveComponent("HeaderInternal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderAppDesktop, {
      class: _normalizeClass(_ctx.view.headerClass)
    }, null, 8, ["class"]),
    _createVNode(_component_HeaderInternal, {
      title: _ctx.doc.title,
      backLinkDoc: _ctx.doc,
      class: _normalizeClass(["header--scroll header--book", _ctx.view.headerClass])
    }, null, 8, ["title", "backLinkDoc", "class"])
  ], 64))
}