<template>
  <div class="short-onboarding__title-block">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/variables.scss';

.short-onboarding__title-block {
  padding-bottom: 64px;
  text-align: center;
  letter-spacing: -0.02em;

  @media (max-width: $w-medium-s) {
    padding-bottom: 12px;
  }

  :deep(.short-onboarding__title-mobile-left) {
    @media (max-width: $w-tablet-s) {
      text-align: left;
      margin-inline: 40px;
    }

    @media (max-width: $w-medium-s) {
      margin-inline: 20px;
    }
  }

  :deep(h2) {
    line-height: 52px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
    font-size: 42px;
    max-width: 900px;
    margin-inline: auto;

    @media (max-width: $w-tablet-b) {
      font-size: 28px;
      line-height: 32px;

      margin-bottom: 12px;
      max-width: 540px;
    }

    @media (max-width: $w-medium-s) {
      line-height: 36px;
    }
  }

  :deep(p) {
    font-size: 18px;
    line-height: 32px;
    color: $c-black-3;

    @media (max-width: $w-tablet-s) {
      font-size: 16px;
      line-height: 28px;
      text-align: left;
      margin-inline: 20px;
    }
  }
}
</style>
