
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import DocLayout from '@/app/components/doc/Layout.vue'
import Footer from '@/app/components/Footer.vue'
import Comments from '@/app/components/community/Comments.vue'
import CommentEdit from '@/app/components/community/CommentEdit.vue'

import { backend } from '@/services/backend'
import { auth } from '@/services/auth'
import { Sentry } from '@/services/sentry'
import { ThreadView } from '@/models/forum'
import { DocView } from '@/models/doc'

@Options({
  components: {
    DocLayout,
    Footer,
    Comments,
    CommentEdit,
  },
})
export default class Thread extends Vue {
  @Prop() private doc!: DocView
  isLoading: boolean = false
  thread: ThreadView | null = null

  async beforeMount(): Promise<void> {
    this.loadThread()
  }

  async loadThread(): Promise<void> {
    try {
      this.isLoading = true
      const response = await backend.getThread(
        this.$route.params.thread as string,
      )
      this.thread = new ThreadView(response)
      this.isLoading = false
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        Sentry.captureException(error)
        throw error
      }
    }
  }

  get isAuthor(): boolean {
    return !!this.thread && this.thread.author.id === auth.getUserId()
  }

  focusReply(): void {
    ;(this.$refs.commentEdit as any).focusInput()
  }
}
