import { AuthUser, Billing, Plan } from '@/models/interfaces'
import { Sentry } from '@/services/sentry'

export function register(): void {
  try {
    window.uetq = window.uetq || []
    window.uetq.push('event', 'Success', {
      event_category: 'Signup',
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function subscribe(
  user: AuthUser | null,
  new_plan: Plan,
  billing: Billing,
): void {
  try {
    if (billing.trial_start !== null) {
      // We only want to track the event once,
      // and we use trial_start for that - it is
      // null only on the first subscription,
      // once subscribed, trial starts and after
      // that trial_start / trial_end remain unchanged.
      // Set 'TestPayment' category for admins.
      return
    }

    let variant: 'd' | 'b' | 'p' = 'd'
    if (billing.billing_plans.plan_monthly < 1390) {
      variant = 'b'
    }
    if (billing.billing_plans.plan_monthly > 1390) {
      variant = 'p'
    }

    const values = {
      d: {
        plan_monthly: 60,
        plan_annual: 145,
      },
      b: {
        plan_monthly: 30,
        plan_annual: 70,
      },
      p: {
        plan_monthly: 100,
        plan_annual: 245,
      },
    }
    const product = values[variant]

    let label = 'Free'
    let value = 0
    if (new_plan.id === 'plan_monthly') {
      label = 'Monthly'
      value = product.plan_monthly
    }
    if (new_plan.id === 'plan_annual') {
      label = 'Annual'
      value = product.plan_annual
    }
    window.uetq = window.uetq || []
    window.uetq.push('event', 'Success', {
      event_category: 'CardSave',
      event_label: label,
      revenue_value: value,
      currency: 'USD',
    })

    /*
    // if (user && user.email.trim().endsWith('shortform.com')) {
    //   this._console_log('Test user, skip bing Purchase with: ' + value)
    //   return
    // }
    // // Set 'TestPayment' category for non-production environments.
    // if (IS_NOT_PRODUCTION) {
    //   this._console_log('Not production, skip bing Purchase with: ' + value)
    //   return
    // }
    */
  } catch (error) {
    Sentry.captureException(error)
  }
}

/*
 * Bing tracking lets optimize ads on bing.
 *
 * Note: we don't explicitely include bing tracking script, it is
 * added by google tag manager.
 *
 * Docs: https://help.ads.microsoft.com/apex/index/3/en/56684
 */
export * as Bing from './bing'
