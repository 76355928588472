/*
 * Questionnaire steps.
 *
 * This enum is used both in QuestionnaireView and here to keep track of
 * steps (current, next, previous) and be able to show needed components.
 */
export enum QuestionnaireStep {
  start = 'questionnaire_start',
  categories = 'questionnaire_categories',
  bookStepOne = 'questionnaire_book_one',
  bookStepTwo = 'questionnaire_book_two',
  bookStepThree = 'questionnaire_book_three',
  quoteStepOne = 'questionnaire_quote_one',
  quoteStepTwo = 'questionnaire_quote_two',
  quoteStepThree = 'questionnaire_quote_three',
  support = 'questionnaire_support',
  time = 'questionnaire_time',
  when = 'questionnaire_when',
  finish = 'questionnaire_finish',
}

/*
 * Questionnaire "How much do you want Shortform to support your reading
 * and learning goals?" options
 */
export enum QuestionnaireReadingSupport {
  coach = 'coach',
  supporter = 'supporter',
  none = 'none',
}

/*
 * Questionnaire "How much time do you want to read each day?" options.
 */
export enum QuestionnaireReadingTime {
  about_15_minutes = 'about_15_minutes',
  about_30_minutes = 'about_30_minutes',
  about_45_minutes = 'about_45_minutes',
  more_than_1_hour = 'more_than_1_hour',
  any_spare_time = 'any_spare_time',
}

/*
 * Questionnaire "When do you want to read?" options.
 */
export enum QuestionnaireReadingWhen {
  in_the_morning = 'in_the_morning',
  while_commuting = 'while_commuting',
  during_my_lunch_break = 'during_my_lunch_break',
  around_dinnertime = 'around_dinnertime',
  before_going_to_sleep = 'before_going_to_sleep',
  any_spare_time = 'any_spare_time',
}
