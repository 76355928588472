import {
  CapacitorMediaSession,
  MediaSessionEventType,
  MediaSessionListenerFunc,
  MediaState,
} from '@/services/capacitor-media-session'
import { platform } from '@/services/platform'
import { Sentry } from '@/services/sentry'
import { Capacitor, PluginListenerHandle } from '@capacitor/core'

/**
 * Callbacks to react on interactions with native media controls (e.g. browser's media
 * controls, or lock screen / notification area media controls on mobile).
 */
export interface MediaSessionCallbacks {
  /**
   * Called when play button is clicked.
   */
  onPlay?(): void

  /**
   * Called when pause button is clicked.
   */
  onPause?(): void

  /**
   * Called when stop button is clicked.
   */
  onStop?(): void

  /**
   * Called when backward button is clicked. Should rewind media by a certain amount,
   * e.g. 10 seconds.
   */
  onSeekBackward?(): void

  /**
   * Called when forward button is clicked. Should fast-forward media by a certain
   * amount, * e.g. 10 seconds.
   */
  onSeekForward?(): void

  /**
   * Called when the user seeks to a specific position.
   */
  onSeekTo?(position?: number | null): void

  /**
   * Called when previous track button (i.e. previous chapter) is clicked.
   */
  onPreviousTrack?(): void

  /**
   * Called when next track button (i.e. next chapter) is clicked.
   */
  onNextTrack?(): void
}

/**
 * Metadata about the current media item (e.g. audiobook).
 */
export interface MediaSessionMetadata {
  /**
   * Title of the book.
   */
  title: string
  /**
   * Author of the book.
   */
  author: string
  /**
   * Current chapter.
   */
  chapter: string
  /**
   * Cover image of the book, optionally in different sizes. Must have at least one
   * element. For example:
   * ```
   * [
   *   { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
   *   { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
   * ]
   * ```
   */
  artwork: MediaImage[]
}

/**
 * Information about playback position, audio duration and playback rate of an
 * audio item.
 */
export interface PositionState {
  /**
   * Audio duration to display in native media controls.
   */
  duration: number
  /**
   * Playback rate to display in native media controls.
   */
  playbackRate: number
  /**
   * Playback position to display in native media controls.
   */
  position: number
}

/**
 * Native media controls (e.g. browser's media controls, or lock screen / notification
 * area media controls on mobile).
 */
export interface MediaSessionHandler {
  /**
   * Update playback position, media duration and playback rate of the native
   * media controls.
   *
   * The playback position does not have to be updated manually during normal playback,
   * but only when moving to a specific position, e.g. when changing playback position
   * with the controls of our in-app player (seek, prev/next chapter, etc.).
   *
   * @param positionState New position, duration and playback rate.
   */
  setPositionState(positionState: PositionState): Promise<void>

  /**
   * Update the playback state of the native media controls, i.e. set to playing
   * or paused.
   *
   * @param playbackState New playback state - 'playing' or 'paused'.
   * @param positionState Position, duration, playback rate and the time of update.
   */
  setPlaybackState(
    playbackState: 'playing' | 'paused',
    positionState: PositionState,
  ): Promise<void>

  /**
   * Update the metadata shown in the native media controls. The main use is to update
   * the current chapter title.
   * @param metadata New metadata
   */
  setMetadata(metadata: MediaSessionMetadata): Promise<void>

  /**
   * Stop showing the native media controls. Implemented only for Android apps.
   */
  releaseMediaSession(): Promise<void>
}

/**
 * Factory function to initialize a MediaSessionHandler.
 *
 * Returns an implementation that uses the Media Session web API
 * (https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API), if supported
 * by the browser, or a stub implementation that does nothing otherwise.
 *
 * For native Android apps, returns an implementation that uses a custom Capacitor
 * plugin, as the Media Session API is not supported by web views in Android apps (just
 * nothing happens).
 */
export async function createMediaSessionHandler(
  metadata: MediaSessionMetadata,
  playbackState: MediaSessionPlaybackState,
  callbacks: MediaSessionCallbacks,
): Promise<MediaSessionHandler> {
  if (
    (await platform.isNativeAndroidApp()) &&
    Capacitor.isPluginAvailable('CapacitorMediaSession')
  ) {
    return MediaSessionAndroid.initialize(metadata, playbackState, callbacks)
  }
  if ('mediaSession' in navigator) {
    return new MediaSessionWeb(metadata, playbackState, callbacks)
  }
  return new MediaSessionStub()
}

/**
 * Fallback stub implementation of MediaSessionHandler for browsers that don't support
 * the Media Session API
 * (https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API)
 */
class MediaSessionStub implements MediaSessionHandler {
  setPlaybackState(): Promise<void> {
    return Promise.resolve()
  }

  setPositionState(): Promise<void> {
    return Promise.resolve()
  }

  setMetadata(): Promise<void> {
    return Promise.resolve()
  }

  releaseMediaSession(): Promise<void> {
    return Promise.resolve()
  }
}

/**
 * Implementation of MediaSessionHandler using the Media Session API:
 * https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API
 */
class MediaSessionWeb implements MediaSessionHandler {
  constructor(
    metadata: MediaSessionMetadata,
    playbackState: MediaSessionPlaybackState,
    callbacks: MediaSessionCallbacks,
  ) {
    navigator.mediaSession.playbackState = playbackState
    navigator.mediaSession.metadata = MediaSessionWeb.convertMetadata(metadata)
    this.setActionHandlers(callbacks)
  }

  setPlaybackState(
    playbackState: 'playing' | 'paused',
    positionState: PositionState,
  ): Promise<void> {
    navigator.mediaSession.playbackState = playbackState
    return this.setPositionState(positionState)
  }

  setPositionState(positionState: PositionState): Promise<void> {
    // When first initializing the player, the duration is still NaN. In this case
    // we don't want to call mediaSession.setPositionState, as it would throw.
    if (positionState.duration && !isNaN(positionState.duration)) {
      navigator.mediaSession.setPositionState(positionState)
    }
    return Promise.resolve()
  }

  setMetadata(metadata: MediaSessionMetadata): Promise<void> {
    navigator.mediaSession.metadata = MediaSessionWeb.convertMetadata(metadata)
    return Promise.resolve()
  }

  releaseMediaSession(): Promise<void> {
    // Nothing to do. Media controls on web cannot be explicitly removed
    // programmatically, only by reloading the page.
    return Promise.resolve()
  }

  private setActionHandlers(callbacks: MediaSessionCallbacks): void {
    const actionToCallbackMapping: {
      [Action in MediaSessionAction]?: MediaSessionActionHandler
    } = {
      play: callbacks.onPlay,
      pause: callbacks.onPause,
      stop: callbacks.onStop,
      seekbackward: callbacks.onSeekBackward,
      seekforward: callbacks.onSeekForward,
      seekto: (details) => callbacks.onSeekTo?.(details.seekTime),
      previoustrack: callbacks.onPreviousTrack,
      nexttrack: callbacks.onNextTrack,
    }

    Object.entries(actionToCallbackMapping).forEach(([action, callback]) => {
      if (callback !== undefined) {
        navigator.mediaSession.setActionHandler(
          action as MediaSessionAction,
          callback,
        )
      }
    })
  }

  private static convertMetadata(
    metadata: MediaSessionMetadata,
  ): MediaMetadata {
    // We purposefully slightly misuse the title and artist fields to make the
    // current chapter the highlighted text - mediaState.title will be shown on the
    // first line of the native media controls, mediaState.artist gets shown on the
    // second line.
    return new window.MediaMetadata({
      title: metadata.chapter,
      artist: `${metadata.author} — ${metadata.title}`,
      artwork: metadata.artwork,
    })
  }
}

/**
 * Implementation of MediaSessionHandler for Android apps using a custom Capacitor
 * plugin, see `mobile/capacitor-media-session`.
 */
class MediaSessionAndroid implements MediaSessionHandler {
  private mediaState: MediaState
  private eventListener?: PluginListenerHandle

  /**
   * Create an instance of MediaSessionAndroid and initialize the native plugin.
   *
   * @param metadata Metadata of the audio item.
   * @param playbackState Initial playback state - 'playing' or 'paused'.
   * @param callbacks Callbacks to react on actions in the native media controls.
   */
  static async initialize(
    metadata: MediaSessionMetadata,
    playbackState: MediaSessionPlaybackState,
    callbacks: MediaSessionCallbacks,
  ): Promise<MediaSessionAndroid> {
    const mediaSession = new MediaSessionAndroid(metadata, playbackState)
    await mediaSession.setActionHandlers(callbacks)
    await mediaSession.initializeNativePlugin()
    return mediaSession
  }

  async setPlaybackState(
    playbackState: 'playing' | 'paused',
    positionState: PositionState,
  ): Promise<void> {
    this.mediaState.isPlaying = playbackState === 'playing'
    this.updatePositionState(positionState)
    CapacitorMediaSession.setMediaState(this.mediaState).catch(
      Sentry.captureException,
    )
  }

  async setPositionState(positionState: PositionState): Promise<void> {
    this.updatePositionState(positionState)
    CapacitorMediaSession.setMediaState(this.mediaState).catch(
      Sentry.captureException,
    )
  }

  async setMetadata(metadata: MediaSessionMetadata): Promise<void> {
    // We purposefully slightly misuse the title and artist fields to make the
    // current chapter the highlighted text - mediaState.title will be shown on the
    // first line of the native media controls, mediaState.artist gets shown on the
    // second line.
    this.mediaState.title = metadata.chapter
    this.mediaState.artist = `${metadata.author} — ${metadata.title}`
    CapacitorMediaSession.setMediaState(this.mediaState).catch(
      Sentry.captureException,
    )
  }

  async releaseMediaSession(): Promise<void> {
    CapacitorMediaSession.releaseMediaSession().catch(Sentry.captureException)
    await this.eventListener?.remove()
  }

  private constructor(
    metadata: MediaSessionMetadata,
    playbackState: MediaSessionPlaybackState,
  ) {
    this.mediaState = {
      isPlaying: playbackState === 'playing',
      title: metadata.chapter,
      artist: `${metadata.author} — ${metadata.title}`,
      artworkUri: metadata.artwork[0].src,
      position: 0,
      duration: 0,
    }
  }

  private async initializeNativePlugin(): Promise<void> {
    CapacitorMediaSession.initMediaSession(this.mediaState).catch(
      Sentry.captureException,
    )
  }

  private async setActionHandlers(
    callbacks: MediaSessionCallbacks,
  ): Promise<void> {
    const actionToCallbackMapping: {
      [Action in MediaSessionEventType]?: MediaSessionListenerFunc
    } = {
      onPlay: callbacks.onPlay,
      onPause: callbacks.onPause,
      onStop: callbacks.onStop,
      onRewind: callbacks.onSeekBackward,
      onFastForward: callbacks.onSeekForward,
      onPrev: callbacks.onPreviousTrack,
      onNext: callbacks.onNextTrack,
      onSeekTo: (event) => {
        const seekTimeSeconds = event.seekTime && event.seekTime / 1000
        callbacks.onSeekTo?.(seekTimeSeconds)
      },
    }

    this.eventListener = await CapacitorMediaSession.addListener(
      'mediaSessionEvent',
      (event) => {
        const callback = actionToCallbackMapping[event.eventName]
        callback?.(event)
      },
    )
  }

  private updatePositionState(positionState: PositionState): void {
    this.mediaState.position =
      (positionState.position / positionState.playbackRate) * 1000
    this.mediaState.duration =
      (positionState.duration / positionState.playbackRate) * 1000
  }
}
