
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { BillingView } from '@/models/billing'
import { wait } from '@/helpers/vue-wait'
import { ValidationState } from '@/helpers/form'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import StripeCard from '@/app/components/billing/StripeCard.vue'
import StripeCardInfo from '@/app/components/billing/StripeCardInfo.vue'

@Options({
  components: {
    StripeCard,
    StripeCardInfo,
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
  },
})
export default class CardEditPopup extends Vue {
  @Prop() private billing!: BillingView

  private validation: ValidationState = new ValidationState()
  private visible: boolean = false

  show(): void {
    this.billing.cardEditMode = true
    this.visible = true
  }

  hide(): void {
    this.billing.cardEditMode = false
    this.visible = false
  }

  async submitForm(event: Event): Promise<void> {
    event.preventDefault()
    await this.subscribe()
  }

  async subscribe(): Promise<void> {
    try {
      wait.start(this, 'Saving')
      if (
        this.billing.cardEditMode &&
        (this.$refs.stripeCard as any).needsSaving()
      ) {
        const result = await (this.$refs.stripeCard as any).save()
        if (result === true) {
          this.hide()
        }
      }
      wait.end(this, 'Saving')
    } catch (error) {
      wait.end(this, 'Saving')
      this.validation.showErrors(error)
    }
  }
}
