import { Adjust } from '@awesome-cordova-plugins/adjust'

import { Billing, Plan, AuthUser } from '@/models/interfaces'
import { auth } from '@/services/auth'
import { backend } from '@/services/backend'
import { platform } from '@/services/platform'
import { Sentry } from '@/services/sentry'
import { request } from '@/services/request'
import { storage } from '@/helpers/storage'

import { waitWithTimeout } from '@/helpers/timer'
import { AdjustWrapper } from '@/analytics/adjust'
import {
  Mixpanel,
  MARKETING_URL_KEY,
  login as mixpanelLogin,
  register as mixpanelRegister,
} from '@/analytics/mixpanel'
import { GTM } from '@/analytics/gtm'
import { GTAG } from '@/analytics/ga'
import { Facebook } from '@/analytics/facebook'
import { Bing } from '@/analytics/bing'

export async function trackLogin(user: AuthUser): Promise<void> {
  await _tryOperation(async () => {
    await waitWithTimeout(
      (done) => {
        mixpanelLogin(user, done)
      },
      1,
      1000,
    )
  })
}

export async function saveMarketingUrl(): Promise<void> {
  await _tryOperation(async () => {
    const marketingUrl = storage.getItem(MARKETING_URL_KEY)
    if (marketingUrl) {
      // Save marketing URL if we have it.
      await request.post('billing/marketing_url', {
        marketing_url: marketingUrl,
      })
    }
  })
}

export async function trackSignup(user: AuthUser): Promise<void> {
  await _tryOperation(async () => {
    GTAG.register(user)
    GTM.register(user)
    Facebook.register(user)
    Bing.register()
    await AdjustWrapper.register()

    // Mixpanel call is important, so wait for it separately.
    await waitWithTimeout(
      (done) => {
        mixpanelRegister(user, done)
      },
      1,
      1000,
    )
  })
}

export async function trackSubscribe(
  new_plan: Plan,
  billing: Billing,
): Promise<void> {
  // Here we need to track new plan vs previous billing data, so
  // we can have from -> to information, so passing in the "old"
  // billing data.
  // But this billing data is not "old" for Apple billing. Instead it is the
  // data updated after the user has subscribed, so be careful how you use it.
  await _tryOperation(async () => {
    await waitWithTimeout(
      (done) => {
        Mixpanel.trackBilling(new_plan, billing, done)
        GTAG.subscribe(auth.getUser(), new_plan, billing, done)
        GTM.subscribe(auth.getUser(), new_plan, billing)
        // Note: facebook and other trackers below don't have callback,
        // so we just invoke then and hope it'll finish before we navigate
        // to another page.
        Facebook.subscribe(auth.getUser(), new_plan, billing)
        Bing.subscribe(auth.getUser(), new_plan, billing)
      },
      3, // GTAG invokes the callback 2 times + 1 mixpanel event
      1000,
    )
  })
}

/**
 * If the user is logged in and these analytics IDs are not set, set them:
 * - Google Analytics 4 client ID
 * - IDFA, iOS Advertising Identifier (iOS native app only)
 * - adid, Adjust identifier (iOS native app only)
 * TODO: Maybe there could be a cleaner way of doing this.
 */
export async function maybeSyncAnalyticsIds(): Promise<void> {
  if (!auth.loggedIn()) {
    return
  }
  const isIOS = await platform.isNativeIOSApp()
  await _tryOperation(async () => {
    await waitWithTimeout(
      (done) => {
        if (!auth.getUser()?.ga4_client_id) {
          GTAG.getGa4ClientId(async (clientId: string) => {
            await backend.setGa4ClientId(clientId)
            auth.setGa4ClientId(clientId)
            done()
          })
        } else {
          done()
        }
      },
      1,
      1000,
    )
    if (isIOS) {
      const newAdjustData: { adid?: string; idfa?: string } = {}
      if (!auth.getUser()?.idfa) {
        newAdjustData.idfa = await Adjust.getIdfa()
      }
      if (!auth.getUser()?.adid) {
        newAdjustData.adid = await Adjust.getAdid()
      }
      if (newAdjustData.adid || newAdjustData.idfa) {
        await backend.setAdjustData(newAdjustData)
        auth.setAdjustData(newAdjustData)
      }
    }
  })
}

type Operation = () => Promise<void>

async function _tryOperation(operation: Operation): Promise<void> {
  try {
    await operation()
  } catch (error) {
    Sentry.captureException(error)
  }
}
