
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'
import QuestionnairePrevNext from '@/app/components/questionnaire/PrevNext.vue'

@Options({
  components: { QuestionnairePrevNext },
})
export default class QuestionnaireCategories extends Vue {
  @Prop() view!: QuestionnaireView

  async beforeMount(): Promise<void> {
    await this.view.getCategories()
  }
}
