
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ArticleCard from '@/app/components/article/Card.vue'
import ListingBlock from '@/app/components/listing/ListingBlock.vue'

import { ArticlesView } from '@/models/article'

@Options({
  components: {
    ArticleCard,
    ListingBlock,
  },
})
export default class Articles extends Vue {
  @Prop() private view!: ArticlesView
  @Prop() private title!: string
  @Prop() private viewAllLink!: string
}
