
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import DeleteAccountPopup from '@/app/components/billing/DeleteAccountPopup.vue'
@Options({
  components: { DeleteAccountPopup },
})
export default class InfoDeleteAccount extends Vue {
  showPopup(): void {
    ;(this.$refs.popupCancel as any).show()
  }
}
