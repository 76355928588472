
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'

import BookCover from '@/app/components/book/card/BookCover.vue'

@Options({
  components: { BookCover },
})
export default class BookHero extends Vue {
  @Prop() private doc!: DocView
  @Prop() private title!: string
  @Prop({ default: true }) private showHighlightsInfo!: boolean

  get highlightsCount(): string {
    const count = this.doc.highlights.length
    switch (count) {
      case 0:
        return 'No Highlights'
      case 1:
        return '1 Highlight'
    }
    return `${count} Highlights`
  }

  get notesCount(): string {
    const notes = this.doc.highlights.filter((h) => h.text)
    const count = notes.length
    switch (count) {
      case 0:
        return 'No Notes'
      case 1:
        return '1 Note'
    }
    return `${count} Notes`
  }
}
