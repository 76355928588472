import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = { class: "billing-details" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "plan-payment" }
const _hoisted_5 = {
  key: 0,
  class: "billing-cancel"
}
const _hoisted_6 = { class: "billing-cancel__text" }
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "billing-cancel"
}
const _hoisted_9 = { class: "billing-cancel__text" }
const _hoisted_10 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BillingNotifications = _resolveComponent("BillingNotifications")!
  const _component_PlanInfoMonthly = _resolveComponent("PlanInfoMonthly")!
  const _component_PlanInfoAnnual = _resolveComponent("PlanInfoAnnual")!
  const _component_InfoFAQ = _resolveComponent("InfoFAQ")!
  const _component_InfoQuestions = _resolveComponent("InfoQuestions")!
  const _component_InfoDeleteAccount = _resolveComponent("InfoDeleteAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BillingNotifications, { billing: _ctx.billing }, null, 8, ["billing"]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.billing.plan.id === 'plan_monthly')
            ? (_openBlock(), _createBlock(_component_PlanInfoMonthly, {
                key: 0,
                billing: _ctx.billing
              }, null, 8, ["billing"]))
            : _createCommentVNode("", true),
          (_ctx.billing.plan.id === 'plan_annual')
            ? (_openBlock(), _createBlock(_component_PlanInfoAnnual, {
                key: 1,
                billing: _ctx.billing
              }, null, 8, ["billing"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.billing.cancel_at_period_end)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, [
                _createTextVNode(" Want to reactivate your Shortform subscription? "),
                _createElementVNode("a", {
                  class: "billing-cancel__link",
                  href: _ctx.billing.paymentProviderSettingsUrl,
                  target: "_blank"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.paymentProviderSettingsText), 1)
                ], 8, _hoisted_7),
                _createTextVNode(". ")
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, [
                _createTextVNode(" Want to stop your Shortform subscription? "),
                _createElementVNode("a", {
                  class: "billing-cancel__link",
                  href: _ctx.billing.paymentProviderSettingsUrl,
                  target: "_blank"
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.paymentProviderSettingsText), 1)
                ], 8, _hoisted_10),
                _createTextVNode(". ")
              ])
            ]))
      ])
    ]),
    _createVNode(_component_InfoFAQ, { id: "faq" }),
    _createVNode(_component_InfoQuestions),
    _createVNode(_component_InfoDeleteAccount)
  ]))
}