
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { RouteLocationNormalized } from 'vue-router'
import { ComponentPublicInstance } from 'vue'

import { backend } from '@/services/backend'

import { wait } from '@/helpers/vue-wait'
import { Sentry } from '@/services/sentry'
import { assertNotNull } from '@/helpers/typing'

import { PodcastEpisodeView } from '@/models/podcast.episode'
import { backLink } from '@/models/backlink'

import DocSummaryPage from '@/app/components/doc/DocSummaryPage.vue'

@Options({
  components: {
    DocSummaryPage,
  },
})
export default class PodcastEpisodeSummaryPage extends Vue {
  episode: PodcastEpisodeView | null = null

  async beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    next((vm: ComponentPublicInstance) => {
      ;(vm as any).backLinkRoute = from
    })
  }

  async beforeRouteUpdate(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    const episode = assertNotNull(this.episode)

    episode.initPage(to, from, this.loadDocument)
    backLink.value.updateMetaTitle(from, episode.title)

    next()
  }

  async beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    // Called when the route that renders this component is about to
    // be navigated away from.
    // Has access to `this` component instance.
    if (this.episode) {
      await this.episode.updateReadTimeAndSavePosition()
      backLink.value.updateMetaTitle(from, this.episode.title)
    }
    next()
  }

  async beforeMount(): Promise<void> {
    await this.loadDocument(this.$route)
  }

  async _fetchDocument(
    route: RouteLocationNormalized,
  ): Promise<PodcastEpisodeView> {
    const url_slug = route.params.url_slug as string

    // TODO: implement offline mode for podcast episodes.
    // if (offline.isOffline) {
    //   await loadBookDetailFromNativeStorage(url_slug)
    // }

    const response = await backend.getPodcastEpisode(url_slug)
    const doc = new PodcastEpisodeView(this, response)

    // Use author from podcast instead of episode, see #11475
    if (response.doc_group && response.doc_group.author) {
      doc.author = response.doc_group.author
    }

    doc.getPageFromRoute(route)

    this.episode = doc
    return this.episode
  }

  async loadDocument(route: RouteLocationNormalized): Promise<void> {
    try {
      wait.start(this, 'Loading Doc')
      wait.end(this, 'Error Loading Doc')

      this.episode = await this._fetchDocument(route)
      this.episode.initDocument(true)

      if (!this.episode.isPreviewPage) {
        await this.episode.scrollToTop()
      }
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        wait.start(this, 'Error Loading Doc')
        Sentry.captureException(error)
      }
    } finally {
      wait.end(this, 'Loading Doc')
    }
  }

  get isLoadingDoc(): boolean {
    return wait.is(this, 'Loading Doc')
  }

  get isErrorLoadingDoc(): boolean {
    return wait.is(this, 'Error Loading Doc')
  }
}
