
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { ExcerptView } from '@/models/excerpt'

import BookCover from '@/app/components/book/card/BookCover.vue'
import BookCardBackground from '@/app/components/book/card/BookCardBackground.vue'

@Options({
  components: { BookCover, BookCardBackground },
})
export default class ExcerptBlock extends Vue {
  @Prop() private view!: ExcerptView
}
