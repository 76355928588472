
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ArticleCard from '@/app/components/article/Card.vue'
import CTACard from '@/app/components/article/CTACard.vue'
import DocsSort from '@/app/components/listing/DocsSort.vue'
import DocsSortMobile from '@/app/components/listing/DocsSort.mobile.vue'
import ListingPageLayoutSimple from '@/app/components/listing/ListingPageLayoutSimple.vue'

import { ArticlesView } from '@/models/article'

@Options({
  components: {
    ArticleCard,
    CTACard,
    DocsSort,
    DocsSortMobile,
    ListingPageLayoutSimple,
  },
})
export default class InfiniteScrollArticlesBlock extends Vue {
  @Prop() view!: ArticlesView
}
