
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import {
  SocialAuthComponentView,
  createSocialAuthView,
} from '@/models/auth/social'

import FormError from '@/components/ui/FormError.vue'
import GoogleSignInButton from './GoogleSignInButton.vue'
import FacebookLoginButton from './FacebookLoginButton.vue'
import AppleSignInButton from './AppleSignInButton.vue'
import { AuthMode } from '@/models/auth/google'

@Options({
  components: {
    GoogleSignInButton,
    FacebookLoginButton,
    AppleSignInButton,
    FormError,
  },
})
export default class SocialAuth extends Vue {
  @Prop() private onStart!: any
  @Prop() private onSuccess!: any
  @Prop() private onError!: any

  @Prop() private authMode!: AuthMode
  @Prop() private renderOnly: 'small' | 'big' | undefined = undefined

  private title: string = ''

  private socialView: SocialAuthComponentView | null = null

  async beforeMount(): Promise<void> {
    this.title =
      this.authMode === AuthMode.Signin ? 'Sign in with' : 'Sign up with'

    this.socialView = await createSocialAuthView(
      this.onStart,
      this.onSuccess,
      this.onError,
    )
    if (this.socialView) {
      this.socialView.init()
    }
  }
}
