
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'
import HeaderRoot from '@/app/components/header/Root.vue'
import ExportSettings from '@/app/components/export/ExportSettings.vue'
import { HeaderView } from '@/models/header'
@Options({
  components: {
    Layout,
    HeaderRoot,
    ExportSettings,
  },
})
export default class Export extends Vue {
  public view: HeaderView | null = null
  async beforeMount(): Promise<void> {
    this.view = new HeaderView(this)
    await this.view.init()
  }
}
