
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconButton',
  props: {
    /**
     * Place the button in a disabled state
     */
    disabled: Boolean,
    /**
     * The iconfont icon to use
     */
    icon: {
      type: String,
      default: undefined,
    },
    /**
     * Use the outlined design variant
     */
    outlined: Boolean,
    /**
     * Use the yellow design variant
     */
    yellow: Boolean,
    /**
     * Anchor tag attribute that transforms components into `<a href="" />`
     */
    href: {
      type: String,
      default: undefined,
    },
    /**
     * Element tag to use for the button. Defaults to `button`
     */
    tag: {
      type: String,
      default: 'button',
    },
    /**
     * Button description displayed on top of the button on `mouseover` event.
     */
    tooltip: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      tooltipActive: false,
    }
  },
  computed: {
    classes(): Record<string, boolean> {
      return {
        'icon-btn': true,
        'icon-btn--disabled': this.disabled,
        'icon-btn--outlined': this.outlined,
        'icon-btn--yellow': this.yellow,
      }
    },
    /**
     * Use `scale-transition` when showing the tooltip.
     * Use `fade-transition` when hiding the tooltip.
     */
    computedTransition(): string {
      return this.tooltipActive ? 'scale-transition' : 'fade-transition'
    },
    elementTag(): string {
      const tag = (this.href && 'a') || this.tag || 'div'
      return tag
    },
  },
  methods: {
    /**
     * Hide or show the tooltip when there is a tooltip present.
     */
    toggleTooltip(show: boolean) {
      if (typeof this.tooltip !== 'undefined') {
        this.tooltipActive = show
      }
    },
  },
})
