
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { QuestionnaireView } from '@/models/questionnaire'
import { QuestionnaireReadingWhenItem } from '@/models/interfaces'
import QuestionnairePrevNext from './PrevNext.vue'

@Options({
  components: { QuestionnairePrevNext },
})
export default class QuestionnaireReadingWhen extends Vue {
  @Prop() view!: QuestionnaireView

  iconUrl(readingWhen: QuestionnaireReadingWhenItem): string {
    return require(`@/assets.app/images/${readingWhen.icon}`)
  }
}
