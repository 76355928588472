
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { BillingView } from '@/models/billing'

import CardEditPopup from './CardEditPopup.vue'

@Options({
  components: {
    CardEditPopup,
  },
})
export default class PaymentInfo extends Vue {
  @Prop() protected billing!: BillingView

  editCard(): void {
    ;(this.$refs.popupCardEdit as any).show()
  }
}
