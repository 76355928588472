/* eslint-disable @typescript-eslint/no-this-alias */
'use strict'

// @ts-ignore
import { Range } from '../../xpath-range/lib'
import { Promise } from 'es6-promise'

import { elementData } from './util'

interface Highlighter {
  element: any
  options: HighlighterOptions
}

type HighlighterOptions = Record<string, unknown> &
  Partial<typeof DefaultOptions>

export const DefaultOptions = {
  // The CSS class to apply to drawn highlights
  highlightClass: 'annotator-hl',
  // Number of annotations to draw at once
  chunkSize: 10,
  // Time (in ms) to pause between drawing chunks of annotations
  chunkDelay: 10,
}

// The highlightRange wraps the DOM Nodes within the provided range with a highlight
// element of the specified class and returns the highlight Elements.
//
// normedRange - A NormalizedRange to be highlighted.
// cssClass - A CSS class to use for the highlight (default: 'annotator-hl')
//
// Returns an array of highlight Elements.
function highlightRange(
  normedRange: any,
  cssClass: string,
  annotationText: string,
) {
  if (typeof cssClass === 'undefined' || cssClass === null) {
    cssClass = 'annotator-hl'
  }
  const white = /^\s*$/

  // Ignore text nodes that contain only whitespace characters. This prevents
  // spans being injected between elements that can only contain a restricted
  // subset of nodes such as table rows and lists. This does mean that there
  // may be the odd abandoned whitespace node in a paragraph that is skipped
  // but better than breaking table layouts.
  const nodes = normedRange.textNodes(),
    results = []
  for (let i = 0, len = nodes.length; i < len; i++) {
    const node = nodes[i]
    if (!white.test(node.nodeValue)) {
      // @ts-ignore
      const hl: Element = document.createElement('span')
      if (annotationText && i === 0) {
        // Add icon class only to the first node (we can have multiple nodes
        // highlighted with one highlight).
        hl.className = cssClass + ' annotator-note-icon'
      } else {
        hl.className = cssClass
      }
      node.parentNode.replaceChild(hl, node)
      hl.appendChild(node)
      results.push(hl)
    }
  }
  return results
}

// The reanchorRange will attempt to normalize a range, swallowing Range.RangeErrors
// for those ranges which are not reanchorable in the current document.
function reanchorRange(range: any, rootElement: any) {
  try {
    return Range.sniff(range).normalize(rootElement)
  } catch (e) {
    if (!(e instanceof Range.RangeError)) {
      // Oh Javascript, why you so crap? This will lose the traceback.
      throw e
    }
    // Otherwise, we simply swallow the error. Callers are responsible
    // for only trying to draw valid annotations.
  }
  return null
}

// Highlighter provides a simple way to draw highlighted <span> tags over
// annotated ranges within a document.
//
// element - The root Element on which to dereference annotation ranges and
//           draw highlights.
// options - An options Object containing configuration options for the plugin.
//           See `Highlighter.options` for available options.
//
export const Highlighter = function (
  this: Highlighter,
  element: any,
  options: HighlighterOptions,
) {
  const defaultOptionsCopy = JSON.parse(JSON.stringify(DefaultOptions))

  this.element = element
  this.options = { ...defaultOptionsCopy, ...options }
} as any as { new (element: any, options: HighlighterOptions): Highlighter }

Highlighter.prototype.destroy = function () {
  const elements = this.element.getElementsByClassName(
    this.options.highlightClass,
  )

  Array.from(elements as HTMLElement[]).forEach((element: HTMLElement) => {
    // Move all children to the element's parent
    if (element.parentNode !== null) {
      while (element.firstChild) {
        element.parentNode.insertBefore(element.firstChild, element)
      }
      element.remove()
    }
  })
}

// Public: Draw highlights for all the given annotations
//
// annotations - An Array of annotation Objects for which to draw highlights.
//
// Returns nothing.
Highlighter.prototype.drawAll = function (annotations: any) {
  const self = this

  const p = new Promise(function (resolve: any) {
    let highlights: any = []

    function loader(annList: any) {
      if (typeof annList === 'undefined' || annList === null) {
        annList = []
      }

      const now = annList.splice(0, self.options.chunkSize)
      for (let i = 0, len = now.length; i < len; i++) {
        highlights = highlights.concat(self.draw(now[i]))
      }

      // If there are more to do, do them after a delay
      if (annList.length > 0) {
        setTimeout(function () {
          loader(annList)
        }, self.options.chunkDelay)
      } else {
        resolve(highlights)
      }
    }

    const clone = annotations.slice()
    loader(clone)
  })

  return p
}

// Public: Draw highlights for the annotation.
//
// annotation - An annotation Object for which to draw highlights.
//
// Returns an Array of drawn highlight elements.
Highlighter.prototype.draw = function (annotation: any) {
  const normedRanges = []

  for (let i = 0, ilen = annotation.ranges.length; i < ilen; i++) {
    const r = reanchorRange(annotation.ranges[i], this.element)
    if (r !== null) {
      normedRanges.push(r)
    }
  }

  const hasLocal =
    typeof annotation._local !== 'undefined' && annotation._local !== null
  if (!hasLocal) {
    annotation._local = {}
  }
  const hasHighlights =
    typeof annotation._local.highlights !== 'undefined' &&
    annotation._local.highlights === null
  if (!hasHighlights) {
    annotation._local.highlights = []
  }

  for (let j = 0, jlen = normedRanges.length; j < jlen; j++) {
    const normed = normedRanges[j]
    annotation._local.highlights.push(
      ...highlightRange(normed, this.options.highlightClass, annotation.text),
    )
  }

  // Save the annotation data on each highlighter element.
  annotation._local.highlights.forEach((highlight: HTMLElement) => {
    elementData.set(highlight, 'annotation', annotation)
  })

  // Add a data attribute for annotation id if the annotation has one
  if (typeof annotation.id !== 'undefined' && annotation.id !== null) {
    annotation._local.highlights.forEach((highlight: any) => {
      highlight.setAttribute('data-annotation-id', annotation.id)
    })
  }

  return annotation._local.highlights
}

// Public: Remove the drawn highlights for the given annotation.
//
// annotation - An annotation Object for which to purge highlights.
//
// Returns nothing.
Highlighter.prototype.undraw = function (annotation: any) {
  const hasHighlights =
    typeof annotation._local !== 'undefined' &&
    annotation._local !== null &&
    typeof annotation._local.highlights !== 'undefined' &&
    annotation._local.highlights !== null

  if (!hasHighlights) {
    return
  }

  for (let i = 0, len = annotation._local.highlights.length; i < len; i++) {
    const h = annotation._local.highlights[i]
    if (h.parentNode !== null) {
      while (h.firstChild) {
        h.parentNode.insertBefore(h.firstChild, h)
      }
      h.remove()
    }
  }
  delete annotation._local.highlights
}

// Public: Redraw the highlights for the given annotation.
//
// annotation - An annotation Object for which to redraw highlights.
//
// Returns the list of newly-drawn highlights.
Highlighter.prototype.redraw = function (annotation: any) {
  this.undraw(annotation)
  return this.draw(annotation)
}

// The standalone is a module that uses the Highlighter to draw/undraw highlights
// automatically when annotations are created and removed.
export function standalone(
  element: any,
  options: HighlighterOptions,
): Record<string, unknown> {
  const widget: any = new Highlighter(element, options)

  return {
    destroy: function () {
      widget.destroy()
    },
    annotationsLoaded: function (anns: any) {
      widget.drawAll(anns)
    },
    annotationCreated: function (ann: any) {
      widget.draw(ann)
    },
    annotationDeleted: function (ann: any) {
      widget.undraw(ann)
    },
    annotationUpdated: function (ann: any) {
      widget.redraw(ann)
    },
  }
}
