import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "player-volume"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_ctx.volumeControl)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.volumeControl.value > 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "player-volume__icon iconfont iplayer-volume",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.volumeControl.mute()))
            }))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "player-volume__icon iconfont iplayer-mute",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.volumeControl.unmute()))
            })),
        _createVNode(_component_vue_slider, {
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
          value: _ctx.volumeControl.value,
          min: 0,
          max: 100,
          class: "player__audioplayer player__volume-slider",
          dragOnClick: true,
          interval: 1,
          tooltip: "none",
          onChange: _ctx.inputChange
        }, null, 8, ["modelValue", "value", "onChange"])
      ]))
    : _createCommentVNode("", true)
}