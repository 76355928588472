
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { Sentry } from '@/services/sentry'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import FormError from '@/components/ui/FormError.vue'
import { ValidationState } from '@/helpers/form'
import { wait } from '@/helpers/vue-wait'
import { assertNotNull } from '@/helpers/typing'

import { BookView } from '@/models/book'
import { backend } from '@/services/backend'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import KindleScreenshotPopup from './KindleScreenshotPopup.vue'
import KindleNotification from './KindleNotification.vue'
import CopyMessagePopup from './CopyMessagePopup.vue'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    KindleScreenshotPopup,
    KindleNotification,
    CopyMessagePopup,
    InputWithContextMenu,
  },
})
export default class KindleExportForm extends Vue {
  @Prop() private book!: BookView

  validation: ValidationState = new ValidationState()
  isExpanded: boolean = true
  showScreenshotPopup: boolean = false
  showNotification: boolean = false
  showCopyNotification: boolean = false

  shortFormEmail: string = 'export@shortform.com'

  kindleEmail: string | null = null

  async beforeMount(): Promise<void> {
    // If the user already has a kindle_email set, don't show the instructions
    // and fill in the Kindle email form field.
    if (this.book.doc_export_stats && this.book.doc_export_stats.kindle_email) {
      this.isExpanded = false
      this.kindleEmail = this.book.doc_export_stats.kindle_email
    }
    // For now, force the settings block to be expanded, so users
    // see the updated email in the instructions, see #12087.
    this.isExpanded = true
  }

  getErrorMessage(error: any): string {
    if (error.response === undefined) {
      Sentry.captureException(error)
      return 'Could not talk to backend. Network or authorisation error?'
    } else {
      // The response structure is that of marshmallow.exceptions.ValidationError:
      //   {errors: {field_name: [error_msg, ...], ...}}
      // field_name may be
      // - `_schema`: default, for a general schema-level error. But we don't
      //   expect any errors like this.
      // - an actual field. For this, the fields we may expect errors from are
      //   `user`, `url_slug`, and `kindle_email`.
      const knownFieldNames = ['_schema', 'user', 'url_slug', 'kindle_email']
      const knownErrorMessages = new Set([
        'Doc export is not available for free users',
        'Doc export is not available during trial',
        'Export limit reached',
        "We didn't find this summary in our database.",
        'The given email does not end with @kindle.com.',
        'No Send to Kindle email given.',
      ])

      // If we find an error message we recognize, don't send the error to
      // Sentry since the user can deal with it.
      const maybeErrors = error?.response?.data?.errors
      if (maybeErrors) {
        for (const fieldName of knownFieldNames) {
          if (fieldName in maybeErrors) {
            for (const errorMessage of maybeErrors[fieldName]) {
              if (knownErrorMessages.has(errorMessage)) {
                return errorMessage
              }
            }
          }
        }
      }

      // Otherwise, it is probably not useful to show the user the specific
      // error, and we should capture it in Sentry instead.
      Sentry.captureException(error)
      return 'Error sending to Kindle. Please contact support.'
    }
  }

  async sendToBackend(): Promise<void> {
    try {
      this.validation.reset()
      wait.start(this, 'Sending')
      const kindleEmail = assertNotNull(this.kindleEmail)
      await backend.sendToKindle(this.book.url_slug, kindleEmail)
      this.toggleNotification()
    } catch (error) {
      this.validation.showMessage('kindle_email', this.getErrorMessage(error))
    } finally {
      wait.end(this, 'Sending')
    }
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded
  }

  toggleScreenshot(): void {
    this.showScreenshotPopup = !this.showScreenshotPopup
  }

  toggleNotification(): void {
    this.showNotification = !this.showNotification

    if (this.showNotification) {
      setTimeout(() => {
        this.showNotification = false
      }, 5000)
    }
  }

  get supportsCopy(): boolean {
    return navigator.clipboard !== undefined
  }

  copy(): void {
    navigator.clipboard.writeText(this.shortFormEmail)
    this.showCopyNotification = !this.showCopyNotification

    setTimeout(() => {
      this.showCopyNotification = false
    }, 2000)
  }
}
