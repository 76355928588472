
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import BasePopup from '@/components/ui/BasePopup.vue'
import { AccountStatus } from '@/models/interfaces'
import FormError from '@/components/ui/FormError.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

@Options({
  components: {
    LoadingSpinner,
    BasePopup,
    'v-wait': vLoading,
    ButtonSpinner,
    FormError,
  },
})
export default class AccountStatusInfo extends Vue {
  @Prop() accountStatus!: AccountStatus
}
