import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-btn__content" }
const _hoisted_2 = {
  key: 0,
  class: "app-btn__spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CircleSpinner = _resolveComponent("CircleSpinner")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.elementTag), {
    class: _normalizeClass(_ctx.classes),
    disabled: _ctx.elementTag === 'button' && _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["iconfont", _ctx.icon])
        }, null, 2),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_CircleSpinner, {
              class: "app-btn__spinner-icon",
              "animation-duration": 2000,
              size: 16
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["class", "disabled"]))
}