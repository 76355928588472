import { ValidationError } from '@/models/error'

export function monthlyPlan(platform: 'ios' | 'android'): string {
  if (platform === 'ios') {
    return 'shortform_monthly'
  }
  return 'plan_monthly'
}

export function annualPlan(platform: 'ios' | 'android'): string {
  if (platform === 'ios') {
    return 'shortform_annual'
  }
  return 'plan_annual'
}

export class UserCancelledError {}

export class GoogleBillingUnavailableError {}

export class PlanChangeFailedError extends Error {
  constructor(error?: string) {
    error =
      error ||
      'We could not change your subscription plan. Please restart the app and try again, or contact support.'
    super(error)
  }
}

// The set of store events (initialization, purchases) that we emit/listen to,
// which enables using a async/await interface.
// See the `appStoreEmitter` docstring for more details.
// This set of events uses the cordova-plugin-purchase v11 types.
export type PurchaseEvents11 = {
  storeInit: undefined
  storeInitError: IapStore.IError
  productOrderOwned: IapStore.IStoreProduct
  productOrderCancelled: IapStore.IStoreProduct
  productOrderError: [IapStore.IError, IapStore.IStoreProduct | undefined]
  verificationDenied: ValidationError
  subscriptionUpdatedOwned: IapStore.IStoreProduct
  purchasesUpdated: undefined
}

// The set of store events (initialization, purchases) that we emit/listen to,
// which enables using a async/await interface.
// See the `appStoreEmitter` docstring for more details.
// This set of events uses the cordova-plugin-purchase v13 types.
export type PurchaseEvents13 = {
  storeInit: undefined
  storeInitError: CdvPurchase.IError[]
  productOrderOwned: CdvPurchase.Product
  productOrderCancelled: CdvPurchase.Product
  productOrderError: [CdvPurchase.IError, CdvPurchase.Product | undefined]
  verificationDenied: ValidationError
  subscriptionUpdatedOwned: undefined
  purchasesUpdated: undefined
}

/**
 * An interface for the global `appStore` object for cordova-plugin-purchase v11.
 *
 * Wraps the native plugin logic and provides async/await interface
 * for the calling code.
 */
export interface AppStore11 {
  // The `store` object provided by cordova-plugin-purchase.
  store: IapStore.IStore

  // Enable or disable debug mode.
  debug: boolean

  // Text status of the App Store data (for debugging).
  status: string
  // Error text related to App Store (for debugging).
  error: string

  // Monthly subscription data fetched from AppStore.
  monthly?: IapStore.IStoreProduct
  // Annual subscription data fetched from AppStore.
  annual?: IapStore.IStoreProduct

  _initialized: boolean
  _deviceReady: boolean
  _platform?: 'ios' | 'android'

  // Initialize the `appStore` object.
  init(platform: 'ios' | 'android'): Promise<void>

  // Inform the AppStore object that native device is ready.
  deviceReady(): void

  // Fetch subscriptions data from AppStore.
  updateProducts(): void

  /**
   * Show the monthly subscription dialog.
   *
   * The process should end either with `productOwned` or `productError`
   * event emitted through `appStoreEmitter`.
   *
   * @param isPlanChange Is this a change from the annual plan to the monthly plan.
   *  Should be set to true only when the plan is changed during an active subscription,
   *  not when resubscribing to another plan after the subscription had already expired.
   */
  subscribeMonthly(isPlanChange: boolean): Promise<IapStore.IStoreProduct>

  /**
   * Show the annual subscription dialog.
   *
   * The process should end either with `productOwned` or `productError`
   * event emitted through `appStoreEmitter`.
   *
   * @param isPlanChange Is this a change from the monthly plan to the annual plan.
   *  Should be set to true only when the plan is changed during an active subscription,
   *  not when resubscribing to another plan after the subscription had already expired.
   */
  subscribeAnnual(isPlanChange: boolean): Promise<IapStore.IStoreProduct>

  // Subscription updated handler.
  _onSubscriptionUpdated(product: IapStore.IStoreProduct): void

  // Log or skip debug messages depeding on the `debug` flag.
  _debugLog(message: string, data?: any): void
}

/**
 * An interface for the global `appStore` object for cordova-plugin-purchase v13.
 *
 * Wraps the native plugin logic and provides async/await interface
 * for the calling code.
 */
export interface AppStore13 {
  // The `store` object provided by cordova-plugin-purchase.
  store: CdvPurchase.Store | undefined

  // Enable or disable debug mode.
  debug: boolean

  // Text status of the App Store data (for debugging).
  status: string
  // Error text related to App Store (for debugging).
  error: string

  // Monthly subscription data fetched from AppStore.
  monthly?: CdvPurchase.Product
  // Annual subscription data fetched from AppStore.
  annual?: CdvPurchase.Product

  _initialized: boolean
  _deviceReady: boolean
  _platform?: 'ios' | 'android'

  // Initialize the `appStore` object.
  init(platform: 'ios' | 'android'): Promise<void>

  // Inform the AppStore object that native device is ready.
  deviceReady(): void

  // Fetch subscriptions data from AppStore.
  updateProducts(): void

  /**
   * Show the monthly subscription dialog.
   *
   * The process should end either with `productOwned` or `productError`
   * event emitted through `appStoreEmitter`.
   *
   * @param isPlanChange Is this a change from the annual plan to the monthly plan.
   *  Should be set to true only when the plan is changed during an active subscription,
   *  not when resubscribing to another plan after the subscription had already expired.
   */
  subscribeMonthly(isPlanChange: boolean): Promise<CdvPurchase.Product>

  /**
   * Show the annual subscription dialog.
   *
   * The process should end either with `productOwned` or `productError`
   * event emitted through `appStoreEmitter`.
   *
   * @param isPlanChange Is this a change from the monthly plan to the annual plan.
   *  Should be set to true only when the plan is changed during an active subscription,
   *  not when resubscribing to another plan after the subscription had already expired.
   */
  subscribeAnnual(isPlanChange: boolean): Promise<CdvPurchase.Product>

  // Receipt updated handler.
  _onReceiptUpdated(transaction: CdvPurchase.Transaction): void

  // Log or skip debug messages depeding on the `debug` flag.
  _debugLog(message: string, data?: any): void
}

// Wrapper types to handle types from both v11 and v13 of cordova-plugin-purchase.
export type AppStore = AppStore11 | AppStore13
export type AppStorePluginProduct = IapStore.IStoreProduct | CdvPurchase.Product
export type AppStorePluginStore = IapStore.IStore | CdvPurchase.Store
