import axios from 'axios'

import { AxiosInstance, AxiosResponse } from 'axios'
import { ReadwiseItem } from '@/models/interfaces'
import { configSentryForAxiosError } from '@/helpers/axios'
import { Sentry } from '@/services/sentry'

import { TIMEOUT } from './constants'
import { MAX_HIGHLIGHT_TEXT_LENGTH } from '@/init/settings'

/**
 * Low-level API wrapper for the Readwise (readwise.io).
 */
export class ReadwiseAPI {
  public _axios: AxiosInstance

  constructor() {
    this._axios = axios.create({
      baseURL: 'https://readwise.io/api/v2/',
      timeout: TIMEOUT,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async _post<T = any, R = AxiosResponse<T>>(
    token: string,
    url: string,
    data: any,
  ): Promise<R> {
    try {
      return await this._axios.post<T, R>(url, data, {
        headers: { Authorization: `Token ${token}` },
      })
    } catch (error: any) {
      configSentryForAxiosError(error, (): boolean => {
        return false
      })
      // The 'Readwise Error' event in the "ADDITIONAL DATA" section will be contain
      // AxiosError exception details, collected using the configSentryForAxiosError() function.
      Sentry.captureMessage('Readwise Error')
      throw error
    }
  }

  async send(token: string, highlights: ReadwiseItem[]): Promise<void> {
    highlights.forEach((item: ReadwiseItem) => {
      item.text = item.text.substring(0, MAX_HIGHLIGHT_TEXT_LENGTH)
      if (item.note) {
        item.note = item.note.substring(0, MAX_HIGHLIGHT_TEXT_LENGTH)
      }
    })

    await this._post(token, `highlights/`, { highlights })
  }
}
