import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppMenu = _resolveComponent("AppMenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["navbar-mobile", { 'navbar-mobile--ios': _ctx.view?.isIOS }])
  }, [
    _createVNode(_component_AppMenu, { view: _ctx.view }, null, 8, ["view"])
  ], 2))
}