
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ListingPageLayout from '@/app/components/listing/ListingPageLayout.vue'
import ListingPageDescription from './ListingPageDescription.vue'
import DocsSort from '@/app/components/listing/DocsSort.vue'
import DocsSortButtonMobile from '@/app/components/listing/DocsSortButton.mobile.vue'
import DocsSortMobile from '@/app/components/listing/DocsSort.mobile.vue'
import PodcastFollowButton from '@/app/components/listing/PodcastFollowButton.vue'

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import LoadingError from '@/components/ui/LoadingError.vue'

import { ListingPage } from '@/models/docs.listing'
import { backend } from '@/services/backend'
import EpisodeCard from '../podcast/card/EpisodeCard.vue'
import { assertNotNull } from '@/helpers/typing'
import { PodcastView } from '@/models/podcast'

@Options({
  components: {
    EpisodeCard,
    DocsSort,
    DocsSortMobile,
    DocsSortButtonMobile,
    LoadingSpinner,
    LoadingError,
    ListingPageLayout,
    ListingPageDescription,
    PodcastFollowButton,
  },
})
export default class BooksBaseListingPage extends Vue {
  @Prop({}) listingPage!: ListingPage
  @Prop({}) podcastUrlSlug?: string | undefined

  view: PodcastView | null = null

  async loadPodcasts(): Promise<PodcastView> {
    // TODO: Implement offline support for podcasts
    //  if (offline.isOffline) {
    //  await loadBookListFromNativeStorage()
    // }
    const response = await backend.getPodcast(
      assertNotNull(this.podcastUrlSlug),
    )

    this.view = new PodcastView(response)
    this.listingPage.initFromPodcast(this.view.podcast)

    return this.view
  }
}
