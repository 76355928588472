import { ref } from 'vue'
import { RouteLocationNormalized } from 'vue-router'

import { platform } from '@/services/platform'
import { router, pageTitle } from '@/helpers/router'

/**
 * Handle the app's navigation history.
 */
export class BackLink {
  public history: RouteLocationNormalized[] = []

  /**
   * Clear the navigation history and push the given route into it.
   */
  public resetHistory(newRootPage: RouteLocationNormalized): void {
    this.history = [newRootPage]
  }

  /**
   * Get the first page in the navigation history.
   */
  public get rootPage(): RouteLocationNormalized {
    return this.history[0]
  }

  /**
   * Get the most recent page in the navigation history.
   */
  public get previousPage(): RouteLocationNormalized {
    // @ts-ignore
    return this.history.at(-1)
  }

  /**
   * Get the title of the previous page in the navigation history.
   */
  public get previousPageTitle(): string {
    return pageTitle(this.previousPage)
  }

  /**
   * Get the label for the previous page in the navigation history.
   *
   * The label is used as a text for the back link.
   */
  public get previousPageLabel(): string {
    let prefix = ''
    if (platform.isDesktopWidth()) {
      prefix = 'Back to '
    }

    const label = this.previousPageTitle
    if (label) {
      return prefix + label
    }

    return ''
  }

  /**
   * Remove the last item from the navigation history.
   */
  private _popHistory(): void {
    this.history.pop()
  }

  /**
   * Returns a root page route from the given path.
   *
   * If no root page is found, return the Discover page.
   */
  private _rootPageFromPath(
    currentRoute: RouteLocationNormalized,
  ): RouteLocationNormalized {
    if (currentRoute.meta.isPublicPage || currentRoute.meta.dontSaveToHistory) {
      return router().resolve('/app')
    }

    // All paths start with '/' so we use slice() to remove
    // the empty string from the array.
    const pathSegments = currentRoute.path.split('/').slice(1)

    // The root page is the first level of the path, after /app/.
    const rootPath = `/app/${pathSegments[1]}`
    const rootPageRoute = router().resolve(rootPath)

    // Search listing pages have a slight different structure, where
    // the root page ('/search/') is in the third path segment, while
    // other routes have the root page in the second segment.
    if (currentRoute.path.includes('/search/')) {
      return router().resolve({
        name: 'search',
        query: { term: currentRoute.params.term },
      })
    }

    // This should be the most common case.
    if (rootPageRoute && rootPageRoute.name) {
      return rootPageRoute
    }

    // In case the route doesn't exist, try appending an 's' to it.
    // Doc pages have the root path set to a singular noun (e.g. `/app/book),
    // but that path doesn't lead to an existing page.
    // Appending an 's' to it should lead to a list page for that doc type.
    const alternativeRootPageRoute = router().resolve(rootPath + 's')

    if (alternativeRootPageRoute && alternativeRootPageRoute.name) {
      return alternativeRootPageRoute
    }

    throw new Error(`Unexpected route path: ${currentRoute.path}`)
  }

  /**
   * Returns true if the next page is the last page in the navigation history.
   */
  public isGoingBack(nextPagePath: string): boolean {
    return this.previousPage.fullPath === nextPagePath
  }

  /**
   * Update the navigation history when navigating to a different page.
   */
  public updateHistory(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): void {
    // History debugging
    // console.log(
    //   backLink.value.history.reduce(
    //     (acc: string, next: RouteLocationNormalized) => {
    //       return `${acc} -> ${next.name as string}`
    //     },
    //     '',
    //   ),
    // )
    const previousPageName = (from.name as string) || undefined

    // If we are not coming from an existing page (that is, if this is
    // the first page being visited in this session), and the next page
    // is not a root page, insert the home page as a default value.
    if (!previousPageName && !to.meta.isRootPage) {
      const rootPage = this._rootPageFromPath(to)
      this.history.push(rootPage)
      return
    }

    // If the next page is a root page, reset the history.
    if (to.meta.isRootPage) {
      this.resetHistory(to)
      return
    }

    // If the next page is the last page in the history, remove
    // it from the history.
    if (this.isGoingBack(to.fullPath)) {
      this._popHistory()
      return
    }

    // Skip navigation within the same page
    // (for example when going from "/books" to "/books?sort=title")
    if (to.path === from.path) {
      return
    }

    // Skip consecutive pages from the same book
    if (
      String(from.name).includes('.page') &&
      String(to.name).includes('.page') &&
      from.params.url_slug === to.params.url_slug
    ) {
      return
    }

    // If the previous page isn't blacklisted and isn't the last page in
    // the navigation history, push it to the navigation history.
    if (
      !from.meta.dontSaveToHistory &&
      !from.meta.isPublicPage &&
      this.previousPage.fullPath !== from.fullPath
    ) {
      this.history.push(from)
    }
  }

  public goBack(): void {
    if (this.previousPage) {
      router().push(this.previousPage)
    }
  }

  /**
   * Update the route `meta.title` depending on the given path.
   *
   * We use this title to show the previous page
   * name in backlink, see `backlink.previousPageTitle()`.
   */
  public updateMetaTitle(
    from: RouteLocationNormalized,
    bookTitle: string,
  ): void {
    let metaTitle = bookTitle

    if (from.path.endsWith('/preview')) {
      metaTitle = `Preview | ${bookTitle}`
    } else if (from.path.endsWith('/community')) {
      metaTitle = `Discussion | ${bookTitle}`
    } else if (from.path.endsWith('/highlights')) {
      metaTitle = `Highlights | ${bookTitle}`
    }

    from.meta.title = metaTitle
  }
}

export const backLink = ref(new BackLink())
