
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

@Options({})
export default class ListingBlockHeader extends Vue {
  @Prop() to?: any
  @Prop() title!: string
  @Prop() viewAllText!: string
}
