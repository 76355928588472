/* package annotator.identity */

'use strict'

/**
 * function:: simple()
 *
 * A module that configures and registers an instance of
 * :class:`annotator.identity.SimpleIdentityPolicy`.
 */
export function simple(): Record<string, unknown> {
  // @ts-ignore
  const identity = new SimpleIdentityPolicy()

  return {
    configure: function (registry: any) {
      registry.registerUtility(identity, 'identityPolicy')
    },
  }
}

/**
 * class:: SimpleIdentityPolicy
 *
 * A simple identity policy that considers the identity to be an opaque
 * identifier.
 */
export function SimpleIdentityPolicy(this: any): void {
  /**
   * Arguments:
   *
   * data:: SimpleIdentityPolicy.identity
   *
   * Default identity. Defaults to `null`, which disables identity-related
   * functionality.
   *
   * This is not part of the identity policy public interface, but provides a
   * simple way for you to set a fixed current user::
   *
   *     app.ident.identity = 'bob';
   */
  this.identity = null
}

/**
 * function:: SimpleIdentityPolicy.prototype.who()
 *
 * Returns the current user identity.
 */
SimpleIdentityPolicy.prototype.who = function () {
  return this.identity
}
