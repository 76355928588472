<template>
  <div class="books__item books__item--large">
    <h2 class="books__title--large">
      Subscribe to get full access to the world's best book summaries!
    </h2>
    <router-link
      :to="{ name: 'billing' }"
      class="books__btn--large btns btns--largest"
    >
      Start Your Free Trial
    </router-link>
  </div>
</template>
