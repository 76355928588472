/* eslint-disable no-console */

import { register } from 'register-service-worker'

export function registerServiceWorker(): void {
  if (process.env.NODE_ENV === 'production') {
    let reloading = false
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB',
        )
      },
      registered() {
        console.log('Service worker has been registered.')
      },
      cached() {
        console.log('Content has been cached for offline use.')
      },
      updatefound() {
        console.log('New content is downloading.')
      },
      updated() {
        console.log('New content is available; please refresh.')
        // Sometimes page starts reloading infinitely, the variable
        // itself should be re-initialized after page reload, so
        // it will work again on next update.
        if (reloading) {
          return
        }
        reloading = true
        setTimeout(function refreshApp() {
          window.location.reload()
        }, 1500)
      },
      offline() {
        console.log(
          'No internet connection found. App is running in offline mode.',
        )
      },
      error(error) {
        console.error('Error during service worker registration:', error)
      },
    })
  }
}
