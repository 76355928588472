import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.favoriteToggle($event)), ["stop","prevent"]))
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.flagClass)
    }, null, 2)
  ]))
}