
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import BasePopup from '@/components/ui/BasePopup.vue'

import { wait } from '@/helpers/vue-wait'

@Options({
  components: {
    BasePopup,
    'v-wait': vLoading,
    ButtonSpinner,
  },
})
export default class DisablePopup extends Vue {
  @Prop() private view!: any
  @Prop() private integrationName!: string
  @Prop() private afterDisableCallback: any

  show(): void {
    ;(this.$refs.popup as any).show()
  }

  hide(): void {
    const popup = this.$refs.popup as any
    if (popup) {
      // Popup can already disappear due to the component rerendering.
      popup.hide()
    }
  }

  async disable(): Promise<void> {
    wait.start(this, 'Disabling')
    await this.view.disableIntegration()
    if (this.afterDisableCallback) {
      await this.afterDisableCallback()
    }
    wait.end(this, 'Disabling')
    this.$emit('update-enabled', false)
    ;(this.$refs.popup as any).hide()
  }

  get title(): string {
    return 'Disable the integration?'
  }
}
