
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { LoginView } from '@/models/auth/models'
import { platform } from '@/services/platform'
import { authCssFix } from '@/helpers/html'
import { assertNotNull } from '@/helpers/typing'
import { wait } from '@/helpers/vue-wait'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'

import SocialAuth from '@/app/components/auth/SocialAuth.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { AuthMode } from '@/models/auth/google'
import { shortOnboarding14516ExperimentVariant } from '@/services/ab'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    SocialAuth,
    InputWithContextMenu,
  },
})
export default class Login extends Vue {
  view: LoginView | null = null

  authMode: AuthMode = AuthMode.Signin

  isNativeIOSApp: boolean = false

  onStart(): void {
    wait.start(this, 'Login')
  }

  onSuccess(nextPage: Record<string, unknown> | null): void {
    wait.start(this, 'Login')

    const redirectQuery = this.$router.currentRoute.value.query?.redirect

    if (redirectQuery) {
      this.$router.push(redirectQuery as string)
    } else if (nextPage) {
      this.$router.push(nextPage)
    }
    wait.end(this, 'Login')
  }

  onError(): void {
    wait.end(this, 'Login')
  }

  async beforeMount(): Promise<void> {
    this.view = new LoginView(this.onStart, this.onSuccess, this.onError)
  }

  get _view(): LoginView {
    return assertNotNull(this.view)
  }

  async mounted(): Promise<void> {
    // If user name was passed a prop (from the signup with email form)
    if (this.$route.query.email) {
      this._view.model.email = this.$route.query.email as string
    }

    this.isNativeIOSApp = await platform.isNativeIOSApp()
    if (this.isNativeIOSApp) {
      this._iosPasswordInputFix()
    }

    authCssFix()
  }

  async submitForm(): Promise<void> {
    await this._view.start()
  }

  get signupRoute(): Record<string, unknown> {
    const isLongOnboardingFlow = shortOnboarding14516ExperimentVariant() === 'C'
    const route = {
      name: isLongOnboardingFlow ? 'long_onboarding' : 'signup',
      query: this.$route.query,
    }
    // If we have the email set, pass it on to the signup page.
    if (this._view.model.email) {
      route.query.email = this._view.model.email
    }
    return route
  }

  /**
   * In the iOS app when autofilling the login credentials using Keychain, 1Password
   * etc., the password field gets filled only visually but the value in our model
   * does not get updated.
   *
   * This is related to a bug in WebKit in that the 'input' event
   * does not get fired for the element when filled by a password manager. So we use
   * the below workaround to explicitly listen to the 'change' event and then update
   * the value of our model.
   *
   * For more information see:
   * - https://github.com/ionic-team/ionic-framework/issues/23335#issuecomment-845270643
   * - https://forum.ionicframework.com/t/vuejs-password-autofill-value-empty/217195/4
   */
  private _iosPasswordInputFix(): void {
    ;(
      (this.$refs.passwordInput as InputWithContextMenu).$el as HTMLInputElement
    ).addEventListener('change', (ev: Event) => {
      requestAnimationFrame(() => {
        this._view.model.password = (ev.target as HTMLInputElement).value
      })
    })
  }
}
