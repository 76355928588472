
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import vLoading from 'vue-wait/src/components/v-wait.vue'

import { SignupView } from '@/models/auth/models'
import { authCssFix } from '@/helpers/html'
import { assertNotNull } from '@/helpers/typing'
import { wait } from '@/helpers/vue-wait'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'

import SocialAuth from '@/app/components/auth/SocialAuth.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { AuthMode } from '@/models/auth/google'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    SocialAuth,
    InputWithContextMenu,
  },
})
export default class Signup extends Vue {
  view: SignupView | null = null

  authMode: AuthMode = AuthMode.Signup

  onStart(): void {
    wait.start(this, 'Register')
  }

  async onSuccess(nextPage: any): Promise<void> {
    wait.start(this, 'Register')

    // Timeout before the redirect to let Mixpanel calls complete.
    // It is less problematic in the main app, where Mixpanel would track
    // missing events on the next page, but in the extension, the next page
    // is the extension auth page, which closes immediately and Mixpanel
    // calls are lost, so we set a longer timeout in this case.
    // See #9509.
    let timeout = 100
    // Redirect to extension auth page if we need to
    if (this.$router.currentRoute.value.query?.redirect === '/extension-auth') {
      nextPage = { name: 'extension_auth' }
      timeout = 3000
    }

    if (nextPage) {
      setTimeout(() => {
        this.$router.push(nextPage)
        wait.end(this, 'Register')
      }, timeout)
    }
  }

  onError(): void {
    wait.end(this, 'Register')
  }

  async beforeMount(): Promise<void> {
    this.view = new SignupView(this.onStart, this.onSuccess, this.onError)
  }

  get _view(): SignupView {
    return assertNotNull(this.view)
  }

  async mounted(): Promise<void> {
    // If user name was passed a prop (from the signup with email form)
    if (this.$route.query.email) {
      this._view.model.email = this.$route.query.email as string
    }

    authCssFix()
  }

  async submitForm(): Promise<void> {
    await this._view.start()
  }

  get loginRoute(): Record<string, unknown> {
    const route = { name: 'login', query: this.$route.query }
    // If we have the email set, pass it on to the signup page.
    if (this._view.model.email) {
      route.query.email = this._view.model.email
    }
    return route
  }
}
