
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'
import { auth } from '@/services/auth'

import BackLink from '@/app/components/BackLink.vue'
import StartFreeTrialButton from '@/app/components/header/StartFreeTrialButton.vue'

@Options({
  components: { BackLink, StartFreeTrialButton },
})
export default class InternalPageMobileHeader extends Vue {
  @Prop() public title!: string
  @Prop() public backLinkDoc?: DocView

  get isFreePlan(): boolean {
    return auth.isFreePlan()
  }
}
