import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createBlock(_component_IconButton, {
    tag: "router-link",
    icon: "idownload",
    tooltip: "Download PDF",
    outlined: "",
    to: {
      name: _ctx.doc.routeNames().export,
      params: { url_slug: _ctx.doc.url_slug },
    }
  }, null, 8, ["to"]))
}