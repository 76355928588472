<template>
  <section class="short-onboarding__section">
    <slot />
  </section>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/variables.scss';

.short-onboarding__section {
  padding: 96px 0;
  justify-content: center;

  @media (max-width: $w-tablet-s) {
    align-items: center;
    text-align: center;
    padding: 44px 0;
  }

  @media (max-width: $w-medium-s) {
    padding: 28px 0;
  }
}

:deep(.short-onboarding__section-columns) {
  display: flex;
  gap: 120px;
  margin-block: 32px;
  justify-content: center;
  padding-inline: 0;
  letter-spacing: -0.02em;

  @media (max-width: $w-largest) {
    gap: 60px;
  }

  @media (max-width: $w-tablet-b) {
    flex-direction: column;
    gap: 40px;
  }

  @media (max-width: $w-mobile-s) {
    gap: 32px;
    margin-bottom: 0px;
  }
}

:deep(.short-onboarding__section-reverse) {
  @media (max-width: $w-tablet-b) {
    flex-direction: column-reverse;
  }
}

:deep(.short-onboarding__section-image) {
  max-width: 420px;
  max-width: 420px;
  object-fit: contain;

  @media (max-width: $w-large-s) {
    max-width: 400px;
    max-height: 320px;
  }

  @media (max-width: $w-tablet-b) {
    max-width: 100%;
  }

  @media (max-width: $w-medium-s) {
    margin: 0 20px;
    max-height: 293px;
  }

  @media (max-width: $w-mobile-l) {
    margin: 0;
  }
}

:deep(.short-onboarding__section-title) {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 24px;
  max-width: 480px;

  @media (max-width: $w-tablet-b) {
    max-width: 100%;
    padding: 0 40px;
    text-align: left;
  }

  @media (max-width: $w-medium-s) {
    padding: 0 20px;
    text-align: left;

    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

:deep(.short-onboarding__section-description) {
  max-width: 480px;
  font-size: 18px;
  line-height: 32px;
  color: $c-black-3;
  letter-spacing: -0.02em;

  @media (max-width: $w-tablet-b) {
    max-width: 640px;
    margin-bottom: 24px;
    text-align: left;
    padding: 0 40px;
  }

  @media (max-width: $w-medium-s) {
    max-width: 100%;
    padding: 16px 20px;
    text-align: left;

    font-size: 16px;
    line-height: 28px;
  }
}
</style>
