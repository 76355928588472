import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout__wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "banner banner-summaries"
}
const _hoisted_3 = { class: "container banner__container" }
const _hoisted_4 = { class: "banner__text-box text-center" }
const _hoisted_5 = {
  itemscope: "",
  itemtype: "https://schema.org/CreativeWork"
}
const _hoisted_6 = {
  class: "banner__title",
  itemprop: "headline"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 1,
  class: "highlight-view"
}
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "blockquote-slider" }
const _hoisted_11 = { class: "blockquote-slider__item" }
const _hoisted_12 = { class: "blockquote-slider__text highlight-view__quote" }
const _hoisted_13 = {
  key: 0,
  class: "my-2"
}
const _hoisted_14 = {
  key: 1,
  class: "highlight-view__note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createBlock(_component_Layout, { title: "Highlight Page" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("section", null, [
          (_ctx.highlight)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("h1", _hoisted_6, [
                        _createElementVNode("a", {
                          href: _ctx.bookUrl()
                        }, _toDisplayString(_ctx.highlight.content.doc.title), 9, _hoisted_7)
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.highlight)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("blockquote", _hoisted_12, _toDisplayString(_ctx.highlight.quote), 1),
                      (_ctx.highlight.text)
                        ? (_openBlock(), _createElementBlock("hr", _hoisted_13))
                        : _createCommentVNode("", true),
                      (_ctx.highlight.text)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.highlight.text), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}