<template>
  <div class="articles__item articles__item--large">
    <h2 class="articles__title--large">
      Subscribe to get full access to the world's best article summaries!
    </h2>
    <router-link
      :to="{ name: 'billing' }"
      class="articles__btn--large btns btns--largest"
    >
      Start Your Free Trial
    </router-link>
  </div>
</template>
