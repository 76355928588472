import axios, { AxiosError, AxiosResponse } from 'axios'

export type ErrorsDict = { [key: string]: string[] }
export type SocialLoginsList = ('Google' | 'Facebook')[]

/**
 * Authentication (401) error stucture returned by our backend.
 */
export interface ApiAuthErrorResponse {
  error: string
  code: string
  social_logins: SocialLoginsList
}

/**
 * Authentication (401) error stucture when there is social login error.
 */
export interface ApiSocialAuthErrorResponse {
  error: {
    social_error: string
  }
}

/**
 * Validation (400) error stucture returned by our backend.
 */
export interface ApiValidationErrorResponse {
  errors: ErrorsDict
}

/**
 * Base error class.
 *
 * Defines type guards to narrow the error type.
 * This way, we can handle errors like this:
 *
 *   const err = convertError(error)
 *   if (err.isAuthError()) {
 *     // err is AuthError
 *     // ...
 *   } else if (err.isValidationError()) {
 *     // err is ValidationError
 *     this.validation.show(err)
 *   } else {
 *     // Unexpected error
 *     Sentry.captureException(error)
 *     this.validation.showMessage('email', 'Unexpected login error')
 *   }
 */
class BaseError {
  public originalError: any

  constructor(error: any) {
    this.originalError = error
  }

  /**
   * Type guard to check and convert the object to UnknownError.
   */
  isUnknownError(): this is UnknownError {
    return this instanceof UnknownError
  }

  /**
   * Type guard to check and convert the object to ValidationError.
   */
  isValidationError(): this is ValidationError {
    return this instanceof ValidationError
  }

  /**
   * Type guard to check and convert the object to AuthError.
   */
  isAuthError(): this is AuthError {
    return this instanceof AuthError
  }

  /**
   * Type guard to check and convert the object to SocialAuthError.
   */
  isSocialAuthError(): this is SocialAuthError {
    return this instanceof SocialAuthError
  }
}

/**
 * Unknown error, when the error type can not be identified.
 */
class UnknownError extends BaseError {
  constructor(error: any) {
    super(error)
  }
}

/**
 * API Validation error.
 */
export class ValidationError extends BaseError {
  public data: ApiValidationErrorResponse

  constructor(error: AxiosError, errorResponse: AxiosResponse) {
    super(error)
    this.data = errorResponse.data as ApiValidationErrorResponse
  }

  errors(): ErrorsDict {
    return this.data.errors
  }

  get(field: string): string[] {
    return this.data.errors[field]
  }

  getOne(field: string): string | null {
    const errors = this.get(field)
    if (errors && errors.length === 1) {
      return errors[0]
    }
    return null
  }

  /**
   * Set new error message for the validation error.
   */
  setOne(field: string, message: string): void {
    this.data.errors[field] = [message]
  }
}

/**
 * API Authentication error.
 */
export class AuthError extends BaseError {
  public data: ApiAuthErrorResponse

  constructor(error: AxiosError, errorResponse: AxiosResponse) {
    super(error)
    this.data = errorResponse.data as ApiAuthErrorResponse
  }

  code(): string {
    return this.data.code
  }

  message(): string {
    return this.data.error
  }

  social_logins(): SocialLoginsList {
    return this.data.social_logins
  }
}

/**
 * API Social authentication error.
 */
export class SocialAuthError extends BaseError {
  public data: ApiSocialAuthErrorResponse

  constructor(error: AxiosError, errorResponse: AxiosResponse) {
    super(error)
    this.data = errorResponse.data as ApiSocialAuthErrorResponse
  }

  message(): string {
    return this.data.error.social_error
  }
}

/**
 * Convert the original error into error object of known type.
 *
 * Returns UnknownError if the error is not a known backend API error.
 */
export function convertError(error: any): BaseError {
  if (!axios.isAxiosError(error)) {
    return new UnknownError(error)
  }

  if (!error.response) {
    return new UnknownError(error)
  }

  const status = error.response.status
  const errorData = error.response.data as any
  if (status === 400 && errorData.errors) {
    return new ValidationError(error, error.response)
  }
  if (status === 401 && errorData.error) {
    if (errorData.error.social_error) {
      return new SocialAuthError(error, error.response)
    }
    return new AuthError(error, error.response)
  }
  return new UnknownError(error)
}
