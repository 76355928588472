
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import Sidebar from '@/app/components/doc/reading/Sidebar.vue'
import HeaderDoc from '@/app/components/doc/Header.vue'
import BackLink from '@/app/components/BackLink.vue'
import Footer from '@/app/components/Footer.vue'

import SidebarTOC from '@/app/components/doc/reading/SidebarTOC.vue'
import SidebarTutorial from '@/app/components/doc/tutorial/SidebarTutorial.vue'
import Tutorial from '@/app/components/doc/tutorial/Tutorial.vue'
import ContentLabel from '@/app/components/doc/ContentLabel.vue'

import { autoShowTutorial } from '@/models/doc.tutorial'
import { DocView } from '@/models/doc'

@Options({
  components: {
    Sidebar,
    SidebarTOC,
    SidebarTutorial,
    Tutorial,
    HeaderDoc,
    BackLink,
    Footer,
    ContentLabel,
  },
})
export default class DocLayout extends Vue {
  @Prop() private doc!: DocView
  @Prop() private title!: string

  // Set to false to hide text controls: font settings, audio player button.
  @Prop({ default: true }) private showTextControls!: boolean

  mounted(): void {
    autoShowTutorial(this.doc.tutorial)
  }
}
