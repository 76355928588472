import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "book-highlights" }
const _hoisted_2 = { class: "container book-highlights__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Hero = _resolveComponent("Hero")!
  const _component_HighlightsList = _resolveComponent("HighlightsList")!
  const _component_DocLayout = _resolveComponent("DocLayout")!

  return (_openBlock(), _createBlock(_component_DocLayout, {
    doc: _ctx.doc,
    "show-text-controls": false
  }, {
    "page-content": _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Hero, {
            doc: _ctx.doc,
            title: "Highlights and Notes of"
          }, null, 8, ["doc"]),
          _createVNode(_component_HighlightsList, { doc: _ctx.doc }, null, 8, ["doc"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["doc"]))
}