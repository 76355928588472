
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { Book } from '@/models/interfaces'

import { BooksView } from '@/models/book'

import BookCardThumbnail from '@/app/components/book/card/BookCardThumbnail.vue'

@Options({
  components: { BookCardThumbnail },
})
export default class ContinueReadingCard extends Vue {
  @Prop() private doc!: Book
  @Prop() private view!: BooksView
}
