import { AudioDoc } from './logic'
import { storage as localStorageWrapper } from '@/helpers/storage'

// The default rate is 1.1 as it sounds more natural.
const DEFAULT_RATE = 1.1
const MIN_RATE = 0.3
const MAX_RATE = 3.5

/**
 * Audio playbadck rate UI control (slower / falser).
 */
export class RateControl {
  private _audio: AudioDoc
  private _playbackRate: number = DEFAULT_RATE

  constructor(audio: AudioDoc) {
    this._audio = audio

    const value = localStorageWrapper.getItem('sf_player_rate')
    if (value) {
      this._playbackRate = parseFloat(value)
    }
    this._audio.playbackRate = this._playbackRate

    this._audio.addListener('ratechange', () => {
      this._ratechangeHandler()
    })
  }

  rate(): number {
    return this._playbackRate
  }

  reset(): void {
    this._playbackRate = DEFAULT_RATE
    this._audio.playbackRate = this._playbackRate
  }

  up(): void {
    this._playbackRate += 0.1
    if (this._playbackRate > MAX_RATE) {
      this._playbackRate = MAX_RATE
    }
    this._audio.playbackRate = this._playbackRate
  }

  down(): void {
    this._playbackRate -= 0.1
    if (this._playbackRate < MIN_RATE) {
      this._playbackRate = MIN_RATE
    }
    this._audio.playbackRate = this._playbackRate
  }

  _ratechangeHandler(): void {
    this._playbackRate = this._audio.playbackRate
    const value = Math.round(this._audio.playbackRate * 10) / 10
    localStorageWrapper.setItem('sf_player_rate', value.toString())
  }
}
