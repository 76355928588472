import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PodcastCard = _resolveComponent("PodcastCard")!
  const _component_ListingPageLayoutSimple = _resolveComponent("ListingPageLayoutSimple")!

  return (_openBlock(), _createBlock(_component_ListingPageLayoutSimple, { view: _ctx.view }, {
    nodata: _withCtx(() => [
      _createTextVNode(" No podcasts found. ")
    ]),
    default: _withCtx(() => [
      (_ctx.view && _ctx.view.podcasts)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.view.podcasts, (podcast) => {
            return (_openBlock(), _createBlock(_component_PodcastCard, {
              key: podcast.id,
              title: podcast.title,
              subtitle: _ctx.view.podcastSubtitle(podcast),
              link: _ctx.view.link(podcast),
              coverImage: podcast.cover_image,
              isFollowed: podcast.is_followed
            }, null, 8, ["title", "subtitle", "link", "coverImage", "isFollowed"]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["view"]))
}