import { storage } from '@/helpers/storage'
import { BookView } from './book'

// Enum with Book tutorial step names
export enum BookTutorialStep {
  start,
  welcome,
  summary,
  fonts,
  toc,
  player,
  favorite,
  night,
}

/*
 * Book Tutorial class.
 *
 * We use this class to enable/disable tutorial and track tutorial steps.
 */
export class BookTutorial {
  // Current tutorial step
  private _tutorialStep: number = 0

  private _visible = true

  private _book: BookView

  private activeSidebarButtonClass = 'control__btn--tutorial'

  constructor(book: BookView) {
    this._book = book
  }

  /*
   * Hide Book Tutorial if the user is assigned to the experiment with option 'no'.
   */
  public hide(): void {
    this._visible = false
  }

  /*
   * Show Book Tutorial if the user is assigned to the experiment with option 'yes'.
   */
  public show(): void {
    this._visible = true
  }

  /*
   * Is book tutorial visible.
   */
  public isVisible(): boolean {
    return this._visible
  }

  /*
   * Is book tutorial active (open).
   */
  public isActive(): boolean {
    return this._visible && this._tutorialStep > 0
  }

  /*
   * Start Book Tutorial, set step to "welcome".
   */
  public start(): void {
    this._tutorialStep = BookTutorialStep.welcome
    // Show the book controls bar if it was hidden.
    this._book.controls.show()
  }

  /*
   * Increment tutorial step.
   */
  public incrementStep(): void {
    this._tutorialStep += 1
    // If the book doesn't have audio and current step is "player", we just
    // increment the step once again.
    if (
      !this._book.has_audio &&
      this._tutorialStep === BookTutorialStep.player
    ) {
      this._tutorialStep += 1
    }
  }

  /*
   * Reset Tutorial step - start over again.
   */
  public resetStep(): void {
    this._tutorialStep = BookTutorialStep.start
  }

  /*
   * Get current tutorial step.
   */
  public get step(): number {
    return this._tutorialStep
  }

  /*
   * Return CSS class for active TOC sidebar button.
   */
  public get tocBtnClass(): string[] {
    if (this._tutorialStep === BookTutorialStep.toc) {
      return [this.activeSidebarButtonClass]
    } else {
      return ['']
    }
  }

  /*
   * Get Book Tutorial steps for the sidebar.
   *
   * Returns a list of numbers with book tutorial steps related to the sidebar.
   * If we need to skip ``player`` step - we return the list without it.
   */
  public sidebarSteps(): number[] {
    if (this._book.has_audio === false) {
      return [
        BookTutorialStep.fonts,
        BookTutorialStep.toc,
        BookTutorialStep.favorite,
        BookTutorialStep.night,
      ]
    }
    return [
      BookTutorialStep.fonts,
      BookTutorialStep.toc,
      BookTutorialStep.player,
      BookTutorialStep.favorite,
      BookTutorialStep.night,
    ]
  }
}

/*
 * Helper function to autostart the Book Tutorial.
 *
 * Called from component and receives a BookTutorial instance as an argument,
 * checks the local storage for the autoshow flag, and if it's set:
 * - set the flag to ``false``: to avoid autoshowing the tutorial for users
 *   that already seen it
 * - start the bookTutorial by calling its ``start()`` method.
 *
 * If the flag is not set (or set to false) - do nothing.
 */
export function autoShowTutorial(bookTutorial: BookTutorial | null): void {
  if (!bookTutorial) {
    return
  }
  const autoShow = JSON.parse(
    storage.getItem('sf_tutorial_auto_show') as string,
  )
  if (autoShow === true) {
    bookTutorialSetAutoShow(false)
    bookTutorial.start()
  }
}

/*
 * Helper function to set the autoshow flag in localStorage.
 *
 * Called from ``backend.ts`` module, made to have all the logic related to
 * book tutorial in this module.
 */
export function bookTutorialSetAutoShow(value: boolean): void {
  storage.setItem('sf_tutorial_auto_show', JSON.stringify(value))
}
