
import { Vue } from 'vue-class-component'
import { Options, Prop, Watch } from 'vue-property-decorator'

import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

import { ForumView } from '@/models/forum'

@Options({
  components: {
    InputWithContextMenu,
  },
})
export default class CommunitySearch extends Vue {
  @Prop() private forum!: ForumView
  search: string = ''

  @Watch('search')
  onSearch(): void {
    this.forum.filter(this.search)
  }
}
