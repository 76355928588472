import { auth } from '@/services/auth'
import { WEGLOT_API_KEY, WEGLOT_ALLOWED_LANGS } from '@/init/settings'

export function isAllowedLanguage(
  language: string | null | undefined,
): boolean {
  if (!language) {
    return false
  }
  return WEGLOT_ALLOWED_LANGS.includes(language)
}

/** Detect the language depending on the firstUrl.
 *
 * @param firstUrl - The first URL of the user
 * @returns The ISO 639-1 2-letter code of a language (or null if not found).
 */
function detectFirstUrlLanguage(firstUrl: string | null): string | null {
  if (firstUrl === null) {
    return null
  }

  // Summary and PDF public pages
  const regexSummaryPdf = /shortform\.com\/([a-z]{2})\/(summary|pdf)\/.+/
  const matchSummaryPdf = firstUrl.match(regexSummaryPdf)
  if (matchSummaryPdf) {
    return matchSummaryPdf[1] // Return the captured language code
  }

  // Blog posts
  const regexBlog = /shortform\.com\/blog\/([a-z]{2})\/.+/
  const matchBlog = firstUrl.match(regexBlog)
  if (matchBlog) {
    return matchBlog[1] // Return the captured language code
  }

  return null
}

/**
 * Get the selectors that should be merged by Weglot.
 *
 * Weglot merges some of the sequential HTML elements, such as `a` and `span`
 * so that the text is not broken into multiple pices.
 * For example, something like <a>Click <span>here</span></a> will be
 * translated as one "Click here" item.
 *
 * This is useful, but sometimes gets in the way because it may break
 * Vue reactivity and event handlers.
 * For example, if we have several `a` elements in a row, such as in the
 * top app menu, Weglot will merge them, translate and then replace
 * with translated HTML all at once, and Vue router clicks stop working.
 *
 * To avoid this, we can use the `wg-no-merge` class to avoid merging.
 *
 * Also note that the `merged_selectors_remove` option is not documented,
 * I found it in the weglot script source code.
 * An alternative (hacky) way is to wrap the content inside `a` into `p`
 * or `div`, for example `<a><p>Click here</p></a>`.
 *
 * See also:
 * https://developers.weglot.com/javascript/advanced-concepts/translation-engines
 * https://developers.weglot.com/javascript/options
 * https://github.com/shortformhq/main/issues/14700
 */
function weglotMergedSelectorsRemove(): { value: string }[] {
  // Note that we could use multiple custom classes, in the array
  // below like `.app-menu-item`, `.something-else`, etc., but it's
  // better to use one class for all such cases, this way we can easily
  // find all such cases in the code.
  return [{ value: '.wg-no-merge' }]
}

export function initWeglotIfNeeded(): void {
  const language = auth.loggedIn()
    ? auth.getUser()?.language
    : detectFirstUrlLanguage(localStorage.getItem('sf_first_page'))

  // Init Weglot only if the language is specified.
  if (isAllowedLanguage(language)) {
    const weglot = (window as any).Weglot
    if (weglot && !weglot.initialized) {
      console.log(`Weglot initialization (${language})...`)

      // Set callback on the "initialized" event.
      weglot.on('initialized', () => {
        weglot.switchTo(language)
      })

      weglot.initialize({
        api_key: WEGLOT_API_KEY,
        // See the explanation option above, in the function docstring.
        merged_selectors_remove: weglotMergedSelectorsRemove(),
      })
    }
  }
}
