
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

import UsernameInput from './UsernameInput.vue'

import { ValidationState, setFocus } from '@/helpers/form'
import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Thread } from '@/models/interfaces'
import { DocView } from '@/models/doc'

const EMPTY_COMMENT = { id: null, text: '' }

@Options({
  components: {
    FormError,
    UsernameInput,
    'v-wait': vLoading,
    ButtonSpinner,
    InputWithContextMenu,
  },
})
export default class CommentEdit extends Vue {
  @Prop() private doc!: DocView
  @Prop() private thread!: Thread

  validation: ValidationState = new ValidationState()
  model: any = Object.assign({}, EMPTY_COMMENT)

  async save(): Promise<void> {
    try {
      wait.start(this, 'Posting Comment')
      const comment = await backend.saveComment(
        this.doc,
        this.thread,
        this.model,
      )
      this.thread.comments.unshift(comment)
      this.thread.comments_count += 1
      this.validation.reset()
      ;(this.$refs.username as any).saved()
      this.model = Object.assign({}, EMPTY_COMMENT)
    } catch (error) {
      this.validation.showErrors(error)
    } finally {
      wait.end(this, 'Posting Comment')
    }
  }

  focusInput(): void {
    setFocus((this.$refs.input as InputWithContextMenu).$el as HTMLElement)
  }
}
