
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { BillingView } from '@/models/billing'
import { wait } from '@/helpers/vue-wait'
import { ValidationState } from '@/helpers/form'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import BasePopup from '@/components/ui/BasePopup.vue'
import {
  PlanChangeFailedError,
  UserCancelledError,
} from '@/services/cordova.purchase.types'

@Options({
  components: {
    BasePopup,
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
  },
})
export default class SwitchToAnnualPopup extends Vue {
  @Prop() private billing!: BillingView

  private validation: ValidationState = new ValidationState()

  show(): void {
    ;(this.$refs.popup as any).show()
  }

  hide(): void {
    const popup = this.$refs.popup as any
    if (popup) {
      // Popup can already disappear due to the component rerendering.
      popup.hide()
    }
    this.validation.reset()
  }

  async subscribe(): Promise<void> {
    try {
      wait.start(this, 'Subscribing')
      await this.billing.subscribeTo(this.billing.annualPlan)
      this.$emit('subscribed')
      this.validation.reset()
      this.hide()
      wait.end(this, 'Subscribing')
    } catch (error) {
      wait.end(this, 'Subscribing')
      if (error instanceof UserCancelledError) {
        return
      }
      if (error instanceof PlanChangeFailedError) {
        this.validation.showError(this.billing.annualPlan.id, error.message)
      } else {
        this.validation.showErrors(error)
      }
    }
  }
}
