
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class QuestionnairePrevNext extends Vue {
  @Prop({ default: true }) nextDisabled!: boolean
}
