
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { BillingView } from '@/models/billing'
import NotificationBox from '@/components/ui/NotificationBox.vue'

@Options({
  components: {
    NotificationBox,
  },
})
export default class BillingNotifications extends Vue {
  @Prop() protected billing!: BillingView
}
