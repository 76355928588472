
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { AnnualPlanView, BillingView, PlanView } from '@/models/billing'
import { convertAndFormatPrice } from '@/models/billing.localized.pricing'
import { assertNotNull } from '@/helpers/typing'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import PlanCard from './PlanCard.vue'
import PlanInfo from './PlanInfo.vue'

@Options({
  components: {
    ButtonSpinner,
    PlanCard,
    PlanInfo,
  },
})
export default class PlanAnnual extends Vue {
  @Prop() private billing!: BillingView

  get plan(): PlanView {
    return this.billing.annualPlan
  }

  get showLocalizedPrice(): boolean {
    // `userCountry` is defined in `BillingData.vue` when the
    // user is in the test group of the `localized_pricing_14281` experiment.
    return this.billing.userCountry !== undefined
  }

  get priceYearValue(): string {
    if (this.showLocalizedPrice) {
      // Replace the text inside the parenthesis with the localized price
      return this.plan.priceYearValue.replace(
        /\(.+\)/,
        this.localizedPrice('year'),
      )
    }

    // If the user is not in the test group of the experiment,
    // return the regular text for `priceYearValue`.
    return this.plan.priceYearValue
  }

  localizedPrice(period: 'month' | 'year' = 'month'): string {
    // We type cast `AnnualPlanView` here because we use
    // `discountedPrice()` below, which is not available in `PlanView`.
    const planView = this.plan as AnnualPlanView
    let price = planView.discountedPrice(this.plan.amount) / 100

    if (period === 'month') {
      price = price / 12
    }

    const localPrice = convertAndFormatPrice(
      price,
      assertNotNull(this.billing.userCountry),
    )

    return `(≈${localPrice}/${period})`
  }
}
