
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { BillingView } from '@/models/billing'

import PlanInfoMonthly from './BillingDataPaid.PlanInfo.Monthly.vue'
import PlanInfoAnnual from './BillingDataPaid.PlanInfo.Annual.vue'
import InfoFAQ from './InfoFAQ.vue'
import InfoQuestions from './InfoQuestions.vue'
import InfoDeleteAccount from '@/app/components/billing/InfoDeleteAccount.vue'

import BillingNotifications from './BillingNotifications.vue'

@Options({
  components: {
    BillingNotifications,
    PlanInfoMonthly,
    PlanInfoAnnual,
    InfoFAQ,
    InfoQuestions,
    InfoDeleteAccount,
  },
})
export default class BillingDataPaid extends Vue {
  @Prop() protected billing!: BillingView

  get paymentProviderSettingsText(): string {
    if (this.billing.billing_type === 'apple') {
      return 'Please use Apple UI to manage your subscription.'
    }
    return 'Manage your subscription on Google Play.'
  }
}
