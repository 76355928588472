import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingError = _resolveComponent("LoadingError")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _component_ContinueReadingBooksBlock = _resolveComponent("ContinueReadingBooksBlock")!
  const _component_FreeBooksBlock = _resolveComponent("FreeBooksBlock")!
  const _component_ExcerptBlock = _resolveComponent("ExcerptBlock")!
  const _component_BooksBlock = _resolveComponent("BooksBlock")!
  const _component_NewReleasesBooksBlock = _resolveComponent("NewReleasesBooksBlock")!
  const _component_CollectionsBlock = _resolveComponent("CollectionsBlock")!
  const _component_BookOfTheDayBlock = _resolveComponent("BookOfTheDayBlock")!
  const _component_ArticlesBlock = _resolveComponent("ArticlesBlock")!
  const _component_RootPageLayout = _resolveComponent("RootPageLayout")!

  return (_openBlock(), _createBlock(_component_RootPageLayout, {
    class: "discover-page",
    title: "Discover the world's best ideas",
    mobileTitle: "Discover"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_wait, { for: "Error Loading Discover" }, {
        waiting: _withCtx(() => [
          _createVNode(_component_LoadingError)
        ]),
        _: 1
      }),
      _createVNode(_component_v_wait, {
        class: "layout__wrapper-inner container",
        for: "Loading Discover"
      }, {
        waiting: _withCtx(() => [
          _createVNode(_component_loading_spinner)
        ]),
        default: _withCtx(() => [
          (_ctx.continueReadingView)
            ? (_openBlock(), _createBlock(_component_ContinueReadingBooksBlock, {
                key: 0,
                view: _ctx.continueReadingView,
                viewAllLink: "/app/books/list/reading"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.freeBooksView && _ctx.freeBooksView.filteredBooks.length && _ctx.isFreeUser)
            ? (_openBlock(), _createBlock(_component_FreeBooksBlock, {
                key: 1,
                view: _ctx.freeBooksView
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.excerptView)
            ? (_openBlock(), _createBlock(_component_ExcerptBlock, {
                key: 2,
                view: _ctx.excerptView
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.hasRecommendations)
            ? (_openBlock(), _createBlock(_component_BooksBlock, {
                key: 3,
                view: _ctx.recommendedBooksView,
                title: "Recommended",
                viewAllLink: "/app/books/list/recommended"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.newReleasesView)
            ? (_openBlock(), _createBlock(_component_NewReleasesBooksBlock, {
                key: 4,
                view: _ctx.newReleasesView,
                viewAllLink: "/app/books/list/new"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.popularBooksView)
            ? (_openBlock(), _createBlock(_component_BooksBlock, {
                key: 5,
                view: _ctx.popularBooksView,
                title: "Popular Books",
                viewAllLink: "/app/books/list/popular"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.collectionsView)
            ? (_openBlock(), _createBlock(_component_CollectionsBlock, {
                key: 6,
                title: "Collections",
                viewAllLink: "/app/collections",
                view: _ctx.collectionsView
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.bookOfTheDayView)
            ? (_openBlock(), _createBlock(_component_BookOfTheDayBlock, {
                key: 7,
                view: _ctx.bookOfTheDayView,
                title: "Book of the Day"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true),
          (_ctx.articlesView)
            ? (_openBlock(), _createBlock(_component_ArticlesBlock, {
                key: 8,
                view: _ctx.articlesView,
                title: "Articles",
                viewAllLink: "/app/articles/list/all"
              }, null, 8, ["view"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}