import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "description-box" }
const _hoisted_2 = { class: "description" }
const _hoisted_3 = { class: "container container--description" }
const _hoisted_4 = { class: "description__text-box" }
const _hoisted_5 = { class: "description__name" }
const _hoisted_6 = {
  key: 0,
  class: "description__subtitle"
}
const _hoisted_7 = { class: "description__text description__text--show" }
const _hoisted_8 = { class: "description__text-c" }
const _hoisted_9 = { class: "description__img-box" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 0,
  class: "container description__url"
}
const _hoisted_12 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.view.title), 1),
          (_ctx.view.author)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.view.author), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.view.displayedText) + " ", 1),
              (_ctx.view.hasLongText)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "description__toggle-full-text",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.view.toggleShowFullText && _ctx.view.toggleShowFullText(...args)))
                  }, _toDisplayString(_ctx.toggleFullTextLabel), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("img", {
            class: "description__img",
            src: _ctx.view.imageUrl,
            alt: ""
          }, null, 8, _hoisted_10)
        ])
      ]),
      (_ctx.view.originalUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.view.originalUrl,
              rel: "noreferrer noopener"
            }, " Listen to the original ", 8, _hoisted_12)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}