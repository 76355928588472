
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import Footer from '@/app/components/Footer.vue'
import HeaderAppDesktop from '@/app/components/header/AppDesktop.vue'
import HeaderAppMobile from '@/app/components/header/AppMobile.vue'

@Options({
  components: {
    Footer,
    HeaderAppDesktop,
    HeaderAppMobile,
  },
})
export default class Layout extends Vue {
  @Prop() public title!: string

  /**
   * Remove the header mobile menu (HeaderAppMobile)
   */
  @Prop({ type: Boolean }) public hideMobileMenu!: boolean

  async beforeMount(): Promise<void> {
    // Starting point for A/B experiments
  }
}
