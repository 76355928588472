import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "releases-block__text" }
const _hoisted_2 = { class: "releases-block__category card-large__category" }
const _hoisted_3 = { class: "releases-block__title card-large__title" }
const _hoisted_4 = { class: "releases-block__author card-large__author" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCover = _resolveComponent("BookCover")!
  const _component_BookCardBackground = _resolveComponent("BookCardBackground")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.view.startLinkParams(_ctx.doc),
    class: "releases-block__item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BookCover, {
        doc: _ctx.doc,
        coverClass: "releases-block__img"
      }, null, 8, ["doc"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.view.firstTagShort(_ctx.doc)), 1),
        _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.doc.title), 1),
        _createElementVNode("h4", _hoisted_4, " By " + _toDisplayString(_ctx.doc.author), 1)
      ]),
      _createVNode(_component_BookCardBackground, { doc: _ctx.doc }, null, 8, ["doc"])
    ]),
    _: 1
  }, 8, ["to"]))
}