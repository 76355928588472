import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b4b253b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "layout__wrapper" }
const _hoisted_3 = { class: "article-single background-section" }
const _hoisted_4 = { class: "container article-single__content control-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBook = _resolveComponent("HeaderBook")!
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_PrevNext = _resolveComponent("PrevNext")!
  const _component_ArticleThread = _resolveComponent("ArticleThread")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderBook, { doc: _ctx.article }, null, 8, ["doc"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BackLink, { class: "article-single__backlink" }),
          _createVNode(_component_Sidebar, { doc: _ctx.article }, null, 8, ["doc"]),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true),
          _createVNode(_component_PrevNext, { article: _ctx.article }, null, 8, ["article"]),
          _createVNode(_component_ArticleThread, { article: _ctx.article }, null, 8, ["article"])
        ])
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}