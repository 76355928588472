import { Adjust, AdjustEvent } from '@awesome-cordova-plugins/adjust'

import { Sentry } from '@/services/sentry'
import { platform } from '@/services/platform'

const SIGNUP_EVENT_TOKEN_IOS = '8l934d'
const TRIAL_START_EVENT_TOKEN_IOS = 'a2db57'

/**
 * Track the Signup event.
 *
 * For now, we only send events on iOS since that is our main platform for
 * using Adjust data.
 */
export async function register(): Promise<void> {
  const isIOS = await platform.isNativeIOSApp()
  if (!isIOS) {
    return
  }
  try {
    const event = new AdjustEvent(SIGNUP_EVENT_TOKEN_IOS)
    Adjust.trackEvent(event)
  } catch (error) {
    Sentry.captureException(error)
  }
}

/**
 * Track the Trial Start event. This is equivalent to Card Save in Mixpanel.
 *
 * For now, we only send events on iOS since that is our main platform for
 * using Adjust data.
 */
export async function trackCardSave(): Promise<void> {
  const isIOS = await platform.isNativeIOSApp()
  if (!isIOS) {
    return
  }
  try {
    const event = new AdjustEvent(TRIAL_START_EVENT_TOKEN_IOS)
    Adjust.trackEvent(event)
  } catch (error) {
    Sentry.captureException(error)
  }
}

/*
 * Adjust helps us track mobile app events so that we can use Apple Search Ads.
 * We name this "AdjustWrapper" to avoid confusion with the actual Adjust SDK.
 *
 * Cordova plugin docs:
 * https://github.com/adjust/cordova_sdk/blob/master/README.md
 * https://github.com/danielsogl/awesome-cordova-plugins/blob/master/src/%40awesome-cordova-plugins/plugins/adjust/index.ts
 */
export * as AdjustWrapper from './adjust'
