import { AudioDoc } from '@/services/player/logic'
import { PlaybackControl } from '@/services/player/control.playback'
import { ChapterPrevNextControl } from '@/services/player/control.chapter.prev-next'
import {
  MediaSessionHandler,
  createMediaSessionHandler,
  MediaSessionMetadata,
  PositionState,
} from '@/services/player/media-session-handler'

/**
 * MediaSessionControl provides a way to synchronize our audio player with native media
 * controls (e.g. a browser's media controls, or lock screen / notification area media
 * controls on mobile).
 *
 * The interactions go in two ways: Actions on the native media controls lead to
 * updates in our audio player, and actions in our audio player lead to
 * updates to the data displayed in the media controls.
 */
export class MediaSessionControl {
  private audio: AudioDoc
  private mediaSessionHandler: MediaSessionHandler
  private currentChapter: string

  /**
   * Create a new instance of MediaSessionControl.
   */
  static async initialize(
    audio: AudioDoc,
    playbackControl: PlaybackControl,
    chapterControl: ChapterPrevNextControl,
  ): Promise<MediaSessionControl> {
    const mediaSessionHandler = await this.initMediaSession(
      audio,
      playbackControl,
      chapterControl,
    )

    return new MediaSessionControl(audio, mediaSessionHandler)
  }

  /**
   * Update the playback state of the native media controls, i.e. set to playing
   * or paused.
   *
   * @param state New playback state - 'playing' or 'paused'.
   */
  setPlaybackState(state: 'playing' | 'paused'): Promise<void> {
    return this.mediaSessionHandler.setPlaybackState(
      state,
      this.getCurrentPositionState(),
    )
  }

  /**
   * Stop showing the native media controls. Implemented only for Android apps.
   */
  releaseMediaSession(): Promise<void> {
    return this.mediaSessionHandler.releaseMediaSession()
  }

  private static async initMediaSession(
    audio: AudioDoc,
    playbackControl: PlaybackControl,
    chapterControl: ChapterPrevNextControl,
  ): Promise<MediaSessionHandler> {
    const metadata = MediaSessionControl.getMetadata(audio)
    const playbackState = playbackControl.paused ? 'paused' : 'playing'

    const onSeekTo = (position?: number | null): void => {
      if (position !== undefined && position !== null) {
        audio.currentTime = position
      }
    }

    const callbacks = {
      onPlay: playbackControl.play.bind(playbackControl),
      onPause: playbackControl.pause.bind(playbackControl),
      onStop: playbackControl.pause.bind(playbackControl),
      onSeekBackward: playbackControl.backward.bind(playbackControl),
      onSeekForward: playbackControl.forward.bind(playbackControl),
      onSeekTo: onSeekTo,
      onPreviousTrack: chapterControl.prev.bind(chapterControl),
      onNextTrack: chapterControl.next.bind(chapterControl),
    }

    return await createMediaSessionHandler(metadata, playbackState, callbacks)
  }

  private constructor(
    audio: AudioDoc,
    mediaSessionHandler: MediaSessionHandler,
  ) {
    this.audio = audio
    this.mediaSessionHandler = mediaSessionHandler
    this.currentChapter = audio.chapters.current().title

    // Update position state once metadata is known.
    audio.addListener('loadedmetadata', this.onPositionStateUpdate.bind(this))

    // Update position state on manual position changes through the controls of the
    // in-app player, like seeking to a position or chapter changes.
    audio.addListener('seeked', this.onPositionStateUpdate.bind(this))

    // Update metadata on chapter changes
    audio.addListener('timeupdate', this.updateMetadata.bind(this))

    // Update position state on playback rate change.
    audio.addListener('ratechange', this.onPositionStateUpdate.bind(this))
  }

  /**
   * Update playback position, media duration and playback rate of the native
   * media controls.
   *
   * The playback position does not have to be updated manually during normal playback,
   * but only when moving to a specific position, e.g. when changing playback position
   * with the controls of our in-app player (seek, prev/next chapter, etc.).
   */
  private async onPositionStateUpdate(): Promise<void> {
    await this.mediaSessionHandler.setPositionState(
      this.getCurrentPositionState(),
    )
  }

  private async updateMetadata(): Promise<void> {
    const metadata = MediaSessionControl.getMetadata(this.audio)
    if (metadata.chapter !== this.currentChapter) {
      this.currentChapter = metadata.chapter
      await this.mediaSessionHandler.setMetadata(metadata)
    }
  }

  private getCurrentPositionState(): PositionState {
    return {
      position: this.audio.currentTime,
      playbackRate: this.audio.playbackRate,
      duration: this.audio.duration,
    }
  }

  private static getMetadata(audio: AudioDoc): MediaSessionMetadata {
    // `audio.doc.cover_image` is e.g. `//media.shortform.com/covers/png/zero-to-one-cover.png`
    // We need prepend `https:` to get a valid URI.
    let img = 'https://media.shortform.com/book-placeholder.png'
    if (audio.doc.cover_image) {
      img = 'https:' + audio.doc.cover_image
    }
    // For articles we don't change the cover URI.
    if (audio.doc.doc_type === 'article') {
      img = 'https:' + audio.doc.cover_image
    }

    return {
      title: audio.doc.title,
      author: audio.doc.author,
      chapter: audio.chapters.current().title,
      artwork: [
        {
          src: img,
          sizes: '158x211',
          type: 'image/png',
        },
      ],
    }
  }
}
