import { Content, DocWithContent } from './interfaces'
import { ChapterView, ContentView, DocView } from './doc'
import { auth } from '@/services/auth'
import { backLink } from './backlink'

export class PodcastEpisodeView extends DocView implements DocWithContent {
  /**
   * Factory function to create chapter Record<string, unknown>
   * based on content_type value.
   */
  protected createChapter(content: Content): ContentView {
    return new ChapterView(this, content)
  }

  routeNames(): Record<string, string> {
    return {
      community: 'podcast.community',
      thread: 'podcast.thread',
      edit_thread: 'podcast.edit_thread',
      highlights: 'podcast.highlights',
      page: 'podcast.page',
      preview: 'podcast.preview',
    }
  }

  get aboutTitle(): string {
    return 'About Episode'
  }

  get showDownload(): boolean {
    return false
  }

  /**
   * Returns 'true' if the DocGroup that this Doc is a part of
   * can be followed.
   */
  isDocGroupFollowable(): boolean {
    return true
  }

  /**
   * Get start/continue reading button variant
   */
  startReadingButton(): any {
    const startedReading = this.progress > 1

    if (auth.isPaidPlan()) {
      return {
        text: startedReading ? 'Continue Reading' : 'Start Reading',
        icon: startedReading ? 'ibook' : 'iadd',
      }
    }
    if (!this.is_free) {
      return {
        text: 'Preview Episode',
        icon: 'ibook',
      }
    }
    return {
      text: startedReading ? 'Continue Reading' : 'Read for Free',
      icon: startedReading ? 'ibook' : 'iadd1',
    }
  }

  public async doneClick(): Promise<void> {
    // Mixpanel.trackPodcastEpisdoeDoneClick(this)
    backLink.value.goBack()
  }

  get doneText(): string {
    return `Go back to ${backLink.value.previousPageTitle}`
  }

  /**
   * Return the full summary item text depending on doc_type.
   */
  public get fullSummaryItemText(): string {
    return 'Full Episode Guide'
  }
}
