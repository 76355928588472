
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { HeaderView } from '@/models/header'

import AppMenu from '@/app/components/header/AppMenu.vue'

@Options({
  components: {
    AppMenu,
  },
})
export default class HeaderAppMobile extends Vue {
  public view: HeaderView | null = null

  async beforeMount(): Promise<void> {
    this.view = new HeaderView(this)
    await this.view.init()
  }
}
