import { registerPlugin } from '@capacitor/core'
import { platform } from '@/services/platform'

interface ContextMenuPlugin {
  /**
   * Enable the text edit menu (copy, cut, paste) that gets shown after a long press.
   * Implemented only on iOS.
   */
  enableContextMenu(): Promise<void>
  /**
   * Supress the text edit menu (copy, cut, paste) that would be shown after a long
   * press. Implemented only on iOS.
   */
  disableContextMenu(): Promise<void>
}

const contextMenuPlugin = registerPlugin<ContextMenuPlugin>('ContextMenu')

/**
 * Enable the text edit menu (copy, cut, paste) that gets shown after a long press
 * on iOS. Does nothing on other platforms.
 */
export async function enableIosContextMenu(): Promise<void> {
  if (await platform.isNativeIOSApp()) {
    await contextMenuPlugin.enableContextMenu()
  }
}

/**
 * Suppress the text edit menu (copy, cut, paste) that gets shown after a long press
 * on iOS. Does nothing on other platforms.
 */
export async function disableIosContextMenu(): Promise<void> {
  if (await platform.isNativeIOSApp()) {
    await contextMenuPlugin.disableContextMenu()
  }
}
