
import { SignupView } from '@/models/auth/models'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import FormError from '@/components/ui/FormError.vue'

@Options({
  components: {
    FormError,
    InputWithContextMenu,
  },
})
export default class SignupSheet extends Vue {
  @Prop() view!: SignupView
}
