import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "all-comments"
}
const _hoisted_2 = { class: "comment-wrapper" }
const _hoisted_3 = { class: "comments__total-count" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "comments__comment" }
const _hoisted_7 = {
  key: 0,
  class: "comments__reply"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommentComponent = _resolveComponent("CommentComponent")!
  const _component_CommentReply = _resolveComponent("CommentReply")!

  return (_ctx.thread.comments_count > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.thread.comments_count) + " ", 1),
            (_ctx.thread.comments_count === 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Comment"))
              : _createCommentVNode("", true),
            (_ctx.thread.comments_count > 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Comments"))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thread.comments, (comment) => {
            return (_openBlock(), _createElementBlock("div", {
              key: comment.id,
              class: "comments"
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_CommentComponent, {
                  comment: comment,
                  onReplyShow: _ctx.showReply
                }, null, 8, ["comment", "onReplyShow"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(comment.replies, (reply) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: reply.id,
                  class: "comments__reply"
                }, [
                  _createVNode(_component_CommentComponent, {
                    comment: reply,
                    onReplyShow: _ctx.showReply
                  }, null, 8, ["comment", "onReplyShow"])
                ]))
              }), 128)),
              (_ctx.isShowReply(comment))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_CommentReply, {
                      doc: _ctx.doc,
                      thread: _ctx.thread,
                      comment: comment,
                      onReplyPosted: _ctx.hideReply
                    }, null, 8, ["doc", "thread", "comment", "onReplyPosted"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}