import { ComponentPublicInstance } from 'vue'

export function formatNumber(num: number): string {
  return (Math.round(num * 100) / 100).toFixed(2)
}

export function authCssFix(): void {
  // Auth pages were developed in terms of the public pages reset system,
  // we have adjustments in the assets.app/css/custom/_sign-up.scss,
  // here we also set body font size, so rem sizes work properly.
  document.documentElement.style.fontSize = '9.5px'
}

export function setText(
  vm: ComponentPublicInstance,
  selector: string,
  text: string,
): void {
  const element = vm.$el.querySelector(selector) as HTMLElement
  if (!element) {
    throw Error(`Element not found by selector ${selector}`)
  }
  element.innerText = text
}
