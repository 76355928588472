import { DocsView, DocSortOption } from './doc'
import { capitalizeTagFirstLetter, truncateSearchTerm } from '@/helpers/text'
import { CollectionListItem, DocGroupItem } from './interfaces'

/**
 * Listing page route names.
 *
 * How to add a new listing page:
 * - Add the route name to this enum.
 * - Add Listing page configuration to the ``ListingPages`` const.
 * - Add mapping between route name and user-friendly title to the
 *   ``pageTypeToTitleMap`` object.
 */
export enum PageType {
  all = 'all',
  popular = 'popular',
  new = 'new',
  favorites = 'favorites',
  reading = 'reading',
  finished = 'finished',
  downloads = 'downloads',
  free = 'free',
  recommended = 'recommended',
  // Special types
  category = 'category',
  search = 'search',
}

export enum ArticlesPageType {
  all = 'all',
  favorites = 'favorites',
  viewed = 'viewed',
}

export enum PodcastsPageType {
  favorites = 'favorites',
  reading = 'reading',
  finished = 'finished',
}

type initBooksViewCallback = (view: DocsView) => Promise<void>

export interface ListingView {
  get pageTitle(): string
  get filterTitle(): string
  get isHeaderCollapsed(): boolean
  set isHeaderCollapsed(value: boolean)
  get dataLength(): number
  setShowLimit(limit: number): void
}

export class ListingPage {
  private _title: string
  private _init?: initBooksViewCallback
  private _descriptionView?: DescriptionView

  public _isHeaderCollapsed: boolean = false

  constructor(title: string, init?: initBooksViewCallback) {
    this._title = title
    this._init = init
  }

  public async init(view: DocsView): Promise<void> {
    if (this._init) {
      await this._init(view)
    }
  }

  public initFromCollection(collection: CollectionListItem): void {
    this._title = collection.title
    this._descriptionView = new DescriptionView(
      collection.title,
      collection.blurb,
      collection.doc_count,
      collection.cover_image,
    )
  }

  public initFromPodcast(podcast: DocGroupItem): void {
    this._title = podcast.title
    this._descriptionView = new DescriptionView(
      podcast.title,
      podcast.blurb,
      podcast.docs.data.length.toString(),
      podcast.cover_image,
      podcast.author,
      podcast.original_url,
    )
  }

  public get pageTitle(): string {
    return this._title
  }

  public get descriptionView(): DescriptionView | undefined {
    return this._descriptionView
  }
}

const booksAll = new ListingPage('All Books')

const newReleases = new ListingPage(
  'New Releases',
  async (view: DocsView): Promise<void> => {
    view.sort(DocSortOption.first_published_at)
    view.setDocsLimit(20)
  },
)

const popular = new ListingPage(
  'Popular',
  async (view: DocsView): Promise<void> => {
    view.sort(DocSortOption.popular)
    view.setDocsLimit(20)
  },
)

const favorites = new ListingPage(
  'Favorites',
  async (view: DocsView): Promise<void> => {
    view.enableActionsButton()
    view.setIsFavorite(true)
  },
)

const articleFavorites = new ListingPage(
  'Favorites',
  async (view: DocsView): Promise<void> => {
    view.setIsFavorite(true)
  },
)

const articleViewed = new ListingPage(
  'Viewed',
  async (view: DocsView): Promise<void> => {
    view.enableContinueReading()
    view.enableActionsButton()
    view.setList('viewed')
    view.sort(DocSortOption.accessed)
  },
)

const reading = new ListingPage(
  'Reading Now',
  async (view: DocsView): Promise<void> => {
    view.enableContinueReading()
    view.enableActionsButton()
    view.setList('reading')
    view.sort(DocSortOption.accessed)
  },
)

const finished = new ListingPage(
  'Finished',
  async (view: DocsView): Promise<void> => {
    view.enableActionsButton()
    view.setList('finished')
  },
)

const downloads = new ListingPage(
  'Downloads',
  async (view: DocsView): Promise<void> => {
    await view.filterByDownloads()
  },
)

const free = new ListingPage('Free', async (view: DocsView): Promise<void> => {
  view.filterByFree()
})

const recommended = new ListingPage(
  'Recommended',
  async (view: DocsView): Promise<void> => {
    await view.sort(DocSortOption.recommended)
    view.setDocsLimit(20)
  },
)

const podcastEpisodesFavorites = new ListingPage(
  'Favorites',
  async (view: DocsView): Promise<void> => {
    view.enableActionsButton()
    view.setIsFavorite(true)
    view.sort(DocSortOption.accessed)
  },
)

const podcastEpisodesReading = new ListingPage(
  'Reading Now',
  async (view: DocsView): Promise<void> => {
    view.enableContinueReading()
    view.enableActionsButton()
    view.setList('reading')
    view.sort(DocSortOption.accessed)
  },
)

const podcastEpisodesFinished = new ListingPage(
  'Finished',
  async (view: DocsView): Promise<void> => {
    view.enableActionsButton()
    view.setList('finished')
    view.sort(DocSortOption.accessed)
  },
)

const articlesAll = new ListingPage('All Articles')

export function listingPageByType(pageType: PageType): ListingPage {
  switch (pageType) {
    case PageType.all:
      return booksAll
    case PageType.new:
      return newReleases
    case PageType.popular:
      return popular
    case PageType.favorites:
      return favorites
    case PageType.reading:
      return reading
    case PageType.finished:
      return finished
    case PageType.downloads:
      return downloads
    case PageType.free:
      return free
    case PageType.recommended:
      return recommended
  }
  throw new Error('Unexpected page type: ' + pageType)
}

/**
 * Prepare books special listing page.
 *
 * We get the pageType parameter from the current route, it could be
 * one of the options from PageType enum.
 * According to the option get from the route, we prepare the books
 * listing and the page title to be used on the listing page.
 */
export function createListingPage(pageType: PageType): ListingPage {
  return listingPageByType(pageType)
}

/**
 * Prepare articles special listing page.
 *
 * Logic is the same as in the books method above.
 */
export function createArticlesListingPage(
  pageType: ArticlesPageType,
): ListingPage {
  if (pageType === ArticlesPageType.all) {
    return articlesAll
  }
  if (pageType === ArticlesPageType.favorites) {
    return articleFavorites
  }

  if (pageType === ArticlesPageType.viewed) {
    return articleViewed
  }

  throw new Error('Unexpected page type: ' + pageType)
}

/**
 * Prepare podcasts special listing page.
 *
 * Logic is the same as in the books method above.
 */
export function createPodcastsListingPage(
  pageType: PodcastsPageType,
): ListingPage {
  if (pageType === PodcastsPageType.favorites) {
    return podcastEpisodesFavorites
  }

  if (pageType === PodcastsPageType.reading) {
    return podcastEpisodesReading
  }

  if (pageType === PodcastsPageType.finished) {
    return podcastEpisodesFinished
  }

  throw new Error('Unexpected page type: ' + pageType)
}

/**
 * Prepare Books category page.
 *
 * We use the tag parameter to set the page title, and also to filter the view
 * (by using ``setTag``).
 */
export function createBooksCategoryPage(tag: string): ListingPage {
  const page = new ListingPage(
    capitalizeTagFirstLetter(tag),
    async (view: DocsView): Promise<void> => {
      view.setTag(tag)
    },
  )
  return page
}

/**
 * Prepare Books collection page.
 *
 */
export function createBooksCollectionPage(
  collectionTitle: string,
): ListingPage {
  const page = new ListingPage(
    capitalizeTagFirstLetter(collectionTitle),
    async (view: DocsView): Promise<void> => {
      // Hide the sorting popup on the collections listing page
      view.setDocsLimit(100)
    },
  )
  return page
}

/**
 * Prepare Podcast page.
 *
 */
export function createPodcastPage(podcastTitle: string): ListingPage {
  const page = new ListingPage(
    capitalizeTagFirstLetter(podcastTitle),
    async (view: DocsView): Promise<void> => {
      // Hide the sorting popup on the collections listing page
      view.setDocsLimit(100)
    },
  )
  return page
}

/**
 * Prepare Articles category page.
 *
 * We use the tag parameter to set the page title, and also to filter the view
 * (by using ``setTag``).
 */
export function createArticlesCategoryPage(tag: string): ListingPage {
  const page = new ListingPage(
    capitalizeTagFirstLetter(tag),
    async (view: DocsView): Promise<void> => {
      view.setTag(tag)
    },
  )
  return page
}

/**
 * Prepare books/articles search results page.
 * @param term - search term
 *
 * Looks like the flexsearch library works just perfect with URL encoded
 * search terms, so we don't decode the search term before filtering with it.
 *
 * If the search term is too long (longer than 30 chars), we truncate it before
 * setting the page title.
 */
export function createSearchPage(term: string): ListingPage {
  const title = `Search results for "${truncateSearchTerm(term)}"`
  return new ListingPage(title, async (view: DocsView): Promise<void> => {
    view.filter(term)
    view.sort(DocSortOption.popular)
  })
}

export class DescriptionView {
  title: string
  fullText: string
  showFullText: boolean
  docsNumber: string
  imageUrl: string
  author: string | undefined
  originalUrl: string | undefined
  constructor(
    title: string,
    fullText: string,
    docsNumber: string,
    imageUrl: string,
    author?: string | undefined,
    originalUrl?: string | undefined,
  ) {
    this.title = title
    this.fullText = fullText
    this.showFullText = !this.hasLongText
    this.docsNumber = `${docsNumber} books`
    this.imageUrl = imageUrl
    this.author = author
    this.originalUrl = originalUrl
  }

  get hasLongText(): boolean {
    return this.fullText.length > 500
  }

  get shortText(): string {
    return this.fullText.substring(0, 500) + '...'
  }

  get displayedText(): string {
    return this.showFullText ? this.fullText : this.shortText
  }

  toggleShowFullText(): void {
    this.showFullText = !this.showFullText
  }
}
