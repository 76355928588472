import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  type: "submit",
  class: "btns btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsernameInput = _resolveComponent("UsernameInput")!
  const _component_InputWithContextMenu = _resolveComponent("InputWithContextMenu")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_button_spinner = _resolveComponent("button-spinner")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _directive_wait = _resolveDirective("wait")!

  return (_openBlock(), _createElementBlock("form", {
    class: "reply-form form",
    novalidate: "",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createVNode(_component_UsernameInput, {
      model: _ctx.model,
      validation: _ctx.validation
    }, null, 8, ["model", "validation"]),
    _createVNode(_component_InputWithContextMenu, {
      is: "textarea",
      ref: "input",
      modelValue: _ctx.model.text,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.text) = $event)),
      rows: "1",
      type: "text",
      class: _normalizeClass(["form-control reply-form__text", _ctx.validation.stateClass('text')]),
      placeholder: "Write a reply..."
    }, null, 8, ["modelValue", "class"]),
    _createVNode(_component_FormError, {
      errors: _ctx.validation.errors,
      field: "text"
    }, null, 8, ["errors"]),
    _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_1, [
      _createVNode(_component_v_wait, { for: "Posting Reply" }, {
        waiting: _withCtx(() => [
          _createTextVNode(" Posting "),
          _createVNode(_component_button_spinner)
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Post Reply ")
        ]),
        _: 1
      })
    ])), [
      [_directive_wait, 'Posting Reply', "disabled"]
    ])
  ], 32))
}