
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocTocView } from '@/models/doc.toc'
import { BookNativeStorageView } from '@/models/book.native.storage.view'
import { DocView } from '@/models/doc'

import NativeStorageDownloadButton from '@/app/components/doc/NativeStorageDownloadButton.vue'
import BackLink from '@/app/components/BackLink.vue'

@Options({
  components: {
    NativeStorageDownloadButton: NativeStorageDownloadButton,
    BackLink,
  },
})
export default class DocSidebarTOC extends Vue {
  @Prop() private doc!: DocView

  private view: DocTocView | null = null
  private nativeStorageView: BookNativeStorageView | null = null

  async beforeMount(): Promise<void> {
    this.view = new DocTocView(this.doc)
    await this.view.init()
    this.nativeStorageView = await BookNativeStorageView.initialize(this.doc)
  }

  get previewPage(): Record<string, any> {
    return {
      name: this.doc.routeNames().preview,
      params: {
        url_slug: this.doc.url_slug,
      },
    }
  }
}
