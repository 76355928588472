<template>
  <div class="skeleton-tag"></div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/variables.scss';
@import '@/assets.app/css/common/animations.scss';

.skeleton-tag {
  width: 100%;
  height: 114px;
  border-radius: 8px;
  animation: skeleton-loading 1s linear infinite alternate;
}

.night-mode .skeleton-tag {
  animation: skeleton-loading-night-mode 1s linear infinite alternate;
}
</style>
