import { AudioDoc } from './logic'
import { storage as localStorageWrapper } from '@/helpers/storage'

/**
 *  Audio volume UI control.
 */
export class VolumeControl {
  private _audio: AudioDoc

  // Current volume value.
  private _volume: number = 1
  // Saved volume value when we mute the audio,
  // we use it to restore the original volume when unmuting.
  private _unmuteVolume: number = 1

  constructor(audio: AudioDoc) {
    this._audio = audio

    const value = localStorageWrapper.getItem('sf_player_volume')
    if (value) {
      this._volume = parseFloat(value)
    }
    this._audio.volume = this._volume
  }

  /**
   * Init event listeners.
   *
   * We can not do this in the constructor as Vue 3 reactivity system
   * is not initialized and `this` is not reactive yet.
   * See #4824.
   */
  public initEventListeners(): void {
    this._audio.addListener('volumechange', () => {
      this._volumeUpdateHandler()
    })
  }

  /**
   * Get current volume value (floating point value between 0 and 1).
   */
  get value(): number {
    return this._volume
  }

  /**
   * Mute the audio.
   *
   * Saves current volume value to restore later.
   *
   * Note: we don't save the "muted" state to local storage,
   * so if we reload the page, the audio will be unmuted with
   * the same volume as before muting it (as we store the volume
   * into local storage).
   */
  mute(): void {
    this._unmuteVolume = this._audio.volume
    this._audio.volume = 0
  }

  /**
   * Unmute the audio.
   *
   * Restores the audio volume when unmuting.
   */
  unmute(): void {
    this._audio.volume = this._unmuteVolume
  }

  /**
   * Reset the volume to 1 (100%).
   */
  reset(): void {
    this._audio.volume = 1
  }

  /**
   * Increase the volume by 10%.
   */
  up(): void {
    this._audio.volume += 0.1
  }

  /**
   * Decrease the volume by 10%.
   */
  down(): void {
    this._audio.volume -= 0.1
  }

  /**
   * Get volume bar CSS style.
   *
   * Sets the width in percents accordingly to the current volume value.
   */
  get style(): Record<string, string> {
    const width = this._volume * 100
    const style = {
      width: `${width}%`,
    }
    return style
  }

  /**
   * Handle volume bar click.
   *
   * Sets the volume accordingly to the drag position.
   */
  change(percent: number): void {
    this._audio.volume = percent
  }

  /**
   * Handle audio 'volumechange' event.
   *
   * Update internal volume value and save it to local storage.
   */
  _volumeUpdateHandler(): void {
    if (this._volume !== this._audio.volume) {
      this._volume = this._audio.volume
    }
    // We don't save the muted state (volume = 0).
    if (this._audio.volume > 0) {
      localStorageWrapper.setItem(
        'sf_player_volume',
        this._audio.volume.toString(),
      )
    }
  }
}
