
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { DocsTagsView as CategoriesView } from '@/models/docs.tags'

export default class CategoryItem extends Vue {
  @Prop() private tag!: string
  @Prop() private view!: CategoriesView

  @Prop({ default: false }) private isMobile!: boolean
  @Prop({ default: 0 }) private itemIndex!: number

  get showItem(): boolean {
    if (!this.isMobile) {
      return this.view.showAllTags || this.itemIndex < 6
    }

    return true
  }
}
