<template>
  <div>
    <div class="player player--disabled">
      <div class="container">
        <div class="player__disabled-info">
          <i class="player__info-icon iconfont itips"></i>
          <span>Audio narrations are available to subscribers only</span>
        </div>
        <div class="player__content">
          <div class="player__control">
            <div class="player-volume--pc">
              <span class="player-volume__icon iconfont iplayer-volume"></span>
            </div>
            <div class="player-play">
              <a
                class="player-play__link player-play__link--backward"
                disabled="disabled"
              >
                <span class="iconfont iplayer-reduce"></span>
              </a>
              <a
                class="player-play__link player-play__link--prev"
                disabled="disabled"
              >
                <span class="iconfont iplayer-prev"></span>
              </a>
              <a class="player-play__link player-play__link--play">
                <span class="iconfont iplayer-play"></span>
              </a>
              <a
                class="player-play__link player-play__link--next"
                disabled="disabled"
              >
                <span class="iconfont iplayer-next"></span>
              </a>
              <a class="player-play__link player-play__link--forward">
                <span class="iconfont iplayer-add"></span>
              </a>
            </div>
            <div class="player-speed--pc">
              <button class="player-speed__btn player-speed__btn--down">
                <span class="iconfont iplayer-slow"></span>
              </button>
              <span class="player-speed__text"> 1.1x </span>
              <button class="player-speed__btn player-speed__btn--up">
                <span class="iconfont iplayer-fast"></span>
              </button>
            </div>
          </div>
          <div class="player-time">
            <p class="player-time__num player-time__num--pos">0:30</p>
            <div class="player-time__progress-bar">
              <div
                class="player-time__progress-bar-now"
                style="width: 10%"
              ></div>
            </div>
            <p class="player-time__num player-time__num--duration">5:30</p>
          </div>
          <div class="player__control-m">
            <div class="player-volume">
              <span class="player-volume__icon iconfont iplayer-volume"></span>
            </div>
            <div class="player-speed">
              <button class="player-speed__btn player-speed__btn--down">
                <span class="iconfont iplayer-slow"></span>
              </button>
              <span class="player-speed__text"> 1.1x </span>
              <button class="player-speed__btn player-speed__btn--up">
                <span class="iconfont iplayer-fast"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
