import { DocListItem } from './interfaces'
import { backend } from '@/services/backend'
import { waitTimer } from '@/helpers/timer'
import { resetCacheByListItemType } from '@/helpers/cache'

/**
 * Mark the document as favorite / unfavorite.
 */
export async function toggleDocFavorite(doc: DocListItem): Promise<void> {
  let result
  if (doc.is_favorite) {
    result = await backend.unfavorite(doc)
  } else {
    result = await backend.favorite(doc)
  }
  doc.is_favorite = result.is_favorite
  // Reset cache to force fresh data loading.
  // Otherwise, we mark the book as favorite on the books page, for example,
  // then open the home page and the book is not marked as favorite (additional
  // page refresh is needed to see the favorite mark).
  await resetCacheByListItemType(doc.doc_type)
}

export class DocFavoriteView {
  private doc: DocListItem

  public buttonDisabled: boolean = false
  public hint: 'none' | 'adding' | 'removing' = 'none'

  constructor(doc: DocListItem) {
    this.doc = doc
  }

  async favoriteToggle($event: Event): Promise<void> {
    $event.preventDefault()
    $event.stopImmediatePropagation()

    this.hint = 'none'
    this.buttonDisabled = true

    if (this.doc.is_favorite) {
      this.hint = 'removing'
    } else {
      this.hint = 'adding'
    }
    setTimeout(() => (this.hint = 'none'), 2000)

    await Promise.all([toggleDocFavorite(this.doc), waitTimer(500)])
    this.buttonDisabled = false
  }
}
