
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { ArticlesView, ArticleView } from '@/models/article'

import { cacheBust } from '@/models/doc'

import FavoriteFlag from '@/app/components/listing/FavoriteFlag.vue'

@Options({
  components: {
    FavoriteFlag,
  },
})
export default class ArticleCard extends Vue {
  @Prop() private view!: ArticlesView
  @Prop() private doc!: ArticleView

  private cacheBust = cacheBust

  get isCTA(): boolean {
    return this.doc.title === 'CTA'
  }
}
