<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
    <path
      d="M16.795 42.597a24.023 24.023 0 0 1-4.362-13.835c0-6.667 5.402-12.071 12.065-12.071 6.663 0 12.064 5.404 12.064 12.07"
      stroke="#fff"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M37.216 40.8c-.217.012-.43.032-.65.032-6.662 0-12.064-5.403-12.064-12.07"
      stroke="#fff"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M27.008 44.136c-5.126-3.197-8.54-8.886-8.54-15.374a6.035 6.035 0 1 1 12.067 0 6.034 6.034 0 1 0 12.067 0c0-10-8.104-18.106-18.098-18.106-9.995 0-18.098 8.105-18.098 18.106 0 2.229.25 4.4.708 6.492"
      stroke="#fff"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.345 12.253C37.23 7.58 31.215 4.62 24.5 4.62c-6.715 0-12.73 2.96-16.845 7.633"
      stroke="#fff"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
