<template>
  <div class="no-results-found">
    <div class="container">
      <div class="no-results-found__content">
        <div class="no-results-found__content-c">
          <img
            class="no-results-found__img"
            src="@/assets.app/images/no-results.svg"
            alt=""
          />
          <h2 class="no-results-found__title">No results found...</h2>
          <p class="no-results-found__text">
            Highlights and notes you add while reading will appear here.
          </p>
          <router-link
            class="highlight-view__chapter-link"
            :to="{ name: 'books' }"
            >Choose a book to start reading!
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
