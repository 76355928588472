import { AudioDoc } from './logic'
import { DocView } from '@/models/doc'

/**
 * Progress bar, current time and chapter duration UI control.
 *
 * The unified logic for book and chapter progress.
 * Specific details for book and chapter are represented by
 * `BookProgress` and `ChapterProgress` accordingly.
 */
export class ChapterProgressControl {
  private _audio: AudioDoc
  private _doc: DocView

  // Playback position in seconds inside the current chapter.
  private _position: number = 0
  // Current chapter duration in seconds.
  private _duration: number = 0
  // Chapter progress in percents.
  private _progress: number = 0

  // We update the reading progress (the same one that is set when scrolling)
  // approximately every 3 seconds of audio listening. This variable
  // tracks the last time that we sent an update in order to avoid sending
  // one again too soon, as `timeUpdateHandler` triggers too frequently
  private _timeOfLastProgressUpdate: Date = new Date(0)

  // Used for rejecting false initial update events when updating reading %
  private _haveSeenRealProgressUpdates = false

  public _updateCallback: any = null

  public _hasMetadata: boolean = false

  constructor(audio: AudioDoc, doc: DocView) {
    this._audio = audio
    this._doc = doc

    const eventHandler = (): void => {
      this._timeUpdateHandler()
    }

    this._audio.addListener('timeupdate', eventHandler)
    // The loadedmetadata handler is needed to update audio file duration
    // when it becomes known.
    this._audio.addListener('loadedmetadata', () => {
      this._hasMetadata = true
      this._timeUpdateHandler()
    })

    // Update durations on playback rate change.
    this._audio.addListener('ratechange', eventHandler)
  }

  /**
   * Handle audio `timeupdate' event.
   *
   * This is used to track and display current playback position
   * (text value, book and chapter progress bars).
   */
  _timeUpdateHandler(): void {
    this._updateProgress()
  }

  /**
   * Playback position within current chapter, seconds.
   */
  get position(): string {
    if (this._hasMetadata) {
      return this._formatTime(this._position)
    }
    return '0:00'
  }

  /**
   * Current chapter position as number.
   */
  get positionInPercent(): number {
    return Math.round((100 / this.durationInSeconds) * this._position)
  }

  /**
   * Current chapter duration, seconds.
   */
  get duration(): string {
    if (this._hasMetadata) {
      return this._formatTime(this._duration)
    }
    return '0:00'
  }

  /**
   * Current chapter duration as number.
   */
  get durationInSeconds(): number {
    return this._duration
  }

  /**
   * Handles the upated progress bar/slider click/drag.
   *
   * We get the click position inside the `progressBar` and
   * then set the audio position accordingly.
   */
  inputChange(percent: number): void {
    const seconds = percent * (this._duration / 100) * this._audio.playbackRate
    this._audio.currentTime = this._audio.chapters.current().start + seconds
    this._updateProgress()
  }

  /**
   * Update current position, duration and progress value.
   *
   * Called in response to the `timeupdate` audio event and from
   * the `seek` method, when we click the progress bar.
   */
  _updateProgress(): void {
    // We call chapters.update in ChapterPrevNextControl,
    // it also has the "timeupdate" event handler.
    // this._chapters.update(this._audio.currentTime)

    // Because of a WebKit bug (see https://github.com/shortformhq/main/issues/5415#issuecomment-1127806210),
    // we can end up in a situation where this._calculatePosition() is a negative value.
    // We don't want that, so we cap the lower bound at 0.
    this._position = Math.max(
      0,
      this._calculatePosition() / this._audio.playbackRate,
    )
    this._duration = this._calculateDuration() / this._audio.playbackRate

    if (this._audio.duration) {
      this._progress = (this._position / this._duration) * 100
    }

    this._updateReadingProgress()

    if (this._updateCallback) {
      this._updateCallback()
    }
  }

  /**
   * Update reading progress on the backend based on audio playback percentage.
   */
  _updateReadingProgress(): void {
    if (!this._haveSeenRealProgressUpdates && this._progress < 0.0001) {
      // Because `this._progress` is initialised to 0, we often get 1 to 3
      // spurious initial updates of 0% when the audio player component mounts.
      //
      // If the audio is actually already in progress that will be quickly
      // corrected in the next tick.
      //
      // If the reader is truly on 0% progress, it doesn't matter, that's
      // the default, so no need to update the reading progress.
      //
      // On the other hand if the audio is actually in progress (or reading
      // progress exists from the page scroll position), we don't want to
      // incorrectly set reading progress to 0.
      //
      // Therefore we just ignore updates matching that pattern.
      return
    }
    this._haveSeenRealProgressUpdates = true
    const now = new Date()
    const threeSecondsAgo = new Date(now.getTime() - 3000)
    const needToUpdate = threeSecondsAgo > this._timeOfLastProgressUpdate
    if (needToUpdate) {
      this._timeOfLastProgressUpdate = now
      this._doc.saveReadingPosition(Math.round(this._progress))
    }
  }

  /**
   * Playback position in seconds for the current chapter.
   *
   * We calculate it as audio `currentTime` minus chapter start time.
   * So if audio current time is 3 minutes and chapter starts at 2-nd minute,
   * we will have 1 minute progress inside the chapter.
   */
  _calculatePosition(): number {
    if (this._audio.currentTime > this._audio.chapters.current().end) {
      this._audio.currentTime = this._audio.chapters.current().end
    }
    if (this._audio.currentTime === 0) {
      // We set it in the `ChapterPrevNextControl._canplayHandler` - we will either restore the
      // audio position to some place inside the chapter or set it to the start of the chapter.
      // While audio time is not set yet, we return 0 here; otherwise, we get a negative value for
      // a brief time while audio is loading and player is initializing.
      return 0
    }
    return this._audio.currentTime - this._audio.chapters.current().start
  }

  /**
   * Current chapter duration in seconds.
   */
  _calculateDuration(): number {
    let end = this._audio.chapters.current().end
    if (end === Infinity) {
      // The audio duration is not avaialble when we initialize chapters,
      // so the `end` for the last chapter is set to Infinity
      // (it is OK for comparisons where we dectect which chapter is playing now,
      // but not good to calculate the chapter duration).
      end = this._audio.duration
    }
    return end - this._audio.chapters.current().start
  }

  /**
   * Format time from seconds to HH:MM:SS format.
   */
  _formatTime(seconds: number): string {
    const hours = Math.floor(seconds / (60 * 60))
    const reminder = seconds % (60 * 60)

    const minutes = Math.floor(reminder / 60)
    const sec = Math.floor(reminder % 60)
    const secStr = sec > 9 ? sec.toString() : '0' + sec.toString()

    if (hours) {
      return `${hours}:${minutes}:${secStr}`
    }
    return `${minutes}:${secStr}`
  }
}
