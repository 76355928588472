
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ContinueReadingCard from '@/app/components/book/card/ContinueReadingCard.vue'
import ListingBlock from '@/app/components/listing/ListingBlock.vue'

import { BooksView } from '@/models/book'
import { PageType, createListingPage } from '@/models/docs.listing'

@Options({
  components: {
    ContinueReadingCard,
    ListingBlock,
  },
})
export default class ContinueReadingBooksBlock extends Vue {
  @Prop() private view!: BooksView
  @Prop() private viewAllLink!: string

  async beforeMount(): Promise<void> {
    const page = createListingPage(PageType.reading)
    page.init(this.view)
  }
}
