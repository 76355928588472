
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import { createPodcastPage, ListingPage } from '@/models/docs.listing'
import PodcastsBaseListingPage from '@/app/components/listing/PodcastsBaseListingPage.vue'

@Options({
  components: {
    PodcastsBaseListingPage,
  },
})
export default class PodcastPage extends Vue {
  listingPage: ListingPage | null = null

  async beforeMount(): Promise<void> {
    this.listingPage = createPodcastPage(this.podcast)
  }

  get podcast(): string {
    return this.$route.params.url_slug as string
  }
}
