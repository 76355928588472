
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { wait } from '@/helpers/vue-wait'
import { BillingView } from '@/models/billing'

import PlanInfoMonthly from './BillingDataPaid.PlanInfo.Monthly.vue'
import PlanInfoAnnual from './BillingDataPaid.PlanInfo.Annual.vue'
import PaymentInfo from './BillingDataPaid.PaymentInfo.vue'
import CancelPopup from './CancelPopup.vue'
import InfoFAQ from './InfoFAQ.vue'
import InfoQuestions from './InfoQuestions.vue'
import InfoDeleteAccount from '@/app/components/billing/InfoDeleteAccount.vue'

import BillingNotifications from './BillingNotifications.vue'

@Options({
  components: {
    BillingNotifications,
    PlanInfoMonthly,
    PlanInfoAnnual,
    PaymentInfo,
    CancelPopup,
    InfoFAQ,
    InfoQuestions,
    InfoDeleteAccount,
  },
})
export default class BillingDataPaid extends Vue {
  @Prop() protected billing!: BillingView

  cancelClick(): void {
    ;(this.$refs.popupCancel as any).show()
  }

  async reactivate(): Promise<void> {
    try {
      wait.start(this, 'Subscribing')
      await this.billing.subscribeTo(this.billing.plan)
      this.$emit('subscribed')
      wait.end(this, 'Subscribing')
    } catch (error) {
      wait.end(this, 'Subscribing')
    }
  }
}
