'use strict'

import { assertNotNull } from '@/helpers/typing'
import { isIOS } from '@/helpers/userAgent'

const ESCAPE_MAP = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#47;',
}

// The escapeHtml sanitizes special characters in text that could be interpreted as
// HTML.
export function escapeHtml(string: string): string {
  /* eslint-disable no-useless-escape */
  return String(string).replace(/[&<>"'\/]/g, function (c) {
    // @ts-ignore
    return ESCAPE_MAP[c]
  })
  /* eslint-enable no-useless-escape */
}

// I18N
export const gettext = (function () {
  // @ts-ignore
  if (typeof global.Gettext === 'function') {
    // @ts-ignore
    const _gettext = new global.Gettext({ domain: 'annotator' })
    return function (msgid: any) {
      return _gettext.gettext(msgid)
    }
  }

  return function (msgid: any) {
    return msgid
  }
})()

/**
 * Get the middle point (in the x-axis) between an array of highlight elements.
 *
 * This function is used to center the popover menu horizontally.
 *
 * @param highlights - An array of highlight elements.
 * @param containerSelector - A CSS selector for the annotator container.
 * @returns the horizontal center between all highlight elements.
 */
export function getHighlightsMiddlePoint(
  highlights: Element[],
  containerSelector: string,
): number {
  let furthestPointToTheLeft = 0
  let furthestPointToTheRight = 0

  highlights.forEach((highlight: Element, index: number) => {
    const highlightRect = highlight.getBoundingClientRect()

    if (furthestPointToTheLeft > highlightRect.left || index === 0) {
      furthestPointToTheLeft = highlightRect.left
    }
    if (highlightRect.right > furthestPointToTheRight) {
      furthestPointToTheRight = highlightRect.right
    }
  })

  const container = assertNotNull(document.querySelector(containerSelector))
  const containerRect = container.getBoundingClientRect()

  const highlightStartPosition = furthestPointToTheLeft - containerRect.left

  return (
    highlightStartPosition +
    (furthestPointToTheRight - furthestPointToTheLeft) / 2
  )
}

/**
 * Get the position of a selection relative to the given container.
 *
 * This function is used to get the position to render the annotator widget while
 * selecting text in the annotator.
 *
 * @param containerSelector - A CSS selector for the annotator container.
 * @returns {top: number, bottom: number, middle: number} - `top` and `bottom` are
 * coordinates to the upper and lower points of the selection, and `middle` is the
 * horizontal center of the selection. All relative to the given container.
 */
export function getSelectionCoords(
  containerSelector: string,
): Record<string, number> {
  const selection = assertNotNull(window.getSelection())
  const range = selection.getRangeAt(0)
  const container = assertNotNull(document.querySelector(containerSelector))

  const selectionRect = range.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  const selectionHorizontalCenter =
    selectionRect.left - containerRect.left + selectionRect.width / 2

  // The selection rect is a little shorter then the highlighted text elements
  // because it doesn't account for the highlighted text elements' padding, so
  // to the widget render at the correct position, we have to compensate for
  // that difference here.
  const selectionHeightCompensation = 8

  // On iOS, selecting text opens the native context menu, and we can't override it,
  // so we have to compensate for that.
  const iosHeightCompensation = isIOS() ? 55 : 0

  return {
    top:
      selectionRect.top - selectionHeightCompensation - iosHeightCompensation,
    bottom:
      selectionRect.bottom +
      selectionHeightCompensation +
      iosHeightCompensation,
    middle: selectionHorizontalCenter,
  }
}

/**
 * Get the position of the given annotation.
 *
 * This function is used to get the position to render the annotator widget while
 * hovering over an annotation.
 *
 * @param highlightElements - The HTML Elements that make up the annotation.
 * @param containerSelector - The CSS selector for the Annotator container.
 * @returns {top: number, bottom: number, middle: number} - `top` and `bottom` are
 * coordinates to the upper and lower points of the highlight, and `middle` is the
 * horizontal center of the highlight, relative to the container.
 */
export function getHighlightCoords(
  highlightElements: HTMLElement[],
  containerSelector: string,
): Record<string, number> {
  // The annotation may consist of multiple elements. Getting its
  // vertical position requires getting the top position of the first
  // element, and the bottom position of the last element.
  const annotationTopRect = highlightElements[0].getBoundingClientRect()
  const annotationBottomRect =
    highlightElements[highlightElements.length - 1].getBoundingClientRect()

  return {
    top: annotationTopRect.top,
    bottom: annotationBottomRect.bottom,
    middle: getHighlightsMiddlePoint(highlightElements, containerSelector),
  }
}

/**
 * Stores data with reference to an html element.
 *
 * A storage solution aimed at replacing jQuerys `$(el).data()` function.
 * Implementation Note: Elements are stored in a (WeakMap)[https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap].
 * This makes sure the data is garbage collected when the node is removed.
 * See: https://stackoverflow.com/a/46522991
 */
export const elementData = {
  _storage: new WeakMap<HTMLElement, Map<string, object>>(),

  _get: function (element: HTMLElement): Map<string, object> {
    return assertNotNull(this._storage.get(element))
  },

  set: function (element: HTMLElement, key: string, obj: object): void {
    if (!this._storage.has(element)) {
      this._storage.set(element, new Map())
    }
    this._get(element).set(key, obj)
  },

  get: function (element: HTMLElement, key: string): object | null {
    if (this.has(element, key) !== false) {
      return this._get(element).get(key) || null
    }
    return null
  },

  has: function (element: HTMLElement, key: string): boolean {
    return this._storage.has(element) && this._get(element).has(key)
  },

  remove: function (element: HTMLElement, key: string): boolean {
    if (this.has(element, key) === false) {
      return false
    }

    const wasKeyRemoved = this._get(element).delete(key)
    if (this._get(element).size === 0) {
      this._storage.delete(element)
    }
    return wasKeyRemoved
  },
}

/**
 * Returns the previous sibling of the given element if matches the given selector.
 * This is a solution for replacing jQuery's `prev(selector)` method.
 */
export function selectPreviousMatchingSibling(
  element: HTMLElement,
  selector: string,
): HTMLElement | null {
  const previousSibling = element.previousElementSibling as HTMLElement

  if (previousSibling && previousSibling.matches(selector)) {
    return previousSibling
  }

  return null
}

/**
 * Returns an array with all the ancestors of the given element that match the given selector.
 * The `includeSelf` parameter can be used to include the given element in the returned array.
 *
 * This is a solution for replacing jQuery's `parents()` method. The `includeSelf` parameter
 * is a solution for replacing the `addBack()` method.
 */
export function selectParents(
  element: HTMLElement,
  includeSelf?: boolean,
  selector?: string,
): HTMLElement[] {
  const parents = []
  let currentElement = element.parentNode as HTMLElement

  // @ts-ignore
  while (currentElement && currentElement !== document) {
    if (!selector || currentElement.matches(selector)) {
      parents.push(element)
    }

    currentElement = currentElement.parentNode as HTMLElement
  }

  if (includeSelf === true) {
    parents.push(element)
  }

  return parents
}

/**
 * Adds an event handler to an HTML element.
 *
 * This is a solution for replacing jQuery's `on()` method.
 * The handler references are stored in the `elementData` object, so they can be
 * removed later, with `removeEventHandler()`.
 *
 * @param element - The HTML element to which the event handler will be added.
 * @param eventType - The type of the event to listen for.
 * @param handler - The function to execute when the event is triggered.
 *  It can optionally receive `target`, which represent the `delegatedSelector` element.
 *  It's used to replace `event.currentTarget`, since that would return the element
 *  where the handler was attached to.
 * @param delegatedSelector - Optional. A selector to delegate the event to.
 *  If provided, the handler will be called only when the event is triggered
 *  on an element that matches this selector.
 *  It functions like jQuery's `$(el).on(eventType, delegatedSelector, handler)`.
 */
export function addEventHandler(
  element: HTMLElement,
  eventType: string,
  handler: (e?: Event | MouseEvent, target?: HTMLElement) => void,
  delegatedSelector?: string,
): void {
  let handlerWrapper: (e: Event) => void
  if (delegatedSelector) {
    handlerWrapper = (e: Event) => {
      // @ts-ignore
      // `e.target` could be inside the delegated element, grab the closest
      // element that matches the selector.
      const delegatedElement = e.target?.closest(delegatedSelector)
      if (delegatedElement) {
        handler(e, delegatedElement)
      }
    }
  } else {
    handlerWrapper = (e: Event) => {
      handler(e)
    }
  }

  // Store the handler reference in the element data, so it can be removed later.
  // It's an array because we can have multiple handlers for the same event type.
  const newHandlers = [handlerWrapper]
  const previousHandlers = elementData.get(element, `${eventType}Handler`)

  if (Array.isArray(previousHandlers)) {
    newHandlers.push(...previousHandlers)
  }
  elementData.set(element, `${eventType}Handler`, newHandlers)

  // Attach the event handler to the element.
  element.addEventListener(eventType, handlerWrapper)
}

/**
 * Removes all event handlers of the given type from an HTML element.
 *
 * This is a solution for replacing jQuery's `off()` method.
 * This function removes handlers stored in the `elementData` object with `addEventHandler()`.
 *
 * @param element - The HTML element from which the event handler will be removed.
 * @param eventType - The type of the event to remove.
 */
export function removeEventHandler(
  element: HTMLElement,
  eventType: string,
): void {
  const handlers = elementData.get(
    element,
    `${eventType}Handler`,
  ) as EventListenerOrEventListenerObject[]

  if (handlers) {
    handlers.forEach((handler) => {
      element.removeEventListener(eventType, handler)
    })
  }
}
