import { emitter } from '@/services/mitt'
import { DocListItem } from './interfaces'
import { DocsView, docShareFacebook, docShareTwitter } from './doc'

/**
 * Card action menu logic
 */
export class DocActionMenu {
  private _docsView: DocsView
  private _doc: DocListItem

  private _visible: boolean = false
  private _useTopPosition: boolean = false
  private _prevScrollTop: number = 0

  constructor(docView: DocsView, doc: DocListItem) {
    this._docsView = docView
    this._doc = doc
  }

  /**
   * Check if the action menu is visible or not
   */
  get isVisible(): boolean {
    return this._visible
  }

  /**
   * The position class for menu.
   * Depends on the result from `this.checkButtonPosition`
   */
  get positionClass(): string {
    return this._useTopPosition
      ? 'books__menu-more--position-top'
      : 'books__menu-more--position-bottom'
  }

  /**
   * Show or hide the action menu depending on the current state.
   * @param event The event object passed from the click event
   */
  toggleVisibility(event: PointerEvent): void {
    this._visible ? this.hide() : this.show(event)
  }

  /**
   * Show the action menu
   * @param event The event object passed from the click event
   */
  show(event: PointerEvent): void {
    emitter.emit('showActionMenu')
    this.checkButtonPosition(event.y)
    this._visible = true
  }

  /**
   * Hide the action menu
   */
  hide(): void {
    this._visible = false
  }

  /**
   * Share the book to Facebook
   */
  shareOnFacebook(): void {
    docShareFacebook(this._doc)
    this.hide()
  }

  /**
   * Share the book to Twitter
   */
  shareOnTwitter(): void {
    docShareTwitter(this._doc)
    this.hide()
  }

  /**
   * Move book to another list.
   * @param list The list to move the book to
   */
  moveTo(list: string): void {
    this._docsView.moveTo(this._doc, list)
    this.hide()
  }

  /**
   * Check if the distance from the `y` position of the clicked
   * menu button to the bottom of the window (`window.innerHeight`)
   * is higher than the height of the menu
   * @param position The position of the clicked button
   */
  checkButtonPosition(position: number): void {
    const screenHeight = window.innerHeight
    // This is the computed height of the menu.
    const menuHeight = 250
    const bufferSpace = screenHeight - position

    if (menuHeight < bufferSpace) {
      this._useTopPosition = true
    } else {
      this._useTopPosition = false
    }
  }

  /**
   * Hide the menu on scroll of both `y` directions
   * @param scrollElement
   */
  hideOnScroll(scrollElement: HTMLElement): void {
    const scrollTop = scrollElement.scrollTop

    // Minimum scroll height when we hide the menu
    const scrollTopThreshold = 10

    // When user scrolls more than `scrollTopThreshold` in any direction, hide the menu
    if (
      scrollTop > this._prevScrollTop + scrollTopThreshold ||
      scrollTop < this._prevScrollTop - scrollTopThreshold
    ) {
      this.hide()
      this._prevScrollTop = scrollTop
    }
  }
}
