import mitt from 'mitt'

type Events = {
  appScroll: HTMLElement
  windowResize: undefined
  docContentClicked: undefined
  headerScrollUpdate: undefined
  showActionMenu: undefined
  overlayMenuOpened: undefined
  overlayMenuClosed: undefined
  closeOverlayMenu: undefined
  logoutOfflineFailure: undefined
  openSortPopup: undefined
  toggleNightMode: undefined
  docContentSelectionStarted: undefined
  showBookControlBar: undefined
}

export const emitter = mitt<Events>()
