import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-822d68c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_menu_item = _resolveComponent("app-menu-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.menuItems, (menuItem, index) => {
      return (_openBlock(), _createBlock(_component_app_menu_item, {
        key: index,
        icon: menuItem.icon,
        link: menuItem.link,
        text: menuItem.text,
        isActive: _ctx.view.isItemActive(menuItem)
      }, null, 8, ["icon", "link", "text", "isActive"]))
    }), 128))
  ]))
}