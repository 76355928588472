import { AudioDoc } from './logic'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import * as TypedAssert from 'typed-assert'

// Create an array of events that we use as player state indication.
// We want to be able to iterate over them, which is why we create
// an array first.
// Then we create a union type from that which gives us approx.:
// ```
// PlaybackType = 'play' | 'pause' | ...
// ```
// For reference see:
// See https://dev.to/shakyshane/2-ways-to-create-a-union-from-an-array-in-typescript-1kd6
// See https://www.typescriptlang.org/docs/handbook/2/indexed-access-types.html
const _playbackStates = ['play', 'pause', 'ended'] as const
export type PlaybackState = (typeof _playbackStates)[number]

const _interruptionEvents = ['loadstart', 'stalled', 'waiting'] as const
export type InterruptionEvent = (typeof _interruptionEvents)[number]

const _interruptionResetEvents = [
  'loadeddata',
  'playing',
  'pause',
  'timeupdate',
] as const
export type InterruptionResetEvent = (typeof _interruptionResetEvents)[number]
/**
 * Audio play, pause and forward/back rewind UI control.
 */
export class PlaybackControl {
  private _audio: AudioDoc

  // True if audio is initially loaded enough to start the playback.
  private _canplay: boolean = false

  // The desired state of playback. There are situations where we cannot
  // immediately set the desired state, like playing when audio is not
  // initially loaded or pausing when playback has not yet begun. Thus we
  // cache it here.
  private _desiredState: 'play' | 'pause' = 'pause'
  private _stateChangeQueue: Promise<void> = Promise.resolve()

  // This flag is set if playback stopped because there was no data.
  // It is reset when playing resumes or when the stream is paused.
  private _interruptedByNetwork: boolean = false

  // Audio position tracker, saves position to the backend.
  private _positionTracker: PositionTracker

  // Callback to track audio state changes (playing, paused, etc).
  private _stateUpdateCallback: (state: PlaybackState) => void

  constructor(
    audio: AudioDoc,
    stateUpdateCallback: any,
    initializePositionTracker: boolean = true,
  ) {
    this._audio = audio
    this._stateUpdateCallback = stateUpdateCallback

    if (initializePositionTracker) {
      this._positionTracker = new PositionTracker(audio)
    } else {
      this._positionTracker = undefined as any
    }
  }

  /**
   * Init event listeners.
   *
   * We can not do this in the constructor as Vue 3 reactivity system
   * is not initialized and `this` is not reactive yet.
   * See #4824.
   */
  public initEventListeners(): void {
    // Usually (everywhere except mobile iOS) we get the "canplay" event
    // right out, as browser starts downloading the audio when the page
    // is loaded (we have <audio> element in the HTMl).
    //
    // Also the "canplay" event is sent repeatedyly so we may not worry about
    // loosing it (but more reliable way might be to also check the
    // audio.readyState, see
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLAudioElement/Audio#determining_when_playback_can_begin
    //
    // On mobile Safari, the download does not start and, what is worse, setting
    // audio.currentTime does nothing (it just remains zero) so we can not set
    // the future desired start position.

    this._audio.addListener('canplay', () => this._handleCanplayEvent())
    this._audio.addListener('playing', () => this._handlePlayingEvent())

    // These events signal interruption of playback due to network.
    _interruptionEvents.forEach((eventName) =>
      this._audio.addListener(eventName, () => {
        this._interruptedByNetwork = true
      }),
    )

    // These events signal that the network interruption is resolved.
    _interruptionResetEvents.forEach((eventName) =>
      this._audio.addListener(eventName, () => {
        this._interruptedByNetwork = false
      }),
    )

    // We forward these events to the `_stateUpdateCallback`.
    _playbackStates.forEach((eventName) =>
      this._audio.addListener(eventName, (event: Event) =>
        this._invokeStateUpdateCallback(event),
      ),
    )
  }

  _handleCanplayEvent(): void {
    if (this._canplay) {
      // The "canplay" event is sent repeatedly, exit.
      return
    }

    this._canplay = true

    // The user could have clicked play before audio was loaded.
    this._applyDesiredState()
  }

  _handlePlayingEvent(): void {
    // While the audio was preparing to play the user could have paused it.
    this._applyDesiredState()
  }

  _invokeStateUpdateCallback(event: Event): void {
    TypedAssert.isOneOf(event.type, _playbackStates)
    this._stateUpdateCallback(event.type)
  }

  /**
   * Resume playback.
   *
   * If the audio is not yet loaded, we call `audio.load()`
   * and the playback starts later, in the `canplay` event handler.
   */
  private async _apply_play(): Promise<void> {
    if (!this._audio.paused) {
      return
    } else if (this._canplay) {
      await this._audio.play()
    } else {
      // This is the workaround for mobile Safari, where audio is not preloaded
      // we call the audio.load() method that eventually triggers the
      // "canplay" event and we try to call the play() method again in the event handler.
      this._audio.load()
    }
  }

  private _apply_pause(): void {
    if (!this._audio.paused) {
      this._audio.pause()
    }
  }

  private _applyDesiredState(): void {
    // On slower machines, `HTMLAudioElement.play()` can take some time.
    // When the user pauses the audio right away, we cannot call
    // `HTMLAudioElement.pause()` right away, as that would raise an
    // exception. Ignoring the user input will lead to the audio playing
    // while being paused. As a solution, we wait for the current state
    // change to finish before calling any methods.
    this._stateChangeQueue = this._stateChangeQueue.then(async () => {
      if (this._desiredState === 'play') {
        await this._apply_play()
      } else {
        this._apply_pause()
      }
    })
  }

  play(): void {
    this._desiredState = 'play'
    this._applyDesiredState()
  }

  pause(): void {
    this._desiredState = 'pause'
    this._applyDesiredState()
  }

  togglePlayback(): void {
    this._desiredState === 'play' ? this.pause() : this.play()
  }

  forward(): void {
    this._audio.currentTime += 15
  }

  backward(): void {
    this._audio.currentTime -= 15
  }

  get audioLoaded(): boolean {
    return this._canplay
  }

  get paused(): boolean {
    return this._desiredState === 'pause'
  }

  get interruptedByNetwork(): boolean {
    return this._interruptedByNetwork
  }
}

/*
 * Audio position tracker.
 *
 * An instance of this class is created in PlaybackControl constructor, and
 * it starts a timer to track audio position.
 *
 * The logic is based on a periodical checking of ``currentTime`` audio property
 * and comparing it to the last seen position (``_previousPosition`` property).
 * If the audio position has changed - we send the tracking request and start a
 * new timer, if the position is the same - just start the new timer.
 */
class PositionTracker {
  private _audio: AudioDoc
  private _previousPosition: number

  constructor(audio: AudioDoc) {
    this._audio = audio
    this._previousPosition = this._audio.currentTime

    this.startTracking()
  }

  /*
   * Wrapper around standard setTimer() to have a better control over the flow.
   */
  wait(): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, 10 * 1000))
  }

  /*
   * Check if audio position changed.
   *
   *
   * Compare the last seen position with current audio position, return true if
   * they are different.
   */
  isPositionChanged(): boolean {
    if (Math.floor(this._audio.currentTime) === this._previousPosition) {
      return false
    }
    return true
  }

  /*
   * Start tracking audio position.
   *
   * If the position was changed - save it and run the timer,
   * if not - just run the timer again.
   */
  async startTracking(): Promise<void> {
    try {
      if (this.isPositionChanged()) {
        await this.saveAudioPosition()
        await this.wait()
        await this.startTracking()
      } else {
        await this.wait()
        await this.startTracking()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  /*
   * Send the backend request to save the current audio position.
   */
  async saveAudioPosition(): Promise<void> {
    const position = Math.floor(this._audio.currentTime)
    this._previousPosition = position
    if (!this._audio.paused) {
      this._audio.doc.audio_position = position
      await backend.saveAudioPosition(this._audio.doc, position)
    }
  }
}
