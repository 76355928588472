import { truncateSearchTerm } from '@/helpers/text'
import { ListingView } from './docs.listing'
import { DocGroupListItem } from './interfaces'

/**
 * Podcasts logic used for listing page.
 */
export class PodcastsView implements ListingView {
  public podcasts: DocGroupListItem[] = []
  private _isHeaderCollapsed: boolean = false
  private _title: string
  private _searchTerm: string | undefined
  private _isSearchPage: boolean
  constructor(
    podcasts: DocGroupListItem[],
    searchTerm: string = '',
    isSearchPage: boolean = false,
  ) {
    this.podcasts = podcasts
    this._searchTerm = searchTerm
    this._isSearchPage = isSearchPage

    this._title = this._isSearchPage
      ? `Search results for "${truncateSearchTerm(this._searchTerm)}"`
      : 'All Podcasts'
  }

  // We don't have incremental load implemented for podcasts yet
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setShowLimit(limit: number): void {}
  /* eslint-enable @typescript-eslint/no-unused-vars */

  get pageTitle(): string {
    return this._title
  }

  get filterTitle(): string {
    return this.pageTitle
  }

  get isHeaderCollapsed(): boolean {
    return this._isHeaderCollapsed
  }

  set isHeaderCollapsed(value: boolean) {
    this._isHeaderCollapsed = value
  }

  get dataLength(): number {
    return this.podcasts.length
  }

  podcastSubtitle(podcast: DocGroupListItem): string {
    const count = podcast.doc_count
    return count + (count === 1 ? ' episode' : ' episodes')
  }

  /**
   * Link for the podcast details (listing) page.
   */
  public link(podcast: DocGroupListItem): Record<string, unknown> {
    return {
      name: 'podcastPage',
      params: {
        url_slug: podcast.url_slug,
      },
    }
  }
}
