export interface Country {
  id: string
  exchangeRate: number
  timeZones: string[]
  locale: string
  currency: string
}

export const COUNTRIES: Country[] = [
  {
    id: 'IN',
    exchangeRate: 83.8,
    timeZones: ['Asia/Calcutta', 'Asia/Kolkata'],
    locale: 'hi-IN',
    currency: 'INR',
  },
  {
    id: 'GB',
    exchangeRate: 0.76,
    timeZones: ['Europe/Belfast', 'Europe/London', 'GB', 'GB-Eire'],
    locale: 'en-GB',
    currency: 'GBP',
  },
  {
    id: 'CA',
    exchangeRate: 1.36,
    timeZones: [
      'America/Atikokan',
      'America/Blanc-Sablon',
      'America/Cambridge_Bay',
      'America/Coral_Harbour',
      'America/Creston',
      'America/Dawson',
      'America/Dawson_Creek',
      'America/Edmonton',
      'America/Fort_Nelson',
      'America/Glace_Bay',
      'America/Goose_Bay',
      'America/Halifax',
      'America/Inuvik',
      'America/Iqaluit',
      'America/Moncton',
      'America/Montreal',
      'America/Nipigon',
      'America/Pangnirtung',
      'America/Rainy_River',
      'America/Rankin_Inlet',
      'America/Regina',
      'America/Resolute',
      'America/St_Johns',
      'America/Swift_Current',
      'America/Thunder_Bay',
      'America/Toronto',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Winnipeg',
      'America/Yellowknife',
      'Canada/Atlantic',
      'Canada/Central',
      'Canada/Eastern',
      'Canada/Mountain',
      'Canada/Newfoundland',
      'Canada/Pacific',
      'Canada/Saskatchewan',
      'Canada/Yukon',
    ],
    locale: 'en-CA',
    currency: 'CAD',
  },
  {
    id: 'AU',
    exchangeRate: 1.47,
    timeZones: [
      'Antarctica/Macquarie',
      'Australia/ACT',
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Broken_Hill',
      'Australia/Canberra',
      'Australia/Currie',
      'Australia/Darwin',
      'Australia/Eucla',
      'Australia/Hobart',
      'Australia/LHI',
      'Australia/Lindeman',
      'Australia/Lord_Howe',
      'Australia/Melbourne',
      'Australia/NSW',
      'Australia/North',
      'Australia/Perth',
      'Australia/Queensland',
      'Australia/South',
      'Australia/Sydney',
      'Australia/Tasmania',
      'Australia/Victoria',
      'Australia/West',
      'Australia/Yancowinna',
    ],
    locale: 'en-AU',
    currency: 'AUD',
  },
  {
    id: 'DE',
    exchangeRate: 0.9,
    timeZones: ['Europe/Berlin', 'Europe/Busingen'],
    locale: 'de-DE',
    currency: 'EUR',
  },
  {
    id: 'PH',
    exchangeRate: 57,
    timeZones: ['Asia/Manila'],
    locale: 'en-PH',
    currency: 'PHP',
  },
  {
    id: 'NG',
    exchangeRate: 1630,
    timeZones: ['Africa/Lagos'],
    locale: 'ha-Latn-NG',
    currency: 'NGN',
  },
  {
    id: 'SG',
    exchangeRate: 1.3,
    timeZones: ['Asia/Singapore', 'Singapore'],
    locale: 'en-SG',
    currency: 'SGD',
  },
  {
    id: 'ID',
    exchangeRate: 15600,
    timeZones: [
      'Asia/Jakarta',
      'Asia/Jayapura',
      'Asia/Makassar',
      'Asia/Pontianak',
      'Asia/Ujung_Pandang',
    ],
    locale: 'id-ID',
    currency: 'IDR',
  },
  {
    id: 'NL',
    exchangeRate: 0.9,
    timeZones: ['Europe/Amsterdam'],
    locale: 'nl-NL',
    currency: 'EUR',
  },
  {
    id: 'KE',
    exchangeRate: 129,
    timeZones: ['Africa/Nairobi'],
    locale: 'ebu-KE',
    currency: 'KES',
  },
  {
    id: 'ZA',
    exchangeRate: 17.5,
    timeZones: ['Africa/Johannesburg'],
    locale: 'af-ZA',
    currency: 'ZAR',
  },
  {
    id: 'PK',
    exchangeRate: 278,
    timeZones: ['Asia/Karachi'],
    locale: 'en-PK',
    currency: 'PKR',
  },
  {
    id: 'BR',
    exchangeRate: 5.55,
    timeZones: [
      'America/Araguaina',
      'America/Bahia',
      'America/Belem',
      'America/Boa_Vista',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Eirunepe',
      'America/Fortaleza',
      'America/Maceio',
      'America/Manaus',
      'America/Noronha',
      'America/Porto_Acre',
      'America/Porto_Velho',
      'America/Recife',
      'America/Rio_Branco',
      'America/Santarem',
      'America/Sao_Paulo',
      'Brazil/Acre',
      'Brazil/DeNoronha',
      'Brazil/East',
      'Brazil/West',
    ],
    locale: 'pt-BR',
    currency: 'BRL',
  },
  {
    id: 'FR',
    exchangeRate: 0.9,
    timeZones: ['Europe/Paris'],
    locale: 'fr-FR',
    currency: 'EUR',
  },
  {
    id: 'ES',
    exchangeRate: 0.9,
    timeZones: ['Africa/Ceuta', 'Atlantic/Canary', 'Europe/Madrid'],
    locale: 'es-ES',
    currency: 'EUR',
  },
  {
    id: 'AE',
    exchangeRate: 3.6725,
    timeZones: ['Asia/Dubai'],
    locale: 'ar-AE',
    currency: 'AED',
  },
  {
    id: 'IT',
    exchangeRate: 0.9,
    timeZones: ['Europe/Rome'],
    locale: 'it-IT',
    currency: 'EUR',
  },
  {
    id: 'VN',
    exchangeRate: 24640,
    timeZones: ['Asia/Ho_Chi_Minh', 'Asia/Saigon'],
    locale: 'vi-VN',
    currency: 'VND',
  },
  {
    id: 'MX',
    exchangeRate: 19.4,
    timeZones: [
      'America/Bahia_Banderas',
      'America/Cancun',
      'America/Chihuahua',
      'America/Ciudad_Juarez',
      'America/Ensenada',
      'America/Hermosillo',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Merida',
      'America/Mexico_City',
      'America/Monterrey',
      'America/Ojinaga',
      'America/Santa_Isabel',
      'America/Tijuana',
      'Mexico/BajaNorte',
      'Mexico/BajaSur',
      'Mexico/General',
    ],
    locale: 'es-MX',
    currency: 'MXN',
  },
]

/**
 * Retrieve the user's country based on their time zone, if it's included in the `COUNTRIES` array.
 */
export function getUserCountry(): Country | undefined {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return COUNTRIES.find((country) => {
    return country.timeZones.includes(timeZone)
  })
}

/**
 * Localize the given price for the given country.
 *
 * @example
 * // Returns 'R$ 5,50'
 * convertAndFormatPrice(1, { exchangeRate: 5.5, locale: 'pt-BR', ... })
 *
 * @example
 * // Returns 'MX$18.86'
 * convertAndFormatPrice(1, { exchangeRate: 18.86, locale: 'es-MX', ... })
 */
export function convertAndFormatPrice(price: number, country: Country): string {
  const convertedPrice = price * country.exchangeRate

  return formatPrice(convertedPrice, country)
}

/**
 * Format the given price to the given country.
 */
function formatPrice(price: number, country: Country): string {
  let locale = country.locale

  // Arabic languages use a different writing system that doesn't work with our UI.
  if (locale.startsWith('ar')) {
    locale = 'en-US'
  }

  // Format the price using the given country's locale.
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: country.currency,
  })
  const formattedPrice = formatter.format(price)

  if (!formattedPrice.startsWith('$')) {
    return formattedPrice
  }

  // If the given country's currency symbol is '$', we format it again
  // using the `en-US` locale to have a clear distinction between the
  // original price in dollars (which uses the '$' symbol) and the
  // localized price.
  //
  // Eg. The symbol for the mexican peso in `es-MX` is '$',
  // so we format it using `en-US` and it becomes 'MX$'.
  const usFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: country.currency,
  })
  return usFormatter.format(price)
}
