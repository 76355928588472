
import { Options, Vue } from 'vue-class-component'
import { DocsTagsView } from '@/models/docs.tags'
import CategoryItem from './CategoryItem.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  components: {
    CategoryItem,
  },
})
export default class CategoriesSelection extends Vue {
  @Prop() private view!: DocsTagsView
}
