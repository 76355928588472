import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "continue-reading__text-box" }
const _hoisted_2 = { class: "continue-reading__text" }
const _hoisted_3 = { class: "continue-reading__title card__title" }
const _hoisted_4 = { class: "continue-reading__author" }
const _hoisted_5 = { class: "continue-reading__progress-box" }
const _hoisted_6 = { class: "continue-reading__progress" }
const _hoisted_7 = ["aria-valuenow"]
const _hoisted_8 = { class: "continue-reading__progress-num" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookCardThumbnail = _resolveComponent("BookCardThumbnail")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.view.startLinkParams(_ctx.doc),
    class: "continue-reading__item",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('unitClick')), ["prevent"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BookCardThumbnail, {
        doc: _ctx.doc,
        class: "continue-reading__img-box",
        coverClass: "continue-reading__img",
        fallbackCoverClass: "continue-reading__img"
      }, null, 8, ["doc"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.doc.title), 1),
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.doc.author), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              "aria-valuenow": _ctx.doc.progress,
              "aria-valuemin": "0",
              "aria-valuemax": "100",
              class: _normalizeClass(["continue-reading__progress-now", `continue-reading__progress-now--${_ctx.doc.progress}`])
            }, null, 10, _hoisted_7)
          ]),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.doc.progress) + "%", 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}