
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import PodcastCard from '@/app/components/podcast/card/PodcastCard.vue'

import { PodcastsView } from '@/models/podcasts'
import ListingPageLayoutSimple from './ListingPageLayoutSimple.vue'

@Options({
  components: {
    PodcastCard,
    ListingPageLayoutSimple,
  },
})
export default class PodcastsListingPage extends Vue {
  @Prop() view!: PodcastsView
}
