
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

/**
 * End-of-content button.
 *
 * Shows fade and a button to go to billing page.
 */
@Options({
  components: {},
})
export default class EOCButton extends Vue {
  @Prop() private text!: string
}
