import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "export-content__integration-button-text" }
const _hoisted_5 = { class: "export-content__integration-button-title" }
const _hoisted_6 = { class: "export-content__integration-button-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: !_ctx.isSelectable,
    class: _normalizeClass(["export-content__integration-button", {
      ['export-content__integration-button-' + _ctx.name]: true,
      'export-content__integration-button-selected': _ctx.isSelected,
    }])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        class: "export-content__integration-button-logo",
        src: _ctx.imageUrl
      }, null, 8, _hoisted_2),
      _createElementVNode("img", {
        class: "export-content__integration-button-logo-dark",
        src: _ctx.darkImageUrl
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.name), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.description), 1)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["export-content__integration-state-label", {
        'export-content__integration-label-coming-soon': !_ctx.isSelectable,
        'export-content__integration-label-connected': _ctx.isEnabled,
      }])
    }, _toDisplayString(_ctx.parsedState), 3)
  ], 10, _hoisted_1))
}