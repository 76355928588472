import { BACKEND_URL } from '@/init/settings'
import { ApiResultBooks, BookListItem, ListItemType } from '@/models/interfaces'

export const BOOKS_CACHE = 'shortform-books-list-cache'
export const BOOK_CACHE = 'shortform-book-cache'
export const ARTICLES_CACHE = 'shortform-articles-list-cache'
export const ARTICLE_CACHE = 'shortform-article-cache'
export const PODCASTS_CACHE = 'shortform-podcasts-list-cache'
export const PODCAST_CACHE = 'shortform-podcast-cache'
export const FOLLOWED_PODCAST_EPISODES_CACHE =
  'shortform-followed-podcast-episodes-cache'
export const NEW_ARTICLES_CACHE = 'shortform-new-articles-cache'
export const HOME_CACHE = 'shortform-home-docs-list-cache'
export const HIGHLIGHTS_CACHE = 'shortform-all-highlights-cache'
export const AUDIO_CACHE = 'shortform-audio'
export const PODCAST_EPISODES_CACHE = 'shortform-podcast-episodes-cache'
export const TAGS_CACHE = 'shortform-tags-cache'
export const COLLECTIONS_CACHE = 'shortform-collections-cache'

export const DOC_KEYS = {
  collection: [COLLECTIONS_CACHE],
  book: [HOME_CACHE, BOOKS_CACHE, BOOK_CACHE],
  article: [HOME_CACHE, ARTICLES_CACHE, ARTICLE_CACHE, NEW_ARTICLES_CACHE],
  podcast: [
    HOME_CACHE,
    PODCASTS_CACHE,
    PODCAST_CACHE,
    FOLLOWED_PODCAST_EPISODES_CACHE,
  ],
  podcast_episode: [
    HOME_CACHE,
    PODCAST_CACHE,
    FOLLOWED_PODCAST_EPISODES_CACHE,
    PODCAST_EPISODES_CACHE,
  ],
}

export const APP_KEYS = [
  BOOKS_CACHE,
  BOOK_CACHE,
  ARTICLES_CACHE,
  ARTICLE_CACHE,
  PODCASTS_CACHE,
  PODCAST_CACHE,
  FOLLOWED_PODCAST_EPISODES_CACHE,
  HOME_CACHE,
  HIGHLIGHTS_CACHE,
  AUDIO_CACHE,
  PODCAST_EPISODES_CACHE,
  TAGS_CACHE,
  COLLECTIONS_CACHE,
]

/**
 * Reset the caches for the given doc type.
 */
export async function resetCacheByListItemType(
  listItemType: ListItemType,
): Promise<void> {
  resetCacheByKeys(DOC_KEYS[listItemType])
}

/**
 * Reset home cache.
 */
export async function resetHomeCache(): Promise<void> {
  await resetCacheByKeys([HOME_CACHE])
}

/**
 * Reset whole app cache.
 *
 * We do this when the user switches from free to paid plan.
 */
export async function resetAppCache(): Promise<void> {
  await resetCacheByKeys(APP_KEYS)
}

/**
 * Reset entire cache.
 */
export async function resetEntireCache(): Promise<void> {
  const keys = await caches.keys()
  await resetCacheByKeys(keys)
}

/**
 * Reset cache by given `keys`.
 */
export async function resetCacheByKeys(keys: string[]): Promise<void> {
  if (await cachesNotAvailable()) {
    return
  }
  // The `caches` is browser CacheStorage:
  // https://developer.mozilla.org/en-US/docs/Web/API/CacheStorage
  await Promise.all(keys.map((cacheName) => caches.delete(cacheName)))
}

/**
 * Update cached book data in the books list cache.
 */
export async function updateBooksCache(book: BookListItem): Promise<void> {
  await updateCache(
    book,
    BOOKS_CACHE,
    BACKEND_URL + '/api/books/',
    transformBookListItemFn,
  )
}

/**
 * Update cached book data in the home list cache.
 */
export async function updateHomeCache(book: BookListItem): Promise<void> {
  await updateCache(
    book,
    HOME_CACHE,
    BACKEND_URL + '/api/home/',
    transformBookListItemFn,
  )
}

/**
 * Transform function to update cached BookListItem data in ApiResultBooks cache.
 */
function transformBookListItemFn(
  book: BookListItem,
  jsonData: ApiResultBooks,
): ApiResultBooks {
  jsonData.data.forEach((cacheBook: BookListItem) => {
    if (cacheBook.url_slug === book.url_slug) {
      Object.assign(cacheBook, book)
    }
  })

  return jsonData
}

/**
 * Create `Response` object that is used as CacheStorage value.
 */
export function createResponse(json: any): Response {
  const blob = new Blob([JSON.stringify(json, null, 2)], {
    type: 'application/json',
  })
  const init = { status: 200, statusText: 'Manual Response' }
  return new Response(blob, init)
}

/**
 * Update the value at key `url` in cache `cacheName` with the result of the provided function.
 * When `url` is not a key of the cache, the cache is left as is.
 *
 * Cached data is stored as a `Response` object.
 * To update it, we read existing `Response`, run json content from it
 * through the `tranformFn` and then create new `Response` object
 * and replace the old one in the cache.
 */
export async function updateCache(
  obj: any,
  cacheName: string,
  url: string,
  transformFn: (obj: any, json: any) => any,
): Promise<void> {
  if (await cachesNotAvailable()) {
    return
  }

  // Get existing cached `Response`.
  const cache: Cache = await caches.open(cacheName)
  const resp: Response | undefined = await cache.match(url)
  if (!resp) {
    // No response in the cache, exit.
    return
  }

  // Read response json and pass to `transformFn` to
  // analyze and update the data.
  const jsonResp = transformFn(obj, await resp.json())

  // Create modified `Response` object with updated json data
  // as a content.
  const updatedResponse = createResponse(jsonResp)

  // Update response in the cache.
  await cache.put(url, updatedResponse)
}

/** Show book authors for cached books.
 *
 * This serves as an example for debugging.
 */
export async function showBooksCache(): Promise<void> {
  const cache = await caches.open(BOOKS_CACHE)
  const resp = await cache.match(BACKEND_URL + '/api/books/')
  if (resp) {
    const apiResult: ApiResultBooks = await resp.json()
    apiResult.data.forEach((item: BookListItem) => console.log(item.author))
  }
}

async function cachesNotAvailable(): Promise<boolean> {
  let isAvailable = !(typeof caches === 'undefined' || !caches)
  if (isAvailable) {
    try {
      // Check CacheStorage object, it doesn't work in Firefox Private Mode.
      // Some of the CacheStorage methods (keys(), open(), delete())
      // raise the "The operation is insecure" exception.
      await caches.keys()
    } catch (error: any) {
      if (error.message === 'The operation is insecure.') {
        isAvailable = false
      } else {
        throw error
      }
    }
  }
  return !isAvailable
}
