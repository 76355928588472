import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44be983e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PodcastFollowButton = _resolveComponent("PodcastFollowButton")!
  const _component_ListingPageDescription = _resolveComponent("ListingPageDescription")!
  const _component_EpisodeCard = _resolveComponent("EpisodeCard")!
  const _component_ListingPageLayout = _resolveComponent("ListingPageLayout")!

  return (_openBlock(), _createBlock(_component_ListingPageLayout, {
    sectionClass: "docs-listing--episodes",
    initView: _ctx.loadPodcasts
  }, {
    descriptionBox: _withCtx(() => [
      (_ctx.listingPage.descriptionView)
        ? (_openBlock(), _createBlock(_component_ListingPageDescription, {
            key: 0,
            view: _ctx.listingPage.descriptionView
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.view)
                  ? (_openBlock(), _createBlock(_component_PodcastFollowButton, {
                      key: 0,
                      view: _ctx.view.followView
                    }, null, 8, ["view"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["view"]))
        : _createCommentVNode("", true)
    ]),
    nodata: _withCtx(() => [
      _createTextVNode(" No podcast found. ")
    ]),
    default: _withCtx(() => [
      (_ctx.view && _ctx.view.podcast.docs.data)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.view.podcast.docs.data, (episode) => {
            return (_openBlock(), _createBlock(_component_EpisodeCard, {
              key: episode.url_slug,
              doc: episode,
              link: _ctx.view.link(episode)
            }, null, 8, ["doc", "link"]))
          }), 128))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["initView"]))
}