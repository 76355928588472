
import { DocView } from '@/models/doc'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Layout from '@/app/components/Layout.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

@Options({
  components: {
    Layout,
    LoadingSpinner,
  },
})
export default class DocSummaryPage extends Vue {
  @Prop() doc!: DocView
  @Prop() isLoadingDoc!: boolean
  @Prop() isErrorLoadingDoc!: boolean

  async beforeUnmount(): Promise<void> {
    if (this.doc && this.doc.readingProgress) {
      this.doc.readingProgress.destroy()
    }
  }
}
