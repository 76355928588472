import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4834591c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "categories-filter__icon-box" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "categories-filter__name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.showItem)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.view.getUrl(_ctx.tag),
        class: "categories-filter__item"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "categories-filter__icon",
              src: _ctx.view.tagIcon(_ctx.tag),
              alt: _ctx.tag
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.tag), 1)
        ]),
        _: 1
      }, 8, ["to"]))
    : _createCommentVNode("", true)
}