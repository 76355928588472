import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { assertNotNull } from '@/helpers/typing'
import { ValidationState } from '@/helpers/form'
import { FacebookResponse } from '@/models/interfaces.auth'
import { convertError } from '@/models/error'
import { afterAuth } from './logic'
import { SocialAuthView } from './social'
import { OnAuthStart, OnAuthSuccess, OnAuthError } from './models'

/*
 * Facebook Authentication View.
 *
 * Interacts with Facebook to get user access token and sends it
 * to the backend during social login.
 *
 */
export class FacebookAuthView implements SocialAuthView {
  public validation: ValidationState

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  isEnabled: boolean = true

  /*
   * Constructor.
   *
   */
  constructor(
    validation: ValidationState,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.validation = validation
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  public init(): void {}

  /*
   * Log in / Signup with Facebook handler.
   *
   * Top level method that's called from LoginView or SignupView on button click.
   *
   * Note: There is one edge case that is not handled by this method:
   * When the user logins/logouts more than 10 times in the row there is an
   * error printed to console by facebook:
   * Your request to oauth has exceeded the rate limit, please try again later
   *
   * I didn't find the way to catch this error, because it's not acutally an
   * error, it's just a console message.
   * The message is printed by ``FB.login()`` method and it
   * breaks the promise completely - from user point of view it looks like
   * nothing is happening when you click the button.
   *
   * After inspecting the Facebook script, there's the following piece of code
   * responsible for handling the error (the script is deobfuscated):
   *
   * if (config.url === "dialog/oauth") {
   *   if (l >= ((result = $("sdk.feature")("max_oauth_dialog_retries", 100)) !== null ? result : 100)) {
   *     $("Log").error("Your request to oauth has exceeded the rate limit, please try again later");
   *     return;
   *   }
   *   l++;
   * }
   */
  async start(): Promise<void> {
    let response: FacebookResponse
    try {
      // Handle FB login
      response = assertNotNull(await this._fbLogin())

      if (response.status && response.status === 'unknown') {
        // Facebook may return this when third-party cookies are blocked.
        // See, for example: https://stackoverflow.com/q/22889646/4612064
        //
        // Facebook docs say: The user is either not logged into Facebook
        // or explicitly logged out of your application so it doesn't
        // attempt to connect to Facebook and thus, we don't know
        // if they've authenticated your application or not. (unknown)
        // https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus/
        throw {
          error: 'Cannot authenticate with Facebook.',
          skipSentry: true,
        }
      }

      if (!response.authResponse) {
        throw new Error(`Unexpected facebook response`)
      }

      // Prepare the model for backend request and run the UI spinner
      const model = { facebookToken: response.authResponse.accessToken }
      this.onStart()

      // Send the backend request
      const authData = await backend.facebookLoginOrRegister(model)

      // Redirect the user to the app
      const nextPage = await afterAuth(authData)
      return this.onSuccess(nextPage)
    } catch (error: any) {
      if (!error.skipSentry) {
        Sentry.withScope((scope) => {
          scope.setContext('SF: Facebook Error', {
            response: response,
          })
          Sentry.captureException(error)
        })
      }
      this.errorHandler(error)
    }
  }

  /*
   * Facebook Login handler.
   *
   * This is just a wrapper around FB.login() method to be able to better control
   * the asyncronous flow.
   *
   */
  async _fbLogin(): Promise<FacebookResponse> {
    return new Promise((resolve, reject) => {
      if (typeof FB !== 'undefined' && FB) {
        FB.login(
          (response: any) => {
            resolve(response)
          },
          {
            auth_type: 'rerequest',
            scope: 'public_profile,email',
            return_scopes: true,
          },
        )
      } else {
        reject({
          error: 'Facebook library could not be loaded.',
          skipSentry: true,
        })
      }
    })
  }

  /*
   * Error handler.
   *
   * Handles all errors appearing during the facebook login and shows them
   * to the user via Validation.
   *
   */
  errorHandler(error: any): any {
    this.onError()
    let message = 'Error occurred. Please try again later.'
    const err = convertError(error)
    if (err.isSocialAuthError() && err.message() === 'fb_no_email_error') {
      message =
        "We couldn't get an email address from your Facebook account. Please try again."
    } else if (error.error) {
      message = error.error
    }
    this.validation.showMessage('social', message)
  }

  static async tryLogout(): Promise<void> {
    try {
      if (typeof FB !== 'undefined' && FB) {
        const response = await FB.getLoginStatus()
        if (response && response.status === 'connected') {
          await FB.logout()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}
