
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import BookHero from '@/app/components/doc/highlights/Hero.vue'
import BasePopup from '@/components/ui/BasePopup.vue'
import PdfDownloadLink from './PdfDownloadLink.vue'

import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { auth } from '@/services/auth'
import { Billing } from '@/models/interfaces'
import { BillingView } from '@/models/billing'
import { DocView } from '@/models/doc'
import { DocExportView } from '@/models/doc.export'
import Layout from '../Layout.vue'
import HeaderRoot from '@/app/components/header/Root.vue'
import KindleExportForm from '@/app/components/book/export/KindleExportForm.vue'
import BookExportLimit from '@/app/components/book/BookExportLimit.vue'
import BackLink from '@/app/components/BackLink.vue'

@Options({
  components: {
    BookHero,
    BasePopup,
    PdfDownloadLink,
    Layout,
    HeaderRoot,
    KindleExportForm,
    BookExportLimit,
    BackLink,
  },
})
export default class BookExport extends Vue {
  @Prop() private seconds!: number
  @Prop() private doc!: DocView

  private billing: BillingView | null = null
  private view: DocExportView | null = null

  async beforeMount(): Promise<void> {
    wait.start(this, 'Loading Book Export View')
    const response = await backend.getBilling()
    await this._initView(response)
    wait.end(this, 'Loading Book Export View')

    setTimeout(() => {
      // Reload the page once in 5 minutes to get new doc export token.
      // This is for the case the user opens the page and leaves it for some time
      // before using the download button.
      if (this.$route.name === 'book.export') {
        window.location.reload()
      }
    }, 5 * 60 * 1000)
  }

  async _initView(billingData: Billing): Promise<void> {
    this.billing = new BillingView(billingData)
    if (!this.doc.doc_export_stats) {
      try {
        await this.doc.getDocExportStats()
      } catch (error: any) {
        Sentry.captureException(error)
      }
    }
    if (this.doc.doc_export_stats) {
      this.view = new DocExportView(this.doc, this.billing)
    }
  }

  get userEmail(): string | null {
    return auth.getEmail()
  }

  showEndTrialPopup(): void {
    ;(this.$refs.popup as any).show()
  }

  hideEndTrialPopup(): void {
    ;(this.$refs.popup as any).hide()
  }

  /**
   * Cancel subscription handler (for modal dialog).
   */
  async cancelTrial(): Promise<void> {
    try {
      this.hideEndTrialPopup()
      wait.start(this, 'Loading Book Export View')
      const response = await backend.cancelTrial()
      await this._initView(response)
    } catch (error) {
      Sentry.captureException(error)
      alert('Error canceling trial')
    } finally {
      wait.end(this, 'Loading Book Export View')
    }
  }

  get isLoading(): boolean {
    return wait.is(this, 'Loading Book Export View')
  }
}
