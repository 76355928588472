
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { ArticleView } from '@/models/article'

@Options({
  components: {},
})
export default class ArticleContent extends Vue {
  @Prop() private article!: ArticleView
}
