import { Router } from 'vue-router'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import {
  Adjust,
  AdjustConfig,
  AdjustEnvironment,
  AdjustLogLevel,
} from '@awesome-cordova-plugins/adjust'

import { platform } from '@/services/platform'

import {
  ADJUST_ANDROID_TOKEN,
  ADJUST_IOS_TOKEN,
  ANDROID_APP_ID,
  IOS_APP_ID,
  IS_PRODUCTION,
} from '@/init/settings'
import { Sentry } from '@/services/sentry'
import { emitter } from '@/services/mitt'

export function init(router: Router): void {
  // The "deviceready" is sent by capacitor only when running in native app.
  document.addEventListener('deviceready', () => onDeviceReady(), false)

  // Enable Shortform links in browser to open in native app.
  // See: https://capacitorjs.com/docs/guides/deep-links
  App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
    // Example url: https://www.shortform.com/app/signup
    // slug = /app/signup
    const slug = event.url.split('.com').pop()

    // We only push to the route if there is a slug present
    if (slug) {
      router.push(slug)
    }
  })

  initAndroidBackButtonHandler(router)

  async function onDeviceReady(): Promise<void> {
    const info = await platform.deviceInfo()
    if (Sentry.setContext) {
      Sentry.setContext('SF: NATIVE APP INFO', info as any)
    }
    initAppRatePlugin()

    // Inform In-App Purchase plugin that the device is ready.
    // We determine which plugin version to use based on the mobile app version.
    // In versions 7 and beyond, we use v13 of the plugin, but in previous
    // versions, we use v11.
    if (info.platform === 'ios' || info.platform === 'android') {
      initAdjustPlugin(info.platform)

      let purchaseModule
      const majorVersion = parseInt(info.appVersion.split('.')[0])
      if (majorVersion >= 7) {
        purchaseModule = await import('@/services/cordova.purchase')
      } else {
        purchaseModule = await import('@/services/cordova.purchase.v11')
      }
      purchaseModule.appStore.deviceReady()
    }
  }
}

/**
 * Initialize the Adjust plugin.
 */
export function initAdjustPlugin(platform: string): void {
  const appToken = platform === 'ios' ? ADJUST_IOS_TOKEN : ADJUST_ANDROID_TOKEN
  const environment = IS_PRODUCTION
    ? AdjustEnvironment.Production
    : AdjustEnvironment.Sandbox
  const config = new AdjustConfig(appToken, environment)
  if (!IS_PRODUCTION) {
    config.setLogLevel(AdjustLogLevel.Verbose)
  }
  Adjust.create(config)
}

export function initAppRatePlugin(): void {
  // App rate plugin does not register itself on window.cordova.plugins,
  // nor in capacitor's Plugins, only exposes global object.
  // @ts-ignore
  const AppRate = window.AppRate
  if (!AppRate) {
    Sentry.captureMessage(
      'No AppRate, probably old native app, skip setPreferences',
    )
    return
  }
  // Note the apprate plugin requires openUrl implementation to open
  // the app store page in the external browser.
  // We use in-app reviews, so should not need this and we do not
  // install / use the browser plugin at the moment.
  //
  // See: https://github.com/pushandplay/cordova-plugin-apprate#installation-prerequisites
  // Installation Prerequisites
  // Choose your preferred browser plugin which will be used to open
  // the store and install it:
  // https://github.com/EddyVerbruggen/cordova-plugin-safariviewcontroller
  // https://github.com/apache/cordova-plugin-inappbrowser
  //
  // You must implement the openUrl method for your chosen plugin.
  AppRate.setPreferences({
    openUrl: function (url: string) {
      Sentry.captureMessage(`AppRate.openUrl call for ${url}`)
    },
    storeAppURL: {
      ios: IOS_APP_ID,
      android: ANDROID_APP_ID,
    },
    reviewType: {
      ios: 'InAppReview',
      android: 'InAppReview',
    },
  })
}

/**
 * Configure behaviour of the back button of Android devices inside the app.
 *
 * The back button can be configured to close a menu instead of navigating to the
 * previous page. This is the common behaviour on Android. To do this, menus should emit
 * the event `overlayMenuOpened` when they get opened, and `overlayMenuClosed`.
 *
 * Once the Android back button gets clicked, if any menu is open as determined by
 * events, the event `closeOverlayMenu` gets emitted. Menus can react on this event
 * and close themselves.
 *
 * Currently, the menu on the home/discover page and the book TOC are set up to be
 * closed on Android back button click.
 *
 * If no menu is open, the current route is the 'home' route (i.e. /app), and the
 * browser navigation history is empty (i.e. already navigated all the way back), the
 * app gets closed.
 *
 * Otherwise, clicking the back button leads to a navigation to the previous
 * route of the browser history.
 */
function initAndroidBackButtonHandler(router: Router): void {
  let modalOpen: boolean = false

  emitter.on('overlayMenuOpened', () => {
    modalOpen = true
  })
  emitter.on('overlayMenuClosed', () => {
    modalOpen = false
  })

  App.addListener('backButton', ({ canGoBack }) => {
    if (modalOpen) {
      emitter.emit('closeOverlayMenu')
    } else if (canGoBack) {
      router.back()
    } else {
      if (router.currentRoute.value.name === 'home') {
        App.exitApp()
      } else {
        // We do `replace` and not `push` here since we do
        // not want next "back" action to navigate again to
        // the previous page, creating a loop between
        // that page and home.
        // Next 'back' on the home page should exit the app.
        router.replace({ name: 'home' })
      }
    }
  })
}
