import { backend } from '@/services/backend'
import { ReadwiseAPI } from './api'
import { AnnotationView, highlightsToViews } from '@/models/doc'
import { AnnotationWithContent, ReadwiseItem } from '@/models/interfaces'

import { CHUNK_SIZE } from './constants'

/*
 * Readwise service.
 *
 * This object is responsible for integration with Readwise service.
 */
export const readwise = {
  _api: new ReadwiseAPI(),

  /*
   * Get Readwise token from backend.
   */
  async getToken(): Promise<string | null> {
    return (await backend.getReadwiseToken()).readwise_token
  },

  /*
   * Send highlights.
   *
   * - token - Readwise token
   * - highlights - highlights array
   * - chunkSize - split highlights by chunks to prevent request time out
   */
  async _send(
    token: string,
    highlights: AnnotationView[],
    chunkSize: number = CHUNK_SIZE,
  ): Promise<void> {
    const readwiseItems: ReadwiseItem[] = []

    highlights.forEach((highlight) => {
      const item: ReadwiseItem = {
        text: highlight.quote,
        title: this._highlightTitle(highlight),
        author: 'Shortform',
        source_type: 'shortform',
        highlighted_at: highlight.created,
        highlight_url: `https://www.shortform.com/app/highlights/${highlight.id}`,
      }

      if (highlight.cover_image) {
        item.image_url = `https:${highlight.cover_image}`
      }

      if (highlight.text.trim()) {
        item['note'] = highlight.text
      }

      if (highlight.doc_type === 'book') {
        item['category'] = 'books'
      }
      if (highlight.doc_type === 'article') {
        item['category'] = 'articles'
      }

      // For some reason we can have highlights with an empty quote - don't add them.
      if (item.text) {
        readwiseItems.push(item)
      }
    })

    // Split items into chunks by 100
    const chunks = []
    for (let i = 0; i < readwiseItems.length; i += chunkSize) {
      chunks.push(readwiseItems.slice(i, i + chunkSize))
    }

    // Send chunks one by one
    for (let i = 0; i < chunks.length; i++) {
      await this._api.send(token, chunks[i])
    }
  },

  /**
   * Compose Readwise highlight title.
   *
   * The logic here:
   * if doc.author is Shortform:
   *     Readwise title: [doc.title]
   * else:
   *     Readwise title: [doc.title] by [doc.author] | Shortform Guide
   * Readwise author: Shortform in all cases
   *
   * See #3896 for details.
   *
   * @param highlight - Our Highlight object
   * @returns title - string with title depending on Doc author
   */
  _highlightTitle(highlight: AnnotationView): string {
    if (highlight.author === 'Shortform') {
      return highlight.docTitle
    }
    return `${highlight.docTitle} by ${highlight.author} | Shortform Guide`
  },

  async disableIntegration(): Promise<void> {
    await backend.removeReadwiseToken()
  },

  /*
   * Enable Readwise integration.
   *
   * We trying to export all user's highlights using the passed token.
   * If the export is successful we store the token.
   */
  async enableAndExportAll(newToken: string): Promise<void> {
    // Get all user's highlights
    const highlights = highlightsToViews(await backend.getHighlights())
    // Send them to readwise with new token.
    await this._send(newToken, highlights)
    // There are no errors, so the token is OK - we can save it.
    await backend.saveReadwiseToken({ readwise_token: newToken })
  },

  /*
   * Send one highlight to Readwise.
   *
   * After the successful creating/updating of a highlight in our backend,
   * we receive a readwise token along with the highlight's data.
   * Because of this we don't store the token and pass it every time.
   */
  async sendOne(
    token: string | null,
    highlight: AnnotationWithContent,
  ): Promise<void> {
    if (!token) {
      return
    }
    try {
      await this._send(token, highlightsToViews([highlight]))
    } catch (error: any) {
      if (error.isAxiosError) {
        // If it is the AxiosError, then we already logged it to Sentry
        // on the ReadwiseAPI level.
        // The current action is not crucial, so we don't throw the error not to
        // ruin the whole process.
      } else {
        throw error
      }
    }
  },
}
