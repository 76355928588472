import { DocView } from '@/models/doc'
import { assertNotNull } from '@/helpers/typing'

/**
 * A structure to hold the chapter data.
 */
export class ChapterData {
  constructor(
    public idx: number,
    public title: string,
    public url_slug: string,
    public start: number,
    public end: number,
  ) {}
}

/**
 * The list of audio chapters.
 *
 * We have one audio file per book and timings metadata with
 * start time for each chapter.
 * This class parses the metadata into an array of `ChapterData`
 * objects and keeps track of the current audio position to
 * find the appropriate book chapter.
 *
 * It does not hook to audio events directly, the `update` method
 * is called by ChapterPrevNextControl when audio time changes.
 */
class Chapters {
  private _book: DocView
  private _chapters: ChapterData[] = []
  private _currentChapterIdx: number = 0

  constructor(book: DocView) {
    this._book = book

    // Go over pages, build the `_chapters` array with chapter timings.
    const pages = book.pages
    const audioData = assertNotNull(this._book.audio)

    for (let idx = 0; idx < pages.length; idx++) {
      const chapter = pages[idx]
      let end = Infinity

      if (idx < pages.length - 1) {
        // Note: Math.floor is needed to make the switch between chapters deterministic,
        // otherwise (no floor), we may randomly miss the chapter start due to floating
        // point errors, for example:
        //
        // - The chapter 4 has the start time: 2099.615 seconds
        // - We click the chapter 4 link in the side menu
        // - We set audio.currentTime to 2099.615 seconds (audio time is in seconds,
        //   but we can use fractional time here)
        // - Now we get into the audio 'timeupdate' event (`_timeUpdateHandler`) and, suddenly,
        //   the audio.currentTime value is 2099.614999 wich is just a bit lower than
        //   the chapter 4 start, so we hit the chapter 3 instead and switch UI to it.
        //
        // At this point we are stuck at chapter 3 and can not switch to chapter 4.
        // Math.floor prevents this by making all timings integer and avoinding floating
        // point issues.
        end = Math.floor(audioData.timings[idx + 1] / 1000)
      }
      const chapterData = {
        idx: idx,
        title: chapter.title,
        url_slug: chapter.url_slug,
        // Same as above, round down the audio start time.
        start: Math.floor(audioData.timings[idx] / 1000),
        end: end,
      }

      if (idx === this._book.pageNum) {
        this._currentChapterIdx = idx
      }

      this._chapters.push(chapterData)
    }
  }

  /**
   * Current chapter data.
   */
  current(): ChapterData {
    return this._chapters[this._currentChapterIdx]
  }

  /**
   * Null chapter, returned when there is no next/prev chapter.
   */
  nullChapter(): ChapterData {
    return {
      idx: 0,
      start: 0,
      end: 0,
      title: '',
      url_slug: '',
    }
  }

  /**
   * Next chapter data.
   */
  next(): ChapterData {
    if (this._currentChapterIdx < this._chapters.length - 1) {
      return this._chapters[this._currentChapterIdx + 1]
    }
    return this.nullChapter()
  }

  /**
   * Prev chapter data.
   */
  prev(): ChapterData {
    if (this._currentChapterIdx > 0) {
      return this._chapters[this._currentChapterIdx - 1]
    }
    return this.nullChapter()
  }

  /**
   * Do we have the next chapter?
   */
  hasNext(): boolean {
    return this.next().url_slug !== ''
  }

  /**
   * Do we have the prev chapter?
   */
  hasPrev(): boolean {
    return this.prev().url_slug !== ''
  }

  /**
   * Switch to the next chapter.
   */
  goNext(): void {
    if (!this.hasNext()) {
      return
    }
    this._currentChapterIdx += 1
  }

  /**
   * Switch to the previous chapter.
   */
  goPrev(): void {
    if (!this.hasPrev()) {
      return
    }
    this._currentChapterIdx -= 1
  }

  /**
   * Audio playback update handler.
   *
   * Sets current chapter accordingly to the audio position.
   */
  go(chapterIndex: number): ChapterData {
    this._currentChapterIdx = chapterIndex
    return this.current()
  }

  /**
   * Update current chapter based on audio playback time.
   *
   * The method is called by ChapterPrevNextControl in the `timeupdate`
   * audio event.
   */
  update(newTime: number): ChapterData | null {
    // The newTime matches current chapter, no update needed.
    if (newTime >= this.current().start && newTime < this.current().end) {
      return null
    }

    // Set current chapter according to the audio time.
    for (const chapter of this._chapters) {
      if (newTime >= chapter.start && newTime < chapter.end) {
        this._currentChapterIdx = chapter.idx
        return chapter
      }
    }

    // We should never be here unless it's a bug.
    /* eslint-disable no-console */
    // console.log(this._chapters.map((el) => JSON.stringify(el)))
    /* eslint-enable no-console */
    throw new Error(
      'Can not find chapter for time: ' +
        newTime +
        ' timings: ' +
        assertNotNull(this._book.audio).timings,
    )
  }
}

export type AudioEventName =
  | 'loadstart'
  | 'progress'
  | 'suspend'
  | 'abort'
  | 'error'
  | 'emptied'
  | 'stalled'
  | 'loadedmetadata'
  | 'loadeddata'
  | 'canplay'
  | 'canplaythrough'
  | 'playing'
  | 'waiting'
  | 'seeking'
  | 'seeked'
  | 'ended'
  | 'durationchange'
  | 'timeupdate'
  | 'play'
  | 'pause'
  | 'ratechange'
  | 'resize'
  | 'volumechange'

export type EventListener = (evt: Event) => void

/**
 * AudioDoc - HTMLAudioElement and doc wrapper.
 */
export class AudioDoc {
  private _doc: DocView
  private _chapters: Chapters

  private _audio: HTMLAudioElement
  private _listeners: {
    eventName: AudioEventName
    eventListener: EventListener
  }[] = []

  // We set it to true once we attempt to add the audio file to cache.
  private _cached: boolean = false

  private _paused: boolean = true

  constructor(audio: HTMLAudioElement, doc: DocView) {
    this._doc = doc
    this._chapters = new Chapters(doc)

    this._audio = audio
  }

  get doc(): DocView {
    return this._doc
  }

  get chapters(): Chapters {
    return this._chapters
  }

  get currentTime(): number {
    return this._audio.currentTime
  }

  set currentTime(value: number) {
    this._audio.currentTime = value
  }

  get playbackRate(): number {
    return this._audio.playbackRate
  }

  set playbackRate(value: number) {
    this._audio.playbackRate = value
  }

  get volume(): number {
    return this._audio.volume
  }

  set volume(value: number) {
    this._audio.volume = value
  }

  get duration(): number {
    return this._audio.duration
  }

  // This getter+private field is necessary to
  // trigger vue reactivity.
  get paused(): boolean {
    return this._paused
  }

  get buffered(): TimeRanges {
    return this._audio.buffered
  }

  async play(): Promise<void> {
    this._cacheAudio()

    // Play is asynchronous. While playing hasn't actually started,
    // calling `pause` raises an Exception.
    // For reference see:
    // https://html.spec.whatwg.org/multipage/media.html#playing-the-media-resource
    await this._audio.play()
    this._paused = this._audio.paused
  }

  pause(): void {
    this._audio.pause()
    this._paused = this._audio.paused
  }

  load(): void {
    this._audio.load()
  }

  /**
   * Add event listener to the audio element.
   * Store all event listeners so that we can remove them.
   * For reference see: https://stackoverflow.com/questions/19469881/remove-all-event-listeners-of-specific-type
   */
  addListener(eventName: AudioEventName, eventListener: EventListener): void {
    this._audio.addEventListener(eventName, eventListener)
    this._listeners.push({ eventName, eventListener })
  }

  /**
   * Remove all attached listeners.
   */
  removeListeners(): void {
    this._listeners.forEach(({ eventName, eventListener }) =>
      this._audio.removeEventListener(eventName, eventListener),
    )
    this._listeners = []
  }

  // Cache audio for offline usage.
  async _cacheAudio(): Promise<void> {
    // Exit if we have added file to cache already.
    if (this._cached) {
      return
    }
    // Exit if window.caches is not available (IE).
    if (!window.caches) {
      return
    }
    try {
      // Preemptively mark this as cached to prevent further calls to this
      // while the audio is actually being cached.
      this._cached = true
      const cache = await caches.open('shortform-audio')
      await cache.add(this._audio.src)
    } catch {
      this._cached = false
    }
  }
}
