import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5efa0ec2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doc-sort__inner" }
const _hoisted_2 = { class: "doc-sort__list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "doc-sort__title" }
const _hoisted_5 = { class: "doc-sort__btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "doc-sort",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hide && _ctx.hide(...args)), ["self"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortView.options, (opt) => {
              return (_openBlock(), _createElementBlock("li", {
                key: opt.name,
                class: _normalizeClass(["doc-sort__item", _ctx.sortView.activeOptionClass(opt)]),
                onClick: ($event: any) => (_ctx.sortView.optionClick(opt))
              }, [
                _createElementVNode("span", _hoisted_4, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["doc-sort__icon iconfont", opt.icon])
                  }, null, 2),
                  _createTextVNode(_toDisplayString(opt.name), 1)
                ])
              ], 10, _hoisted_3))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("a", {
              class: "doc-sort__btn btns",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sortView.applySort()))
            }, "Apply")
          ])
        ])
      ], 512), [
        [_vShow, _ctx.sortView.visible]
      ])
    ]),
    _: 1
  }))
}