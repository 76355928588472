
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import Sidebar from '@/app/components/doc/reading/Sidebar.vue'
import HeaderBook from '@/app/components/doc/Header.vue'
import BackLink from '@/app/components/BackLink.vue'
import Footer from '@/app/components/Footer.vue'

import PrevNext from './PrevNext.vue'
import ArticleThread from './Thread.vue'

import { ArticleView } from '@/models/article'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {
    ArticleThread,
    Sidebar,
    HeaderBook,
    BackLink,
    Footer,
    PrevNext,
  },
})
export default class ArticleLayout extends Vue {
  @Prop() private article!: ArticleView
  @Prop() private title!: string

  get headerStyle(): Record<string, string> {
    if (assertNotNull(this.article).audio) {
      return {
        'padding-bottom': '0px',
      }
    }
    return {}
  }
}
