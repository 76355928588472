
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { VolumeControl, AudioDoc } from '@/services/player'
import VueSlider from 'vue-slider-component'

@Options({
  components: { VueSlider },
})
export default class Player extends Vue {
  @Prop() private audio!: AudioDoc

  private volumeControl: VolumeControl | null = null
  private value: number | null = null

  created(): void {
    this.volumeControl = new VolumeControl(this.audio)
    this.volumeControl.initEventListeners()
    this.updateVolume()
  }

  inputChange(value: number): void {
    const percent = value / 100
    if (this.volumeControl?.value !== percent && !isNaN(percent)) {
      this.volumeControl?.change(percent)
    }
  }

  updateVolume(): void {
    // When we use regular Vue approach to render position and duration,
    // the value sometimes rendered multiple times.
    // For example, in Chrome, mobile width, if we click forward (+15sec)
    // button 3 times quickly and then start the playback, the position
    // value will be duplicated.
    // Similarly, when the audio is playing, if we select the position text
    // with mouse, it will be duplicated.
    // I think this is Vue bug, especially since it is reproduced just with
    // text selection - we do not do anything here.
    // I was also able to reproduce it with simple counter increasing in
    // `setInterval` handler.
    // On the other hand, it might be a combination of data setup and HTML
    // structure and can be caused by something in our code.
    //
    // As a last resort solution, I update timings manually here.
    // See #2150.
    //
    // We also save current position and duration into component fields
    // for tests, as they are invisible in HTML.

    if (this.volumeControl?.value && !isNaN(this.volumeControl?.value)) {
      this.value = this.volumeControl.value * 100
    }
  }
}
