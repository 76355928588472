
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class QuestionnaireLikeBar extends Vue {
  @Prop() likeChoice!: boolean | null
}
