
import IconButton from '@/app/components/button/IconButton.vue'

import { defineComponent } from 'vue'
import { DocView } from '@/models/doc'

export default defineComponent({
  name: 'ExportBookButton',
  components: {
    IconButton,
  },
  props: {
    doc: {
      type: DocView,
      required: true,
    },
  },
})
