
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BillingView } from '@/models/billing'

import { formatTrialDateDebug } from '@/helpers/time'

@Options({
  components: {},
})
export default class PlanDate extends Vue {
  @Prop() protected billing!: BillingView

  formatDateDebug(dt: string): string {
    return formatTrialDateDebug(dt)
  }
}
