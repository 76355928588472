'use strict'

import extend from 'backbone-extend-standalone'

// Public: Base class for the Editor and Viewer elements. Contains methods that
// are shared between the two.
export function Widget(this: any, options: any): void {
  this.element = this.constructor.template.cloneNode(true)
  this.classes = { ...Widget.classes, ...this.constructor.classes }
  this.options = { ...Widget.options, ...this.constructor.options, ...options }
  this.extensionsInstalled = false
}

// Public: Destroy the Widget, unbinding all events and removing the element.
//
// Returns nothing.
Widget.prototype.destroy = function () {
  this.element.remove()
}

// Executes all given widget-extensions
Widget.prototype.installExtensions = function () {
  if (this.options.extensions) {
    for (let i = 0, len = this.options.extensions.length; i < len; i++) {
      const extension = this.options.extensions[i]
      extension(this)
    }
  }
}

Widget.prototype._maybeInstallExtensions = function () {
  if (!this.extensionsInstalled) {
    this.extensionsInstalled = true
    this.installExtensions()
  }
}

// Public: Attach the widget to a css selector or element
// Plus do any post-construction install
Widget.prototype.attach = function () {
  const appendTo = document.querySelector(this.options.appendTo)
  appendTo.appendChild(this.element)
  this._maybeInstallExtensions()
}

// Public: Show the widget.
//
// @param position - The position of the selection / annotation.
// We use this parameter to calculate where to render the Widget.
// Returns nothing.
Widget.prototype.show = function (position: any) {
  // Remove the "fixed" class
  this.element.classList.remove(this.classes.fixed)
  // Show the widget. We show it before calculating the position because
  // the position is dependant on the widget's dimensions.
  this.element.classList.remove(this.classes.hide)

  const container = document.querySelector(this.options.appendTo)
  const containerRect = container.getBoundingClientRect()

  const widgetHeight = parseInt(window.getComputedStyle(this.element).height)

  // If the start of the highlight is outside of the current viewport
  const isAboveViewport = position.top - widgetHeight < 0
  // If the end of the highlight is outside of the current viewport
  const isBelowViewport = position.bottom + widgetHeight > window.innerHeight

  if (isAboveViewport && isBelowViewport) {
    // If the highlight starts and ends outside the viewport, we add the "fixed"
    // class, which positions the widget fixed at the bottom of the viewport.
    this.element.classList.add(this.classes.fixed)
  } else {
    // If either the start or the end of the highlight is visible, we position
    // the widget relative to the selection.

    // The gap between the highlight and the widget.
    const widgetGap = 10

    // If the start of the highlight is visible we display the widget above it,
    // otherwise we display the widget below it.
    const widgetPositionTop = isAboveViewport
      ? position.bottom - containerRect.top + widgetGap
      : position.top - containerRect.top - widgetHeight - widgetGap

    this.element.style.top = `${widgetPositionTop}px`
    this.element.style.left = this.getWidgetPositionLeft(
      position.middle,
      container,
    )
  }
}

// Public: Hide the widget.
//
// Returns nothing.
Widget.prototype.hide = function () {
  this.element.classList.add(this.classes.hide)
}

// Public: Returns true if the widget is currently displayed, false otherwise.
//
// Examples
//
//   widget.show()
//   widget.isShown() # => true
//
//   widget.hide()
//   widget.isShown() # => false
//
// Returns true if the widget is visible.
Widget.prototype.isShown = function () {
  return !this.element.classList.contains(this.classes.hide)
}

// Public: Calculate the horizontal position of the widget based on the
// horizontal center of the highlight.
Widget.prototype.getWidgetPositionLeft = function (
  highlightMiddlePoint: number,
  container: HTMLElement,
): string {
  const containerWidth = container.offsetWidth
  const widgetWidth = parseInt(window.getComputedStyle(this.element).width)

  const halfWidgetWidth = widgetWidth / 2

  if (highlightMiddlePoint - halfWidgetWidth < 0) {
    // If the widget would intersect with the left edge of the container,
    // render it at the left edge instead.
    return '0px'
  }

  if (highlightMiddlePoint + halfWidgetWidth > containerWidth) {
    // If the widget would intersect with the right edge of the container,
    // render it at the right edge instead.
    return `${containerWidth - widgetWidth}px`
  }

  // If the widget is not intersecting any edges, render it normally.
  return `${highlightMiddlePoint - halfWidgetWidth}px`
}

// Classes used to alter the widgets state.
Widget.classes = {
  hide: 'annotator-hide',
  fixed: 'annotator-widget__fixed',
}

Widget.template = document.createElement('div')

// Default options for the widget.
Widget.options = {
  // A CSS selector or Element to append the Widget to.
  appendTo: '.highlights-content',
}

Widget.extend = extend
