import { ExcerptData } from '@/models/interfaces'
import { trackExcerptClick as mixpanelTrackExcerptClick } from '@/analytics/mixpanel/mixpanel'
import { backend } from '@/services/backend'

export class ExcerptView {
  public readonly data: ExcerptData

  constructor(data: ExcerptData) {
    this.data = data
  }

  /**
   * The route to redirect to when the excerpt gets clicked.
   */
  get excerptRoute(): Record<string, any> {
    return {
      name: 'book.preview',
      params: {
        url_slug: this.data.doc_url_slug,
      },
    }
  }

  /**
   * Track that the excerpt was clicked.
   */
  trackExcerptClick(): void {
    mixpanelTrackExcerptClick(this.data)
    backend.trackExcerptClick(this.data)
  }
}
