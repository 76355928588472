import { debounce } from '@/helpers/form'
import { DocView } from '@/models/doc'
import { Timer } from '@/helpers/timer'
import { emitter } from '@/services/mitt'

/**
 * Track access and read progress for the document.
 */

export class ReadingProgress {
  // The document to track the progress for
  private _doc: DocView

  private _tProgress: Timer
  private _tPositionRestore: Timer
  private _tPositionSave: Timer

  private _scrollHandler: (...args: any[]) => void

  constructor(doc: DocView, restorePosition: boolean = true) {
    this._doc = doc

    this._tProgress = new Timer(20000, () => {
      this._doc.saveProgress()
    })

    // We use a timer of 0 just to make the function run
    // asynchronously, deferring it to the end of the javascript
    // execution queue.
    this._tPositionRestore = new Timer(0, () => {
      if (restorePosition) {
        this._doc.restoreReadingPosition()
      }
    })

    this._tPositionSave = new Timer(3000, () => {
      this._doc.saveReadingPositionFromScrollPosition()
    })

    // Add scroll listener, it will initiate the reading position save
    this._scrollHandler = debounce(() => {
      this.scrollEvent()
    }, 500)
    emitter.on('appScroll', this._scrollHandler)
  }

  /*
   * Remove event listener and stop timers on destroy
   */
  destroy(): void {
    this.removeScrollEventListener()
    this.clearTimers()
  }

  clearTimers(): void {
    this._tProgress.clear()
    this._tPositionRestore.clear()
    this._tPositionSave.clear()
  }

  /*
   * Start (or restart) tracking
   */
  restartTracking(): void {
    // Clear previous timers.
    this.clearTimers()

    // Start Save Progress timer once
    this._tProgress.start()

    // Start Restore reading position timer once.
    // We don't put _doc.restoreReadingPosition() into DocBasePage.loadDocument()
    // because when calling from here, appEl.scrollHeight differs from correct
    // value (when page is fully loaded).
    this._tPositionRestore.start()

    // Start Save Reading Position timer at start
    // and later scroll event (this._scrollHandler listener) will initiates save reading position
    this.startSavePositionTimer()
  }

  startSavePositionTimer(): void {
    // Clear previous - we need only the last.
    this._tPositionSave.clear()
    this._tPositionSave.start()
  }

  /*
   * Scroll event starts timer for reading position saving.
   */
  scrollEvent(): void {
    this.startSavePositionTimer()
  }

  /**
   * Detach the scroll event handler.
   */
  removeScrollEventListener(): void {
    // Remove Event listener
    emitter.off('appScroll', this._scrollHandler)
    // Clear running timer for postiton save.
    this._tPositionSave.clear()
  }
}
