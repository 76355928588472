
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { wait } from '@/helpers/vue-wait'
import { BillingView } from '@/models/billing'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import BasePopup from '@/components/ui/BasePopup.vue'
import BookCover from '@/app/components/book/card/BookCover.vue'

import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { Book } from '@/models/interfaces'

@Options({
  components: {
    BasePopup,
    'v-wait': vLoading,
    ButtonSpinner,
    BookCover,
  },
})
export default class CancelPopup extends Vue {
  @Prop() private billing!: BillingView

  // For two-step cancellation flow, starting step is 1
  step: number = 1
  books: Book[] = []

  async mounted(): Promise<void> {
    this.books = await backend.getLatestPopularBooks()
  }

  /*
   * Set the popup title depending on the current step.
   *
   * Step 1: The first step in the two-step cancellation flow.
   * Step 2: The second step in the two-step cancellation flow.
   */
  get popupTitle(): string {
    switch (this.step) {
      case 1:
        return 'Are you sure you want to end your subscription?'
      case 2:
        return 'Before you go, check out our recent hit summaries.'
    }
    return 'Cancel your subscirption?'
  }

  /*
   * Set the popup class depending on the current step.
   *
   * For the 1st step we use 'popup--simple',
   * for the 2nd step we need to use a different class
   * because the style of the popup is different.
   */
  get popupClass(): string {
    if (this.step === 2) {
      return 'popup--books'
    }
    return 'popup--simple'
  }

  show(): void {
    ;(this.$refs.popup as any).show()
  }

  hide(): void {
    const popup = this.$refs.popup as any
    if (popup) {
      // Popup can already disappear due to the component rerendering.
      popup.hide()
    }
    this.step = 1
  }

  clickEnd(): void {
    this.step += 1
  }

  openBook(book: any): void {
    this.$router.push({
      name: 'book.page',
      params: {
        url_slug: book.url_slug,
        position: book.position,
      },
    })
  }

  async cancelSubscription(event: Event): Promise<void> {
    try {
      event.preventDefault()
      wait.start(this, 'Canceling')
      await this.billing.subscribeTo(this.billing.freePlan)
      this.$emit('canceled')
      wait.end(this, 'Canceling')
    } catch (error) {
      wait.end(this, 'Canceling')
      Sentry.captureException(error)
      alert('Failed to cancel the subscription')
    }
    this.hide()
  }
}
