import { backend } from '@/services/backend'
import { NotificationSettings } from './interfaces'

import { Sentry } from '@/services/sentry'

export class SettingsView {
  private _isLoading: boolean
  private _isError: boolean

  private _notificationSettings?: NotificationSettings

  constructor() {
    this._isLoading = false
    this._isError = false
  }

  public async init(): Promise<void> {
    try {
      this._isLoading = true
      this._notificationSettings = await backend.getNotificationSettings()
    } catch (error: any) {
      this._isError = true
      Sentry.captureException(error)
    } finally {
      this._isLoading = false
    }
  }

  public get isLoaded(): boolean {
    return (
      !this._isLoading &&
      !this._isError &&
      this._notificationSettings !== undefined
    )
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public get isError(): boolean {
    return this._isError
  }

  public get notificationSettings():
    | Readonly<NotificationSettings>
    | undefined {
    return this._notificationSettings
  }

  public async toggleNotificationSetting(
    setting: keyof NotificationSettings,
    value: boolean,
  ): Promise<void> {
    if (this._notificationSettings === undefined) {
      return
    }

    this._notificationSettings[setting] = value
    this._notificationSettings = await backend.putNotificationSettings(
      this._notificationSettings,
    )
  }
}
