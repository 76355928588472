import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContinueReadingCard = _resolveComponent("ContinueReadingCard")!
  const _component_ListingBlock = _resolveComponent("ListingBlock")!

  return (_ctx.view.filteredBooks.length)
    ? (_openBlock(), _createBlock(_component_ListingBlock, {
        key: 0,
        title: "Continue reading",
        viewAllLink: _ctx.viewAllLink
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.filteredBooks.slice(0, 3), (book) => {
            return (_openBlock(), _createBlock(_component_ContinueReadingCard, {
              key: book.id,
              doc: book,
              view: _ctx.view
            }, null, 8, ["doc", "view"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["viewAllLink"]))
    : _createCommentVNode("", true)
}