import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fbc572a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header header--mobile header--internal header--ios" }
const _hoisted_2 = { class: "header__inner" }
const _hoisted_3 = { class: "header__title-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "header__title"
}
const _hoisted_5 = { class: "header__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackLink = _resolveComponent("BackLink")!
  const _component_StartFreeTrialButton = _resolveComponent("StartFreeTrialButton")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BackLink, { class: "header__backlink" }),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "actions", {}, undefined, true),
        (_ctx.isFreePlan)
          ? (_openBlock(), _createBlock(_component_StartFreeTrialButton, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}