import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderAppDesktop = _resolveComponent("HeaderAppDesktop")!
  const _component_HeaderAppMobile = _resolveComponent("HeaderAppMobile")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderAppDesktop),
    _renderSlot(_ctx.$slots, "default"),
    (!_ctx.hideMobileMenu)
      ? (_openBlock(), _createBlock(_component_HeaderAppMobile, { key: 0 }))
      : (_openBlock(), _createBlock(_component_Footer, { key: 1 }))
  ]))
}