
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import FavoriteFlag from '@/app/components/listing/FavoriteFlag.vue'
import ActionMenu from '@/app/components/book/card/ActionMenu.vue'
import BookListenIcon from '@/app/components/book/card/BookListenIcon.vue'
import FreeBookIcon from '@/app/components/book/card/FreeBookIcon.vue'
import BookCardThumbnail from '@/app/components/book/card/BookCardThumbnail.vue'

import { Book } from '@/models/interfaces'

import { BooksView } from '@/models/book'

@Options({
  components: {
    FavoriteFlag,
    ActionMenu,
    FreeBookIcon,
    BookListenIcon,
    BookCardThumbnail,
  },
})
export default class BookCard extends Vue {
  @Prop() private doc!: Book
  @Prop() private view!: BooksView

  @Prop({ default: true }) private showFreeBooks!: boolean

  get isCTA(): boolean {
    return this.doc.title === 'CTA'
  }
}
