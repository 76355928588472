import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "plan__date" }
const _hoisted_2 = { style: {"display":"none"} }
const _hoisted_3 = { class: "billing-period-end" }
const _hoisted_4 = { class: "billing-period-end-local" }
const _hoisted_5 = { class: "billing-trial-start" }
const _hoisted_6 = { class: "billing-trial-start-local" }
const _hoisted_7 = { class: "billing-trial-end" }
const _hoisted_8 = { class: "billing-trial-end-local" }
const _hoisted_9 = { class: "plan__date-text" }
const _hoisted_10 = { class: "plan__date-text--black-text" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "plan__date-text--black-text plan__date-date" }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { class: "plan__date-text--black-text plan__date-date" }
const _hoisted_15 = {
  key: 0,
  class: "plan__date-text plan__trial-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.billing.current_period_end_datetime), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.formatDateDebug(_ctx.billing.current_period_end_datetime)), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.billing.trial_start_datetime), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatDateDebug(_ctx.billing.trial_start_datetime)), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.billing.trial_end_datetime), 1),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDateDebug(_ctx.billing.trial_end_datetime)), 1)
    ]),
    _createElementVNode("p", _hoisted_9, [
      _createTextVNode(" Billed "),
      _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.billing.plan.periodInfo) + ".", 1),
      (_ctx.billing.cancel_at_period_end)
        ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
            _createTextVNode(" Active until "),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.billing.billingPeriodEnd) + ".", 1)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_13, [
            _createTextVNode(" Next billing on "),
            _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.billing.billingPeriodEnd) + ".", 1)
          ]))
    ]),
    (_ctx.billing.is_trial)
      ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
          _createTextVNode(" Trial period: "),
          _createElementVNode("span", null, _toDisplayString(_ctx.billing.trialMode), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}