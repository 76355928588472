
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import { wait } from '@/helpers/vue-wait'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import BasePopup from '@/components/ui/BasePopup.vue'
import { Mixpanel } from '@/analytics/mixpanel'
import { Sentry } from '@/services/sentry'
import { backend } from '@/services/backend'
import { AccountStatus } from '@/models/interfaces'
import { ValidationState } from '@/helpers/form'
import FormError from '@/components/ui/FormError.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import AccountStatusInfo from '@/app/components/account/AccountStatusInfo.vue'
import { auth } from '@/services/auth'

@Options({
  components: {
    AccountStatusInfo,
    LoadingSpinner,
    BasePopup,
    'v-wait': vLoading,
    ButtonSpinner,
    FormError,
  },
})
export default class DeleteAccountPopup extends Vue {
  private accountStatus: AccountStatus | null = null
  private validation: ValidationState = new ValidationState()
  private deletionRequestComplete: boolean = false
  private email: string | null = null

  beforeMount(): void {
    this.email = auth.getEmail()
  }

  async show(): Promise<void> {
    ;(this.$refs.popup as any).show()

    if (this.accountStatus === null) {
      try {
        this.accountStatus = await backend.getAccountStatus()
      } catch (error) {
        // The error here is usually NetworkError or Timeout, but
        // we still capture all errors to Sentry to be aware if
        // error rate increases.
        Sentry.withScope(function (scope) {
          scope.setFingerprint(['account-status-loading-error'])
          Sentry.captureException(error)
        })
      }
    }
    Mixpanel.trackAccountDeleteClick()
  }

  hide(): void {
    this.validation.reset()
    const popup = this.$refs.popup as any
    if (popup) {
      // Popup can already disappear due to the component rerendering.
      popup.hide()
    }
    Mixpanel.trackAccountDeleteCancel()
  }

  async requestDeletion(event: Event): Promise<void> {
    event.preventDefault()
    this.validation.reset()
    try {
      wait.start(this, 'Deleting')
      await backend.requestAccountDeletion()
      this.deletionRequestComplete = true
      wait.end(this, 'Deleting')
    } catch (error) {
      wait.end(this, 'Deleting')
      Sentry.captureException(error)
      this.validation.showError(
        'delete_account',
        'Account deletion failed. Please try again.',
      )
    }
  }

  get popupTitle(): string {
    return this.deletionRequestComplete
      ? 'Check Your Email to Delete'
      : 'Delete Account'
  }
}
