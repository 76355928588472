
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { Book } from '@/models/interfaces'

import { BooksView } from '@/models/book'

import BookCover from '@/app/components/book/card/BookCover.vue'
import BookCardBackground from '@/app/components/book/card/BookCardBackground.vue'

@Options({
  components: { BookCover, BookCardBackground },
})
export default class NewReleasesCard extends Vue {
  @Prop() private doc!: Book
  @Prop() private view!: BooksView
}
