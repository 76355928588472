
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { secondsToTextDate } from '@/helpers/time'

export default class BookExportLimit extends Vue {
  @Prop() private seconds!: number

  private textTime: string = ''
  private recallTimer: boolean = true
  private secondsLeft: number = 0

  beforeMount(): void {
    this.secondsLeft = this.seconds
    this.updateTextTime()
  }

  /**
   * Reformat seconds to a "days, hours, minutes, seconds" string.
   * Recalls itself if secondsLeft more or equal to 0
   */
  updateTextTime(): void {
    if (this.secondsLeft <= 0) {
      // Reload the page to display the download link.
      setTimeout(() => {
        window.location.reload()
      }, 500)
      return
    }
    // Reformat seconds to a string.
    this.textTime = secondsToTextDate(this.secondsLeft)
    // Update secondsLeft (1 second less)
    this.secondsLeft--
    // If secondsLeft is more or equal to 0, call function 1 more time with 1 second timeout
    if (this.recallTimer && this.secondsLeft >= 0) {
      setTimeout(() => {
        this.updateTextTime()
      }, 1000)
      return
    }
    return
  }

  beforeUnmount(): void {
    // Stop timer when component is closed
    this.secondsLeft = 0
    this.recallTimer = false
  }
}
