import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "coupon v-fade" }
const _hoisted_2 = { class: "coupon__content" }
const _hoisted_3 = { class: "coupon__text-box" }
const _hoisted_4 = { class: "coupon__title" }
const _hoisted_5 = { class: "coupon__value" }
const _hoisted_6 = { class: "coupon__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, [
          _createTextVNode(" You've unlocked "),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.billing.billing_plans.prompt_value) + "% ", 1),
          _createTextVNode(" off your subscription! ")
        ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.billing.billing_plans.prompt), 1)
      ])
    ])
  ]))
}