
import { Vue } from 'vue-class-component'
import { Options, Prop, Watch } from 'vue-property-decorator'

import { DocView } from '@/models/doc'
import { DocFavoriteView } from '@/models/doc.favorite'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {},
})
export default class SidebarFavorite extends Vue {
  private view: DocFavoriteView | null = null

  @Prop() private doc!: DocView

  beforeMount(): void {
    this.view = new DocFavoriteView(this.doc)
  }

  @Watch('doc')
  onDocUpdate(): void {
    this.view = new DocFavoriteView(this.doc)
  }

  async favoriteToggle($event: Event): Promise<void> {
    await assertNotNull(this.view).favoriteToggle($event)
  }

  get showAdded(): boolean {
    return assertNotNull(this.view).hint === 'adding'
  }

  get showRemoved(): boolean {
    return assertNotNull(this.view).hint === 'removing'
  }
}
