
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { HeaderView } from '@/models/header'

import AppMenuItem from './AppMenuItem.vue'

@Options({
  components: {
    AppMenuItem,
  },
})
export default class AppMenu extends Vue {
  @Prop() public view!: HeaderView
}
