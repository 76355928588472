import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewReleasesCard = _resolveComponent("NewReleasesCard")!
  const _component_ListingBlock = _resolveComponent("ListingBlock")!

  return (_openBlock(), _createBlock(_component_ListingBlock, {
    title: "New Releases",
    viewAllLink: _ctx.viewAllLink,
    numberOfColumns: 2
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.filteredBooks.slice(0, 3), (book, index) => {
        return (_openBlock(), _createBlock(_component_NewReleasesCard, {
          key: book.id,
          doc: book,
          view: _ctx.view,
          class: _normalizeClass(_ctx.thirdCard(index)),
          onUnitClick: ($event: any) => (_ctx.openBook(book))
        }, null, 8, ["doc", "view", "class", "onUnitClick"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["viewAllLink"]))
}