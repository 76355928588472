
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import FormError from '@/components/ui/FormError.vue'
import { ValidationState } from '@/helpers/form'

import DisablePopup from '@/app/components/export/DisablePopup.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { readwise } from '@/services/readwise'
import { wait } from '@/helpers/vue-wait'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    DisablePopup,
    InputWithContextMenu,
  },
})
export default class ReadwiseSettings extends Vue {
  validation: ValidationState = new ValidationState()
  token: string | null = null
  view = readwise

  @Prop({ default: false }) protected isReadwiseEnabled!: boolean

  async beforeMount(): Promise<void> {
    await this.refresh()
    this.validation.reset()
  }

  async refresh(): Promise<void> {
    this.token = await readwise.getToken()
    this.$emit('update-enabled', this.token !== null)
  }

  async checkForm(): Promise<void> {
    if (!this.token || this.token.trim() === '') {
      this.validation.showMessage('readwise_token', 'Token may not be blank.')
      return
    }
    if (this.isReadwiseEnabled) {
      this.disableReadwise()
    } else {
      await this.enableReadwise()
    }
  }

  getErrorMessage(error: any): string {
    // We already sent the error to the Sentry (see ReadwiseAPI._post).
    // Here we just show the error for a user.
    let errorMessage = 'Readwise integration error. Contact support, please.'

    // We can get error without response in case when the browser
    // received a 401 unauthorized response when doing the preflight
    // OPTION request, resulting in a Network Error.
    // See: https://stackoverflow.com/questions/49886315/axios-interceptors-response-undefined
    if (error.response === undefined) {
      errorMessage = 'Wrong Access Token: Authorization Failed.'
    }
    // Just in case added handling for 401 Authorization Error.
    // Not sure if this case will be at all or we always will
    // receive the previous case (an error without response),
    // but it is better to have this.
    if (error.response && error.response.status === 401) {
      errorMessage = 'Wrong Access Token: Authorization Failed.'
    }

    return errorMessage
  }

  async enableReadwise(): Promise<void> {
    try {
      this.validation.reset()
      wait.start(this, 'Enabling')
      await readwise.enableAndExportAll(assertNotNull(this.token))
      this.$emit('update-enabled', true)
    } catch (error) {
      this.validation.showMessage('readwise_token', this.getErrorMessage(error))
    } finally {
      wait.end(this, 'Enabling')
    }
  }

  disableReadwise(): void {
    ;(this.$refs.popupDisable as any).show()
  }
}
