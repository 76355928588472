import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionCard = _resolveComponent("CollectionCard")!
  const _component_ListingBlock = _resolveComponent("ListingBlock")!

  return (_ctx.view.dataLength)
    ? (_openBlock(), _createBlock(_component_ListingBlock, {
        key: 0,
        title: _ctx.title,
        viewAllLink: _ctx.viewAllLink
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.view.filteredDocs.slice(0, 3), (collection) => {
            return (_openBlock(), _createBlock(_component_CollectionCard, {
              key: collection.url_slug,
              doc: collection,
              view: _ctx.view
            }, null, 8, ["doc", "view"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["title", "viewAllLink"]))
    : _createCommentVNode("", true)
}