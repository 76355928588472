
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { HeaderView } from '@/models/header'

import HeaderUserMenu from './UserMenu.vue'
import StartFreeTrialButton from './StartFreeTrialButton.vue'
import DocsSortButtonMobile from '@/app/components/listing/DocsSortButton.mobile.vue'

@Options({
  components: {
    HeaderUserMenu,
    StartFreeTrialButton,
    DocsSortButtonMobile,
  },
})
export default class RootPageMobileHeader extends Vue {
  @Prop() public title!: string
  @Prop() public hasDocSort: boolean = false
  public view: HeaderView | null = null

  async beforeMount(): Promise<void> {
    this.view = new HeaderView(this)
    await this.view.init()
  }
}
