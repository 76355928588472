
import * as TypedAssert from 'typed-assert'
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import CircleSpinner from '@/components/ui/CircleSpinner.vue'

import { Mixpanel } from '@/analytics/mixpanel'
import {
  AudioDoc,
  PlaybackControl,
  PlaybackState,
  ChapterProgressControl,
  ChapterPrevNextControl,
} from '@/services/player'
import { assertNotNull } from '@/helpers/typing'

import PlayerChapterProgress from './ChapterProgress.vue'
import PlayerVolume from './Volume.vue'
import PlayerRate from './Rate.vue'
import { MediaSessionControl } from '@/services/player/control.media-session'
import {
  loadAudioFromNativeStorage,
  isAudioDownloaded,
  androidLocalAudioFileSrc,
} from '@/services/native.storage'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import { platform } from '@/services/platform'
import { Book } from '@/models/interfaces'
import { DocView } from '@/models/doc'

@Options({
  components: {
    LoadingSpinner,
    PlayerChapterProgress,
    PlayerVolume,
    PlayerRate,
    CircleSpinner,
  },
})
export default class Player extends Vue {
  @Prop() private doc!: DocView

  // Audio element reference.
  private htmlAudio: HTMLAudioElement | null = null
  private audio: AudioDoc | null = null

  // We initialize this as an empty string to  wait for a potential load of the audio
  // file from native storage to finish to prevent the browser from fetching the file
  // online in case we have it available offline.
  private audioSource: string = ''

  // Set to true when the initialization process is over.
  private ready: boolean = false

  private isPlaybackFromDownload: boolean = false

  private playbackState: PlaybackState = 'pause'

  private playbackControl: PlaybackControl | null = null
  private chapterControl: ChapterPrevNextControl | null = null
  private progressControl: ChapterProgressControl | null = null
  private mediaSessionControl: MediaSessionControl | null = null

  private playbackStartTime: number = 0

  async mounted(): Promise<void> {
    const { audioSource, isPlaybackFromDownload } = await getAudioSource(
      this.doc,
    )
    this.audioSource = audioSource
    this.isPlaybackFromDownload = isPlaybackFromDownload

    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/audio
    const element = document.querySelector('audio')
    TypedAssert.isNotNull(element)
    this.htmlAudio = element
    this.audio = new AudioDoc(this.htmlAudio, this.doc)

    // Preload audio when we show the Player component.
    // It is not rendered until we click the button in the sidebar to show it,
    // see the `doc.controls.isPlayerRendered` property and `AudioPlayer` tag
    // in the `@/app/components/doc/reading/Content.vue`.
    this.audio.load()

    this.playbackControl = new PlaybackControl(this.audio, this.playbackUpdate)
    this.playbackControl.initEventListeners()
    this.chapterControl = new ChapterPrevNextControl(
      this.audio,
      (pageIdx: number) => {
        this.$router.push(this.doc.pageLink(pageIdx).params)
      },
    )
    this.progressControl = new ChapterProgressControl(this.audio, this.doc)
    this.mediaSessionControl = await MediaSessionControl.initialize(
      this.audio,
      this.playbackControl,
      this.chapterControl,
    )

    // Hook chapter control to doc.pageNum changes, so when we navigate
    // the text chapters, the playback follows.
    this.$watch('doc.pageNum', (newPageNum: number) => {
      assertNotNull(this.chapterControl).go(newPageNum)
    })

    this.ready = true
  }

  async unmounted(): Promise<void> {
    await this.mediaSessionControl?.releaseMediaSession()
  }

  /**
   * Playback state update handler.
   */
  async playbackUpdate(state: PlaybackState): Promise<void> {
    if (state === 'play') {
      this.playbackStartTime = Date.now()
    }

    // We transfer from playing to pause / end.
    if (this.playbackState === 'play' && state !== 'play') {
      // Send the mixpanel 'Audio Listen' event.
      const timeDiffInSeconds = Math.floor(
        (Date.now() - this.playbackStartTime) / 1000,
      )
      Mixpanel.trackAudioListen(
        this.doc,
        timeDiffInSeconds,
        this.isPlaybackFromDownload,
      )
    }

    this.playbackState = state

    await this.mediaSessionControl?.setPlaybackState(
      state === 'play' ? 'playing' : 'paused',
    )
  }

  get isBook(): boolean {
    return this.doc.doc_type === 'book'
  }
}

/**
 * Get the url to use as the audio `src` attribute. Will use the downloaded audio data
 * if the book was downloaded to native device storage, or the regular web URL otherwise.
 *
 * On iOS, can have the side effect of transferring the audio data from native storage
 * to the browser cache to make the data accessible. This takes a few seconds.
 */
async function getAudioSource(book: Book): Promise<{
  /**
   * The URL to use as the `src` attribute of the HTML audio element.
   */
  audioSource: string
  /**
   * If the audio playback will be using data from a native storage download.
   */
  isPlaybackFromDownload: boolean
}> {
  if (await platform.isNativeIOSApp()) {
    // In the iOS app, transfer the audio data from native storage into the browser
    // cache, if the data is downloaded to native storage and not yet in browser
    // cache.
    // `isPlaybackFromDownload` will be true if the audio data got transferred
    // from native storage to browser cache now or is still present from a previous
    // transfer.
    // `isPlaybackFromDownload` will be false if the audio gets streamed from
    // the internet, or from browser cache but the cache was not populated through
    // native storage.
    return {
      isPlaybackFromDownload: await loadAudioFromNativeStorage(book),
      audioSource: book.audio?.url || '',
    }
  } else if (
    (await platform.isNativeAndroidApp()) &&
    (await isAudioDownloaded(book))
  ) {
    // If in the Android app and the audio data is downloaded to native storage,
    // use the native storage file directly as the source for the audio element.
    // This is only possible on Android. On iOS, it does not work because of a CORS
    // issue, see https://github.com/shortformhq/main/issues/6169#issuecomment-1225582895
    return {
      isPlaybackFromDownload: true,
      audioSource: await androidLocalAudioFileSrc(book),
    }
  } else {
    // Otherwise, i.e. on Android without the audio data downloaded or on web (where
    // downloads are not supported), simply use the regular web URL of the audio file.
    return {
      isPlaybackFromDownload: false,
      audioSource: book.audio?.url || '',
    }
  }
}
