
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import PlanCard from './PlanCard.vue'
import PlanInfo from './PlanInfo.vue'

import { BillingView, PlanView } from '@/models/billing'
import { convertAndFormatPrice } from '@/models/billing.localized.pricing'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {
    ButtonSpinner,
    PlanCard,
    PlanInfo,
  },
})
export default class PlanMonthly extends Vue {
  @Prop() private billing!: BillingView

  get plan(): PlanView {
    return this.billing.monthlyPlan
  }

  get showLocalizedPrice(): boolean {
    // `userCountry` is defined in `BillingData.vue` when the
    // user is in the test group of the `localized_pricing_14281` experiment.
    return this.billing.userCountry !== undefined
  }

  get localizedPrice(): string {
    const price = this.plan.amount / 100

    const localPrice = convertAndFormatPrice(
      price,
      assertNotNull(this.billing.userCountry),
    )

    return `(≈${localPrice}/month)`
  }
}
