import Fuse from 'fuse.js'

import { ForumUser, Comment, Forum, Thread } from './interfaces'
import { calendar, relative } from '@/helpers/time'

/**
 * Thread data and logic to display thread in components.
 */
export class ThreadView implements Thread {
  id: string
  url_slug: string
  title: string
  text: string
  author: ForumUser
  created: string
  updated: string
  last_active: string
  comments: Comment[]
  comments_count: number

  constructor(thread: Thread) {
    this.id = thread.id
    this.url_slug = thread.url_slug
    this.title = thread.title
    this.text = thread.text
    this.author = thread.author
    this.created = thread.created
    this.updated = thread.updated
    this.last_active = thread.last_active
    this.comments_count = thread.comments_count
    this.comments = thread.comments || []

    // Assign parent comments, we use them in the UI logic
    // to quickly access the reply's parent comment.
    for (const idx in this.comments) {
      const comment = this.comments[idx]
      for (const jdx in comment.replies) {
        comment.replies[jdx].parent = comment
      }
    }
    // @ts-ignore
  }

  get last_active_time(): string {
    return relative(this.last_active)
  }

  get posted_time(): string {
    return calendar(this.updated)
  }
}

/**
 * Forum data and logic to display forum in components.
 */
export class ForumView implements Forum {
  private _forum: Forum
  private _threads: ThreadView[]
  private _allThreads: ThreadView[]

  public id: string
  public title: string

  constructor(forum: Forum) {
    this._forum = forum
    this.id = forum.id
    this.title = forum.title

    this._threads = new Array<ThreadView>()
    for (const idx in forum.threads) {
      const thread = forum.threads[idx]
      this._threads.push(new ThreadView(thread))
    }

    this._allThreads = this._threads
  }

  /**
   * Forum threads.
   */
  get threads(): ThreadView[] {
    return this._threads
  }

  filter(search: string): void {
    search = search.trim()
    if (search === '') {
      this._threads = this._allThreads
      return
    }

    const options: Fuse.IFuseOptions<ThreadView> = {
      // @ts-ignore fuse type doesn't handle nested author.username and ts
      // thinks it is unassignable.
      keys: ['title', 'author.username'],
    }
    const fuse = new Fuse(this._allThreads, options)
    const results = fuse.search(search)
    // @ts-ignore
    this._threads = results.map((r: Fuse.FuseResult<DocListItem>) => {
      return r.item
    })
  }
}
