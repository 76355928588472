
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import DocLayout from '@/app/components/doc/Layout.vue'
import Footer from '@/app/components/Footer.vue'
import CommunitySearch from '@/app/components/community/Search.vue'
import ThreadListItem from '@/app/components/community/ThreadListItem.vue'
import EOCButton from '@/app/components/billing/EOCButton.vue'

import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { ForumView } from '@/models/forum'
import { DocView } from '@/models/doc'

@Options({
  components: {
    DocLayout,
    Footer,
    CommunitySearch,
    ThreadListItem,
    EOCButton,
  },
})
export default class Community extends Vue {
  @Prop() private doc!: DocView
  isLoading: boolean = true
  forum: ForumView | null = null

  async beforeMount(): Promise<void> {
    await this.loadForum()
  }

  async loadForum(): Promise<void> {
    try {
      this.isLoading = true
      const response = await backend.getForum(this.doc.forum_id)
      this.forum = new ForumView(response)
      this.isLoading = false
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        Sentry.captureException(error)
        throw error
      }
    }
  }
}
