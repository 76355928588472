
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BookTutorial } from '@/models/doc.tutorial'

@Options({})
export default class Tutorial extends Vue {
  @Prop() private bookTutorial!: BookTutorial

  private showTooltip: boolean = false
}
