import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocsSort = _resolveComponent("DocsSort")!
  const _component_BookCTACard = _resolveComponent("BookCTACard")!
  const _component_BookCard = _resolveComponent("BookCard")!
  const _component_DocsSortMobile = _resolveComponent("DocsSortMobile")!
  const _component_ListingPageLayoutSimple = _resolveComponent("ListingPageLayoutSimple")!

  return (_openBlock(), _createBlock(_component_ListingPageLayoutSimple, { view: _ctx.view }, _createSlots({
    nodata: _withCtx(() => [
      _createTextVNode(" No books found. ")
    ]),
    default: _withCtx(() => [
      (_ctx.view && _ctx.view.books)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.view.filteredBooks, (book) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (book.title === 'CTA')
                ? (_openBlock(), _createBlock(_component_BookCTACard, {
                    key: book.id
                  }))
                : (_openBlock(), _createBlock(_component_BookCard, {
                    key: book.id,
                    doc: book,
                    view: _ctx.view
                  }, null, 8, ["doc", "view"]))
            ], 64))
          }), 256))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.view)
      ? {
          name: "filterBox",
          fn: _withCtx(() => [
            _createVNode(_component_DocsSort, {
              view: _ctx.view,
              class: "filter__order--hide-mobile"
            }, null, 8, ["view"])
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.view)
      ? {
          name: "filterBoxMobile",
          fn: _withCtx(() => [
            _createVNode(_component_DocsSortMobile, { view: _ctx.view }, null, 8, ["view"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["view"]))
}