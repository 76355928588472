import { assertNotNull } from '@/helpers/typing'
import { WaitUI } from '@/models/interfaces'
import { getCurrentInstance, ref, computed } from 'vue'

export function useWait(): WaitUI {
  const vueInstance = assertNotNull(getCurrentInstance())
  const instanceProxy = assertNotNull(vueInstance.proxy)

  const start = (action: string): void => {
    instanceProxy.$wait.start(action)
  }

  const end = (action: string): void => {
    instanceProxy.$wait.end(action)
  }

  const is = (action: string): boolean => {
    // We use a ref to reactively track the state
    const isLoading = ref(false)

    // The computed property will update whenever the wait state changes
    const isLoadingComputed = computed(() => instanceProxy.$wait.is(action))

    // Update the ref whenever the computed property changes
    isLoading.value = isLoadingComputed.value

    return isLoading.value
  }

  return { is, start, end }
}
