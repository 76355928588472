
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BillingView, PlanView } from '@/models/billing'

@Options({})
export default class PlanInfo extends Vue {
  @Prop() private billing!: BillingView
  @Prop() private plan!: PlanView
}
