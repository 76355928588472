import { storage } from '@/helpers/storage'
import { Sentry } from '@/services/sentry'

export enum FontSize {
  small = 'font-small',
  medium = 'font-medium',
  large = 'font-large',
  largest = 'font-largest',
}

enum FontSizeClass {
  active = 'control-font__size-btn--active',
  normal = 'reading-settings iconfont control-font__size-btn',
  buttonSize = 'control-font__size-btn--size-',
}

type FontOption = {
  size: FontSize
  title: string
}

const FONT_OPTIONS: FontOption[] = [
  { size: FontSize.small, title: 'Small' },
  { size: FontSize.medium, title: 'Medium' },
  { size: FontSize.large, title: 'Large' },
  { size: FontSize.largest, title: 'Largest' },
]

export enum LineHeight {
  small = 'line-small',
  medium = 'line-medium',
  large = 'line-large',
}

enum LineHeightClass {
  active = 'control-font__line-height-btn--active',
  normal = 'reading-settings control-font__line-height-btn',
  buttonSize = 'control-font__line-height-btn--check-',
}

type LineOption = {
  size: LineHeight
  title: string
}

const LINE_OPTIONS: LineOption[] = [
  { size: LineHeight.small, title: 'Small' },
  { size: LineHeight.medium, title: 'Medium' },
  { size: LineHeight.large, title: 'Large' },
]

export enum MarginSize {
  small = 'margin-small',
  medium = 'margin-medium',
  large = 'margin-large',
}

enum MarginSizeClass {
  active = 'control-font__margins-btn--active',
  normal = 'reading-settings control-font__margins-btn',
  buttonSize = 'control-font__margins-btn--check-',
}

type MarginOption = {
  size: MarginSize
  title: string
}

const MARGIN_OPTIONS: MarginOption[] = [
  { size: MarginSize.small, title: 'Small' },
  { size: MarginSize.medium, title: 'Medium' },
  { size: MarginSize.large, title: 'Large' },
]

export enum FontFamily {
  rotunda = 'Rotunda, sans-serif',
  openSans = 'Open Sans, sans-serif',
  merriweather = 'Merriweather, serif',
}

export type FontFamilyOption = {
  font: FontFamily
  title: string
}

export const FONT_FAMILY_OPTIONS: FontFamilyOption[] = [
  { font: FontFamily.rotunda, title: 'Rotunda' },
  { font: FontFamily.openSans, title: 'Open Sans' },
  { font: FontFamily.merriweather, title: 'Merriweather' },
]

type ReadingSettingsOptions = {
  fontOptions: FontOption[]
  marginOptions: MarginOption[]
  lineOptions: LineOption[]
  fontFamilyOptions: FontFamilyOption[]
}

export class DocReadingSettings {
  public fontSize: FontSize = FontSize.medium
  public fontFamily: FontFamily = FontFamily.rotunda
  public lineHeight: LineHeight = LineHeight.medium
  public marginSize: MarginSize = MarginSize.medium
  public options: ReadingSettingsOptions

  constructor() {
    // Read the data from local storage.
    try {
      const data = storage.getItem('sf_reading_settings')
      if (data) {
        const settings = JSON.parse(data)
        this.fontSize = settings.fontSize
        this.marginSize = settings.marginSize
        this.fontFamily = settings.fontFamily
        this.lineHeight = settings.lineHeight
      }
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      this.options = {
        fontOptions: FONT_OPTIONS,
        lineOptions: LINE_OPTIONS,
        marginOptions: MARGIN_OPTIONS,
        fontFamilyOptions: FONT_FAMILY_OPTIONS,
      }
    }
  }

  public get cssClass(): string {
    return `${this.fontSize} ${this.lineHeight} ${this.marginSize}`
  }

  public get readingOptions(): Record<string, unknown> {
    return this.options
  }

  public get selectedFontFamily(): FontFamilyOption {
    const opts = this.options.fontFamilyOptions
    for (let i = 0; i < opts.length; i += 1) {
      if (this.fontFamily === opts[i].font) {
        return opts[i]
      }
    }
    return FONT_FAMILY_OPTIONS[0]
  }

  public getFontSizeClass(item: FontOption, index: number): string {
    const activeClass = this.fontSize === item.size ? FontSizeClass.active : ''
    return `${FontSizeClass.normal} ${FontSizeClass.buttonSize}${
      index + 1
    } ${activeClass}`
  }

  public getLineHeightClass(item: LineOption, index: number): string {
    const activeClass =
      this.lineHeight === item.size ? LineHeightClass.active : ''
    return `${LineHeightClass.normal} ${LineHeightClass.buttonSize}${
      index + 1
    } ${activeClass}`
  }

  public getMarginClass(item: MarginOption, index: number): string {
    const activeClass =
      this.marginSize === item.size ? MarginSizeClass.active : ''
    return `${MarginSizeClass.normal} ${MarginSizeClass.buttonSize}${
      index + 1
    } ${activeClass}`
  }

  public saveSettings(): void {
    const settings = {
      fontSize: this.fontSize,
      marginSize: this.marginSize,
      fontFamily: this.fontFamily,
      lineHeight: this.lineHeight,
    }
    storage.setItem('sf_reading_settings', JSON.stringify(settings))
  }

  public resetToDefaults(): void {
    this.fontSize = FontSize.medium
    this.marginSize = MarginSize.medium
    this.fontFamily = FontFamily.rotunda
    this.lineHeight = LineHeight.medium
    this.saveSettings()
  }
}
