import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in'

import { IS_PRODUCTION } from '@/init/settings'
import { platform } from '@/services/platform'
import { convertError } from '@/models/error'

/**
 * Authentication Request Options:
 *
 * - clientId:
 *  - For native app: the application ID, same as “appId”
 *    in capacitor config (see https://capacitorjs.com/docs/config).
 *  - For web app: a client ID assigned to it after creating a
 *    new identity record for the website in
 *    "Certificates, Identifiers & Profiles",
 *    Register a new identifier - Services IDs
 *    (https://developer.apple.com/account/resources/identifiers/list)
 *    See also "About Sign in with Apple" (https://help.apple.com/developer-account/?lang=en#/devde676e696).
 *
 * - redirectUri: As I understand, `redirectUri` is only relevant for
 * the web app, the URL to redirect to from the Apple Sign In dialog.
 *
 * - state: used as CSRF token:
 *
 * * App backend generates “state” value when we display the login form
 * * Frontend adds “state” to the auth request
 * * Apple sends back the response with “state” in it
 * * Response is forwarded to the backend
 * * Backend compares initial “state” with the one in the response
 *
 * Note: probably this is more relevant for the sign in process in the web app,
 * not sure if there is a way to hack into the sign in when it is performed
 * in the native app (as request/response here happen on the level
 * of the Apple platform code).
 *
 * - nonce: it is encoded into the “identityToken” that can be decoded
 * (JWT token) and compared on the server:
 *
 * * App backend (or frontend?) generates unique “nonce” value when
 *   we display the login form
 * * Frontend adds “nonce” to the auth request
 * * Apple sends back the response with “identityToken”
 *   with “nonce”encoded in it
 * * Frontend resends apple response to the backend
 * * Backend decodes “identityToken” and makes sure that “nonce” value
 *   is the same as was initially sent.
 *
 * This is to prevent replay attacks: a network sniffer records Apple
 * response and then a recorded response is sent to the app to get
 * the account access.
 */

const optionsNative: SignInWithAppleOptions = {
  // App Bunlde ID (app ID, com.shortform.app also works here)
  clientId: 'com.shortform.application',
  redirectURI: 'https://www.shortform.com/app',
  scopes: 'email name',
  // CSRF-like token, we do not use it currently.
  // It would require additional backend interaction to create user session
  // before we start the login and save 'state' for it.
  // This should be more relevant for the web app flow and less for the
  // native app.
  state: '12345',
  // OAuth 'nonce' to prevent replay attacks.
  // I am not sure if it is safe to just generate a unique value here
  // and then pass it to the backend along with returned identityToken.
  // The attacker could intercept that backend request and it would
  // be replayable.
  // Probably we could generate it on the backend, but again (same as for
  // 'state' above) we would have to create user session before we
  // start the auth process and it does not fit our current auth flows.
  nonce: 'nonce',
}

// For web, see
// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
//
// It looks like apple does not return unique user ID in this case.
const optionsWeb: SignInWithAppleOptions = {
  // App Serivce ID (from Certificates, Identifiers & Profiles)
  clientId: 'com.shortform.www',
  redirectURI: IS_PRODUCTION
    ? 'https://www.shortform.com/app'
    : 'https://dev.shortform.com/app',
  scopes: 'email name',
  state: '12345',
  nonce: 'nonce',
}

import { ValidationState } from '@/helpers/form'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

import { afterAuth } from './logic'
import { SocialAuthView } from './social'
import { OnAuthStart, OnAuthSuccess, OnAuthError } from './models'

/*
 * Handle Apple authentication in the native app.
 *
 * See https://github.com/capacitor-community/apple-sign-in
 */
export class AppleNativeAuthView implements SocialAuthView {
  public validation: ValidationState

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  isEnabled: boolean = true

  constructor(
    validation: ValidationState,
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.validation = validation
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  public init(): void {}

  /**
   * Login with Apple.
   */
  async start(): Promise<void> {
    let appleResponse: SignInWithAppleResponse
    try {
      // Response structure:
      // interface SignInWithAppleResponse {
      //  response: {
      //    user: string | null;
      //    email: string | null;
      //    givenName: string | null;
      //    familyName: string | null;
      //    identityToken: string;
      //    authorizationCode: string;
      //  }
      // }
      //
      // Also see apple docs for the response details:
      // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms  # noqa
      const isNativeApp = await platform.isNativeApp()
      const options = isNativeApp ? optionsNative : optionsWeb

      appleResponse = await SignInWithApple.authorize(options)

      if (!IS_PRODUCTION && window.__UNIT_TESTING !== true) {
        // Temporary, log apple response on develop for debugging.
        console.log(appleResponse)
      }

      // Apple has "Hide email" option in the Sign In dialog and in
      // that case we get an email like this:
      // rfnjh4rgme@privaterelay.appleid.com
      // In this case the user is doomed to create another, duplicate and
      // empty account if another sign in option is used later.
      //
      // I considered raising an error here, but it does not work good.
      // Apple only shows the Hide My Email / Share My Email option once,
      // the first time the Sign In dialog is used.
      //
      // On the second Sign In this option is not shown and the user ends
      // up with "Cannot access email." error.
      // If we decide later to revert to this option, we need to provide
      // a more detailed guide for the user to reset the state:
      //
      // - Go to Settings app
      // - Open Apple ID - Password & Security - Apps Using Apple ID - Shortform
      // - Press "Stop using Apple ID"
      // - Try to Sign In with Apple again, choose "Share my Email"
      //
      // This is neccessary to allow signing in to your account with
      // other options - email and password, Google and Facebook.
      //
      // // To avoid relay email accounts we restrict the "Hide my email" option.
      // if (email.split('@')[1] === 'privaterelay.appleid.com') {
      //   throw {
      //     error: 'Cannot access email.',
      //   }
      // }

      const appleUser = appleResponse.response
      const newUser = {
        email: appleUser.email,
        appleUser: appleUser.user,
        appleGivenName: appleUser.givenName,
        appleFamilyName: appleUser.familyName,
        appleIdentityToken: appleUser.identityToken,
        appleAuthorizationCode: appleUser.authorizationCode,
      }

      const authData = await backend.appleLoginOrRegister(newUser)
      const nextPage = await afterAuth(authData)

      // Redirect the user to the app
      return this.onSuccess(nextPage)
    } catch (error: any) {
      let message = 'Apple authentication failed.'

      const err = convertError(error)
      if (error.error) {
        message = 'Apple authentication failed: '
        if (error.error === 'popup_closed_by_user') {
          // Expected error, when user does not finish the popup.
          message = message + 'popup closed by user.'
        } else if (error.error === 'user_trigger_new_signin_flow') {
          message = message + 'triggered new signin flow.'
        } else if (error.error === 'popup_blocked_by_browser') {
          message = message + 'popup blocked by browser.'
        } else {
          message = message + error.error
          // Send unexpected error to Sentry.
          Sentry.captureMessage(error.error)
        }
      } else if (
        err.isSocialAuthError() &&
        err.message() === 'apple_no_email_error'
      ) {
        message =
          "We couldn't get an email address from your Apple account. Please try again."
      } else {
        // Unexpected error, log it to Sentry.
        Sentry.withScope((scope) => {
          scope.setContext('SF: Apple Response', {
            response: appleResponse,
          })
          Sentry.captureException(error)
        })
      }
      this.validation.showError('social', message)
      this.onError()
    }
  }

  static async tryLogout(): Promise<void> {
    // Do nothing, SignInWithApple does not have logout method.
  }
}
