
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import {
  ChapterProgressControl,
  ChapterPrevNextControl,
} from '@/services/player'
import { setText } from '@/helpers/html'
import VueSlider from 'vue-slider-component'
import { ref } from 'vue'
@Options({
  components: {
    VueSlider,
  },
})
export default class PlayerChapterProgress extends Vue {
  @Prop() private progressControl!: ChapterProgressControl
  @Prop() private chapterControl!: ChapterPrevNextControl
  public currentPosition: string = ''
  public currentDuration: string = ''

  slider: any = ref(null)
  mounted(): void {
    this.progressControl._updateCallback = this.updateTimings
    // Update timings initially, while audio is loading.
    this.updateTimings()
  }

  inputChange(percent: number): void {
    if (this.progressControl.positionInPercent !== percent && !isNaN(percent)) {
      this.progressControl.inputChange(percent)
    }
  }

  dragStart(): void {
    this.chapterControl.dragStart()
  }

  dragEnd(): void {
    this.chapterControl.dragEnd()
  }

  updateTimings(): void {
    // When we use regular Vue approach to render position and duration,
    // the value sometimes rendered multiple times.
    // For example, in Chrome, mobile width, if we click forward (+15sec)
    // button 3 times quickly and then start the playback, the position
    // value will be duplicated.
    // Similarly, when the audio is playing, if we select the position text
    // with mouse, it will be duplicated.
    // I think this is Vue bug, especially since it is reproduced just with
    // text selection - we do not do anything here.
    // I was also able to reproduce it with simple counter increasing in
    // `setInterval` handler.
    // On the other hand, it might be a combination of data setup and HTML
    // structure and can be caused by something in our code.
    //
    // As a last resort solution, I update timings manually here.
    // See #2150.
    //
    // We also save current position and duration into component fields
    // for tests, as they are invisible in HTML.
    this.currentPosition = this.progressControl.position
    this.currentDuration = this.progressControl.duration

    setText(this, '.player-time__num--pos', this.currentPosition)
    setText(this, '.player-time__num--duration', this.currentDuration)

    if (this.slider && !isNaN(this.progressControl.positionInPercent)) {
      this.slider.setValue(this.progressControl.positionInPercent)
    }
  }
}
