
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'

import { ListingView } from '@/models/docs.listing'
import { DocsScroll } from '@/models/docs.scroll'
import { Mixpanel } from '@/analytics/mixpanel'

@Options({
  components: {
    LoadingSpinner,
  },
})
export default class ListingPageLayoutSimple extends Vue {
  @Prop() view!: ListingView

  docsScroll: DocsScroll | null = null

  mounted(): void {
    if (this.view) {
      this.docsScroll = new DocsScroll(this.view)
    }
  }

  beforeUnmount(): void {
    if (this.docsScroll) {
      this.docsScroll.removeScrollEventListener()
    }
  }

  clickHandler(event: Event): void {
    try {
      Mixpanel.trackDocView(
        event,
        this.view.filterTitle,
        this.$router.options.history.state.back as string,
      )
    } catch (error) {
      // Unexpected error in the Mixpanel tracking
      console.error(error)
      return
    }
  }
}
