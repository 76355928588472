
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'
import {
  FontFamilyOption,
  FONT_FAMILY_OPTIONS,
  FontSize,
  LineHeight,
  MarginSize,
} from '@/models/doc.settings'
import { clickOutside } from '@/helpers/directives'

@Options({
  directives: {
    clickOutside: clickOutside,
  },
})
export default class SidebarSettings extends Vue {
  @Prop() private doc!: DocView
  private showFonts: boolean = false
  private selectedFontFamily: FontFamilyOption = FONT_FAMILY_OPTIONS[0]

  beforeMount(): void {
    this.selectedFontFamily = this.doc.settings.selectedFontFamily
  }

  hideSettings(): void {
    this.doc.controls.hideReadingSettings()
  }

  setFontSize(fontSize: FontSize): void {
    this.doc.settings.fontSize = fontSize
    this.doc.settings.saveSettings()
  }

  setMargins(marginSize: MarginSize): void {
    this.doc.settings.marginSize = marginSize
    this.doc.settings.saveSettings()
  }

  setLineHeight(lineHeight: LineHeight): void {
    this.doc.settings.lineHeight = lineHeight
    this.doc.settings.saveSettings()
  }

  setFontFamily(fontFamily: FontFamilyOption): void {
    this.doc.settings.fontFamily = fontFamily.font
    this.selectedFontFamily = fontFamily
    this.doc.settings.saveSettings()
  }

  resetToDefaults(): void {
    this.doc.settings.resetToDefaults()
    this.selectedFontFamily = this.doc.settings.selectedFontFamily
  }
}
