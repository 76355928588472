
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import FormError from '@/components/ui/FormError.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import { auth } from '@/services/auth'
import { ValidationState } from '@/helpers/form'

@Options({
  components: {
    FormError,
    InputWithContextMenu,
  },
})
export default class Username extends Vue {
  @Prop() private model!: any
  @Prop() private validation!: ValidationState

  hasUsername: boolean = true

  beforeMount(): void {
    this.model.username = auth.getUsername()
    if (!this.model.username) {
      this.hasUsername = false
    }
  }

  saved(): void {
    const username = auth.getUsername()
    if (username) {
      this.hasUsername = true
    }
  }
}
