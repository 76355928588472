
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

export default class IntegrationButton extends Vue {
  @Prop() private name!: string
  @Prop() private description!: string
  @Prop() private imageUrl!: string
  @Prop() private darkImageUrl!: string

  @Prop({ default: false }) private isSelected!: boolean
  @Prop({ default: true }) private isSelectable!: boolean
  @Prop({ default: false }) private isEnabled!: boolean

  get parsedState(): string {
    if (!this.isSelectable) {
      return 'Coming Soon'
    }
    if (this.isEnabled) {
      return 'Connected'
    }
    return ''
  }
}
