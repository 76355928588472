<template>
  <div class="skeleton-podcast">
    <div class="skeleton-podcast__cover"></div>
    <div class="skeleton-podcast__lower">
      <div class="skeleton-podcast__title"></div>
      <div class="skeleton-podcast__episodes"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.app/css/common/variables.scss';
@import '@/assets.app/css/common/animations.scss';

.skeleton-podcast {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 326px;
  padding: 20px;
  border-radius: 8px;
  background: $c-white;
  border: 1px solid $c-border;
  @media only screen and (max-width: $w-tablet-s) {
    flex-direction: row;
    flex-shrink: 0;
    --listing-block-items-width-mobile: 312px;
    height: 138px;
    padding: 16px;
  }

  &__cover {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 4px;
    margin-bottom: 20px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      flex-shrink: 0;
      width: 104px;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  &__lower {
    width: 100%;
    @media only screen and (max-width: $w-tablet-s) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__title {
    width: 100%;
    height: 18px;
    border-radius: 4px;
    margin-bottom: 8px;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  &__episodes {
    width: 25%;
    height: 16px;
    border-radius: 4px;
    animation: skeleton-loading 1s linear infinite alternate;
    @media only screen and (max-width: $w-tablet-s) {
      width: 50%;
    }
  }
}

.night-mode .skeleton-podcast {
  background: $c-dark-1;
  border-color: $c-dark-3;

  &__cover,
  &__title,
  &__episodes {
    animation: skeleton-loading-night-mode 1s linear infinite alternate;
  }
}
</style>
