<template>
  <div class="cta">
    <div>
      <h4 class="cta__title">
        <slot name="title">Try Shortform for free </slot>
      </h4>
      <div class="cta__text">
        <slot name="description">
          Learn the key points of a book in 30 minutes or less.
        </slot>
      </div>

      <router-link
        :to="{ name: 'signup' }"
        class="button button--theme button--size-m"
      >
        <slot name="button">Sign up for free</slot>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets.v2/css/custom/_variables.scss';

.cta {
  border-radius: 12px;
  border: 1px solid $c-theme;
  background: $c-yellow;
  width: 100%;
  padding: 40px 60px;
  text-align: center;

  @media only screen and (max-width: $sm) {
    padding: 40px 30px;
  }

  &__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  &__text {
    color: $c;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.button {
  font-size: 16px;
  line-height: 18px;
}
</style>
