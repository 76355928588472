
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { AppleNativeAuthView } from '@/models/auth/apple.native'

@Options({})
export default class AppleSignInButton extends Vue {
  @Prop() private view!: AppleNativeAuthView
  @Prop() private title!: string

  async login(): Promise<void> {
    await this.view.start()
  }
}
