
import { Vue } from 'vue-class-component'
import { Options, Prop, Watch } from 'vue-property-decorator'

// The `import annotator from 'annotator'` imports 'browser.js' module
// from the annotator which adds related modules to the export object
// (for example, it has the `ui` field for UI module).
// But it only works in browser (doesn't work in tests for example),
// so we import the modules we need individually.
// Note: `browser.js` is specified as `browser` field in annotator's
// package.json, that is why it is only used in browser, but not in node.
// import annotator from 'annotator'

import '@/app/annotator/css/annotator.css'
import '@/assets.app/css/annotator.scss'
import { App } from '@/app/annotator/src/app'
import { main } from '@/app/annotator/src/ui'

import { backendStorage } from '@/services/annotator.backend.storage'

import { Annotation } from '@/models/interfaces'
import { ContentView, getHighlightPublicLink } from '@/models/doc'
import { trim } from '@/helpers/text'
import { emitter } from '@/services/mitt'
import { Sentry } from '@/services/sentry'

/**
 * A note about how the annotator keeps the position of the highlight.
 * Every annotation has "start" and "end" xpath selectors and "startOffset" and "endOffset",
 * the position inside the element, found by the selector.
 *
 * For example, we have following content:
 *
 * ```html
 * <p>Idea generation</p>
 * <ul>
 *   <li>To generate good new ideas,
 *   <strong>the most important factor is to generate lots of ideas</strong>.
 *   </li>
 * </ul>
 * ```
 *
 * And the annotations:
 *
 * ```javascript
 *   highlights: [
 *     {
 *       quote: 'generation',
 *       text: 'my note',
 *       ranges: [ {
 *         start: '/p[1]',
 *         startOffset: 5,
 *         end: '/p[1]',
 *         endOffset: 15
 *       } ],
 *     },
 *     {
 *       quote: 'good new ideas',
 *       text: '',
 *       ranges: [ {
 *           start: '/ul[1]/li[1]',
 *           startOffset: 12,
 *           end: '/ul[1]/li[1]',
 *           endOffset: 26,
 *       } ],
 *     },
 *   ],
 * ```
 *
 * The first annotation is attached to the 1st `<p>` (the `/p[1]` selector for both start and end).
 * And the offsets are 5-15, so it selects the word `generation`.
 *
 * The second annotation is attached to the 1st `<li>` inside the 1st `<ul>`
 * (the `/ul[1]/li[1]` selector for both start and end).
 * And the offsets are 12-26, so it selects the phrase `good new ideas`.
 *
 * This means that text edits will affect the highlights:
 *
 * - If new text is added or removed before the highlight, the highlight will move back or forth
 * - If new document elements (paragraphs, lists) are added or removed before the highlight,
 * the highlight may jump to the wrong paragraph.
 *
 * Related: https://github.com/openannotation/annotator/issues/115
 *
 */

/**
 * A wrapper component for annotator.js (https://github.com/openannotation/annotator).
 */
@Options({})
export default class Annotator extends Vue {
  @Prop() private content!: ContentView

  private app?: any = null
  private _translatedObserver?: MutationObserver = undefined

  @Watch('content')
  async onContentChanged(): Promise<void> {
    await this.app.destroy()
    this.startApp()
  }

  async unmounted(): Promise<void> {
    await this.app.destroy()
  }

  beforeDestroy(): void {
    if (this._translatedObserver) {
      this._translatedObserver.disconnect()
    }
  }

  mounted(): void {
    this.startApp()
    this.setupTranslationObserver()
  }

  /**
   * Handles the case where book is translated with Google Translate.
   *
   * In this case the document text is updated after initial load.
   * It is possible to make annotation in the translated language,
   * but after going out of the book and back in, annotations are
   * not visible because annotator is started before the page
   * translation.
   *
   * We hanlde this issue by setting up a MutationObserver that
   * watches for the root `html` element.
   * When Google Translate updates the page, it adds the
   * class=`translated-ltr` or `translated-rtl` to the HTML element.
   *
   * When this class is added, we rigger the Annotator application
   * restart.
   */
  setupTranslationObserver(): void {
    // Restart annotator app if the page was translated by
    // Google Translate (the Google Chrome feature).
    // Detection code is from
    // https://stackoverflow.com/questions/4887156/detecting-google-chrome-translation
    const restartAnnotator = (): void => {
      // Delay the update a bit, Google Translate may not
      // update the page content immediately.
      setTimeout(() => {
        // Page has been translated.
        this.app.destroy().then(() => {
          this.startApp()
        })
      }, 1500)
    }

    // Check if the page was translated.
    const checkTranslation = (el: HTMLElement): void => {
      const classList = el.className
      if (classList.match('translated')) {
        this.$nextTick(() => {
          restartAnnotator()
        })
      }
    }

    // Setup the MutationObserver.
    this._translatedObserver = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const target = m.target as HTMLElement
        checkTranslation(target)
      }
    })

    // Watch for the `html` element class change.
    this._translatedObserver.observe(document.documentElement, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
      childList: false,
      characterData: false,
    })

    // We will not have class change if we go to the book page
    // when translation is already enabled (for example going
    // from Discover to book with enabled translation).
    // So we also check on book page load if the translation
    // is already active.
    checkTranslation(document.documentElement)
  }

  startApp(): void {
    /* eslint-disable @typescript-eslint/no-this-alias */
    const component = this
    /* eslint-enable @typescript-eslint/no-this-alias */

    /* eslint-disable no-console */
    // @ts-ignore
    this.app = new App()
    this.app.include(main, {
      element: this.$el.querySelector('.highlights-content > div.sf-chapter'),
      // Unused:
      // editorExtensions: [ui.tags.editorExtension],
      viewerExtensions: [
        // Unused:
        // ui.markdown.viewerExtension,
        // ui.tags.viewerExtension,
      ],
      onSelectionStarted: () => {
        emitter.emit('docContentSelectionStarted')
      },
    })
    this.app.include(backendStorage(this.content))
    this.app
      .start()
      .then(() => {
        // Patch app.annotations to support 'share' actions.
        this.app.annotations.share = function (
          where: string,
          obj: Annotation,
        ): any {
          if (where === 'facebook') {
            return this._cycle(
              obj,
              'shareFacebook',
              'beforeAnnotationShareFacebook',
              'annotationSharedFacebook',
            ).then((obj: Annotation) => {
              component.shareFacebook(obj)
            })
          }
          if (where === 'twitter') {
            return this._cycle(
              obj,
              'shareTwitter',
              'beforeAnnotationShareTwitter',
              'annotationSharedTwitter',
            ).then((obj: Annotation) => {
              component.shareTwitter(obj)
            })
          }
          if (where === 'link') {
            return this._cycle(
              obj,
              'shareLink',
              'beforeAnnotationShareLink',
              'annotationSharedLink',
            ).then((obj: Annotation) => {
              component.shareLink(obj)
            })
          }
          throw new Error('Unknown share method: ' + where)
        }
        this.app.annotations
          .load()
          .then(function () {
            // Debug:
            // console.log('Annotations loaded!')
          })
          .catch(function (error: any) {
            Sentry.captureException(error)
          })
      })
      .catch(function (error: any) {
        Sentry.captureException(error)
      })
    /* eslint-enable no-console */
  }

  shareFacebook(annotation: Annotation): void {
    const quote = trim(annotation.quote, 200, '...')
    const text = `Check out this quote: "${quote}"`

    // https://developers.facebook.com/docs/sharing/reference/share-dialog
    FB.ui({
      method: 'share',
      display: 'popup',
      href: getHighlightPublicLink(this, annotation, this.content),
      quote: text,
    })
  }

  shareTwitter(annotation: Annotation): void {
    const url = encodeURIComponent(
      getHighlightPublicLink(this, annotation, this.content),
    )

    const quote = trim(annotation.quote, 100, '...')
    const book = this.content.doc.title
    const author = this.content.doc.author
    const text = `"${quote}"\n--Shortform summary of ${book} by ${author}`

    const urlText = encodeURIComponent(text)
    window.open(
      `https://twitter.com/intent/tweet?url=${url}&text=${urlText}&via=_shortform`,
    )
  }

  shareLink(annotation: Annotation): void {
    const url = getHighlightPublicLink(this, annotation, this.content)
    window.open(url)
  }
}
