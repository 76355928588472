import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets.v2/images/link-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-729ed482"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "learn-more-item__link" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_4 = {
  key: 1,
  class: "iconfont ilink"
}
const _hoisted_5 = { class: "learn-more-item__title" }
const _hoisted_6 = { class: "learn-more-item__snippet" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    rel: "noopener noreferrer",
    target: "_blank",
    class: "learn-more-item",
    href: _ctx.item?.url
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isPublicPage)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("span", _hoisted_4))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.item.name), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.item.snippet), 1)
    ])
  ], 8, _hoisted_1))
}