import { Book } from '@/models/interfaces'
import * as NativeStorage from '@/services/native.storage'
import { Mixpanel } from '@/analytics/mixpanel'

type AudioDownloadState = 'downloaded' | 'noDownload' | 'inProgress'

export class BookNativeStorageView {
  public readonly supportsAudioDownloads: boolean = false

  private readonly book: Book
  private _error: string | null = null
  private _audioDownloadState: AudioDownloadState = 'noDownload'

  static async initialize(book: Book): Promise<BookNativeStorageView> {
    const supportsAudioDownloads =
      !!book.audio && (await NativeStorage.isNativeStorageSupported())
    const instance = new BookNativeStorageView(book, supportsAudioDownloads)
    await instance.refreshDownloadState()
    return instance
  }

  private constructor(book: Book, supportsAudioDownloads: boolean) {
    this.book = book
    this.supportsAudioDownloads = supportsAudioDownloads
  }

  async downloadAudio(): Promise<void> {
    this._error = null
    this._audioDownloadState = 'inProgress'
    NativeStorage.downloadBook(this.book)
      .then(this.refreshDownloadState.bind(this))
      .catch(() => {
        this._error = 'Error: Download failed'
        this.refreshDownloadState()
      })

    Mixpanel.trackDeviceStorageDownload(this.book)
  }

  async deleteAudioDownload(): Promise<void> {
    this._error = null

    Mixpanel.trackDeviceStorageDelete(this.book)

    await NativeStorage.deleteDownload(this.book)
    await this.refreshDownloadState()
  }

  get audioDownloadState(): AudioDownloadState {
    return this._audioDownloadState
  }

  get error(): string | null {
    return this._error
  }

  private async refreshDownloadState(): Promise<void> {
    this._audioDownloadState = (await NativeStorage.isAudioDownloaded(
      this.book,
    ))
      ? 'downloaded'
      : 'noDownload'
  }
}
