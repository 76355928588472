
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'
import { emitter } from '@/services/mitt'
import SidebarFavorite from '@/app/components/doc/reading/SidebarFavorite.vue'
import SidebarSettings from '@/app/components/doc/reading/SidebarSettings.vue'
import { DESKTOP_WIDTH } from '@/init/settings'

@Options({
  components: {
    SidebarFavorite,
    SidebarSettings,
  },
})
export default class DocSidebar extends Vue {
  @Prop() private doc!: DocView

  // Set to false to hide text controls: font settings, audio player button.
  @Prop({ default: true }) private showTextControls!: boolean

  private nightMode: boolean = false

  async mounted(): Promise<void> {
    // The 'docContentClicked' is emitted in @/app/components/doc/reading/Content.vue and
    // @/app/components/article/Content.vue
    emitter.on('docContentClicked', (event: any) => {
      this.doc.controls.onDocContentClicked(event)
    })
    emitter.on('showBookControlBar', () => {
      this.doc.controls.show()
    })
    emitter.on('windowResize', () => {
      this.doc.controls.onWindowResize()
    })
    emitter.on('toggleNightMode', async () => {
      this.doc.controls.onToggleNightMode()
    })
    // The 'docContentSelectionStarted' is emitted in @/app/components/doc/reading/Annotator.vue
    emitter.on('docContentSelectionStarted', () => {
      // Don't hide controls on desktop screens
      if (window.innerWidth >= DESKTOP_WIDTH) {
        return
      }

      this.doc.controls.hide()
    })
  }

  togglePlayer(event: Event): void {
    this.doc.controls.togglePlayer(event)
  }

  async toggleNightMode(): Promise<void> {
    await this.doc.controls.toggleNightMode()
  }
}
