<template>
  <div class="short-onboarding__banner">
    <p class="short-onboarding__banner-text">Start your 5-day trial!</p>

    <div class="short-onboarding__banner-buttons">
      <router-link
        :to="{ name: 'billing' }"
        class="btns btns--white short-onboarding__banner-button"
      >
        Start Trial
      </router-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets.app/css/common/variables.scss';

.short-onboarding {
  &__banner {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: $c-yellow;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    padding: 29px 150px 24px 150px;

    @media (max-width: $w-tablet-b) {
      padding: 26px 40px;
      @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(26px + env(safe-area-inset-top));
      }
    }

    @media (max-width: $w-medium-b) {
      padding: 12px 16px;
      @supports (padding-top: env(safe-area-inset-top)) {
        padding-top: calc(12px + env(safe-area-inset-top));
      }
    }

    &-text {
      color: $c-black-2;
      font-weight: 500;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 0;
      align-content: center;

      @media (max-width: $w-tablet-b) {
        font-size: 24px;
      }

      @media (max-width: $w-medium-b) {
        font-size: 16px;
      }
    }

    &-buttons {
      display: flex;
      gap: 12px;
    }

    &-button {
      @media (max-width: $w-medium-b) {
        font-size: 14px;
      }

      &:hover {
        background: $c-black-17;
      }
    }
  }
}
</style>
