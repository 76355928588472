
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { BillingView } from '@/models/billing'
import { platform } from '@/services/platform'

import PlanAnnual from './PlanAnnual.vue'
import PlanMonthly from './PlanMonthly.vue'
import InfoWhyGreat from './InfoWhyGreat.vue'
import InfoFAQ from './InfoFAQ.vue'
import InfoQuestions from './InfoQuestions.vue'
import InfoDeleteAccount from '@/app/components/billing/InfoDeleteAccount.vue'
import BillingCoupon from './BillingCoupon.vue'
import BillingNotifications from './BillingNotifications.vue'

import { localizedPricing14281Experiment } from '@/services/ab'
import { getUserCountry } from '@/models/billing.localized.pricing'

@Options({
  components: {
    PlanAnnual,
    PlanMonthly,
    InfoWhyGreat,
    InfoFAQ,
    InfoQuestions,
    InfoDeleteAccount,
    BillingCoupon,
    BillingNotifications,
  },
})
export default class BillingData extends Vue {
  // The `firstVisit` is a string because it is used as route property.
  @Prop({ default: 'false' }) private firstVisit!: string
  @Prop() protected billing!: BillingView

  private isFirstVisit: boolean = false
  private isAndroidApp: boolean = false

  async beforeMount(): Promise<void> {
    const isNativeApp = await platform.isNativeApp()
    if (isNativeApp) {
      return
    }

    const userCountry = getUserCountry()

    if (userCountry === undefined) {
      // User country was not found among test countries,
      // so we exclude them from the experiment.
      await localizedPricing14281Experiment(true)
      return
    }

    const showLocalizedPricing = await localizedPricing14281Experiment()

    // We check for `userCountry` here again (even though it has to be `true` to
    // start the experiment) because it's technically possible (though pretty unlikely)
    // that a user would start the experiment in a time zone from one of the tested countries
    // and then come back to this page from a time zone outside of the tested countries.
    if (showLocalizedPricing && userCountry) {
      this.billing.userCountry = userCountry
    }
  }

  async mounted(): Promise<void> {
    // Copy the firstVisit value, so it doesn't get reset when
    // we use anchor link for the #FAQ section.
    this.isFirstVisit = this.firstVisit === 'true'
    // On the Android native app, we show text informing users of the
    // free content they can access, so that we do not violate the
    // Subscriptions policy. See issue #10818.
    this.isAndroidApp = await platform.isNativeAndroidApp()
  }
}
