
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import BackLink from '@/app/components/BackLink.vue'
import ShareMenu from '@/app/components/doc/preview/ShareMenu.vue'
import ActionsMenu from '@/app/components/doc/preview/ActionsMenu.vue'
import AddToFavorite from '@/app/components/doc/preview/AddToFavorite.vue'
import ExportBookButton from '@/app/components/doc/preview/ExportBookButton.vue'
import { emitter } from '@/services/mitt'
import { DocView } from '@/models/doc'

@Options({
  components: {
    BackLink,
    ShareMenu,
    ActionsMenu,
    AddToFavorite,
    ExportBookButton,
  },
})
export default class Header extends Vue {
  @Prop() private readonly doc?: DocView
  isRounded: boolean = false

  beforeMount(): void {
    emitter.on('appScroll', this.scrollHandler)
  }

  beforeUnmount(): void {
    emitter.off('appScroll')
  }

  scrollHandler(): void {
    // Get current scrolling position
    const scrollTop = (document.getElementById('app') as HTMLElement).scrollTop

    // Get height of the book block - it's different on different widths
    const bookBlockHeight = (
      document.querySelector('.doc-preview__cover') as HTMLElement
    ).offsetHeight

    // Book block has roundings, and we need to apply rounding to the header
    // before it reaches the roundings of the book block.
    const maxScroll = bookBlockHeight - 8
    this.isRounded = scrollTop > maxScroll
  }
}
