
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { Doc } from '@/models/interfaces'
import { DocFavoriteView } from '@/models/doc.favorite'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {},
})
export default class FavoriteFlag extends Vue {
  @Prop() private doc!: Doc
  private view: DocFavoriteView | null = null

  beforeMount(): void {
    this.view = new DocFavoriteView(this.doc)
  }

  get flagClass(): string {
    return this.doc.is_favorite
      ? 'collection__icon collection__icon--active iconfont iflag2'
      : 'collection__icon iconfont iflag'
  }

  /**
   * Click handler.
   *
   * We did use the CSS property `:disabled` to prevent the favorite toggle
   * being clicked too often, but if the user clicks favorite button twice,
   * the book is being opened, which is not the behavior we want. This was
   * happening because the disabled button still propagates event to the
   * parent, which is book card.
   *
   * Instead of disabling the button via CSS, we just stop the
   * event propagation and do nothing in the click handler.
   */
  async favoriteToggle($event: Event): Promise<void> {
    if (this.view?.buttonDisabled) {
      return
    }
    await assertNotNull(this.view).favoriteToggle($event)
  }
}
