import { AudioDoc, ChapterData } from './logic'

export const CHAPTER_UPDATE_TIMEOUT_MS = 800

/**
 * Chapter back/forward UI control for audio player.
 *
 * Allows to switch the playback to the next / previous book chapters.
 * Also restores saved audio position when we move between chapters.
 *
 * Watches for 'timeupdate' events to detect when the next chapter starts
 * playing and calls the `chapterChangeCallback` in that case (in the player
 * UI we switch to the next book page).
 */
export class ChapterPrevNextControl {
  private audio: AudioDoc
  private _canplay: boolean = false

  // True if the user is adjusting the slider but hasn't released it
  private _isDragging = false

  /**
   * Unix time in milliseconds saying when the latest chapter navigation was request,
   * i.e. through the next/previous chapter buttons of the audio controls, or by
   * choosing a specific chapter in the book controls.
   */
  private _lastChapterNavigationDate?: number

  private _chapterChangeCallback: any

  constructor(audio: AudioDoc, chapterChangeCallback: any) {
    this.audio = audio
    this._chapterChangeCallback = chapterChangeCallback

    this.audio.addListener('timeupdate', () => {
      this._timeUpdateHandler()
    })
    this.audio.addListener('canplay', () => {
      this._canplayHandler()
    })
  }

  /* Used by the UI to tell us that the user has started dragging the slider */
  dragStart(): void {
    this._isDragging = true
  }

  /* Used by the UI to tell us that the user has stopped dragging the slider */
  dragEnd(): void {
    this._isDragging = false
    // End of chapter checking is disabled while dragging, so we need to recheck it when
    // Dragging is done, in case the user dragged all the way till the end and stopped playback
    this._timeUpdateHandler()
  }

  hasNext(): boolean {
    return this.audio.chapters.hasNext()
  }

  hasPrev(): boolean {
    return this.audio.chapters.hasPrev()
  }

  next(): void {
    if (!this.audio.chapters.hasNext()) {
      return
    }
    this.go(this.audio.chapters.next().idx)
  }

  prev(): void {
    if (!this.audio.chapters.hasPrev()) {
      return
    }
    this.go(this.audio.chapters.prev().idx)
  }

  go(chapterIndex: number): void {
    const currentIndex = this.audio.chapters.current().idx
    if (chapterIndex === currentIndex) {
      return
    }
    const chapter = this.audio.chapters.go(chapterIndex)
    if (this._canplay) {
      this._lastChapterNavigationDate = Date.now()
      this.audio.currentTime = chapter.start
      this._restoreAudioPosition(chapter)
      this._chapterChangeCallback(chapterIndex)
    }
  }

  /*
   * Restore audio position.
   *
   * The function checks whether the saved position is within the given chapter
   * (between chapter start and chapter end), and restores it to the position
   * saved in the database.
   *
   */
  _restoreAudioPosition(chapter: ChapterData): void {
    const savedPosition = this.audio.doc.audio_position
    const currentStart = chapter.start
    const currentEnd = chapter.end
    if (savedPosition > currentStart && savedPosition < currentEnd) {
      // Saved audio position is within the current chapter, restore it
      this.audio.currentTime = savedPosition
    }
  }

  _canplayHandler(): void {
    if (this._canplay) {
      // The "canplay" event is sent repeatedly, exit
      return
    }

    this._canplay = true

    // Run the position resotre only after we get the "canplay" event.
    // On mobile Safari, setting the audio.currentTime before that
    // does not work.
    this._restoreAudioPosition(this.audio.chapters.current())

    if (!this.audio.currentTime) {
      this.audio.currentTime = this.audio.chapters.current().start
    }
  }

  /**
   * Track the audio playback progress (the `timeupdate` event).
   *
   * This is used to switch to the next chapter in the UI when the audio
   * reaches the start of next chapter.
   */
  _timeUpdateHandler(): void {
    // Do not try to change the chapter when the audio is not loaded.
    // We can still receive `timeupdate` events during the audio initialization.
    if (!this._canplay) {
      return
    }

    // Do not try to change the chapter when the slider is not released
    if (this._isDragging) {
      return
    }

    // There is a bug in Webkit for iOS through which after setting the `currentPosition`
    // of a HTMLAudioElement to position x while the audio is playing, the currentPosition
    // first correctly goes to position x but then jumps back to a few milliseconds
    // earlier than x. We set currentPosition on user-initiated navigations, i.e.
    // when clicking next/previous chapter in the audio controls or when selecting a
    // specific chapter of the book controls.
    //
    // We want to ignore this jumping back to a few milliseconds earlier than x, so we
    // return early here if a user-initiated navigation happened during the last
    // CHAPTER_UPDATE_TIMEOUT_MS milliseconds.
    //
    // See also https://github.com/shortformhq/main/issues/5415
    if (this._isUpdateFromNavigation()) {
      return
    }

    const newTime = this.audio.currentTime
    const result = this.audio.chapters.update(newTime)
    if (result) {
      this._chapterChangeCallback(result.idx)
    }
  }

  private _isUpdateFromNavigation(): boolean {
    if (!this._lastChapterNavigationDate) {
      return false
    }
    const msSinceNavigation = Date.now() - this._lastChapterNavigationDate
    return msSinceNavigation < CHAPTER_UPDATE_TIMEOUT_MS
  }
}
