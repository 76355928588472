
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { ThreadView } from '@/models/forum'
import { DocView } from '@/models/doc'

@Options({
  components: {},
})
export default class ThreadListItem extends Vue {
  @Prop() private thread!: ThreadView
  @Prop() private doc!: DocView
}
