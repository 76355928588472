
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import IconButton from '@/app/components/button/IconButton.vue'

import { DocView } from '@/models/doc'
import { clickOutside } from '@/helpers/directives'

@Options({
  directives: {
    clickOutside,
  },
  components: {
    IconButton,
  },
})
export default class ActionsMenu extends Vue {
  @Prop() private doc!: DocView

  private isMenuVisible: boolean = false

  private toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible
  }

  private hideMenu(): void {
    this.isMenuVisible = false
  }
}
