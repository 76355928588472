
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import CommentReply from '@/app/components/community/CommentReply.vue'
import { Comment } from '@/models/interfaces'
import { calendar } from '@/helpers/time'

@Options({
  components: {
    CommentReply,
  },
})
export default class CommentComponent extends Vue {
  @Prop() private comment!: Comment

  get posted(): string {
    return calendar(this.comment.created)
  }

  get parentComment(): Comment {
    if (this.comment.parent) {
      return this.comment.parent
    }
    return this.comment
  }

  showReply(): void {
    this.$emit('replyShow', this.parentComment)
  }
}
