
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import IconButton from '@/app/components/button/IconButton.vue'

import { DocView } from '@/models/doc'
import { toggleDocFavorite } from '@/models/doc.favorite'
import { waitTimer } from '@/helpers/timer'

@Options({
  components: {
    IconButton,
  },
})
export default class AddToFavorite extends Vue {
  @Prop() private doc!: DocView

  /**
   * Controls the add to favorite poppup state
   */
  private showAddFavoritePopup: boolean = false

  /**
   * Controls the remove from favorite poppup state
   */
  private showRemoveFavoritePopup: boolean = false

  /**
   * Temporarily disable the button when request is being made
   */
  private buttonDisabled: boolean = false

  private get isFavorite(): boolean {
    return this.doc.is_favorite
  }

  private showPopup(): void {
    if (this.doc.is_favorite) {
      this.showRemoveFavoritePopup = true
    } else {
      this.showAddFavoritePopup = true
    }
  }

  private hidePopup(): void {
    this.showAddFavoritePopup = false
    this.showRemoveFavoritePopup = false
  }

  private async toggleFavorite(): Promise<void> {
    if (this.buttonDisabled) {
      return
    }

    this.buttonDisabled = true
    this.showPopup()

    setTimeout(() => this.hidePopup(), 1000)

    await toggleDocFavorite(this.doc)
    await waitTimer(500)

    this.buttonDisabled = false
  }
}
