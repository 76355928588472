
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import BasePopup from '@/components/ui/BasePopup.vue'
import { AnnotationView } from '@/models/doc'

@Options({
  components: {
    BasePopup,
  },
})
export default class DeleteHighlightPopup extends Vue {
  private highlight!: AnnotationView

  show(highlight: AnnotationView): void {
    this.highlight = highlight
    ;(this.$refs.popup as any).show()
  }

  hide(): void {
    ;(this.$refs.popup as any).hide()
  }

  confirmDeletion(): void {
    this.$emit('confirm-delete', this.highlight)
    this.hide()
  }
}
