
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import Layout from '@/app/components/Layout.vue'

import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { AnnotationWithContent } from '@/models/interfaces'
import { assertNotNull } from '@/helpers/typing'

@Options({
  components: {
    Layout,
  },
})
export default class HighlightPage extends Vue {
  highlight: AnnotationWithContent | null = null

  async beforeCreate(): Promise<void> {
    try {
      this.highlight = await backend.getHighlight(
        this.$route.params.highlight_id as string,
      )
    } catch (error: any) {
      if (error.request && error.request.status === 404) {
        // Redirect to the non-existing page
        this.$router.push({ name: 'not_found' })
      } else {
        Sentry.captureException(error)
        throw error
      }
    }
  }

  bookUrl(): string {
    const content = assertNotNull(this.highlight).content
    return `/app/book/${content.doc.url_slug}/${content.url_slug}/`
  }
}
