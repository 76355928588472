
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import HighlightsCard from '@/app/components/doc/highlights/HighlightsCard.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import HighlightsEmpty from '@/app/components/book/highlights/HighlightsEmpty.vue'

import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { AnnotationDoc, HighlightsAllDoc } from '@/models/interfaces'

@Options({
  components: {
    'v-wait': vLoading,
    LoadingSpinner,
    HighlightsCard,
    HighlightsEmpty,
  },
})
export default class HighlightsByBook extends Vue {
  books: AnnotationDoc[] = []

  async beforeMount(): Promise<void> {
    this.refreshHighlights()
  }

  async refreshHighlights(): Promise<void> {
    wait.end(this, 'Error Loading Books')
    wait.start(this, 'Loading Books')
    try {
      const response = await backend.getHighlightsGrouped()
      this.books = response
    } catch (error: any) {
      wait.start(this, 'Error Loading Books')
      Sentry.captureException(error)
    } finally {
      wait.end(this, 'Loading Books')
    }
  }

  openBook(book: HighlightsAllDoc): void {
    this.$router.push({
      // Note: this works only for books, if we want to show
      // Highlights for other types of content, we need to fix this.
      // https://github.com/shortformhq/main/issues/11389
      name: 'book.highlights',
      params: {
        url_slug: book.url_slug,
      },
    })
  }

  isLoadingBooks(): boolean {
    return wait.is(this, 'Loading Books')
  }
}
