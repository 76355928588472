import { IS_NOT_PRODUCTION } from '@/init/settings'
import { AuthUser, Billing, Plan } from '@/models/interfaces'

export function register(user: AuthUser): void {
  if (!hasGtm()) {
    // Exit if 'dataLayer' is undefined (tracking blocked).
    return
  }
  dataLayer.push({
    event: 'Signup',
    userId: user.id,
  })
}

export function subscribe(
  user: AuthUser | null,
  new_plan: Plan,
  billing: Billing,
): void {
  let value = 0
  let label = ''
  let category = 'CardSave'
  if (new_plan.id === 'plan_monthly') {
    value = 100
    label = 'Monthly'
  } else if (new_plan.id === 'plan_annual') {
    value = 245
    label = 'Annual'
  }
  if (billing.trial_start === null) {
    // We only want to track the event once,
    // and we use trial_start for that - it is
    // null only on the first subscription,
    // once subscribed, trial starts and after
    // that trial_start / trial_end remain unchanged.
    // Set 'TestCardSave' category for shortform users.
    if (user && user.email.trim().endsWith('shortform.com')) {
      category = 'TestCardSave'
    }
    // Set 'TestCardSave' category for non-production environments.
    if (IS_NOT_PRODUCTION) {
      category = 'TestCardSave'
    }
  } else {
    category = 'BillingChange'
  }
  dataLayer.push({
    event: category,
    conversionValue: value,
    plan: label,
    userId: billing.user_id,
  })
}

export function hasGtm(): boolean {
  if (typeof dataLayer !== 'undefined' && dataLayer) {
    return true
  }
  return false
}

/*
 * Send Google Tag Manager events via dataLayer.
 *
 * Related documentation:
 * - https://developers.google.com/tag-platform/tag-manager/datalayer
 */
export * as GTM from './gtm'
