
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { clickOutside } from '@/helpers/directives'

import { Book } from '@/models/interfaces'
import { emitter } from '@/services/mitt'
import { DocActionMenu } from '@/models/doc.card.menu'
import { DocsView } from '@/models/doc'

@Options({
  directives: {
    clickOutside: clickOutside,
  },
})
export default class ActionMenu extends Vue {
  @Prop() view!: DocsView
  @Prop() doc!: Book

  menuView: DocActionMenu = new DocActionMenu(this.view, this.doc)

  mounted(): void {
    // Close other instances of ActionMenu when we show this one
    emitter.on('showActionMenu', () => {
      this.menuView.hide()
    })

    // Close the menu when the page is scrolled
    emitter.on('appScroll', (scrollElement) => {
      this.menuView.hideOnScroll(scrollElement)
    })
  }

  /**
   * Proxy function to call the menuView.hide()
   *
   * This function is necessary for Vue to properly handle
   * reactivity and set the menuView._visible property on
   * click outside.
   */
  hideMenu(): void {
    this.menuView.hide()
  }
}
