import { ComponentPublicInstance } from 'vue'

/**
 * A wrapper around vue-wait library.
 *
 * Allows to get rid of magic global properties in components:
 * "`this.$wait` - wait, what is '$wait'???"
 * Also it would make it easier to switch to another wait library
 * or replace it with own simplified implementation.
 */
export const wait = {
  start(vm: ComponentPublicInstance, action: string): void {
    vm.$wait.start(action)
  },

  end(vm: ComponentPublicInstance, action: string): void {
    vm.$wait.end(action)
  },

  is(vm: ComponentPublicInstance, action: string): boolean {
    return vm.$wait.is(action)
  },
}
