
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'

import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'
import UsernameInput from './UsernameInput.vue'

import { ValidationState, setFocus } from '@/helpers/form'
import { wait } from '@/helpers/vue-wait'
import { backend } from '@/services/backend'
import { Comment, Thread } from '@/models/interfaces'
import { DocView } from '@/models/doc'

const EMPTY_COMMENT = { id: null, text: '' }

@Options({
  components: {
    FormError,
    UsernameInput,
    'v-wait': vLoading,
    ButtonSpinner,
    InputWithContextMenu,
  },
})
export default class CommentReply extends Vue {
  @Prop() private doc!: DocView
  @Prop() private thread!: Thread
  @Prop() private comment!: Comment

  validation: ValidationState = new ValidationState()
  model: any = Object.assign({}, EMPTY_COMMENT)

  async save(): Promise<void> {
    try {
      wait.start(this, 'Posting Reply')
      // Send the reply to backend.
      const reply = await backend.saveReply(
        this.doc,
        this.thread,
        this.comment,
        this.model,
      )

      // Update comment replies with new reply.
      this.comment.replies.push(reply)
      reply.parent = this.comment

      // Reset the form state.
      this.validation.reset()
      this.model = Object.assign({}, EMPTY_COMMENT)

      // Notify parent compoment that the reply was posted
      // (parent component hides the reply form in the even handler).
      this.$emit('replyPosted', this.comment)
    } catch (error) {
      this.validation.showErrors(error)
    } finally {
      wait.end(this, 'Posting Reply')
    }
  }

  focusInput(): void {
    setFocus((this.$refs.input as InputWithContextMenu).$el as HTMLElement)
  }

  mounted(): void {
    this.focusInput()
  }
}
