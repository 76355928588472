
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocView } from '@/models/doc'
import { HeaderView } from '@/models/header'

import HeaderAppDesktop from '@/app/components/header/AppDesktop.vue'
import HeaderInternal from '@/app/components/header/Internal.vue'

@Options({
  components: { HeaderAppDesktop, HeaderInternal },
})
export default class Header extends Vue {
  @Prop() private readonly doc!: DocView
  private view: HeaderView | null = null

  async beforeMount(): Promise<void> {
    this.view = new HeaderView(this, this.doc)
    await this.view.init()
  }
}
