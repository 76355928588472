import { DocsListView } from './docs.list'
import { ListItemType, ListItem, CollectionListItem } from './interfaces'

/**
 * Collections logic used both by block and listing page.
 */
export class CollectionsView implements DocsListView {
  private _collections: CollectionListItem[]

  constructor(collections: CollectionListItem[]) {
    this._collections = collections
  }

  public get filteredDocs(): ListItem[] {
    return this._collections
  }

  public get dataLength(): number {
    return this._collections.length
  }

  public docTypeName(): ListItemType {
    return 'collection'
  }

  setShowLimit(): void {
    // Not implemented for collections
  }

  isDocSortVisible(): boolean {
    return false
  }

  /**
   * Link for the collection details (listing) page.
   */
  public startLinkParams(doc: ListItem): Record<string, unknown> {
    return {
      name: 'bookCollection',
      params: {
        collection: doc.url_slug,
      },
    }
  }
}
