import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "docs-listing__container" }
const _hoisted_2 = { class: "filter" }
const _hoisted_3 = {
  key: 0,
  class: "filter__container"
}
const _hoisted_4 = { class: "filter__title" }
const _hoisted_5 = { class: "filter__box" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.view)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.view.filterTitle), 1),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "filterBox")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      class: "docs-listing__list",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.clickHandler($event)), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.view && !_ctx.view.dataLength)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "nodata", {}, () => [
              _createTextVNode(" No data found. ")
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "filterBoxMobile")
  ]))
}