/**
 * Calculate the difference in days between current date
 * and the specified `isoDate`.
 *
 * For example:
 * > daysDiff('2021-03-24T00:00:00+00:00')
 *
 * Calculate the difference between now and 24 March, 2021.
 *
 * The function will return a nagative number if the specified
 * `isoDate` is later than current date (for example, tomorrow
 * date will result in `-1`).
 */
export function daysDiff(isoDate: string): number {
  const nowDate = new Date()
  const otherDate = new Date(isoDate)
  const millisecDiff = nowDate.getTime() - otherDate.getTime()
  return Math.floor(millisecDiff / (1000 * 60 * 60 * 24))
}
