
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'
import { BillingView } from '@/models/billing'
import { wait } from '@/helpers/vue-wait'

import SwitchToAnnualPopup from './SwitchToAnnualPopup.vue'
import PlanDate from './BillingDataPaid.PlanDate.vue'
import { PlanChangeFailedError } from '@/services/cordova.purchase.types'

@Options({
  components: {
    PlanDate,
    SwitchToAnnualPopup,
  },
})
export default class PlanInfo extends Vue {
  @Prop() protected billing!: BillingView
  private error: string | null = null

  switchAnnual(): void {
    ;(this.$refs.popupAnnual as any).show()
  }

  async switchAnnualDirectly(): Promise<void> {
    this.error = null
    try {
      wait.start(this, 'Subscribing')
      await this.billing.subscribeTo(this.billing.annualPlan)
      this.$emit('subscribed')
      wait.end(this, 'Subscribing')
    } catch (error) {
      if (error instanceof PlanChangeFailedError) {
        this.error = error.message
      }
      wait.end(this, 'Subscribing')
    }
  }

  async reactivate(): Promise<void> {
    try {
      wait.start(this, 'Subscribing')
      await this.billing.subscribeTo(this.billing.plan)
      this.$emit('subscribed')
      wait.end(this, 'Subscribing')
    } catch (error) {
      wait.end(this, 'Subscribing')
    }
  }
}
