import bannerAbout from '@/assets.app/images/banner-about.png'
import bookPlaceholder from '@/assets.app/images/book-placeholder.png'

export const lazyLoadConfig = {
  preLoad: 1.3,
  error: bannerAbout,
  loading: bookPlaceholder,
  // Unused, configuration example:
  // error: "https://media.shortform.com/images/banner-about.png",
  // loading: 'https://media.shortform.com/images/book-placeholder.png',
  attempt: 1,
  lazyComponent: true,
  // Enable observer to fix the images not loading issue we had in #2173.
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
  observer: true,
  // The "cors" property was added to vue-lazyload in this PR -
  // https://github.com/hilongjw/vue-lazyload/pull/402
  // BUT this change was not released! In the npm version of the library,
  // there is no "cors" property, so the setting below does not do anything.
  // cors: 'anonymous',
  //
  // Since "cors" not yet available, we use "adapter" to add "crossorign"
  // property manually, see https://github.com/hilongjw/vue-lazyload#element-adapter
  adapter: {
    loaded(loadedInfo: any): void {
      // We use lazy load only for book covers, so we just always set
      // crossorigin here.
      // NOTE: It does not work (see #2741) if we use the `el.crossOrigin`
      // property (with captial `O`).
      loadedInfo.el.crossorigin = 'anonymous'
    },
  },
}
