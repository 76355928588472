import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon-btn__content" }
const _hoisted_2 = {
  key: 0,
  class: "icon-btn__tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.elementTag), {
    class: _normalizeClass(_ctx.classes),
    disabled: _ctx.elementTag === 'button' && _ctx.disabled,
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleTooltip(true))),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleTooltip(false)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass(["iconfont", _ctx.icon])
        }, null, 2),
        _createVNode(_Transition, { name: _ctx.computedTransition }, {
          default: _withCtx(() => [
            (_ctx.tooltipActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.tooltip), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name"])
      ])
    ]),
    _: 1
  }, 40, ["class", "disabled"]))
}