/**
 * Model for the Search View that's added as a part of Design Project 4
 */

import { DocListItem, DocType } from './interfaces'
import { backend } from '@/services/backend'
import { performanceOptimization14000ExperimentVariant } from '@/services/ab'

/**
 * We only want to load the needed amount of docs from the backend for the search page blocks.
 */
export const SEARCH_RESULT_LIMIT = 100

/**
 * Contains search logic that is shared between views that extends the DocsView.
 *
 * Performs a search on the initial docs (books/articles/tags) added in the constructor.
 */
export class Search {
  private _search: string = ''
  private _docType: DocType
  public searchTerm: string | null = null
  private _initialDocs: DocListItem[]
  private _abortController: AbortController | null = null

  constructor(initialDocs: DocListItem[], docType: DocType) {
    this._docType = docType
    this._initialDocs = initialDocs
  }

  public async search(): Promise<DocListItem[]> {
    // If `searchTerm` is `null`, that means that there's
    // no active search.
    if (this.searchTerm === null) {
      return this._initialDocs
    }

    // Abort the previous request before sending a new one.
    this._abortController?.abort()

    // Create a new AbortController so that we can cancel
    // this request if a new query is made before it's fulfilled.
    let signal: AbortSignal | undefined = undefined
    if (performanceOptimization14000ExperimentVariant()) {
      this._abortController = new AbortController()
      signal = this._abortController.signal
    }

    // If there's no search query, we display the most popular docs.
    // For this, we use the regular listing endpoint instead of
    // the search endpoint.
    if (
      this.searchTerm === '' &&
      performanceOptimization14000ExperimentVariant()
    ) {
      if (this._docType === 'book') {
        const response = await backend.getBooks(signal)
        return response.data
      }

      if (this._docType === 'article') {
        const response = await backend.getArticles(signal)
        return response.data
      }
    }

    this._search = this.searchTerm
    const docs = await backend.getDocSearch(
      this._docType,
      this._search,
      SEARCH_RESULT_LIMIT,
      undefined,
      signal,
    )

    return docs.data
  }

  public get isSearchActive(): boolean {
    return this._search !== ''
  }
}
