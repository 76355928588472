
import { Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { CollectionListItem } from '@/models/interfaces'
import { CollectionsView } from '@/models/collection'

export default class CollectionCard extends Vue {
  @Prop() private doc!: CollectionListItem
  @Prop() private view!: CollectionsView
}
