/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-empty-function */
'use strict'

import { MAX_NOTE_LENGTH } from '@/init/settings'
import { Promise } from 'es6-promise'

import { Widget } from './widget'
import { gettext as _t, addEventHandler, removeEventHandler } from './util'

// The id returns an identifier unique within this session
const id = (function () {
  let counter: any
  counter = -1
  return function () {
    return (counter += 1)
  }
})()

// The preventEventDefault prevents an event's default, but handles the condition
// that the event is null or doesn't have a preventDefault function.
function preventEventDefault(event: Event) {
  if (
    typeof event !== 'undefined' &&
    event !== null &&
    typeof event.preventDefault === 'function'
  ) {
    event.preventDefault()
  }
}

// The dragTracker is a function which allows a callback to track changes made to
// the position of a draggable "handle" element.
//
// handle - A DOM element to make draggable
// callback - Callback function
//
// Callback arguments:
//
// delta - An Object with two properties, "x" and "y", denoting the amount the
//         mouse has moved since the last (tracked) call.
//
// Callback returns: Boolean indicating whether to track the last movement. If
// the movement is not tracked, then the amount the mouse has moved will be
// accumulated and passed to the next mousemove event.
//
export const dragTracker = function dragTracker(
  handle: any,
  callback: any,
): Record<string, unknown> {
  let lastPos: any = null,
    throttled = false

  // Event handler for mousemove
  function mouseMove(e: MouseEvent) {
    if (throttled || lastPos === null) {
      return
    }

    const delta = {
      y: e.pageY - lastPos.top,
      x: e.pageX - lastPos.left,
    }

    let trackLastMove = true
    // The callback function can return false to indicate that the tracker
    // shouldn't keep updating the last position. This can be used to
    // implement "walls" beyond which (for example) resizing has no effect.
    if (typeof callback === 'function') {
      trackLastMove = callback(delta)
    }

    if (trackLastMove !== false) {
      lastPos = {
        top: e.pageY,
        left: e.pageX,
      }
    }

    // Throttle repeated mousemove events
    throttled = true
    setTimeout(function () {
      throttled = false
    }, 1000 / 60)
  }

  // Event handler for mouseup
  function mouseUp() {
    lastPos = null
    handle.ownerDocument.removeEventListener('mouseup', mouseUp)
    handle.ownerDocument.removeEventListener('mousemove', mouseMove)
  }

  // Event handler for mousedown -- starts drag tracking
  function mouseDown(e: MouseEvent) {
    if (e.target !== handle) {
      return
    }

    lastPos = {
      top: e.pageY,
      left: e.pageX,
    }

    handle.ownerDocument.addEventListener('mouseup', mouseUp)
    handle.ownerDocument.addEventListener('mousemove', mouseMove)

    e.preventDefault()
  }

  // Public: turn off drag tracking for this dragTracker object.
  function destroy() {
    handle.removeEventListener('mousedown', mouseDown)
  }

  handle.addEventListener('mousedown', mouseDown)

  return { destroy: destroy }
}

// The resizer is a component that uses a dragTracker under the hood to track the
// dragging of a handle element, using that motion to resize another element.
//
// element - DOM Element to resize
// handle - DOM Element to use as a resize handle
// options - Object of options.
//
// Available options:
//
// invertedY - If this option is defined as a function, and that function
//             returns a truthy value, the vertical sense of the drag will be
//             inverted. Useful if the drag handle is at the bottom of the
//             element, and so dragging down means "grow the element"
export const resizer = function resizer(
  element: HTMLElement,
  handle: HTMLElement,
  options: any,
): Record<string, unknown> {
  if (typeof options === 'undefined' || options === null) {
    options = {}
  }

  // Translate the delta supplied by dragTracker into a delta that takes
  // account of the invertedY callback if defined.
  function translate(delta: any) {
    let directionY = -1

    if (typeof options.invertedY === 'function' && options.invertedY()) {
      directionY = 1
    }

    return {
      x: delta.x,
      y: delta.y * directionY,
    }
  }

  // Callback for dragTracker
  function resize(delta: any) {
    const height = element.offsetHeight,
      width = element.offsetWidth,
      translated = translate(delta)

    if (Math.abs(translated.x) > 0) {
      element.style.width = `${width + translated.x}px`
    }
    if (Math.abs(translated.y) > 0) {
      element.style.height = `${height + translated.y}px`
    }

    // Did the element dimensions actually change? If not, then we've
    // reached the minimum size, and we shouldn't track
    const didChange =
      element.offsetHeight !== height || element.offsetWidth !== width
    return didChange
  }

  // We return the dragTracker object in order to expose its methods.
  return dragTracker(handle, resize)
}

// The mover is a component that uses a dragTracker under the hood to track the
// dragging of a handle element, using that motion to move another element.
//
// element - DOM Element to move
// handle - DOM Element to use as a move handle
//
export const mover = function mover(
  element: HTMLElement,
  handle: HTMLElement,
): Record<string, unknown> {
  function move(delta: any) {
    const currentTop = parseInt(element.style.top, 10)
    const currentLeft = parseInt(element.style.left, 10)

    element.style.top = `${currentTop + delta.y}px`
    element.style.left = `${currentLeft + delta.x}px`
  }

  // We return the dragTracker object in order to expose its methods.
  return dragTracker(handle, move)
}

// Public: Creates an element for editing annotations.
export const Editor = Widget.extend({
  // Public: Creates an instance of the Editor object.
  //
  // options - An Object literal containing options.
  //
  // Examples
  //
  //   # Creates a new editor, adds a custom field and
  //   # loads an annotation for editing.
  //   editor = new Annotator.Editor
  //   editor.addField({
  //     label: 'My custom input field',
  //     type:  'textarea'
  //     load:  someLoadCallback
  //     save:  someSaveCallback
  //   })
  //   editor.load(annotation)
  //
  // Returns a new Editor instance.
  constructor: function (options: any) {
    Widget.call(this, options)

    this.fields = []
    this.annotation = {}

    if (this.options.defaultFields) {
      this.addField({
        type: 'textarea',
        label: _t('Note') + '\u2026',
        load: function (field: any, annotation: any) {
          field.querySelector('textarea').value = annotation.text || ''
        },
        submit: function (field: any, annotation: any) {
          annotation.text = field.querySelector('textarea').value
        },
      })
    }

    const self = this

    addEventHandler(
      this.element,
      'submit',
      (e) => {
        self._onFormSubmit(e)
      },
      'form',
    )
    addEventHandler(
      this.element,
      'mouseup',
      () => {
        // Remove the selection.
        // Note: we need to do this in mouseup handler to prevent the
        // textselector from finding the selection and triggering an Adder
        // to be shown.
        // See addEventHandler(this.document.body, 'mouseup') handler in TextSelector.
        const selection = window.getSelection()
        if (selection) {
          selection.removeAllRanges()
        }
      },
      'button',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onSaveClick(e)
      },
      '.annotator-save',
    )
    addEventHandler(
      this.element,
      'click',
      (e) => {
        self._onCancelClick(e)
      },
      '.annotator-cancel',
    )
    addEventHandler(
      this.element,
      'mouseover',
      () => {
        self._onCancelMouseover()
      },
      '.annotator-cancel',
    )
    addEventHandler(
      this.element,
      'keydown',
      (e) => {
        self._onTextareaKeydown(e)
      },
      'textarea',
    )
    addEventHandler(
      this.element,
      'input',
      (e) => {
        self._onTextareaInput(e)
      },
      'textarea',
    )
    addEventHandler(
      this.element,
      'change',
      (e) => {
        self._onTextareaInput(e)
      },
      'textarea',
    )
  },

  destroy: function (): void {
    removeEventHandler(this.element, 'submit')
    removeEventHandler(this.element, 'click')
    removeEventHandler(this.element, 'mouseover')
    removeEventHandler(this.element, 'keydown')
    removeEventHandler(this.element, 'input')
    removeEventHandler(this.element, 'change')
    removeEventHandler(this.element, 'mouseup')

    Widget.prototype.destroy.call(this)
  },

  // Public: Show the editor.
  //
  // position - An Object specifying the position in which to show the editor
  //
  // Examples
  //
  //   editor.show()
  //   editor.hide()
  //   editor.show({top: 100, bottom: 80, middle: 50})
  //
  // Returns nothing.
  show: function (position: any): void {
    this.element
      .querySelector('.annotator-save')
      .classList.add(this.classes.focus)

    Widget.prototype.show.call(this, position)

    // Save selection.
    const selection = window.getSelection()
    if (selection) {
      const range = selection.rangeCount ? selection.getRangeAt(0) : null

      // Give main textarea focus.
      this.element.querySelector('input, textarea').focus()

      // Restore selection.
      if (range) {
        selection.addRange(range)
      }
    }

    this._setupDraggables()
  },

  // Public: Load an annotation into the editor and display it.
  //
  // annotation - An annotation Object to display for editing.
  // position - An Object specifying the position in which to show the editor
  //
  // Returns a Promise that is resolved when the editor is submitted, or
  // rejected if editing is cancelled.
  load: function (annotation: any, position: any): Promise<any> {
    this.annotation = annotation

    for (let i = 0, len = this.fields.length; i < len; i++) {
      const field = this.fields[i]
      field.load(field.element, this.annotation)
    }
    this._updateWordCount()
    const self = this
    return new Promise(function (resolve: any, reject: any) {
      self.dfd = { resolve: resolve, reject: reject }
      self.show(position)
    })
  },

  // Public: Submits the editor and saves any changes made to the annotation.
  //
  // Returns nothing.
  submit: function (): void {
    for (let i = 0, len = this.fields.length; i < len; i++) {
      const field = this.fields[i]
      field.submit(field.element, this.annotation)
    }

    if (typeof this.dfd !== 'undefined' && this.dfd !== null) {
      this.dfd.resolve()
    }
    this.hide()
  },

  // Public: Cancels the editing process, discarding any edits made to the
  // annotation.
  //
  // Returns itself.
  cancel: function () {
    if (typeof this.dfd !== 'undefined' && this.dfd !== null) {
      this.dfd.reject('editing cancelled')
    }
    this.hide()
  },

  // Public: Adds an addional form field to the editor. Callbacks can be
  // provided to update the view and anotations on load and submission.
  //
  // options - An options Object. Options are as follows:
  //           id     - A unique id for the form element will also be set as
  //                    the "for" attrubute of a label if there is one.
  //                    (default: "annotator-field-{number}")
  //           type   - Input type String. One of "input", "textarea",
  //                    "checkbox", "select" (default: "input")
  //           label  - Label to display either in a label Element or as
  //                    placeholder text depending on the type. (default: "")
  //           load   - Callback Function called when the editor is loaded
  //                    with a new annotation. Receives the field <li> element
  //                    and the annotation to be loaded.
  //           submit - Callback Function called when the editor is submitted.
  //                    Receives the field <li> element and the annotation to
  //                    be updated.
  //
  // Examples
  //
  //   # Add a new input element.
  //   editor.addField({
  //     label: "Tags",
  //
  //     # This is called when the editor is loaded use it to update your
  //     # input.
  //     load: (field, annotation) ->
  //       # Do something with the annotation.
  //       value = getTagString(annotation.tags)
  //       $(field).find('input').val(value)
  //
  //     # This is called when the editor is submitted use it to retrieve data
  //     # from your input and save it to the annotation.
  //     submit: (field, annotation) ->
  //       value = $(field).find('input').val()
  //       annotation.tags = getTagsFromString(value)
  //   })
  //
  //   # Add a new checkbox element.
  //   editor.addField({
  //     type: 'checkbox',
  //     id: 'annotator-field-my-checkbox',
  //     label: 'Allow anyone to see this annotation',
  //     load: (field, annotation) ->
  //       # Check what state of input should be.
  //       if checked
  //         $(field).find('input').attr('checked', 'checked')
  //       else
  //         $(field).find('input').removeAttr('checked')

  //     submit: (field, annotation) ->
  //       checked = $(field).find('input').is(':checked')
  //       # Do something.
  //   })
  //
  // Returns the created <li> Element.
  addField: function (options: any): Element {
    const defaultOptions = {
      id: 'annotator-field-' + id(),
      type: 'input',
      label: '',
      load: function () {},
      submit: function () {},
    }
    const field = { ...defaultOptions, ...options }

    let input = null
    const element = document.createElement('li')
    element.className = 'annotator-item'

    field.element = element

    if (field.type === 'textarea') {
      input = document.createElement('textarea')
    } else if (field.type === 'checkbox') {
      input = document.createElement('input')
      input.type = 'checkbox'
    } else if (field.type === 'input') {
      input = document.createElement('input')
    } else if (field.type === 'select') {
      input = document.createElement('select')
    }

    if (input) {
      element.appendChild(input)
      input.id = field.id
      // @ts-ignore
      input.placeholder = field.label
    }

    if (field.type === 'checkbox') {
      element.classList.add('annotator-checkbox')

      const label = document.createElement('label')
      label.setAttribute('for', field.id)
      label.innerHTML = field.label

      element.appendChild(label)
    }

    this.element.querySelector('ul').appendChild(element)
    this.fields.push(field)

    return field.element
  },

  checkOrientation: function (): any {
    Widget.prototype.checkOrientation.call(this)
    return this
  },

  // Event callback: called when a user clicks the editor form (by pressing
  // return, for example).
  //
  // Returns nothing
  _onFormSubmit: function (event: Event): void {
    preventEventDefault(event)
    this.submit()
  },

  // Event callback: called when a user clicks the editor's save button.
  //
  // Returns nothing
  _onSaveClick: function (event: Event): void {
    preventEventDefault(event)
    this.submit()
  },

  // Event callback: called when a user clicks the editor's cancel button.
  //
  // Returns nothing
  _onCancelClick: function (event: Event): void {
    preventEventDefault(event)
    this.cancel()
  },

  // Event callback: called when a user mouses over the editor's cancel
  // button.
  //
  // Returns nothing
  _onCancelMouseover: function (): void {
    const element = this.element.getElementsByClassName(this.classes.focus)[0]
    if (element) {
      element.classList.remove(this.classes.focus)
    }
  },

  // Event callback: listens for the following special keypresses.
  // - escape: Hides the editor
  // - enter:  Submits the editor
  //
  // event - A keydown Event object.
  //
  // Returns nothing
  _onTextareaKeydown: function (event: KeyboardEvent): void {
    this._updateWordCount()
    if (event.which === 27) {
      // "Escape" key => abort.
      this.cancel()
    } else if (event.which === 13 && !event.shiftKey) {
      // If "return" was pressed without the shift key, we're done.
      this.submit()
    }
  },

  // Event callback: listens for every input event and content change
  //
  // Returns nothing
  _onTextareaInput: function (): void {
    this._updateWordCount()
  },

  // Update the word count
  //
  // Returns nothing
  _updateWordCount: function (): void {
    const content = this.element.querySelector('textarea').value
    const wordcount = this.element.querySelector('.annotator-wordcount')
    const saveButton = this.element.querySelector('.annotator-save')
    if (content.length > MAX_NOTE_LENGTH) {
      saveButton.disabled = true
      wordcount.textContent = `Note exceeded character limit. (${content.length}/${MAX_NOTE_LENGTH})`
    } else {
      saveButton.disabled = false
      wordcount.textContent = ''
    }
  },

  // Sets up mouse events for resizing and dragging the editor window.
  //
  // Returns nothing.
  _setupDraggables: function (): void {
    if (typeof this._resizer !== 'undefined' && this._resizer !== null) {
      this._resizer.destroy()
    }
    if (typeof this._mover !== 'undefined' && this._mover !== null) {
      this._mover.destroy()
    }

    this.element.querySelector('.annotator-resize')?.remove()

    // Find the annotator item containging textarea.
    const cornerItem = this.element.querySelector('textarea').parentNode
    if (cornerItem) {
      const resizeHandle = document.createElement('span')
      resizeHandle.className = 'annotator-resize'
      cornerItem.appendChild(resizeHandle)
    }

    const controls = this.element.querySelector('.annotator-controls')
    const textarea = this.element.querySelector('textarea')
    const resizeHandle = this.element.querySelector('.annotator-resize')
    const self = this

    this._resizer = resizer(textarea, resizeHandle, {
      invertedY: function () {
        return self.element.classList.contains(self.classes.invert.y)
      },
    })

    this._mover = mover(this.element, controls)
  },
})

// Classes to toggle state.
Editor.classes = {
  hide: 'annotator-hide',
  focus: 'annotator-focus',
}

Editor.template = document.createElement('div')
// HTML template for this.element.
Editor.template.classList.add(
  'annotator-outer',
  'annotator-editor',
  'annotator-hide',
)
Editor.template.innerHTML = `
<form class="annotator-widget">
  <ul class="annotator-listing"></ul>
  <p class="annotator-wordcount text-danger"></p>
  <hr class="annotator-divider">
  <div class="annotator-controls">
    <button class="annotator-save annotator-focus btn btn-shortform">${_t(
      'Save',
    )}</button>
    <button class="annotator-cancel btn btn-white">${_t('Cancel')}</button>
  </div>
</form>
`

// Configuration options
Editor.options = {
  // Add the default field(s) to the editor.
  defaultFields: true,
}

// The standalone is a module that uses the Editor to display an editor widget
// allowing the user to provide a note (and other data) before an annotation is
// created or updated.
export function standalone(options: any): Record<string, unknown> {
  const widget = new Editor(options)

  return {
    destroy: function () {
      widget.destroy()
    },
    beforeAnnotationCreated: function (annotation: any) {
      return widget.load(annotation)
    },
    beforeAnnotationUpdated: function (annotation: any) {
      return widget.load(annotation)
    },
  }
}
