
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { DocsView } from '@/models/doc'
import { DocsSortMobileView as DocsSortView } from '@/models/docs.sort'
import { assertNotNull } from '@/helpers/typing'
import { clickOutside } from '@/helpers/directives'
import { emitter } from '@/services/mitt'

@Options({
  directives: {
    clickOutside: clickOutside,
  },
})
export default class DocsSort extends Vue {
  @Prop() private view!: DocsView
  private sortView: DocsSortView | null = null

  async beforeMount(): Promise<void> {
    this.sortView = new DocsSortView(this)
    emitter.on('openSortPopup', () => {
      this.show()
    })
  }

  hide(): void {
    assertNotNull(this.sortView).hide()
  }

  show(): void {
    assertNotNull(this.sortView).show()
  }
}
