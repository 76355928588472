
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import vLoading from 'vue-wait/src/components/v-wait.vue'
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue'
import RootPageLayout from '@/app/components/RootPageLayout.vue'
import { backend } from '@/services/backend'
import {
  BookOfTheDayView,
  BooksView,
  showReviewPrompt,
  trustpilotReviewPrompt,
} from '@/models/book'
import { ArticlesView } from '@/models/article'
import { wait } from '@/helpers/vue-wait'

import ContinueReadingBooksBlock from '@/app/components/listing/ContinueReadingBooksBlock.vue'
import NewReleasesBooksBlock from '@/app/components/listing/NewReleasesBooksBlock.vue'
import FreeBooksBlock from '@/app/components/listing/FreeBooksBlock.vue'
import BooksBlock from '@/app/components/listing/BooksBlock.vue'
import ArticlesBlock from '@/app/components/listing/ArticlesBlock.vue'
import { DocSortOption } from '@/models/doc'
import { PageType, createListingPage } from '@/models/docs.listing'
import { auth } from '@/services/auth'
import { RouteLocationNormalized } from 'vue-router'
import { CollectionsView } from '@/models/collection'
import CollectionsBlock from '@/app/components/collections/CollectionsBlock.vue'
import BookOfTheDayBlock from '@/app/components/listing/BookOfTheDayBlock.vue'
import { ExcerptView } from '@/models/excerpt'
import ExcerptBlock from '@/app/components/excerpt/ExcerptBlock.vue'

@Options({
  components: {
    RootPageLayout,
    'v-wait': vLoading,
    LoadingSpinner,
    ContinueReadingBooksBlock,
    NewReleasesBooksBlock,
    FreeBooksBlock,
    BooksBlock,
    ArticlesBlock,
    CollectionsBlock,
    BookOfTheDayBlock,
    ExcerptBlock,
  },
})
export default class Discover extends Vue {
  articlesView: ArticlesView | null = null
  freeBooksView: BooksView | null = null
  newReleasesView: BooksView | null = null
  continueReadingView: BooksView | null = null
  popularBooksView: BooksView | null = null
  recommendedBooksView: BooksView | null = null
  collectionsView: CollectionsView | null = null
  bookOfTheDayView: BookOfTheDayView | null = null
  excerptView: ExcerptView | null = null
  isFreeUser: boolean = false
  failedBlocks: number = 0

  async beforeRouteEnter(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): Promise<void> {
    if (from.name === 'book.page') {
      to.query = { from: from.name }
    }
  }

  async beforeMount(): Promise<void> {
    this.isFreeUser = auth.isFreePlan()

    await this.getBooksAndArticles()
  }

  async mounted(): Promise<void> {
    // Check if we need to show the App Review Prompt
    // The trigger is going back to Home from reading a book. (#2812)
    if (this.$router.currentRoute.value.query.from === 'book.page') {
      await showReviewPrompt()
      await trustpilotReviewPrompt()
    }
  }

  async getBooksAndArticles(): Promise<void> {
    wait.start(this, 'Loading Discover')

    const blocksSetupFunctions = [
      this.setupBooks,
      this.setupArticles,
      this.setupBookCollections,
      this.setupExcerpt,
      this.setupBookOfTheDay,
    ]

    const setupPromises = blocksSetupFunctions.map((setupFunction) =>
      setupFunction(),
    )
    const setupResults = await Promise.allSettled(setupPromises)
    setupResults.forEach((result) => {
      if (result.status === 'rejected') {
        this.failedBlocks++
      }
    })

    wait.end(this, 'Loading Discover')

    if (this.failedBlocks >= blocksSetupFunctions.length) {
      // If all blocks fail, display an error
      wait.start(this, 'Error Loading Discover')
    }
  }

  async setupBooks(): Promise<void> {
    const [booksData, userDocs] = await Promise.all([
      backend.getBooksWithRecommendations(),
      backend.getUserDocs(),
    ])

    if (booksData) {
      const showCtaBlock = false
      const showFreeAtTheTop = false
      this.continueReadingView = await BooksView.createView(
        {
          data: userDocs.filter((doc) => doc.doc_type === 'book'),
        },
        undefined, // The defaultSort
        createListingPage(PageType.reading),
        showCtaBlock,
        showFreeAtTheTop,
      )
      this.freeBooksView = await BooksView.createView(
        booksData,
        DocSortOption.first_published_at,
        createListingPage(PageType.free), // The listingPage
        showCtaBlock,
        showFreeAtTheTop,
      )
      this.newReleasesView = await BooksView.createView(
        booksData,
        DocSortOption.first_published_at,
        undefined, // The listingPage
        showCtaBlock,
        showFreeAtTheTop,
      )
      this.popularBooksView = await BooksView.createView(
        booksData,
        DocSortOption.popular,
        undefined, // The listingPage
        showCtaBlock,
        showFreeAtTheTop,
      )
      this.recommendedBooksView = await BooksView.createView(
        booksData,
        DocSortOption.recommended,
        undefined, // The listingPage
        false, // The showCtaBlock
        false, // The showFreeAtTheTop
      )
    }
  }

  async setupArticles(): Promise<void> {
    const articlesData = await backend.getHomeArticles()
    if (articlesData) {
      this.articlesView = await ArticlesView.createView(articlesData)
    }
  }

  async setupBookCollections(): Promise<void> {
    const collectionsData = await backend.getBookCollections()
    if (collectionsData) {
      this.collectionsView = new CollectionsView(collectionsData)
    }
  }

  async setupExcerpt(): Promise<void> {
    const excerptData = await backend.getExcerpt()
    if (excerptData) {
      this.excerptView = new ExcerptView(excerptData)
    }
  }

  async setupBookOfTheDay(): Promise<void> {
    const bookOfTheDayData = await backend.getBookOfTheDay()
    if (bookOfTheDayData) {
      this.bookOfTheDayView = await BookOfTheDayView.createView(
        bookOfTheDayData,
      )
    }
  }

  get hasRecommendations(): boolean {
    return !!this.recommendedBooksView?.hasRecommendations
  }
}
