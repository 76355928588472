import { STRIPE_PUBLIC_KEY } from '@/init/settings'
import { platform } from '@/services/platform'

export async function initStripe(): Promise<void> {
  const isIOS = await platform.isNativeIOSApp()
  if (isIOS) {
    // Stripe.js freezes the native iOS app after visting the
    // external link.
    // See: https://github.com/shortformhq/main/issues/13217
    // and https://github.com/stripe/stripe-js/issues/614
    return
  }

  // Note: we can not use normal import, `import { loadStripe } from '@stripe/stripe-js'`
  // as it still triggers the same problem.
  // Dynamic import (https://webpack.js.org/api/module-methods/#dynamic-expressions-in-import) works:
  const stripe = await import('@stripe/stripe-js')
  await stripe.loadStripe(STRIPE_PUBLIC_KEY)
}
