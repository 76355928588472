import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'
import { User, NewUser, ResetData } from '@/models/interfaces.auth'
import { convertError } from '@/models/error'
import { ValidationState } from '@/helpers/form'
import { afterSignup, afterLogin } from './logic'
import { appendCurrentParamsToPath } from '@/helpers/url'

const EMPTY_USER = { email: '', password: '' }

export type OnAuthStart = () => void
export type OnAuthSuccess = (nextPage: Record<string, unknown> | null) => void
export type OnAuthError = () => void

/*
 * Authentication view interface.
 *
 * We use this interface to unify the interaction with GoogleAuthView and
 * FacebookAuthView.
 */
export interface AuthView {
  // Responsible for showing errors in UI
  validation: ValidationState

  onStart: OnAuthStart
  onSuccess: OnAuthSuccess
  onError: OnAuthError
}

export class SignupView implements AuthView {
  public validation: ValidationState = new ValidationState()

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  public model: NewUser = Object.assign({}, EMPTY_USER)

  constructor(
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  _authError(error: any): void {
    const err = convertError(error)
    const redirectPath = makeRedirectPath(this.model, '/app/login')

    if (err.isValidationError()) {
      if (err.getOne('email') === 'Email has already been taken') {
        const message = `This email is already taken. Try <a href="${redirectPath}">logging in</a> instead?`
        this.validation.showMessage('email', message)
      } else {
        this.validation.show(err)
      }
    } else if (
      err.isAuthError() &&
      err.code() === 'credentials_wrong_has_social_auth'
    ) {
      const socialLogins = err.social_logins()
      const message = `We've found an account linked to this email. Try <a href="${redirectPath}">logging in</a> via ${socialLogins.join(
        ' or ',
      )} like last time.`
      this.validation.showMessage('email', message)
    } else {
      this.validation.showMessage('email', 'Unexpected signup error')
      Sentry.captureException(error)
    }
  }

  async start(): Promise<void> {
    try {
      this.onStart()

      const authData = await backend.register(this.model)

      // Update returned user with full name from signup form
      if (this.model.full_name) {
        authData.user.full_name = this.model.full_name
      }

      this.validation.reset()
      const nextPage = await afterSignup(authData)
      this.onSuccess(nextPage)
    } catch (error: any) {
      this._authError(error)
      this.onError()
    }
  }
}

export class LoginView implements AuthView {
  public validation: ValidationState = new ValidationState()

  public onStart: OnAuthStart
  public onSuccess: OnAuthSuccess
  public onError: OnAuthError

  public model: User = Object.assign({}, EMPTY_USER)

  constructor(
    onStart: OnAuthStart,
    onSuccess: OnAuthSuccess,
    onError: OnAuthError,
  ) {
    this.onStart = onStart
    this.onSuccess = onSuccess
    this.onError = onError
  }

  _authError(error: any): void {
    const err = convertError(error)
    if (err.isAuthError()) {
      const redirectPath = makeRedirectPath(this.model, '/app/password/forgot')

      // Default message
      let message = `Email or password is invalid. <a href="${redirectPath}">Forgot password?</a>`

      // User has logged in with social account before
      if (err.code() === 'credentials_wrong_has_social_auth') {
        const socialLogins = err.social_logins()
        message = `We don't have a password saved for this account. Try logging in via ${socialLogins.join(
          ' or ',
        )} like last time, or <b><a class="error" href="${redirectPath}">reset your password</a></b>`
      }

      this.validation.showMessage('email', message)
    } else if (err.isValidationError()) {
      this.validation.show(err)
    } else {
      // Unexpected error
      Sentry.captureException(error)
      this.validation.showMessage('email', 'Unexpected login error')
    }
  }

  async start(): Promise<void> {
    try {
      this.onStart()
      const authData = await backend.login(this.model)
      this.validation.reset()
      const nextPage = await afterLogin(authData)
      this.onSuccess(nextPage)
    } catch (error: any) {
      this._authError(error)
      this.onError()
    }
  }
}

const EMPTY_DATA = { reset_token: '', password: '' }

export class PasswordResetView {
  public model: ResetData = Object.assign({}, EMPTY_DATA)
  public validation: ValidationState = new ValidationState()
  public state: string = 'form'

  initResetToken(token: string): void {
    this.model.reset_token = token
    if (!this.model.reset_token) {
      this.state = 'notoken'
    }
  }

  async submitForm(): Promise<void> {
    try {
      await backend.resetPassword(this.model)
      this.state = 'success'
    } catch (error: any) {
      this.state = 'form'
      this.validation.showErrors(error)
    }
  }
}

export class PasswordForgotView {
  public model: User = Object.assign({}, EMPTY_USER)
  public validation: ValidationState = new ValidationState()
  public state: string = 'form'

  async submitForm(): Promise<void> {
    try {
      await backend.forgotPassword(this.model)
      this.validation.reset()
      this.state = 'success'
    } catch (error: any) {
      this.state = 'form'

      const err = convertError(error)
      if (
        err.isValidationError() &&
        err.getOne('email') === 'This email does not exist in our database'
      ) {
        const redirectPath = makeRedirectPath(this.model, '/app/signup')
        const message = `This email isn\'t registered at Shortform. Try <a href="${redirectPath}">signing up</a> instead? <br/> Alternatively, you might have signed up with a different email. Search your email for emails from Shortform - the email we send to may be the one you signed up with.`
        err.setOne('email', message)
        this.state = 'form'
        this.validation.show(err)
      } else {
        this.validation.showErrors(error)
      }
    }
  }
}

function makeRedirectPath(model: NewUser | User, path: string): string {
  const emailQuery = model.email ? { email: model.email } : undefined

  return appendCurrentParamsToPath(path, emailQuery)
}
