import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__group" }
const _hoisted_2 = { class: "form__group" }
const _hoisted_3 = { class: "form__group" }
const _hoisted_4 = {
  key: 0,
  type: "submit",
  class: "btns btns--large"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UsernameInput = _resolveComponent("UsernameInput")!
  const _component_InputWithContextMenu = _resolveComponent("InputWithContextMenu")!
  const _component_FormError = _resolveComponent("FormError")!
  const _component_button_spinner = _resolveComponent("button-spinner")!
  const _component_v_wait = _resolveComponent("v-wait")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_wait = _resolveDirective("wait")!

  return (_openBlock(), _createElementBlock("form", {
    role: "form",
    class: "form comment-form",
    novalidate: "",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_UsernameInput, {
        ref: "username",
        model: _ctx.model,
        validation: _ctx.validation
      }, null, 8, ["model", "validation"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_InputWithContextMenu, {
        is: "textarea",
        ref: "input",
        modelValue: _ctx.model.text,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.text) = $event)),
        rows: "2",
        type: "text",
        class: _normalizeClass(["form-control mb-4", _ctx.validation.stateClass('text')]),
        placeholder: "Write a comment..."
      }, null, 8, ["modelValue", "class"]),
      _createVNode(_component_FormError, {
        errors: _ctx.validation.errors,
        field: "text"
      }, null, 8, ["errors"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.doc.isCommunityEOC)
        ? _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_4, [
            _createVNode(_component_v_wait, { for: "Posting Comment" }, {
              waiting: _withCtx(() => [
                _createTextVNode(" Posting "),
                _createVNode(_component_button_spinner)
              ]),
              default: _withCtx(() => [
                _createTextVNode(" Post Comment ")
              ]),
              _: 1
            })
          ])), [
            [_directive_wait, 'Posting Comment', "disabled"]
          ])
        : (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: { name: 'billing' },
            class: "btns btns--large eoc-button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Subscribe to Post Comment ")
            ]),
            _: 1
          }))
    ])
  ], 32))
}