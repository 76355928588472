/*
 * Helper class for scheduling a function call with timer.
 */
export class Timer {
  // Timeout, msec
  private _timeout: number
  // Callback function
  private _callback: TimerHandler
  // Timer ID returned by setTimeout () method
  private _id: number = 0

  constructor(timeout: number, callback: TimerHandler) {
    this._timeout = timeout
    this._callback = callback
  }

  /*
   * Start timer to run callback function once after the interval of time.
   */
  start(): void {
    if (this._id) {
      throw new Error('Timer already started')
    }
    this._id = setTimeout(this._callback, this._timeout)
  }

  /*
   * Stop timer and cancel the function execution.
   */
  clear(): void {
    if (this._id) {
      clearTimeout(this._id)
      this._id = 0
    }
  }
}

/**
 * Helper to auto-fire the callback if it was not
 * called `count` times within the specified `timeout` microseconds.
 *
 * Default `count` is 1, default `timeout` is 1000 sec.
 *
 * This is useful for google analytics / mixpanel
 * callbacks: we often trigger the navigation to another
 * page after registring the event (such as on login,
 * on registration, etc).
 * In this case the event call might be canceled before
 * it completes, so we want to use the callback to make
 * sure the event was registered before we navigate to
 * another page.
 * On the other hand, if something goes wrong and the
 * callback is not called at all, it may break the UI,
 * like the user is not being navigated to the application
 * after login.
 *
 * This is the scenario for this helper: we wrap the callback
 * into `callbackWithTimeout` and it will be fired automatically
 * after the `timeout` unless it was called explicitely within
 * this timeout.
 *
 * The `callbackCount` parameter is useful when we want to wait for
 * multiple events being tracked (like mixpanel + GA event).
 * In this case we don't want to wait for 1 second for each
 * event, as it may result in 2 seconds delay: instead we
 * wait for both events to complete withn the same `timeout`
 * interval.
 */
type WrapperFn = (fromTimeout?: boolean) => void

export function callbackWithTimeout(
  callback: () => void,
  callbackCount?: number,
  timeout?: number,
): WrapperFn {
  const count = callbackCount || 1
  let called = false
  let calledCount = 0

  function wrapper(fromTimeout?: boolean): void {
    calledCount += 1
    if (calledCount < count && fromTimeout !== true) {
      // If we didn't reach the desired call count yet and
      // this is not triggered from the timeout, exit.
      return
    }

    if (!called) {
      called = true
      if (fromTimeout === true) {
        // We don't have any other way here, Sentry would require
        // another delay to send the event to the server, so we
        // log to console.
        /* eslint-disable no-console */
        // console.log('callbackWithTimeout: timeout!', calledCount)
        /* eslint-enable no-console */
      }
      callback()
    }
  }
  setTimeout(() => wrapper(true), timeout || 1000)
  return wrapper
}

/**
 * Wrapper for `callbackWithTimeout` to use with async / await:
 *
 * // Wait for 2 events tracked within 1 second.
 * await waitWithTimeout((done) => {
 *   Mixpanel.trackBilling(new_plan, billing, done)
 *   GTAG.subscribe(auth.getUser(), new_plan, billing, done)
 * }, 2, 1000)
 *
 */
export async function waitWithTimeout(
  waitFor: (done: WrapperFn) => void,
  count?: number,
  timeout?: number,
): Promise<void> {
  return new Promise((resolve) => {
    // Call the function we wait for and resolve either when
    // the callback was called specified `count` times or after the `timeout`.
    waitFor(callbackWithTimeout(resolve, count, timeout))
  })
}

export async function waitTimer(time: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, time))
}
