
import { nextTick } from 'vue'
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

import { setFocus } from '@/helpers/form'
import { formatDate } from '@/helpers/time'
import { backend } from '@/services/backend'
import { ContentView, AnnotationView } from '@/models/doc'
import { DocView } from '@/models/doc'
import IconButton from '@/app/components/button/IconButton.vue'
import DeleteHighlightPopup from './DeleteHighlightPopup.vue'

@Options({
  components: {
    InputWithContextMenu,
    IconButton,
    DeleteHighlightPopup,
  },
})
export default class Highlight extends Vue {
  @Prop() private doc!: DocView
  @Prop() private content!: ContentView
  @Prop() private highlight!: AnnotationView
  @Prop({ default: false }) private showFrom!: boolean

  private highlightEditId: string = ''
  private copyTooltip: string = 'Copy'

  copyHighlight(highlight: AnnotationView): void {
    this.doc.copyHighlight(highlight)
    this.copyTooltip = 'Copied!'
    setTimeout(() => {
      this.copyTooltip = 'Copy'
    }, 2000)
  }

  showDeletePopup(highlight: AnnotationView): void {
    ;(this.$refs.deletePopup as any).show(highlight)
  }

  deleteHighlight(highlight: AnnotationView): void {
    this.doc.deleteHighlight(highlight)
  }

  toggleEdit(highlight: AnnotationView): void {
    if (this.highlightEditId) {
      this.highlightEditId = ''
    } else {
      this.highlightEditId = highlight.id
      nextTick(() => {
        // Focus the edtior field after switching to edit mode.
        setFocus((this.$refs.input as InputWithContextMenu).$el as HTMLElement)
      })
    }
  }

  saveEdit(highlight: AnnotationView): void {
    // Save highlight note changes.
    backend.updateHighlightNote(highlight)
    this.toggleEdit(highlight)
  }

  getDate(): string {
    if (this.highlight.created) {
      return formatDate(this.highlight.created)
    }
    return ''
  }
}
