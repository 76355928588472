
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import vLoading from 'vue-wait/src/components/v-wait.vue'

import RootPageLayout from '@/app/components/RootPageLayout.vue'
import ContentWrapper from '@/components/ui/ContentWrapper.vue'
import FormError from '@/components/ui/FormError.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import InputWithContextMenu from '@/app/components/InputWithContextMenu.vue'

import { APP_VERSION } from '@/init/settings'
import { ValidationState, setInputFocusStart } from '@/helpers/form'
import { wait } from '@/helpers/vue-wait'
import { auth } from '@/services/auth'
import { offline } from '@/services/offline'
import { backend } from '@/services/backend'
import { platform } from '@/services/platform'
import { processDevCommands } from '@/services/commands'
import { promptForRating } from '@/services/cordova.apprate'

@Options({
  components: {
    RootPageLayout,
    ContentWrapper,
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    InputWithContextMenu,
  },
})
export default class Help extends Vue {
  validation: ValidationState = new ValidationState()
  redirectDelay: number = 2000

  sentAlert: boolean = false
  subject: string = ''
  body: string = ''

  async mounted(): Promise<void> {
    if (offline.isOnline) {
      const input: HTMLInputElement = (this.$refs.subjectInput as any)
        .$el as HTMLInputElement
      setInputFocusStart(input)

      const info = await platform.deviceInfo()
      if (window.__UNIT_TESTING !== true) {
        console.log(info)
      }
    }
    processDevCommands()
  }

  get email(): string | null {
    return auth.getEmail()
  }

  get appVersion(): string {
    return APP_VERSION
  }

  async send(): Promise<void> {
    try {
      wait.start(this, 'Sending')
      await backend.sendSupportRequest(this.subject, this.body)
      this.validation.reset()

      // Show subscribed alert and redirect to books page after delay.
      this.sentAlert = true

      setTimeout(() => {
        wait.end(this, 'Sending')
        this.$router.push({ name: 'home' })
      }, this.redirectDelay)
    } catch (error) {
      this.validation.showErrors(error)
      wait.end(this, 'Sending')
    }
  }

  async prompt(): Promise<void> {
    await promptForRating()
  }

  get isOffline(): boolean {
    return offline.isOffline
  }
}
