
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'

import vLoading from 'vue-wait/src/components/v-wait.vue'
import ButtonSpinner from '@/components/ui/ButtonSpinner.vue'
import FormError from '@/components/ui/FormError.vue'
import ReadwiseSettings from '@/app/components/export/ReadwiseSettings.vue'
import NotionSettings from '@/app/components/export/NotionSettings.vue'
import IntegrationButton from '@/app/components/export/IntegrationButton.vue'

import readwiseLogo from '@/assets.v2/images/readwise-logo.svg'
import readwiseLogoDark from '@/assets.v2/images/readwise-logo-dark.svg'
import notionLogo from '@/assets.v2/images/notion-logo.svg'
import notionLogoDark from '@/assets.v2/images/notion-logo-dark.svg'

@Options({
  components: {
    FormError,
    'v-wait': vLoading,
    ButtonSpinner,
    IntegrationButton,
    ReadwiseSettings,
    NotionSettings,
  },
})
export default class ExportSettings extends Vue {
  selectedIntegration: string | null = null
  isReadwiseEnabled: boolean = false
  isNotionEnabled: boolean = false

  readwiseLogo = readwiseLogo
  readwiseLogoDark = readwiseLogoDark
  notionLogo = notionLogo
  notionLogoDark = notionLogoDark

  clickIntegrationButton(integration: string): void {
    this.selectedIntegration = integration
  }

  setReadwiseEnabled(value: boolean): void {
    this.isReadwiseEnabled = value
  }

  setNotionEnabled(value: boolean): void {
    this.isNotionEnabled = value
  }
}
