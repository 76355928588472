
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import { ListDescriptionView } from '@/models/docs.list'

@Options({
  components: {},
})
export default class ListingPageDescription extends Vue {
  @Prop({}) view!: ListDescriptionView

  get toggleFullTextLabel(): string {
    return this.view.showFullText ? 'Show less' : 'Show more'
  }
}
