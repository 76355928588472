
import { Vue } from 'vue-class-component'
import { Options } from 'vue-property-decorator'
import { RouteLocationNormalized } from 'vue-router'
import { QuestionnaireView } from '@/models/questionnaire'
import { QuestionnaireStep } from '@/models/questionnaire_types'
import QuestionnaireProgressDots from '@/app/components/questionnaire/ProgressDots.vue'
import { backend } from '@/services/backend'
import { Sentry } from '@/services/sentry'

@Options({
  components: {
    QuestionnaireProgressDots,
  },
})
export default class Questionnaire extends Vue {
  view: QuestionnaireView | null = null
  questionnaireStep: typeof QuestionnaireStep = QuestionnaireStep

  async beforeRouteUpdate(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any,
  ): Promise<void> {
    // Called when the route that renders this component has changed,
    // but this component is reused in the new route.
    if (this.view) {
      this.view.getPageFromRoute(to)
    }
    next()
  }

  async beforeMount(): Promise<void> {
    this.view = new QuestionnaireView()
    this.view.init()

    // Preload cache data while the user goes through the questionnaire.
    // We run it without `await` on purpose so that it doesn't block the
    // loading of the rest of the page.
    this.preloadCacheData()
  }

  async preloadCacheData(): Promise<void> {
    try {
      await Promise.all([
        backend.getBooks(),
        backend.getArticles(),
        backend.getPodcasts(),
        backend.getBookCollections(),
        backend.getRecommendations(),
        backend.getExcerpt(),
        backend.getBookOfTheDay(),
        backend.getUserDocs(),
        backend.getBookTags(),
      ])
    } catch (error: any) {
      Sentry.captureException(error)
    }
  }
}
